<script lang="ts" setup>
import { computed } from 'vue';
import { colorFromString } from './util';
import { useCurrentUser } from '@/api/auth';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const userInfo = useCurrentUser();

const color = computed(() => {
  return colorFromString(userInfo.value?.id ?? t('anonymous'));
});
</script>

<template>
  <div class="mb-1 flex h-10 flex-row">
    <div
      class="text-xml mr-2.5 flex h-10 w-10 items-center justify-center rounded-full text-gnist-white"
      :style="`background: ${color}`"
    >
      {{ (userInfo?.firstName ?? t('anonymous')).charAt(0) }}
    </div>
    <div class="text-p2 flex items-center">
      {{ `${userInfo?.firstName} ${userInfo?.lastName}` }}
    </div>
  </div>
</template>
