<script setup lang="ts">
import ButtonComponent from '@/components/ButtonComponent.vue';
import MaterialIcon from '@/components/MaterialIcon.vue';
import ModalComponent from '@/components/ModalComponent.vue';
import TextInput from '@/components/forms/InputText.vue';

import type { ArrayItem } from '@/api/types';
import { defaultBlocksRouteName } from '@/utilities/routeUtils';
import {
  commentDocownerDiscard,
  useUnassessedComments,
} from '@/api/commentoClient';
import { computed, ref, watch } from 'vue';
import { formatDate } from '@/utilities/formatDate';
import { socialPageRouteId } from '@/config';
import {
  stringCompareCaseInsensitive,
  undefinedCompare,
} from '@/utilities/order';
import { translateStringOrLocale } from '@/i18n';
import { useI18n } from 'vue-i18n';
import {
  getChatPage,
  getChatPageParamFromId,
  suggestionPageIdPrefix,
  suggestionPageParam,
  type CommentAssessment,
  type chatPageId,
} from '@/api/commentoTypes';
import type { RouteLocationNamedRaw } from 'vue-router';
import { SocialPageType } from '@/enums/SocialPageType';
import TableComponent from './TableComponent.vue';

const props = defineProps<{
  label: string;
  includeAllBlocks: boolean;
  getNonBlockComments: boolean;
  hideTypePrefix?: boolean;
  useModeratorEndpoint?: boolean;
}>();

const emit = defineEmits<{
  (e: 'loaded', value: NonNullable<typeof discussions.value>): void;
}>();

const { t } = useI18n();

const { result: discussions, loaded } = useUnassessedComments(
  'DocumentOwner',
  props.includeAllBlocks,
  props.getNonBlockComments,
);
watch(loaded, () => emit('loaded', discussions.value ?? []));

const comments = computed(() =>
  discussions.value?.map((d) => {
    const comment: CommentAssessment & {
      discussion:
        | {
            name: string;
            typePrefix: string;
            to: RouteLocationNamedRaw;
            type: SocialPageType;
          }
        | undefined;
    } = { ...d, discussion: undefined };
    if (d.block) {
      comment.discussion = {
        name: translateStringOrLocale(d.block.name).value,
        typePrefix: 'social.documents',
        to: {
          name: socialPageRouteId,
          params: {
            pageId: defaultBlocksRouteName,
            blockId: d.block.blockId,
          },
        },
        type: SocialPageType.BlockPage,
      };
    } else if (d.suggestion) {
      comment.discussion = {
        name: translateStringOrLocale(d.suggestion.title).value,
        typePrefix: 'social.suggestions',
        to: {
          name: socialPageRouteId + suggestionPageParam,
          params: {
            pageId: suggestionPageParam,
            discussionId: suggestionPageIdPrefix + d.suggestion.id,
          },
        },
        type: SocialPageType.SuggestionPage,
      };
    } else if (d.pagePath) {
      const chatpage = getChatPage(
        getChatPageParamFromId(d.pagePath as chatPageId),
      );
      comment.discussion = {
        name: t(chatpage.chatPageName),
        typePrefix: 'social.forum',
        to: {
          name: socialPageRouteId,
          params: {
            pageId: chatpage.id,
            blockId: 'comment', // Hack to match correct route when linking directly to a comment for a chatPage
          },
        },
        type: SocialPageType.ChatPage,
      };
    }
    if (!comment.discussion?.to.params) return comment;
    comment.discussion.to.params.commentId = d.commentHex;
    return comment;
  }),
);

type CommentColumnKey = keyof ArrayItem<typeof comments.value>;
type CommentActionKey = 'NoAction' | 'Respond';
const commentColumns: (CommentColumnKey | CommentActionKey)[] = [
  'block',
  'date',
  'user',
  'html',
  'Respond',
  'NoAction',
];
const reason = ref<string>('');
const discard = ref<{ hex: string } | null>(null);
</script>

<template>
  <section class="gnist-page-width py-8">
    <h2 class="mb-4">
      {{ t(label) }}
    </h2>
    <TableComponent
      sixe="table-xs"
      column-header-class="max-w-20 md:max-w-full"
      :rows="comments"
      show-spinner
      i18n-key="admin.management.commenttable"
      :get-key="(comment) => comment.commentHex"
      :columns="commentColumns as CommentColumnKey[]"
      :sort-fields="['discussion']"
      :comparers="{
        discussion: undefinedCompare((a, b) =>
          stringCompareCaseInsensitive(a.name ?? '', b.name ?? ''),
        ),
      }"
    >
      <template #columnHeader="{ item: comment }">
        <RouterLink
          v-if="comment.discussion"
          :to="comment.discussion.to"
          class="break-words font-normal underline"
        >
          <MaterialIcon class="no-underline" aria-hidden="true">
            tag
          </MaterialIcon>
          <template v-if="!hideTypePrefix">
            {{ t(comment.discussion.typePrefix) }}:
          </template>
          {{ comment.discussion.name }}
        </RouterLink>
      </template>
      <template #columns="{ item: comment }">
        <template v-if="comment.discussion">
          <td class="whitespace-nowrap">
            {{ formatDate(comment.date) }}
          </td>
          <td class="whitespace-nowrap">
            {{ comment.user.name }}
          </td>
          <td>
            <!-- eslint-disable vue/no-v-html -->
            <div v-html="comment.html" />
          </td>
          <td>
            <RouterLink
              class="underline"
              :to="{
                ...comment.discussion.to,
                query: {
                  edit: `${true}`,
                },
              }"
              target="_blank"
              @click="
                discussions =
                  discussions?.filter(
                    (d) => d.commentHex !== comment.commentHex,
                  ) ?? null
              "
            >
              <ButtonComponent
                type="primary"
                :text="t('admin.management.docownerReply')"
              />
            </RouterLink>
          </td>
          <td>
            <ButtonComponent
              :text="t('admin.management.commenttable.NoAction')"
              @click="() => (discard = { hex: comment.commentHex })"
            />
          </td>
        </template>
      </template>
    </TableComponent>
    <ModalComponent
      :show-modal="discard !== null"
      :title="t('admin.management.docownerDiscard')"
      @close="() => (discard = null)"
      @handle-click="
        async () => {
          const discardHex = discard?.hex;
          if (!discardHex) return;
          await commentDocownerDiscard(
            { commentHex: discardHex },
            useModeratorEndpoint ? 'moderator-discard' : 'docowner-discard',
            reason,
          );
          discard = null;
          if (!discussions) return;
          discussions = discussions.filter((d) => d.commentHex !== discardHex);
        }
      "
    >
      <template #default>
        <TextInput
          v-model="reason"
          label="social.moderator.reasonLabel"
          multiline
        />
      </template>
    </ModalComponent>
  </section>
</template>
