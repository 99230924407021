<script setup lang="ts">
import ButtonComponent from './ButtonComponent.vue';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps<{ notes?: string }>();
const emit = defineEmits<{
  (e: 'addNote', note: string): void;
}>();

const favoriteNote = ref(props.notes);
const canSave = computed(() => favoriteNote.value !== props.notes);
const noteId = `note-${Math.random()}`;
</script>

<template>
  <form
    class="flex flex-col gap-4 bg-gnist-gray-light-light p-4"
    @submit.prevent="emit('addNote', favoriteNote ?? '')"
  >
    <label :for="noteId" class="block">
      {{ t('favorite.addNote') }}
    </label>
    <textarea
      :id="noteId"
      v-model="favoriteNote"
      :name="noteId"
      class="block h-40 w-full border p-4"
    />
    <div class="self-end">
      <ButtonComponent
        :text="t('profile.save')"
        type="primary"
        :disabled="!canSave"
        :submit="true"
      />
    </div>
  </form>
</template>
