import type { BlockImage, LayoutItem, Translated } from '@/api/types';
import type { supported_locale } from '@/config';
import {
  attachSource,
  setBlockLocaleValue,
  setBlockLocaleValueInObject,
  translateBlockValue,
  type TranslatedWithSource,
} from '@/i18n';
import type { FieldInfo } from '@/views/BlockContentEditorView.vue';

export function translateLayoutItem(
  blockValue: LayoutItem,
  locale: supported_locale,
  addFallbackInfo = false,
): TranslatedWithSource<LayoutItem> {
  const translatedItem: Translated<LayoutItem> = {
    ...blockValue,
    markdown:
      blockValue.markdown !== undefined
        ? translateBlockValue(
            blockValue.markdown,
            locale,
            'markdown',
            addFallbackInfo,
          )
        : undefined,
    image:
      blockValue.image !== undefined
        ? translateBlockValue(
            blockValue.image,
            locale,
            'image',
            addFallbackInfo,
          )
        : undefined,
    children: blockValue.children?.map(
      (child): Translated<LayoutItem> =>
        translateLayoutItem(child, locale, addFallbackInfo),
    ),
  };
  return attachSource(translatedItem, blockValue);
}
export function setLayoutItemBlockLocaleValues(
  newValue: Translated<LayoutItem>,
  source: LayoutItem | undefined,
  locale: supported_locale,
): LayoutItem {
  return {
    ...newValue,
    markdown:
      newValue.markdown !== undefined
        ? setBlockLocaleValue(
            newValue.markdown,
            source?.markdown,
            locale,
            'markdown',
          )
        : undefined,
    image:
      newValue.image !== undefined
        ? setBlockLocaleValueInObject(
            newValue.image as TranslatedWithSource<BlockImage>,
            'image',
            locale,
          )
        : undefined,
    children: newValue.children?.map((child) => {
      const source = (child as TranslatedWithSource<LayoutItem>)['_source'];
      delete (child as { _source?: unknown })['_source'];
      return setLayoutItemBlockLocaleValues(child, source, locale);
    }),
  } as LayoutItem;
}
export function getLayoutItemLocaleUpdates(field: LayoutItem): FieldInfo[] {
  if (field.children) {
    return field.children.map((c) => getLayoutItemLocaleUpdates(c)).flat();
  } else if (field.markdown) {
    return [{ field: field.markdown, schemaType: 'markdown' }];
  } else {
    return [];
  }
}
