<script setup lang="ts">
import ApimSchemaViewer from '@/components/ApimSchemaViewer.vue';
import JsonCodeBlock from '@/components/JsonCodeBlock.vue';
import BlockContentTab from '@/components/BlockContentTab.vue';
import { useI18n } from 'vue-i18n';
import type { ApiSchema } from '@/api/types';
import { useAttrs } from 'vue';

const { t } = useI18n();

defineProps<{
  groupName: string;
  schema: ApiSchema | null | undefined;
  example: string | null | undefined;
}>();

defineOptions({
  inheritAttrs: false,
});
const attrs = useAttrs();
</script>

<template>
  <div v-if="schema && example" role="tablist" class="tabs tabs-lifted">
    <BlockContentTab
      v-if="schema"
      :group-name="groupName"
      :header="`${t('block.responseSchema')}`"
      initial-checked
      header-type="none"
      v-bind="attrs"
    >
      <ApimSchemaViewer :schema="schema" />
    </BlockContentTab>
    <BlockContentTab
      v-if="example"
      :group-name="groupName"
      :header="`${t('block.example')}`"
      header-type="none"
      v-bind="attrs"
    >
      <JsonCodeBlock :content="JSON.parse(example)" />
    </BlockContentTab>
  </div>
  <template v-else>
    <ApimSchemaViewer v-if="schema" :schema="schema" />
    <JsonCodeBlock v-if="example" :content="JSON.parse(example)" />
  </template>
</template>
