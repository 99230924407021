<script setup lang="ts">
import ContentPage from '../components/ContentPage.vue';
import SubNav from '@/components/SubNav.vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
</script>

<template>
  <ContentPage :title="t('analytics.title')">
    <div class="flex w-full flex-col items-center">
      <SubNav
        :menu="[
          { routeName: 'viewCount', label: 'analytics.siteUsage' },
          { routeName: 'apiM', label: 'analytics.apiM' },
          { routeName: 'siteContent', label: 'analytics.siteContent' },
        ]"
      />
      <RouterView />
    </div>
  </ContentPage>
</template>
<style></style>
