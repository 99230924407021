<script setup lang="ts">
import { useAttrs } from 'vue';
import { md } from '@/utilities/markdown/MarkdownIt';
import { getMdEnv } from '@/utilities/markdown/utilities';
import OptionalDiv from '@/components/OptionalDiv.vue';
import type { Schema } from '@/api/types';
import type { IBlobUrlInput } from '@/api/blob';

const props = defineProps<{
  schema?: Schema;
  value: string;
  /** Use "public" if images are not stored as blobs (requires absolute url's for all content that would have been blobs) */
  blobLocation: IBlobUrlInput;
  /** Set this to remove href attribute from links */
  removeLinkHref?: boolean;
}>();

/** Since we have a top level header outside markdown, we change all headers down one level */
const fixHeaders = (md: string) => {
  return md
    .replace(/^##### /gm, '###### ')
    .replace(/^#### /gm, '##### ')
    .replace(/^### /gm, '#### ')
    .replace(/^## /gm, '### ')
    .replace(/^# /gm, '## ');
};
const fixExtensions = (md: string) => {
  return md.replace(/(:::)([^:\n]*\[[^:\n]*])(:::)/g, '$1$2\n$3');
};

function renderMd(mdValue: string) {
  let renderedMd = md.render(
    fixHeaders(fixExtensions(mdValue)),
    getMdEnv(props.blobLocation),
  );
  if (props.removeLinkHref) {
    renderedMd = renderedMd.replace(/(<a[^>]*)( href="[^"]*")/g, '$1');
  }
  return renderedMd;
}

defineOptions({
  inheritAttrs: false,
});
const attrs = useAttrs();
</script>

<template>
  <OptionalDiv v-if="props.value.trim()" :show="schema?.showTitle">
    <h1 v-if="schema?.showTitle">{{ schema.title }}</h1>
    <!-- eslint-disable-next-line vue/no-v-html -->
    <div v-bind="attrs" class="html-render" v-html="renderMd(props.value)" />
  </OptionalDiv>
</template>
