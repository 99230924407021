<script setup lang="ts">
import { computed, ref } from 'vue';
import type { Tag, TagGroup } from '@/api/types';
import { useCategoriesLazy, useCategoryHomePageLink } from '@/api/category';
import { useTagGroup, useTags } from '@/api/tags';
import { watch } from 'vue';
import TagRenderer from './TagRenderer.vue';
import { getBlocksRouteLink } from '@/utilities/routeUtils';
import type { RouteLocation } from 'vue-router';
import { useRouter } from 'vue-router';
import ButtonComponent from './ButtonComponent.vue';
import { useI18n } from 'vue-i18n';

const props = defineProps<{
  group: TagGroup['id'] | 'categories';
  list?: number[];
  maxItems?: number;
  showAllTags?: boolean;
  showGroupLabel?: boolean;
  size?: 'badge-xs' | 'badge-sm' | 'badge-md' | 'badge-lg';
}>();

const { t } = useI18n();

const tagGroupId = computed(() =>
  props.group === 'categories' ? undefined : props.group,
);
const { result: tagGroup } = useTagGroup(tagGroupId);
const { result: tags, init: loadTags } = useTags(tagGroupId, true);
const { result: categories, init: loadCategories } = useCategoriesLazy(true);
watch(
  () => props.group,
  () => {
    if (props.group === 'categories') loadCategories();
    else loadTags();
  },
  { immediate: true },
);

const router = useRouter();
const _items = computed(
  ():
    | (Pick<Tag, 'text' | 'color' | 'tagGroups' | 'primaryTagGroupId'> & {
        link: RouteLocation;
      })[]
    | undefined => {
    if (props.group === 'categories') {
      return categories.value?.map((c) => ({
        text: c.name,
        color: c.color,
        link: useCategoryHomePageLink(c)!,
      }));
    } else {
      if (props.list) {
        return tags.value
          ?.filter((t) => props.list?.includes(t.id))
          ?.map((t) => ({
            text: t.text,
            tagGroups: t.tagGroups,
            primaryTagGroupId: t.primaryTagGroupId,
            link: router.resolve(
              getBlocksRouteLink(t.homePageId, { tags: t.id }),
            ),
          }));
      } else {
        return tags.value?.map((t) => ({
          text: t.text,
          link: router.resolve(
            getBlocksRouteLink(t.homePageId, { tags: t.id }),
          ),
        }));
      }
    }
  },
);
const showAllToggle = ref(false);
const items = computed(() =>
  props.showAllTags
    ? _items.value
    : showAllToggle.value
      ? _items.value
      : _items.value?.slice(0, props.maxItems),
);
</script>

<template>
  <div class="my-8 flex flex-col">
    <div class="grid grid-cols-1 gap-4 sm:grid-cols-5">
      <h3 v-if="showGroupLabel" class="ml-0 mt-0 text-sm sm:col-span-2">
        {{ tagGroup?.label.no }}
      </h3>
      <div
        class="flex flex-wrap gap-2"
        :class="showGroupLabel ? 'col-span-3' : 'col-span-4'"
      >
        <RouterLink v-for="item in items" :key="item.text.no" :to="item.link">
          <TagRenderer
            class="inline-flex"
            :tags="[item]"
            :size="size ? size : 'badge-lg'"
            :color="tagGroup?.color ?? item.color"
          />
        </RouterLink>
      </div>
    </div>
    <ButtonComponent
      v-if="!showAllTags && _items && maxItems && _items?.length > maxItems"
      :text="t(showAllToggle ? 'tagList.less' : 'tagList.more')"
      class="self-end"
      @click="showAllToggle = !showAllToggle"
    />
  </div>
</template>
