import {
  appVersion,
  apiUrl,
  notFoundRouteName,
  socialPageRouteId,
} from '@/config';
import { computed, ref, watch, type Ref, isRef } from 'vue';
import type { UserStatus, Notification, localeValue } from './types';
import { useCurrentUser } from './auth';
import { composeWithRefresh } from './composable';
import type { RouteLocationRaw } from 'vue-router';
import {
  getChatPage,
  type commentPageId,
  type chatPageParam,
  isChatPage,
  suggestionPageIdPrefix,
} from './commentoTypes';
import { SocialPageType, getCommentPageIdParam } from '@/enums/SocialPageType';

const statusUrl = apiUrl + '/status';

const userInfo = useCurrentUser();
const userStatus = ref<UserStatus>({
  isAuthenticated: false,
  tryRelogin: false,
  appVersion: '',
  notificationCount: 0,
});

async function fetchStatus() {
  try {
    const response = await fetch(statusUrl, {
      credentials: 'include',
    });

    if (response.ok && response.status === 200) {
      userStatus.value = await response.json();
    }
  } catch (e) {
    console.warn(`Error fetching status: ${e}`);
  }
}

// Fetch status when application loads and try to relogin if user didn't log out
(async () => {
  await fetchStatus();
  if (!userStatus.value.isAuthenticated && userStatus.value.tryRelogin) {
    window.location.assign(
      `${apiUrl}/auth/login?rd=${encodeURIComponent(
        window.location.href.replace(window.location.origin, ''),
      )}&silent=true`,
    );
  }
})();

console.info('App version:', appVersion);

// Start polling for user status
setInterval(async function () {
  await fetchStatus();
}, 1000 * 30); // 30 seconds

// Run callback when version mismatch detected between frontend and backend
export const useNewAppVersion = (callback: () => void) => {
  watch(userStatus, (status) => {
    if (status.appVersion && appVersion && status.appVersion !== appVersion) {
      callback();
    }
  });
};

// Run callback when user's session times out
export const useUserSessionTimeout = (callback: () => void) => {
  watch(userStatus, () => {
    if (userInfo.value && !userStatus.value.isAuthenticated) {
      userInfo.value = null;
      callback();
    }
  });
};

export const useNotificationCount = () =>
  computed(() => userStatus.value.notificationCount);
export const updateNotificationCount = () => fetchStatus();

export const useNotifications = (params?: Ref<NotificationQueryParams>) => {
  return composeWithRefresh(
    async () => getNotifications(params),
    () => [
      params?.value.includeRead,
      params?.value.pageSize,
      params?.value.page,
    ],
    false,
  );
};

type WithLink = {
  link: RouteLocationRaw;
  title: localeValue | string;
};
type NotificationWithLink = Omit<Notification, 'title'> & WithLink;
export type NotificationQueryParams = {
  includeRead?: boolean;
  pageSize?: number;
  page?: number;
};
export const getNotifications = async (
  params?: NotificationQueryParams | Ref<NotificationQueryParams>,
): Promise<NotificationWithLink[]> => {
  if (isRef(params)) params = params.value;
  let query = new URLSearchParams(params as Record<string, string>).toString(); // Typescript doesn't allow non-string values in URLSearchParams, but it works fine due to javascript type coercion
  if (query) query = `?${query}`;
  const response = await fetch(`${statusUrl}/notifications${query}`, {
    method: 'GET',
    credentials: 'include',
  });
  if (!response.ok) throw new Error('error.get_notifications_failed');
  const notifications: (Omit<Notification, 'title'> & Partial<WithLink>)[] =
    await response.json();
  notifications.map((n) => {
    if (
      n.type === 'CommentReply' ||
      n.type === 'CommentModeration' ||
      n.type === 'CommentDocumentOwnerRequest' ||
      n.type === 'SuggestionComment'
    ) {
      const [blockId, commentId] = n.pointer.split('#');
      const pageType = blockId.startsWith(suggestionPageIdPrefix)
        ? SocialPageType.SuggestionPage
        : isChatPage(blockId)
          ? SocialPageType.ChatPage
          : SocialPageType.BlockPage;
      if (pageType === SocialPageType.ChatPage) {
        n.title = getChatPage(
          getCommentPageIdParam(
            blockId as commentPageId,
            pageType,
          ) as chatPageParam,
        ).chatPageName;
      }
      n.link = {
        name: socialPageRouteId,
        params: {
          pageId: getCommentPageIdParam(blockId as commentPageId, pageType),
          blockId: pageType === SocialPageType.ChatPage ? 'comment' : blockId,
          commentId,
        },
      };
    } else if (n.type === 'News') {
      n.link = {
        name: 'news',
        params: { articleId: n.pointer },
      };
    } else if (n.type === 'PublishRequest') {
      n.link = { name: 'editor_management' };
    } else if (
      n.type === 'SuggestionStatusChanged' ||
      n.type === 'SuggestionEdited'
    ) {
      n.link = {
        name: 'suggestion_details',
        params: {
          suggestionId: n.pointer,
        },
      };
    } else if (n.type === 'DeniedFromOrg' || n.type === 'RemovedFromOrg') {
      n.link = { path: '/about_orgs' };
    } else if (n.type === 'AddedToOrg' || n.type === 'ApprovedToOrg') {
      n.link = { name: 'organization', params: { organizationId: n.pointer } };
    } else {
      console.error(`Missing link definition for ${n.type}`);
      n.link = { name: notFoundRouteName };
    }
  });
  return notifications as Required<NotificationWithLink>[];
};

export const updateNotification = async (
  notificationId: number | undefined,
  read: boolean,
) => {
  const response = await fetch(
    `${statusUrl}/notifications/${notificationId ?? 'markall'}?read=${read}`,
    {
      method: 'PUT',
      credentials: 'include',
    },
  );
  if (!response.ok) throw new Error('error.update_notification_failed');
};

export const deleteNotification = async (notificationId: number) => {
  const response = await fetch(`${statusUrl}/notifications/${notificationId}`, {
    method: 'DELETE',
    credentials: 'include',
  });
  if (!response.ok) throw new Error('error.delete_notification_failed');
};
