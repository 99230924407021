import {
  InlineContainerTypes,
  type MdExtensionType,
  type InlineContainerType,
  renderers,
  type MdExtensionAttributeType,
  type OnelineContainerType,
  OnelineContainerTypes,
  type LinkbasedExtensionType,
  linkbasedRenderers,
  LinkbasedExtensionTypes,
} from '@/utilities/markdown/GnistMarkdownExtension';
import type { InsertMdExtensionPayload as InsertMdExtensionPayload } from './CommandHelper';
import { getBlobUrl, type IBlobUrlInput } from '@/api/blob';
import type { Guid } from '@/api/types';

/** When saving a url pointing to something in blob-storage, the url is saved simply as the `Guid` of that file. */
function isBlobUrl(url: string): url is Guid {
  return !!url.match(
    /^([0-9a-fA-F]){8}-([0-9a-fA-F]){4}-([0-9a-fA-F]){4}-([0-9a-fA-F]){4}-([0-9a-fA-F]){12}$/,
  );
}

export type mdEnvironment = {
  url: string;
};
export function getMdEnv(
  blobLocation: IBlobUrlInput,
  urlRoot = '',
): mdEnvironment {
  return {
    url: urlRoot + getBlobUrl(blobLocation, '/'),
  };
}
export function calculateUrl(
  destination: string | null,
  env: mdEnvironment,
): string {
  destination ??= '';
  if (isBlobUrl(destination)) {
    return env.url + destination;
  } else {
    return destination;
  }
}

export function getGroupRe(
  marker: string,
  type: 'text' | 'number' | 'complexText' = 'text',
  groupName = marker,
): string {
  if (type === 'text')
    return `(?:(?:${marker}=)(?<${groupName}>[^\\s\\]]*)\\s?)?`;
  else if (type === 'number')
    return `(?:(?:${marker}=?)(?<${groupName}>\\d*)*\\s?)?`;
  else if (type === 'complexText')
    return `(?:(?:${marker}=")(?<${groupName}>[^"\\]]*)"\\s?)?`;
  else return '';
}

export type MdExtensionMode = 'inline' | 'oneline' | 'linkbased';

export type MdExtensionAttributes<
  M extends MdExtensionType,
  A extends MdExtensionAttributeType<M> = MdExtensionAttributeType<M>,
> = {
  mode: M;
  oldText: string;
  fullMatch?: string;
} & Record<M, A>;
export type MdExtensionAttributesPartial<
  M extends MdExtensionType = MdExtensionType,
  A extends MdExtensionAttributeType<M> = MdExtensionAttributeType<M>,
> = Partial<MdExtensionAttributes<M, A>> &
  Pick<MdExtensionAttributes<M, A>, 'mode' | 'oldText'>;

export function serializeMdExtensionAttributes(
  type: MdExtensionType,
  attributedata?: string[],
  /** Used by linkbased renderers to report the link-part of the serialized value */
  linkData?: { linkmode: LinkbasedExtensionType; link: string },
): InsertMdExtensionPayload {
  const id = type.substring(0, 1).toUpperCase() + type.substring(1);
  const attributes = attributedata
    ? attributedata.filter((i) => i).join(' ') // Remove empty strings
    : '';
  if (InlineContainerTypes.includes(type as InlineContainerType)) {
    return { serialized: `:::${id} ${attributes}:::`, mode: 'inline' };
  } else if (OnelineContainerTypes.includes(type as OnelineContainerType)) {
    return { serialized: `:::${id} [${attributes}]:::`, mode: 'oneline' };
  } else if (LinkbasedExtensionTypes.includes(type as LinkbasedExtensionType)) {
    return {
      serialized: `${linkData?.linkmode === 'image' ? '!' : ''}[${attributes}](${linkData?.link})`,
      mode: 'linkbased',
    };
  } else {
    throw new Error('Unknown container type');
  }
}

export function getInlineContainerAttributes<
  M extends InlineContainerType = InlineContainerType,
>(text: string): MdExtensionAttributes<M> | null {
  const inlineMatch = text.match(/:::(?<mode>\S+) ?(?<data>[^[[:\n]+):::/);
  if (!inlineMatch) return null;
  const { mode, data } = inlineMatch.groups ?? {};
  const modeKey = mode?.toLowerCase().trim();
  return {
    mode: modeKey as InlineContainerType,
    [modeKey]: { data },
    oldText: text,
    fullMatch: inlineMatch[0],
  } as MdExtensionAttributes<M>;
}

export function getLinkBasedExtensionAttributes<
  M extends LinkbasedExtensionType = LinkbasedExtensionType,
>(text: string, mode: M): MdExtensionAttributes<M> | null {
  const renderer = linkbasedRenderers[mode];
  const attrs = renderer.parse(text, renderer.re);
  if (attrs.failedMatch !== undefined) {
    console.error(
      `This should not happen: text that was selected with RegExp ${attrs.re} did not match that RegExp!`,
      { text, match: attrs.failedMatch },
    );
    return null;
  }
  return {
    mode,
    [mode]: attrs,
    oldText: text,
  } as MdExtensionAttributes<M>;
}

export function getOnelineContainerAttributes(
  text: string,
): MdExtensionAttributesPartial<OnelineContainerType> {
  const inlineMatch = text.match(/:::(?<mode>\S+) ?(?<data>[^[[:\n]+):::/);
  if (inlineMatch !== null) {
    throw new Error(
      'Found inline match in getContainerAttributes(), this should not happen',
    );
  }
  let modeMatch = text.match(/::::*(?<mode>[^:\s]*) \[.*?\]/);
  if (modeMatch === null) modeMatch = text.match(/::::*(?<mode>.*)$/);
  const modeKey = modeMatch?.groups?.['mode']
    ?.toLowerCase()
    .trim() as OnelineContainerType;
  const renderReTxts = renderers[modeKey]?.reList?.join('') ?? '';
  let reTxt = '::::*';
  reTxt += '(?<mode>[^:\\s]*) \\[';
  reTxt += renderReTxts;
  reTxt += '.*?\\]';
  const match = text.match(new RegExp(reTxt));
  Object.entries(match?.groups ?? {}).forEach(([key, value]) => {
    if (value === 'undefined' || value === 'null') {
      (match?.groups as Record<string, string | undefined>)[key] = undefined;
    }
  });
  const attrs = renderers[modeKey]?.parseRegexMatch?.(match?.groups);
  return {
    mode: modeKey,
    [modeKey]: attrs as MdExtensionAttributeType<OnelineContainerType>,
    oldText: text,
  } as MdExtensionAttributesPartial<OnelineContainerType>;
}

export function fixWidgetNoise(text: string) {
  return text.replace(/\$\$widget\S* (.*?)\$\$/g, '$1');
}
