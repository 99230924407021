<script setup lang="ts" generic="T">
import { throwOnError } from '@/api/composable';
import { useI18n } from 'vue-i18n';
import { useBlocks } from '@/api/blocks';
import { editBlockVersionsRouteId } from '@/config';
import { Status } from '@/api/types';
import { formatDate } from '@/utilities/formatDate';
import {
  stringCompareCaseInsensitive,
  undefinedCompare,
} from '@/utilities/order';
import { getStatusText } from './BlockStatusBar.vue';
import TableComponent from './TableComponent.vue';

const { t } = useI18n();

const props = defineProps<{
  headerlabel: string;
  type: 'MyDocuments' | 'Management';
}>();

const { result: blocks } = throwOnError(
  useBlocks({
    mode: 'adminview',
    forMyDocumentsList: props.type === 'MyDocuments',
    forManagementList: props.type === 'Management',
  }),
);
</script>
<template>
  <section class="gnist-page-width py-8">
    <h2 class="mb-4">
      {{ t(headerlabel) }}
    </h2>
    <TableComponent
      size="table-xs"
      column-header-class="max-w-[5rem] md:max-w-full"
      :rows="blocks"
      show-spinner
      i18n-key="admin.management.doctable"
      :get-key="(block) => block.blockId"
      :sort-fields="['name', 'category', 'ownerOrg']"
      :comparers="{
        ownerOrg: undefinedCompare((a, b) =>
          stringCompareCaseInsensitive(a.name, b.name),
        ),
      }"
      :columns="[
        'name',
        'category',
        'lastChanged',
        'lastChangedBy',
        'ownerOrg',
        'documentOwners',
        'audiences',
        'currentVersionNumber',
        'versions',
      ]"
    >
      <template #columnHeader="{ item: block }">
        <RouterLink
          :to="{
            name: editBlockVersionsRouteId,
            params: { blockId: block.blockId },
          }"
          class="break-words font-normal underline"
        >
          {{ block.name }}
        </RouterLink>
      </template>
      <template #columns="{ item: block }">
        <td class="whitespace-nowrap">
          {{ block.category?.name }}
        </td>
        <td class="whitespace-nowrap">
          {{ formatDate(block.lastChanged) }}
        </td>
        <td class="whitespace-nowrap">
          {{ block.lastChangedBy?.name }}
        </td>
        <td>
          <div v-if="!block.ownerOrg" class="alert alert-error p-0">&nbsp;</div>
          <template v-else> {{ block.ownerOrg?.name }}</template>
        </td>
        <td>
          <div
            v-if="(block.documentOwners?.length ?? 0) == 0"
            class="alert alert-error p-0"
          >
            &nbsp;
          </div>
          <template v-else>
            <span
              v-for="user in block.documentOwners"
              :key="user.name"
              class="block whitespace-nowrap"
            >
              {{ user.name }}
            </span>
          </template>
        </td>
        <td>
          <span
            v-for="audience in block.audiences"
            :key="audience"
            class="block whitespace-nowrap"
          >
            {{ t(`roleSelection.values.${audience}`) }}
          </span>
        </td>
        <td>
          {{ block.currentVersionNumber }}
        </td>
        <td>
          <span
            v-for="version in block.versions"
            :key="version.versionNumber"
            class="block whitespace-nowrap"
            :class="{
              'text-gnist-gray': version.status === Status.Archived,
              'text-gnist-green': version.status === Status.Draft,
              'text-gnist-sp-blue-light':
                version.status === Status.Published && version.isBacklog,
            }"
          >
            <span v-if="version.versionNumber === block.currentVersionNumber">
              &gt;
            </span>
            {{ version.versionNumber }} ({{
              t(getStatusText(version)) +
              (version.draftStatus
                ? ` / ${t(`block.draftStatus.${version.draftStatus}`)}`
                : '')
            }})
          </span>
        </td>
      </template>
    </TableComponent>
  </section>
</template>
