<script setup lang="ts" generic="T = string">
import type { SimpleLookupValue } from '@/api/types';
import TextInput from '@/components/forms/InputText.vue';
import { useNestedMV } from '@/utilities/useInternalState';
import { useI18n } from 'vue-i18n';

const props = defineProps<{
  modelValue: SimpleLookupValue<T>;
  i18nKey?: string;
  includeExtendedDescription?: boolean;
}>();
const emit = defineEmits<{
  (e: 'update:modelValue', value: typeof props.modelValue): void;
}>();

const { t } = useI18n();

const value = useNestedMV(props, (val) => emit('update:modelValue', val));

const noPrefix = t('language.values.no') + ' ';
const enPrefix = t('language.values.en') + ' ';
</script>

<template>
  <TextInput
    v-model="value.key as string | number"
    :label="`${i18nKey ?? 'masterData.simpleLookup'}.key`"
    :type="typeof value.key === 'number' ? 'number' : 'text'"
    required
  />
  <TextInput
    v-model="value.text.no"
    :label="noPrefix + t(`${i18nKey ?? 'masterData.simpleLookup'}.text`)"
    required
  />
  <TextInput
    v-model="value.text.en"
    :label="enPrefix + t(`${i18nKey ?? 'masterData.simpleLookup'}.text`)"
  />
  <TextInput
    v-if="includeExtendedDescription"
    v-model="value.extendedDescription.no"
    :label="
      noPrefix +
      t(`${i18nKey ?? 'masterData.simpleLookup'}.extendedDescription`)
    "
  />
  <TextInput
    v-if="includeExtendedDescription"
    v-model="value.extendedDescription.en"
    :label="
      enPrefix +
      t(`${i18nKey ?? 'masterData.simpleLookup'}.extendedDescription`)
    "
  />
  <slot />
</template>
