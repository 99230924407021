<script setup lang="ts" generic="T = string">
import type {
  FieldLabel,
  Link,
  localeValue,
  OnlyKeysWithValsOfType,
  PartialSome,
  RequiredSome,
  Translated,
} from '@/api/types';
import TextInput from '@/components/forms/InputText.vue';
import MarkdownInput from '@/components/forms/InputMarkdown.vue';
import Checkbox from '@/components/forms/InputCheckbox.vue';
import Dropdown from '@/components/forms/InputDropdown.vue';
import { useInternalState } from '@/utilities/useInternalState';
import { useI18n } from 'vue-i18n';
import { toDropdownItems } from './forms/InputDropdown.vue';
import { allFieldSets, getFieldSetByKey } from '@/api/field_labels';
import { computed, toRef } from 'vue';
import type { supported_locale } from '@/config';
import LinkInput from './forms/InputLink.vue';
import { groupBy } from '@/utilities/groupBy';

const props = defineProps<{
  modelValue: FieldLabel;
  allFields: FieldLabel[];
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', value: typeof props.modelValue): void;
}>();

const { t } = useI18n();

type InternalValType = RequiredSome<
  typeof props.modelValue,
  'tooltip' | 'placeholder' | 'description'
>;

function toInternalState(externalState: FieldLabel): InternalValType {
  return {
    ...externalState,
    tooltip: externalState.tooltip ?? { no: '' },
    placeholder: externalState.placeholder ?? { no: '' },
    description: externalState.description ?? { no: '' },
  };
}

const value = useInternalState<typeof props.modelValue, InternalValType>(
  toRef(props, 'modelValue'),
  toInternalState(props.modelValue),
  (val) => emit('update:modelValue', val),
  (newExtState) => toInternalState(newExtState),
  (newIntState) => {
    return {
      ...newIntState,
      tooltip: !newIntState.tooltip.no ? undefined : newIntState.tooltip,
      placeholder: !newIntState.placeholder.no
        ? undefined
        : newIntState.placeholder,
      description: !newIntState.description.no
        ? undefined
        : newIntState.description,
    };
  },
  true,
  true,
);

const groupedFields = computed(() =>
  groupBy(props.allFields, 'group', (item) => item.field),
);

const setOptions = toDropdownItems(
  Object.values(allFieldSets).filter((set) => {
    const usedFieldCount = groupedFields.value[set.group]?.length ?? 0;
    const availableFieldCount = Object.keys(set.fields).length;
    return (
      value.value.group === set.group || availableFieldCount > usedFieldCount
    );
  }),
  (set) => [
    set.group,
    t(`masterData.field_labels.groups.${set.group.replaceAll('.', '_')}`),
    set.group,
  ],
  true,
  undefined,
);

const existingGroupFields = computed(() =>
  props.allFields.filter((f) => f.group === value.value.group),
);

const fieldOptions = computed(() =>
  toDropdownItems(
    value.value.group
      ? Object.entries(getFieldSetByKey(value.value.group).fields)
          .map(([key, value]) => ({ key, value }))
          .filter(
            (f) =>
              f.key === value.value.field ||
              !existingGroupFields.value.find((e) => e.field === f.key),
          )
      : [],
    (item) => [item.key, `${item.value}.label`, item.key],
    true,
  ),
);

function setLocaleValue(
  key: keyof OnlyKeysWithValsOfType<InternalValType, localeValue>,
  locale: supported_locale,
  val: string,
) {
  if (!value.value[key]) {
    value.value[key] = { no: '' };
  }
  value.value[key][locale] = val;
}
const url = computed<PartialSome<Translated<Link>, 'text' | 'alt'>>({
  get() {
    return { url: value.value.link ?? '' };
  },
  set(newValue) {
    value.value.link = newValue.url ? newValue.url : undefined;
  },
});
</script>

<template>
  <div class="flex grid-cols-2 flex-col gap-4 md:grid">
    <Dropdown
      v-model="value.group"
      label="masterData.field_labels.group"
      :options="setOptions"
      :getkey="(t) => t ?? ''"
      allow-empty-value
      disable-empty-option
      :disabled="!!value.group"
    />
    <Dropdown
      v-model="value.field"
      label="masterData.field_labels.field"
      :options="fieldOptions"
      :getkey="(t) => t"
      allow-empty-value
      disable-empty-option
      :disabled="!!value.field"
      :class="{ invisible: !value.group }"
    />
    <div class="col-span-2">
      <Checkbox
        v-model="value.richTextDescription"
        label="masterData.field_labels.richTextDescription"
        direction="horizontal"
        mode="toggle"
      />
    </div>
    <div class="font-bold">
      {{ t('language.values.no') }}
    </div>
    <div class="font-bold">
      {{ t('language.values.en') }}
    </div>
    <TextInput
      v-model="value.label.no"
      label="masterData.field_labels.label.no"
      required
    />
    <TextInput v-model="value.label.en" label="&nbsp;" />
    <TextInput
      :model-value="value.tooltip?.no"
      label="masterData.field_labels.tooltip.no"
      @update:model-value="(val) => setLocaleValue('tooltip', 'no', val)"
    />
    <TextInput
      :model-value="value.tooltip?.en"
      label="&nbsp;"
      @update:model-value="(val) => setLocaleValue('tooltip', 'en', val)"
    />
    <TextInput
      :model-value="value.placeholder?.no"
      label="masterData.field_labels.placeholder.no"
      @update:model-value="(val) => setLocaleValue('placeholder', 'no', val)"
    />
    <TextInput
      :model-value="value.placeholder?.en"
      label="&nbsp;"
      @update:model-value="(val) => setLocaleValue('placeholder', 'en', val)"
    />
    <template v-if="!value.richTextDescription">
      <TextInput
        :model-value="value.description?.no"
        label="masterData.field_labels.description.no"
        @update:model-value="(val) => setLocaleValue('description', 'no', val)"
      />
      <TextInput
        :model-value="value.description?.en"
        label="&nbsp;"
        @update:model-value="(val) => setLocaleValue('description', 'en', val)"
      />
    </template>
    <template v-else>
      <MarkdownInput
        v-model="value.description.no"
        for-name="description_no"
        :label="`${t('masterData.field_labels.description.no')} (${t('language.values.no')})`"
        editor-height="20rem"
        required
        class="col-span-2"
      />
      <MarkdownInput
        v-model="value.description.en"
        for-name="description_en"
        :label="`${t('masterData.field_labels.description.no')} (${t('language.values.en')})`"
        editor-height="20rem"
        class="col-span-2"
      />
    </template>
    <div class="col-span-2">
      <LinkInput
        v-model="url"
        for-name="field_labels_link"
        label="masterData.field_labels.link"
        inline
        hide-text
        hide-description
        can-target-blocks
        cannot-target-external
      />
    </div>
  </div>
</template>
