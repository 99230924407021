import { apiUrl } from '@/config';
import type {
  AgreementReference,
  ApplicationService,
  ArchType,
  Company,
  CustomText,
  FieldLabel,
  InformationClass,
  SimpleLookupValue,
} from './types';
import { compose } from './composable';
import {
  addLocaleData,
  loadDynamicLocaleMessages,
  type LocaleMessageSet,
} from '@/i18n';
import type { Router } from 'vue-router';

const masterdataUrl = apiUrl + '/masterdata';

const mdEndpoints = [
  'application_services',
  'agreement_references',
  'companies',
  'infoclasses',
  'criticalities',
  'archtypes',
  'field_labels',
  'strings',
  'form_configurations',
  'default_alz_roles',
  'default_github_teams',
] as const;
export type MdEndpoint = (typeof mdEndpoints)[number];

export async function getMasterData<T>(
  endpoint: MdEndpoint,
  urlEnd?: string,
): Promise<T[]> {
  const response = await fetch(`${masterdataUrl}/${endpoint}${urlEnd ?? ''}`, {
    method: 'GET',
    credentials: 'include',
  });
  if (!response.ok) throw new Error(await response.text());
  return await response.json();
}
export async function getTexts<T>(
  endpoint: MdEndpoint,
  groups?: string | string[],
): Promise<T[]> {
  const arrGroups = !groups ? [] : Array.isArray(groups) ? groups : [groups];
  return (
    await Promise.all(
      arrGroups.map(async (group) => {
        const response = await fetch(`${masterdataUrl}/${endpoint}/${group}`, {
          method: 'GET',
          credentials: 'include',
        });
        if (!response.ok) throw new Error(await response.text());
        return await response.json();
      }),
    )
  ).flat();
}

export const agreementReferences = compose(
  getMasterData<AgreementReference>('agreement_references'),
);

export const myAgreementReferences = compose(
  getMasterData<AgreementReference>('agreement_references', '/my_agreements'),
);
export const cpoAgreementReferences = compose(
  getMasterData<AgreementReference>('agreement_references', '/cpo_list'),
);

export const applicationServices = compose(
  getMasterData<ApplicationService>('application_services'),
);

export const companies = compose(getMasterData<Company>('companies'));

export const informationClasses = compose(
  getMasterData<InformationClass>('infoclasses'),
);

export const criticalities = compose(
  getMasterData<SimpleLookupValue<number>>('criticalities'),
);

export const archTypes = compose(getMasterData<ArchType>('archtypes'));

export const standardLzRoles = compose(
  getMasterData<SimpleLookupValue>('default_alz_roles'),
);
export const standardGithubTeams = compose(
  getMasterData<SimpleLookupValue>('default_github_teams'),
);

export function useDynamicText(
  fieldGroups?: string | string[],
  msgGroups?: string | string[],
  richGroups?: string | string[],
  router?: Router,
) {
  return compose(
    (async () => {
      const dbFieldLabels = await getTexts<FieldLabel>(
        'field_labels',
        fieldGroups,
      );
      const dbMessages = await getTexts<CustomText>('strings', msgGroups);
      const dbRichtext = richGroups
        ? await getTexts<CustomText>('strings', richGroups)
        : [];

      const data: LocaleMessageSet = { no: {}, en: {} };

      for (const m of dbFieldLabels) {
        addLocaleData(data, m.label, m.group, `${m.field}.label`);
        addLocaleData(data, m.tooltip, m.group, `${m.field}.tooltip`);
        addLocaleData(data, m.placeholder, m.group, `${m.field}.placeholder`);
        addLocaleData(data, m.description, m.group, `${m.field}.description`);
      }
      for (const m of dbMessages) {
        addLocaleData(data, m.text, m.group, m.identifier);
      }
      await loadDynamicLocaleMessages(data, router);
      return { dbFieldLabels, dbRichtext };
    })(),
  );
}

type GenericMasterData = {
  Set: string;
  Key: string;
  data: string;
};
export async function addMasterDataRow<T>(
  endpoint: MdEndpoint,
  data: T,
  keyProperty: keyof T,
): Promise<T> {
  const payload: GenericMasterData = {
    Set: endpoint,
    Key: `${data[keyProperty]}`,
    data: JSON.stringify(data),
  };
  const response = await fetch(`${masterdataUrl}/${endpoint}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    body: JSON.stringify(endpoint === 'agreement_references' ? data : payload),
  });
  if (!response.ok) throw new Error(await response.text());
  return await response.json();
}

export async function updateMasterDataRow<T>(
  endpoint: MdEndpoint,
  id: string | number,
  data: T,
): Promise<void> {
  const payload: GenericMasterData = {
    Set: endpoint,
    Key: id.toString(),
    data: JSON.stringify(data),
  };
  const response = await fetch(`${masterdataUrl}/${endpoint}/${id}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    body: JSON.stringify(endpoint === 'agreement_references' ? data : payload),
  });
  if (!response.ok) throw new Error(await response.text());
}

export async function deleteMasterDataRow(
  endpoint: MdEndpoint,
  id: string | number,
): Promise<void> {
  const response = await fetch(`${masterdataUrl}/${endpoint}/${id}`, {
    method: 'DELETE',
    credentials: 'include',
  });
  if (!response.ok) throw new Error(await response.text());
}
