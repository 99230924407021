import type { OnlyKeysWithValsOfType } from '@/api/types';

export function groupBy<
  TItem,
  TKey extends keyof OnlyKeysWithValsOfType<TItem, string>,
  TGroupValue extends TItem[TKey] & string,
  TValue = TItem,
>(arr: TItem[], groupKey: TKey, selector?: (item: TItem) => TValue) {
  return arr.reduce(
    function (result, current) {
      const keyProp: TItem[TKey] = current[groupKey];
      if (!isTypeAndString<TItem[TKey], TGroupValue>(keyProp)) {
        throw 'Only properties returning strings can be used as a group by key!';
      } else {
        keyProp;
        if (!result[keyProp]) {
          result[keyProp] = [];
        }
        if (selector) {
          result[keyProp].push(selector?.(current));
        } else {
          result[keyProp].push(current as unknown as TValue);
        }
        return result;
      }
    },
    {} as { [key in TGroupValue]: TValue[] },
  );
}

export function groupByIntoArray<
  TItem,
  TKey extends keyof OnlyKeysWithValsOfType<TItem, string>,
  TValue = TItem,
>(arr: TItem[], groupKey: TKey, selector?: (item: TItem) => TValue) {
  return Object.entries(groupBy(arr, groupKey, selector)).map(
    ([group, values]) => ({
      group: group as TItem[TKey],
      values: values as TValue[],
    }),
  );
}

function isTypeAndString<TBaseType, TWithString extends TBaseType & string>(
  val: TBaseType,
): val is TWithString {
  return typeof val === 'string';
}
