<script setup lang="ts">
import { useStoreAbility } from '@/abilities';
import type { Block } from '@/api/types';
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import ModalComponent from '../ModalComponent.vue';
import { deleteBlock } from '@/api/blocks';
import { useI18n } from 'vue-i18n';
import { editBlocksRouteId } from '@/config';
import { translateStringOrLocale } from '@/i18n';

const { t } = useI18n();

const { can } = useStoreAbility();
const router = useRouter();

const props = defineProps<{
  block: Block;
  editable: boolean;
}>();

const showDeleteBlockModal = ref(false);

async function doDeleteBlock() {
  await deleteBlock(props.block.blockId);
  showDeleteBlockModal.value = false;
  router.push({ name: editBlocksRouteId });
}
const description = translateStringOrLocale(() => props.block.description);
const category = translateStringOrLocale(() => props.block.category?.name);
</script>
<template>
  <div>
    <p class="mb-2 mt-2 italic">
      {{ category }}
    </p>
    <p>{{ description }}</p>

    <RouterLink
      v-if="editable"
      :to="{ name: editBlocksRouteId, params: {} }"
      class="gnist-button mt-4"
    >
      {{ t('admin.blockProduction.goBackToToolList') }}
    </RouterLink>

    <RouterLink
      v-if="editable"
      :to="{ name: 'edit_block', params: { blockId: block.blockId } }"
      class="gnist-button gnist-button-primary ml-4 mt-4"
    >
      {{ t('admin.blockProduction.editBlock') }}
    </RouterLink>

    <button
      v-if="editable && can('delete', 'Block')"
      class="gnist-button gnist-button-danger ml-4 mt-4"
      data-cy-id="delete-block-button"
      @click="() => (showDeleteBlockModal = true)"
    >
      {{ t('admin.blockProduction.deleteBlock') }}
    </button>

    <ModalComponent
      :show-modal="showDeleteBlockModal"
      :title="t('admin.blockProduction.confirmDeleteBlock')"
      @close="() => (showDeleteBlockModal = false)"
      @handle-click="doDeleteBlock"
    >
      <template #default>
        <p>{{ t('admin.blockProduction.deleteBlockPermanently') }}</p>
      </template>
    </ModalComponent>
  </div>
</template>
