<script setup lang="ts" generic="T = string">
import type { CustomText } from '@/api/types';
import TextInput from '@/components/forms/InputText.vue';
import MarkdownInput from '@/components/forms/InputMarkdown.vue';
import Dropdown from '@/components/forms/InputDropdown.vue';
import { useNestedMV } from '@/utilities/useInternalState';
import { useI18n } from 'vue-i18n';
import { toDropdownItems } from './forms/InputDropdown.vue';
import {
  allTextSets,
  getTextSetByKey,
  richTextPrefix,
  type TextSetDetails,
} from '@/api/field_labels';
import { computed } from 'vue';
import { groupBy } from '@/utilities/groupBy';

const props = defineProps<{
  modelValue: CustomText;
  allFields: CustomText[];
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', value: typeof props.modelValue): void;
}>();

const { t } = useI18n();

const value = useNestedMV(props, (val) => emit('update:modelValue', val));

const setOptions = toDropdownItems(
  Object.values(allTextSets).filter((set) => {
    const availableFields = Object.keys(set.fields);
    const fieldsFromDb =
      groupBy(props.allFields, 'group', (item) => item.identifier)[set.group] ??
      [];
    const fieldsFromDbExceptDeprecated = fieldsFromDb.filter((usedField) =>
      availableFields.find((f) => f === usedField),
    );
    return (
      value.value.group === set.group ||
      availableFields.length > fieldsFromDbExceptDeprecated.length
    );
  }),
  (set) => [
    set.group,
    t(`masterData.strings.groups.${set.group.replaceAll('.', '_')}`),
    set,
  ],
  true,
  undefined,
);

const existingGroupFields = computed(() =>
  props.allFields.filter((f) => f.group === value.value.group),
);

function getText(value: string): string {
  const isValueItem = value.indexOf('.values.') > -1;
  if (isValueItem) {
    return t(`${value.split('.values.')[0]}.label`) + ': ' + t(value);
  } else {
    return value.replace(richTextPrefix, '');
  }
}

const textOptions = computed(() =>
  toDropdownItems(
    value.value.group
      ? Object.entries(getTextSetByKey(value.value.group).fields)
          .map(([key, value]) => ({ key, value }))
          .filter(
            (f) =>
              f.key === value.value.identifier ||
              !existingGroupFields.value.find((e) => e.identifier === f.key),
          )
      : [],
    (item) => [item.key, getText(item.value), item.key],
    true,
  ),
);

const selectedGroup = computed<Pick<TextSetDetails, 'group' | 'richtext'>>({
  get() {
    return {
      group: value.value.group,
      richtext: getTextSetByKey(value.value.group).richtext,
    };
  },
  set(newValue) {
    value.value.group = newValue.group;
  },
});
</script>

<template>
  <div class="flex grid-cols-2 flex-col gap-4 md:grid">
    <Dropdown
      v-model="selectedGroup"
      label="masterData.strings.group"
      :options="setOptions"
      :getkey="(t) => t?.group ?? ''"
      allow-empty-value
      disable-empty-option
      :disabled="!!value.group"
    />
    <Dropdown
      v-model="value.identifier"
      label="masterData.strings.identifier"
      :options="textOptions"
      :getkey="(t) => t"
      allow-empty-value
      disable-empty-option
      :disabled="!!value.identifier"
      :class="{ invisible: !value.group }"
    />
    <template v-if="!selectedGroup.richtext">
      <div class="font-bold">
        {{ t('language.values.no') }}
      </div>
      <div class="font-bold">
        {{ t('language.values.en') }}
      </div>
      <TextInput
        v-model="value.text.no"
        label="masterData.strings.text.no"
        required
      />
      <TextInput v-model="value.text.en" label="&nbsp;" />
    </template>
    <template v-else>
      <MarkdownInput
        v-model="value.text.no"
        for-name="custom_text_no"
        :label="`${t('masterData.strings.text.no')} (${t('language.values.no')})`"
        editor-height="20rem"
        required
        class="col-span-2"
      />
      <MarkdownInput
        v-model="value.text.en"
        for-name="custom_text_en"
        :label="`${t('masterData.strings.text.no')} (${t('language.values.en')})`"
        editor-height="20rem"
        class="col-span-2"
      />
    </template>
  </div>
</template>
