import { error_pages } from '@/api/types';

/** Throws an error that will trigger showing an error page if response status is 401, 403 or 404 */
export function throwErrorPageTrigger(response: Response) {
  if (response.status === 401) throwUnauthorized();
  else if (response.status === 403) throwForbidden();
  else if (response.status === 404) throw new Error(error_pages[404]);
}
export function throwUnauthorized() {
  throw new Error(error_pages[401]);
}
export function throwForbidden() {
  throw new Error(error_pages[403]);
}
