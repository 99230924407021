<script setup lang="ts">
import MarkdownRenderer from '@/components/block/MarkdownRenderer.vue';
import type { ApiOperation, Guid } from '@/api/types';
import { useRoute } from 'vue-router';
import idEncode from '@/utilities/idEncode';
import { getBlocksRouteLink } from '@/utilities/routeUtils';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const route = useRoute();

defineProps<{
  operation: Pick<ApiOperation, 'name' | 'method' | 'description'>;
  endPoint: string;
}>();
</script>

<template>
  <section class="text-gnist-black">
    <table class="text-left">
      <tbody>
        <tr>
          <th class="pr-4 font-semibold">
            {{ t('console.documentation') }}
          </th>
          <td>
            <RouterLink
            class="underline"
            :to="
                getBlocksRouteLink(
                  route.params.blockId as Guid,
                  undefined,
                  `#${idEncode(operation.name)}`,
                )
                "
            >
            {{ operation.name ?? '' }}
          </RouterLink>
          </td>
        </tr>
        <tr>
          <th class="font-semibold">{{ t('console.endpoint') }}:</th>
          <td>{{ endPoint }}</td>
        </tr>
        <tr>
          <th class="font-semibold">{{ t('console.method') }}:</th>
          <td>{{ operation?.method ?? '' }}</td>
        </tr>
      </tbody>
    </table>
    <MarkdownRenderer
      v-if="operation?.description"
      :value="operation.description"
      blob-location="public"
      class="mt-6"
    />
  </section>
</template>
