<script lang="ts">
export const defaultSchema: Omit<Schema, 'name'> = {
  title: 'Innhold',
  required: false,
  showTitle: false,
  type: 'singleline',
};
</script>

<script lang="ts" setup>
import TextInput from '@/components/forms/InputText.vue';
import MarkdownInput from '@/components/forms/InputMarkdown.vue';
import Checkbox from '@/components/forms/InputCheckbox.vue';
import Dropdown from '@/components/forms/InputDropdown.vue';
import ModalComponent from '@/components/ModalComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import MaterialIcon from '@/components/MaterialIcon.vue';
import { useI18n } from 'vue-i18n';
import { ref, watch } from 'vue';
import { useNestedMVDefault } from '@/utilities/useInternalState';
import {
  schemaTypeValues,
  type Schema,
  type RenderTemplateDefinition,
} from '@/api/types';
import {
  cardTemplates,
  type cardTemplateIds,
  type pageTemplateIds,
  pageTemplates,
} from '@/renderTemplates';
import { mapValueList } from '@/utilities/mapValueList';

const { t } = useI18n();

const props = defineProps<{
  modelValue: Schema[] | undefined;
  legend?: string;
  cardTemplateId?: cardTemplateIds;
  pageTemplateId?: pageTemplateIds;
}>();
const emit = defineEmits<{
  (e: 'update:modelValue', value: Schema[] | undefined): void;
}>();

const value = useNestedMVDefault(
  props,
  (val) => emit('update:modelValue', val),
  [],
  true,
);

const schemaTypeOptions = mapValueList(
  schemaTypeValues,
  'categoryAdmin.schema.type',
);

const addRequiredFieds = (
  fields?: RenderTemplateDefinition['requiredFields'],
) => {
  if (!fields) return;
  for (const field of fields) {
    field.name = field.name.toLowerCase();
    const matchingField = value.value.find(
      (s) => s.name.toLowerCase() === field.name,
    );
    if (matchingField) {
      matchingField.type = field.type;
      matchingField.requiredByTemplate = true;
      matchingField.canUnsetRequired = field.canUnsetRequired;
      if (!field.canUnsetRequired) matchingField.required = true;
      continue;
    }
    value.value.push({
      name: field.name,
      type: field.type,
      showTitle: false,
      title: field.defaultTitle ?? '',
      required: true,
      requiredByTemplate: true,
      canUnsetRequired: field.canUnsetRequired,
    });
  }
};

watch(
  () => [props.cardTemplateId, props.pageTemplateId],
  () => {
    for (const field of value.value) {
      field.requiredByTemplate = false;
    }
    addRequiredFieds(
      cardTemplates.find((i) => i.id === props.cardTemplateId)?.requiredFields,
    );
    addRequiredFieds(
      pageTemplates.find((i) => i.id === props.pageTemplateId)?.requiredFields,
    );
  },
  { immediate: true },
);

function addSchemaItem() {
  const emptySchema: Schema = {
    ...defaultSchema,
    name: 'Field' + (value.value.length + 1),
  };
  value.value = [...value.value, emptySchema];
}

const showDeleteModal = ref(false);
const indexToDelete = ref<number>();
async function doDelete() {
  if (indexToDelete.value === undefined) return;
  showDeleteModal.value = false;
  const newSchema = [...value.value];
  newSchema.splice(indexToDelete.value, 1);
  value.value = newSchema;
  indexToDelete.value = undefined;
}

function moveItem(index: number, direction: -1 | 1) {
  const newIndex = index + direction;
  if (newIndex >= 0 && newIndex < value.value.length) {
    // swap entries:
    [value.value[newIndex], value.value[index]] = [
      value.value[index],
      value.value[newIndex],
    ];
  }
}
</script>

<template>
  <fieldset class="min-w-0 border p-4 ps-4">
    <legend>{{ t(legend ?? 'categoryAdmin.schema.fieldsetLegend') }}</legend>
    <ButtonComponent
      :text="t('categoryAdmin.schema.create')"
      data-cy-id="CreateSchemaItemButton"
      @click="addSchemaItem"
    />
    <div
      class="mt-4 flex grow flex-col items-stretch gap-8 overflow-x-auto p-1 md:grid md:grid-cols-[repeat(4,minmax(max-content,1fr))]"
    >
      <template v-for="(s, idx) of value" :key="idx">
        <hr class="col-span-full mt-2 first:hidden" />
        <TextInput
          v-model="s.title"
          label="categoryAdmin.schema.title"
          required
        />
        <Dropdown
          v-model="s.type"
          label="categoryAdmin.schema.type.title"
          :options="schemaTypeOptions"
          :getkey="(val) => val ?? 'empty'"
          :disabled="s.requiredByTemplate"
          required
        />
        <TextInput
          v-model="s.placeholder"
          label="categoryAdmin.schema.placeholder"
          :class="{ invisible: s.type === 'link' }"
        />
        <div class="flex flex-row justify-evenly">
          <Checkbox
            v-model="s.required"
            label="categoryAdmin.schema.required"
            :class="{ invisible: s.type === 'link' }"
            :disabled="s.requiredByTemplate && !s.canUnsetRequired"
          />
          <Checkbox
            v-model="s.showTitle"
            label="categoryAdmin.schema.showTitle"
          />
        </div>
        <TextInput
          v-model="s.minlength"
          label="categoryAdmin.schema.minLength"
          type="number"
          :class="{ invisible: s.type === 'link' }"
        />
        <TextInput
          v-model="s.maxlength"
          label="categoryAdmin.schema.maxLength"
          type="number"
          :class="{ invisible: s.type === 'link' }"
        />
        <TextInput
          v-model="s.name"
          label="categoryAdmin.schema.name"
          :disabled="s.requiredByTemplate"
          required
        />
        <div class="flex flex-row">
          <div class="my-2 mb-3 h-9 w-11 self-end px-1">
            <button
              v-if="idx !== 0"
              class="gnist-button w-9 p-0"
              @click.prevent="() => moveItem(idx, -1)"
            >
              <MaterialIcon class="text-2xl" aria-hidden="true">
                arrow_upward
              </MaterialIcon>
            </button>
          </div>

          <div class="my-2 mb-3 h-9 w-11 self-end px-1">
            <button
              v-if="idx < value.length - 1"
              class="gnist-button w-9 p-0"
              @click.prevent="() => moveItem(idx, 1)"
            >
              <MaterialIcon class="text-2xl" aria-hidden="true">
                arrow_downward
              </MaterialIcon>
            </button>
          </div>

          <ButtonComponent
            v-if="!s.requiredByTemplate"
            :text="t('categoryAdmin.schema.delete.button')"
            class="gnist-button gnist-button-danger my-2 mb-3 ml-4 self-end"
            @click.prevent="
              (ev) => {
                ev.preventDefault();
                indexToDelete = idx;
                showDeleteModal = true;
              }
            "
          />

          <div v-else class="flex flex-col whitespace-nowrap">
            <div class="leading-8">&nbsp;</div>
            <div class="my-2 flex grow items-start justify-center">
              <span
                :data-tip="t('categoryAdmin.template.requiredTooltip')"
                class="tooltip static ml-2 whitespace-break-spaces font-normal sm:relative"
              >
                <MaterialIcon class="text-2xl hover:text-secondary">
                  info
                </MaterialIcon>
              </span>
            </div>
          </div>
        </div>
        <MarkdownInput
          v-model="s.defaultValue"
          label="categoryAdmin.schema.defaultValue"
          :class="['md:col-span-4', { hidden: s.type !== 'markdown' }]"
          multiline
          :rows="4"
          :for-name="s.name + '_SchemaDefaultValue'"
          editor-type="wysiwyg"
          editor-height="20rem"
        />
      </template>
    </div>
    <ModalComponent
      :show-modal="showDeleteModal"
      :title="t('categoryAdmin.schema.delete.title')"
      @close="
        () => {
          indexToDelete = undefined;
          showDeleteModal = false;
        }
      "
      @handle-click="doDelete"
    >
      <template #default>
        <p>{{ t('categoryAdmin.schema.delete.warning') }}</p>
      </template>
    </ModalComponent>
  </fieldset>
</template>
