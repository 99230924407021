<script setup lang="ts">
import MaterialIcon from '@/components/MaterialIcon.vue';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

const props = defineProps<{
  label: string;
  subscriptionkey: string;
}>();

const { t } = useI18n();

const showSubscriptionKey = ref(false);
const displayedKeyValue = computed(() =>
  showSubscriptionKey.value
    ? props.subscriptionkey ?? ''
    : '••••••••••••••••••••••••••••••••',
);

const copyToClipboard = () => {
  navigator.clipboard.writeText(props.subscriptionkey ?? '');
};
</script>
<template>
  <label> {{ t(label) }}:</label>
  <div class="relative ml-2 inline-flex h-8 max-w-full">
    <input
      disabled
      type="text"
      class="h-8 overflow-hidden rounded border pl-2 pr-6 text-sm"
      size="48"
      :value="displayedKeyValue"
    />
    <button
      class="absolute right-6 mr-0.5 mt-0.5 h-[1.75rem] p-1 hover:cursor-pointer hover:bg-gnist-white active:bg-gnist-gray-light"
      @click="showSubscriptionKey = !showSubscriptionKey"
    >
      <MaterialIcon class="text-[1rem]" aria-hidden="true">
        {{ showSubscriptionKey ? 'visibility_off' : 'visibility' }}
      </MaterialIcon>
    </button>
    <button
      class="absolute right-0 mr-0.5 mt-0.5 h-[1.75rem] p-1 hover:cursor-pointer hover:bg-gnist-white active:bg-gnist-gray-light"
      @click="copyToClipboard"
    >
      <MaterialIcon class="text-[1rem]" aria-hidden="true">
        content_copy
      </MaterialIcon>
    </button>
  </div>
</template>
