<script setup lang="ts">
import { addNewsArticle, updateNewsArtice } from '@/api/news';
import { getEmptyLocaleValue, type ChangeProps, type News } from '@/api/types';
import { ref } from 'vue';
import ButtonComponent from './ButtonComponent.vue';
import MarkdownInput from '@/components/forms/InputMarkdown.vue';
import TextInput from '@/components/forms/InputText.vue';
import DateInput from '@/components/forms/InputDate.vue';
import Checkbox from '@/components/forms/InputCheckbox.vue';
import { useI18n } from 'vue-i18n';
import { useValidationTracking } from '@/utilities/useValidationTracking';

const { t } = useI18n();

const props = defineProps<{
  newsItem?: News;
}>();
const emit = defineEmits<{
  (e: 'edit'): void;
  (e: 'cancel'): void;
  (e: 'update'): void;
}>();

const defaultContent = `
::::::Flex [dir=horizontal gap=2rem wrap=false]

:::Basic []

&lt;Tekst her&gt;

:::

:::Image [maxwidth=16rem]

&lt;bilde her&gt;

:::

::::::`;

const item = ref<ChangeProps<News, 'datePublished', Date>>({
  ...props.newsItem,
  title: props.newsItem?.title ?? getEmptyLocaleValue(),
  content: props.newsItem?.content ?? {
    no: defaultContent,
    en: defaultContent,
  },
  datePublished: props.newsItem?.datePublished
    ? new Date(props.newsItem.datePublished)
    : new Date(),
});

const showValidationMessages = ref<boolean>();
const triggerFormValidation = ref<boolean>(false);
const { onValidChanged, formRef, isValid } = useValidationTracking(
  [item.value.title, item.value.content],
  triggerFormValidation,
);
const saveNews = async () => {
  if (!isValid.value) {
    triggerFormValidation.value = true;
    return;
  }
  triggerFormValidation.value = false;

  const newsData = {
    ...item.value,
    datePublished: item.value.datePublished.toISOString(),
  } satisfies News;
  item.value.newsArticleId
    ? await updateNewsArtice(newsData)
    : await addNewsArticle(newsData);

  emit('edit');
  emit('update');
};
</script>

<template>
  <form ref="formRef" class="m-8 rounded-sm border p-4">
    <div
      class="my-4 mt-auto flex grid-cols-[auto_1fr_1fr] justify-between gap-4 md:grid"
    >
      <DateInput
        v-model="item.datePublished"
        label="news.date"
        class="justify-self-end"
      />
      <TextInput
        v-model="item.title.no"
        label="news.newsTitle"
        required
        show-validation-message
      />
      <TextInput
        v-model="item.title.en"
        label="news.newsTitleEn"
        required
        show-validation-message
      />
    </div>

    <Checkbox
      v-model="item.published"
      label="news.published"
      direction="horizontal"
      mode="toggle"
      class="mt-4"
    />
    <MarkdownInput
      v-model="item.content.no"
      for-name="content_no"
      label="news.newsContent"
      required
      blob-location="/news"
      :show-validation-message="showValidationMessages"
      @report-validation-error="
        (name, hasError) => onValidChanged(name, !hasError)
      "
    />
    <MarkdownInput
      v-model="item.content.en"
      for-name="content_en"
      label="news.newsContentEn"
      required
      blob-location="/news"
      :show-validation-message="showValidationMessages"
      @report-validation-error="
        (name, hasError) => onValidChanged(name, !hasError)
      "
    />
    <div class="flex justify-end">
      <div class="gnist-button-group">
        <ButtonComponent
          :text="t('buttons.cancel')"
          submit
          @click="() => $emit('edit')"
        />
        <ButtonComponent
          :text="t('news.save')"
          submit
          type="primary"
          :disabled="!isValid"
          @click="() => saveNews()"
        />
      </div>
    </div>
  </form>
</template>
