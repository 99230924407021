import { apiUrl } from '@/config';
import type { Blob, Guid } from './types';
import { ref, type Ref } from 'vue';

const blobUrl = `${apiUrl}/blob` as const;
export type blobUrl = '/news' | '/organization';
type BlockBlobUrlInput = {
  blockId: Guid;
  versionId: number;
};
type SuggestionBlobUrlInput = {
  suggestionId: Guid | number;
};
export type IBlobUrlInput =
  | BlockBlobUrlInput
  | SuggestionBlobUrlInput
  | blobUrl
  | 'public';
function isBlockBlobUrlInput(
  location: IBlobUrlInput,
): location is BlockBlobUrlInput {
  return (location as BlockBlobUrlInput).blockId !== undefined;
}
function isSuggestionBlobUrlInput(
  location: IBlobUrlInput,
): location is SuggestionBlobUrlInput {
  return (location as SuggestionBlobUrlInput).suggestionId !== undefined;
}
export function getBlobFilename(location: IBlobUrlInput, fileName: string) {
  const baseUrl = getBlobUrl(location);
  return fileName.replace(baseUrl, '').replace(/^\/*/, '');
}
type blobEndpointUrl =
  `${typeof blobUrl}${blobUrl | `/block/${Guid}/version/${number}` | `/suggestions/${number | Guid}`}`;
// prettier-ignore
export function getBlobUrl(location: IBlobUrlInput): '' | blobEndpointUrl;
// prettier-ignore
export function getBlobUrl(location: IBlobUrlInput, fileName: string): '' | `${blobEndpointUrl}${string}`;
// prettier-ignore
export function getBlobUrl(location: IBlobUrlInput, fileName?: string): '' | `${blobEndpointUrl}${string}` {
  if (location === 'public') return ''; // Used if there is no blob location
  const suffix = !fileName
    ? ''
    : (fileName.startsWith('/') ? '' : '/') + fileName;
  if (typeof location === 'string') {
    return `${blobUrl}${location}${suffix}`;
  } else if (isBlockBlobUrlInput(location)) {
    return `${blobUrl}/block/${location.blockId}/version/${location.versionId}${suffix}`;
  } else if (isSuggestionBlobUrlInput(location)) {
    return `${blobUrl}/suggestions/${location.suggestionId}${suffix}`;
  } else {
    return '';
  }
}

export const uploadBlob = async (
  blobLocation: IBlobUrlInput,
  file: File,
): Promise<string> => {
  const data = new FormData();
  data.append('file', file);

  const response = await fetch(getBlobUrl(blobLocation), {
    method: 'POST',
    body: data,
    credentials: 'include',
  });
  if (!response.ok) throw new Error('error.upload_failed');
  const { fileId } = await response.json();

  return fileId;
};

export async function importImage(
  blobLocation: IBlobUrlInput,
  env: 'test' | 'prod',
  sourceUrl: string,
  fileId: string,
) {
  const response = await fetch(
    `${getBlobUrl(blobLocation)}/import_from/${env}/${encodeURIComponent(sourceUrl)}/${fileId}`,
    {
      method: 'PUT',
      credentials: 'include',
    },
  );
  if (response.ok) return undefined;
  const error = await response.text();
  let userError: string;
  // check for standard Azure Blob error format:
  const knownData = error.match(/^(Error|Status|ErrorCode): .*$/gm);
  if (knownData) {
    userError =
      knownData.find((e) => e.startsWith('Status:')) ??
      knownData.find((e) => e.startsWith('ErrorCode:')) ??
      knownData.find((e) => e.startsWith('Error:')) ??
      knownData[0];
  } else {
    userError = error.split('\n')[0];
  }
  throw new Error(userError, { cause: error });
}

export function useBlobs(location: IBlobUrlInput): {
  blobs: Ref<Blob[]>;
  refresh: () => Promise<Blob[]>;
} {
  const blobs = ref<Blob[]>([]);
  const refresh = async () => (blobs.value = await getBlobs(location));
  refresh();
  return { blobs, refresh };
}

async function getBlobs(location: IBlobUrlInput): Promise<Blob[]> {
  const response = await fetch(getBlobUrl(location), {
    method: 'GET',
    credentials: 'include',
  });
  return await response.json();
}

export async function deleteBlob(
  location: IBlobUrlInput,
  fileId: string,
): Promise<void> {
  await fetch(getBlobUrl(location, fileId), {
    method: 'DELETE',
    credentials: 'include',
  });
}
