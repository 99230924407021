import type {
  Block,
  Guid,
  OrganizationUser,
  Suggestion,
  localeValue,
} from './types';
import type { SocialPageType } from '@/enums/SocialPageType';
import type { defaultBlocksRouteName } from '@/utilities/routeUtils';

export interface Commenter {
  userId: string;
  name: string;
}

export interface Comment {
  commentHex: string;
  markdown: string;
  html: string;
  parentHex: string;
  score: number;
  state: 'approved' | 'unapproved' | 'flagged';
  creationDate: string;
  direction: -1 | 0 | 1;
  deleted: boolean;
  userId: string;
  commenterUserRoles?: string[];
  commenterOwnsPageBlock?: boolean;
}
export interface CommentAssessment {
  commentHex: string;
  html: string;
  date: string | Date;
  user: OrganizationUser;
  block?: Block;
  suggestion?: Suggestion;
  pagePath?: string;
}

export interface ThreadComment extends Comment {
  commenter?: Commenter;
  parent?: ThreadComment;
  children: ThreadComment[];
}

export type MyThreadsComment = {
  commentId: string;
  created: string;
  discussionId: commentPageId;
  discussionType: SocialPageType;
  pageDescription?: localeValue;
  content: string;
  userId: string;
  firstName: string;
  lastName: string;
};

export type ChatPage = {
  id: chatPageParam;
  chatPageName: string;
  chatPageDescription: string;
};

export interface Discussion {
  commenterMap: Map<string, Commenter>;
  commentMap: Map<string, ThreadComment>;
  stickiedComment?: ThreadComment;
  threads: ThreadComment[];
  attributes: DiscussionAttributes;
  defaultSortPolicy: SortStrategy;
}

export interface DiscussionAttributes {
  id: commentPageId;
  isLocked: boolean;
  commentCount: number;
  stickyCommentHex: string; // can be 'none'
  pageType: SocialPageType;
}

// Most upvotes first, Newest First, Oldest first
export type SortStrategy =
  | 'score-desc'
  | 'creationdate-desc'
  | 'creationdate-asc';

export type SortFunction = (a: ThreadComment, b: ThreadComment) => number;

export type AssessmentType = 'Moderator' | 'DocumentOwner';

const chatPageIds = ['general', 'suggestions', 'technicalhelp'] as const;
export type chatPageId = `/${(typeof chatPageIds)[number]}`;
export const suggestionPageParam = 'backlog';
type SuggestionPageParam = typeof suggestionPageParam;
export const suggestionPageIdPrefix = 'suggestion';
export type suggestionPageId = `${typeof suggestionPageIdPrefix}${string}`;
export type chatPageParam = chatPageId extends `/${infer pageParam}`
  ? pageParam
  : never;
export type commentPageParam =
  | chatPageParam
  | typeof defaultBlocksRouteName
  | SuggestionPageParam;
export type commentPageId = chatPageId | Guid | suggestionPageId;
export function isChatPage(id: chatPageId | unknown): id is chatPageId {
  return chatPageIds.includes(getChatPageParamFromId(id as chatPageId));
}
export function getChatPageParamFromId(id: chatPageId): chatPageParam {
  return id.split('/').slice(-1)[0] as chatPageParam;
}
export const getChatPages = (): ChatPage[] => {
  return chatPageIds.map((i) => getChatPage(i) as ChatPage);
};

export const getChatPage = (id: chatPageParam): ChatPage => {
  return {
    id: id,
    chatPageName: `social.pages.${id}.name`,
    chatPageDescription: `social.pages.${id}.description`,
  };
};
