<script setup lang="ts">
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const route = useRoute();
console.log({ route });
</script>
<template>
  <div class="grow">
    <div class="gnist-page-width mx-2 mt-0 p-4 lg:p-16">
      <h1 data-cy-id="404_header">{{ t('error.page.notFoundTitle') }}</h1>
      <p class="m-4">{{ t('error.page.notFoundText') }}</p>
    </div>
  </div>
</template>
