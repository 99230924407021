<script setup lang="ts">
import type { TranslatedBlock } from '@/api/blocks';
import { cardTemplates, type cardTemplateIds } from '@/renderTemplates';
import { computed } from 'vue';

const props = defineProps<{
  blockItem: TranslatedBlock;
  routeName: string;
  cardTemplateId?: cardTemplateIds;
}>();

const templateId = computed(
  () => props.cardTemplateId ?? props.blockItem.category?.cardTemplateId,
);
const template = computed(
  () => cardTemplates.find((i) => i.id == templateId.value) ?? cardTemplates[0],
);
</script>

<template>
  <RouterLink
    v-if="template.isLink"
    :to="{ name: routeName, params: { blockId: blockItem.blockId } }"
  >
    <component :is="template.component" :block="blockItem" />
  </RouterLink>
  <template v-else>
    <component
      :is="template.component"
      :block="blockItem"
      :route-name="routeName"
    />
  </template>
</template>
