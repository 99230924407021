<script setup lang="ts">
import { useAttrs } from 'vue';

defineProps<{
  show?: boolean;
}>();
defineOptions({
  inheritAttrs: false,
});
const attrs = useAttrs();
</script>

<template>
  <template v-if="show">
    <div v-bind="attrs">
      <slot />
    </div>
  </template>
  <template v-else>
    <slot />
  </template>
</template>
