<script setup lang="ts">
import { useRoute } from 'vue-router';
import VersionList from '@/components/admin/VersionList.vue';
import BlockDetails from '@/components/admin/BlockDetails.vue';
import ContentPage from '../components/ContentPage.vue';
import { useBlock } from '@/api/blocks';
import { throwOnError } from '@/api/composable';
import type { Guid } from '@/api/types';
import { translateStringOrLocale } from '@/i18n';
import { watchEffect } from 'vue';
import { getRouteTitle } from '@/utilities/routeUtils';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const route = useRoute();
const blockId = route.params.blockId as Guid;
const { result: block, refresh } = throwOnError(useBlock(blockId, true));
const blockTitle = translateStringOrLocale(() => block.value?.name);
watchEffect(() => {
  if (blockTitle.value)
    document.title = getRouteTitle([
      t('admin.blockProduction.versionsTitle'),
      blockTitle.value,
    ]);
});
</script>

<template>
  <ContentPage :title="block?.name ?? ''" class="bg-gnist-gray-light-light">
    <template #banner>
      <BlockDetails v-if="block" class="mb-4" :block="block" :editable="true" />
    </template>
    <template #default>
      <div class="py-8">
        <VersionList v-if="block" :block="block" @refresh-block="refresh" />
      </div>
    </template>
  </ContentPage>
</template>
