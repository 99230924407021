<script lang="ts">
export const bannerClasses = 'pt-12 md:pt-16 pb-4 md:pb-7';
</script>
<script setup lang="ts">
import type { FgColors, localeValue } from '@/api/types';
import { translateStringOrLocale } from '@/i18n';

const props = defineProps<{
  title: string | localeValue;
  subTitle?: string;
  description?: string | localeValue;
  underline?: boolean;
  hideDescriptionMobile?: boolean;
  textColor?: FgColors;
}>();
const _title = translateStringOrLocale(() => props.title);
const _description = translateStringOrLocale(() => props.description);
</script>

<template>
  <div class="w-full" role="banner">
    <div class="gnist-page-width min-h-16 px-6 lg:min-h-32 lg:px-8">
      <div :class="bannerClasses">
        <h1 data-cy-id="PageTitle" class="mb-2 md:mb-4">
          {{ _title }}
        </h1>
        <h2 v-if="subTitle" class="mb-2 md:mb-4">
          {{ subTitle }}
        </h2>
        <p
          v-if="_description?.trim()"
          :class="[
            'text-subtitle pb-4 pt-1 md:block',
            { hidden: !!hideDescriptionMobile },
            `text-gnist-${textColor}`,
          ]"
        >
          {{ _description }}
        </p>
      </div>
      <slot />
    </div>
  </div>
</template>
