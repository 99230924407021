import MarkdownIt, { type Options } from 'markdown-it';
import MarkdownItAnchor from 'markdown-it-anchor';
import markdownItBr from '@iktakahiro/markdown-it-br';
import slugify from 'slugify';
import hljs from 'highlight.js/lib/core';
import type Token from 'markdown-it/lib/token.mjs';
import Renderer, { type RenderRule } from 'markdown-it/lib/renderer.mjs';
import {
  AddGnistMarkdownContainers,
  CustomImageRenderer,
  CustomLink,
  InlineContainers,
  WhitelistHtmlTags,
} from '@/utilities/markdown/GnistMarkdownExtension';
import {
  calculateUrl,
  type mdEnvironment,
} from '@/utilities/markdown/utilities';

export const md = new MarkdownIt({
  linkify: true,
  highlight: function (str, lang) {
    if (lang && hljs.getLanguage(lang)) {
      try {
        return hljs.highlight(str, { language: lang }).value;
      } catch (e) {
        console.log(e);
      }
    }
    return ''; // use external default escaping
  },
});

const proxy: RenderRule = (tokens, idx, options, env, self) =>
  self.renderToken(tokens, idx, options);

md.use(MarkdownItAnchor, { slugify: slugify });
md.use(InlineContainers); // This must be added before containers, so "inline containers" are tokenized before "block containers". If not, "inline containers" might be interpreted as block containers.
AddGnistMarkdownContainers(md);
md.use(markdownItBr);
md.use(WhitelistHtmlTags);
md.use(CustomLink);

md.renderer.rules.fence = wrapCodeRenderer(md.renderer.rules.fence);
md.renderer.rules.code_block = wrapCodeRenderer(md.renderer.rules.code_block);

const defaultImageRender: RenderRule | undefined = md.renderer.rules.image;
md.renderer.rules.image = imageRenderer;

const defaultLinkOpenRender = md.renderer.rules.link_open || proxy;
md.renderer.rules.link_open = linkOpenRenderer;

// Wrap a render function to add `hljs` class to code blocks
function wrapCodeRenderer(renderer?: RenderRule): RenderRule | undefined {
  if (!renderer) return undefined;
  return function wrappedRenderer(...args) {
    return renderer(...args)
      .replace('<code class="', '<code class="hljs ')
      .replace('<code>', '<code class="hljs">');
  };
}

// Overrides the markdown image renderer to show images in markdown without using absolute paths
function imageRenderer(
  tokens: Token[],
  idx: number,
  options: Options,
  env: mdEnvironment,
  self: Renderer,
): string {
  const token = tokens[idx];

  if (token.attrs) {
    token.attrs[token.attrIndex('src')][1] = calculateUrl(
      token.attrs[token.attrIndex('src')][1],
      env,
    );
  }

  return (
    CustomImageRenderer(token, env) ??
    defaultImageRender?.(tokens, idx, options, env, self) ??
    ''
  );
}

// Overrides the markdown link renderer to show links in markdown without using absolute paths
function linkOpenRenderer(
  tokens: Token[],
  idx: number,
  options: Options,
  env: mdEnvironment,
  self: Renderer,
): string {
  const token = tokens[idx];

  if (token.attrs) {
    token.attrs[token.attrIndex('href')][1] = calculateUrl(
      token.attrs[token.attrIndex('href')][1],
      env,
    );
  }
  return defaultLinkOpenRender(tokens, idx, options, env, self);
}
