<script setup lang="ts">
import {
  getApimDetails,
  type Block,
  type BlockApimDetails,
  type BlockImage,
  type BlockLocaleValue,
  type Link,
  type Schema,
  type Step,
  type Translated,
  type InteractiveImage,
  type LayoutItem,
} from '@/api/types';
import { computed, ref, useAttrs } from 'vue';
import SinglelineRenderer from '@/components/block/SinglelineRenderer.vue';
import MultilineRenderer from '@/components/block/MultilineRenderer.vue';
import MarkdownRenderer from '@/components/block/MarkdownRenderer.vue';
import LinkRenderer from '@/components/block/LinkRenderer.vue';
import ImageRenderer from '@/components/block/ImageRenderer.vue';
import BlockSteps from '@/components/BlockSteps.vue';
import InteractiveImageRenderer from '@/components/block/InteractiveImageRenderer.vue';
import { translateBlockValue } from '@/i18n';
import { type supported_locale } from '@/config';
import { useI18n } from 'vue-i18n';
import ApiDetails from '@/components/block/ApiDetails.vue';
import LayoutRenderer from './LayoutRenderer.vue';

const props = defineProps<{
  block: Block;
  containers?: { idx: number; el: HTMLElement }[];
}>();

const emit = defineEmits<{
  (e: 'update:containers', container: { idx: number; el: HTMLElement }[]): void;
}>();

const { locale } = useI18n();

const apimId = computed(() => getApimDetails(props.block?.content)?.apimId);

const updateRef = (container: HTMLElement, idx: number, skip = false) => {
  if (skip || !props.containers) return;
  if (!props.containers.find((i) => i.idx == idx)) {
    emit('update:containers', [...props.containers, { idx, el: container }]);
  }
};

const mappedContent = computed(() => {
  const contentKeys = Object.keys(props.block.content);
  const hasCustomSchemaContent = !!props.block.customSchema?.schema.find((i) =>
    contentKeys.includes(i.name),
  );
  const hasBlockSchemaContent = !!props.block.category?.schema?.find((i) =>
    contentKeys.includes(i.name),
  );
  const schema = hasCustomSchemaContent
    ? props.block.customSchema!.schema
    : hasBlockSchemaContent
      ? props.block.category!.schema!
      : contentKeys.map(
          (k): Schema => ({
            name: k,
            type: 'markdown',
            title: '',
            showTitle: false,
          }),
        );
  return schema.map((s) => ({
    schema: s,
    itemContent: translateBlockValue(
      props.block.content[s.name] ?? '',
      locale.value as supported_locale,
      s.type,
    ),
  }));
});
const attrs = useAttrs();
const blobLocation = computed(() => ({
  blockId: props.block.blockId,
  versionId: props.block.currentVersionId,
}));

const hasSubscription = ref(false);
</script>

<template>
  <template
    v-for="({ itemContent, schema }, idx) in mappedContent"
    :key="schema.name"
  >
    <div
      :ref="
        (container) =>
          updateRef(container as HTMLElement, idx, schema.type === 'apim')
      "
      :class="[
        attrs.class,
        [
          {
            'html-render':
              schema.type !== 'markdown' && schema.type !== 'layout',
          },
        ],
      ]"
      :data-cy-id="`field_${schema.name}`"
    >
      <div v-if="itemContent">
        <SinglelineRenderer
          v-if="schema.type === 'singleline'"
          :schema="schema"
          :value="itemContent as Translated<BlockLocaleValue>"
        />
        <MultilineRenderer
          v-else-if="schema.type === 'multiline'"
          :schema="schema"
          :value="itemContent as Translated<BlockLocaleValue>"
        />
        <template v-else-if="schema.type === 'markdown'">
          <MarkdownRenderer
            v-if="block"
            :schema="schema"
            :value="itemContent as Translated<BlockLocaleValue>"
            :blob-location="blobLocation"
          />
        </template>
        <LinkRenderer
          v-else-if="schema.type === 'link'"
          :schema="schema"
          :value="itemContent as Translated<Link>"
        />
        <template v-else-if="schema.type === 'image'">
          <ImageRenderer
            v-if="block"
            :schema="schema"
            :value="itemContent as Translated<BlockImage>"
            :blob-location="blobLocation"
            class="w-full"
          />
        </template>
        <template v-else-if="schema.type === 'steps'">
          <BlockSteps
            v-if="block"
            :steps="itemContent as Translated<Step[]>[]"
            :schema="schema"
            class="mx-5 mt-20"
            :api-id="apimId"
            :has-api-subscription="hasSubscription"
            :block-id="block.blockId"
            :version-id="block.currentVersionId"
          />
        </template>
        <ApiDetails
          v-else-if="schema.type === 'apim'"
          :config="itemContent as BlockApimDetails"
          :version-number="block.currentVersionNumber"
          @update:has-subscription="(hasSub) => (hasSubscription = hasSub)"
          @container-loaded="(container) => updateRef(container, idx)"
        />
        <InteractiveImageRenderer
          v-else-if="schema.type === 'interactive_image'"
          :value="itemContent as Translated<InteractiveImage>"
          :blob-location="blobLocation"
        />
        <LayoutRenderer
          v-else-if="schema.type === 'layout'"
          :item="itemContent as Translated<LayoutItem>"
          :blob-location="blobLocation"
        />
        <!-- Default renderer if schema type is unknown -->
        <div v-else>
          <MarkdownRenderer
            v-if="block"
            :schema="{ showTitle: false } as Schema"
            :value="itemContent as Translated<BlockLocaleValue>"
            :blob-location="blobLocation"
          />
        </div>
      </div>
    </div>
  </template>
</template>
