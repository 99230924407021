<script setup lang="ts">
import { format, parseISO } from 'date-fns';
import { useI18n } from 'vue-i18n';
import { useThreads } from '@/api/commentoClient';
import { colorFromString } from '@/components/discussion/util';
import { getCommentPageIdParam } from '@/enums/SocialPageType';
import {
  getChatPageParamFromId,
  type MyThreadsComment,
  type chatPageId,
} from '@/api/commentoTypes';
import { translateStringOrLocaleWithDefault } from '@/i18n';
import { socialPageRouteId } from '@/config';

const threads = useThreads();
const { t } = useI18n();

function pageDescription(thread: MyThreadsComment) {
  return translateStringOrLocaleWithDefault(
    thread.pageDescription,
    `social.pages.${getChatPageParamFromId(
      thread.discussionId as chatPageId,
    )}.name`,
  );
}

function formatDatetime(thread: MyThreadsComment) {
  return format(parseISO(thread.created), 'dd.MM.yyyy HH:mm');
}

function avatarName(name: string) {
  return name.charAt(0).toUpperCase();
}

function avatarColor(thread: MyThreadsComment) {
  return colorFromString(thread.userId);
}
</script>

<template>
  <div class="">
    <h2 class="p-2">Mine tråder</h2>
    <div v-if="threads.length === 0" class="my-6">
      {{ t('social.noThreads') }}
    </div>
    <div v-for="thread in threads" :key="thread.commentId" class="my-6">
      <RouterLink
        class="thread-link"
        :to="{
          name: socialPageRouteId,
          params: {
            pageId: getCommentPageIdParam(
              thread.discussionId,
              thread.discussionType,
            ),
            blockId: thread.discussionId,
            commentId: thread.commentId,
          },
        }"
      >
        <p class="text-p3 p-2"># {{ pageDescription(thread) }}</p>
        <div class="flex rounded-lg border p-4">
          <div>
            <div
              class="flex h-10 w-10 items-center justify-center rounded-full text-xl text-gnist-white"
              :style="{ backgroundColor: avatarColor(thread) }"
            >
              {{ avatarName(thread.firstName) }}
            </div>
          </div>
          <div class="w-full pl-3">
            <div class="flex justify-between pb-2 text-xs">
              <p>{{ `${thread.firstName} ${thread.lastName}` }}</p>
              <p>{{ formatDatetime(thread) }}</p>
            </div>
            <div class="underline">
              {{ thread.content }}
            </div>
          </div>
        </div>
      </RouterLink>
    </div>
  </div>
</template>
<style scoped>
/* For some unknown reason, outline-style=solid, doesn't work in "Mine tråder" */
a.thread-link:focus-visible {
  outline-style: auto;
}
</style>
