<script setup lang="ts">
import type { ApiDTO, ApiOperation, ApiSchema } from '@/api/types';
import BlockContentTab from '@/components/BlockContentTab.vue';
import ApiDataRepresentation from '@/components/ApiDataRepresentation.vue';
import MarkdownRenderer from '@/components/block/MarkdownRenderer.vue';
import MaterialIcon from '@/components/MaterialIcon.vue';
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';
import { computeEndpoint } from '@/utilities/computeEndpoint';
import ApiParameterList from '@/views/ApiParameterList.vue';

const { t } = useI18n();

const props = defineProps<{
  operation: ApiOperation;
  apimSchema: Record<string, ApiSchema> | null;
  hasApiSubscription?: boolean;
  versionNumber?: string;
  initialChecked?: boolean;
  endpointData: Pick<ApiDTO, 'gatewayUrl' | 'apiPathUrl'> | null;
}>();

const requestBody = computed(() => {
  const schema = props.operation.schemaOperation
    ? props.apimSchema?.[props.operation.schemaOperation]
    : null;
  const example =
    props.operation.request?.representations?.[0]?.examples?.default?.value;
  const description = props.operation.request?.description;
  if (!schema && !example) return undefined;
  return { schema, example, description };
});
const endpoint = computed(() =>
  computeEndpoint(props.endpointData, props.operation),
);

const initialTab = computed((): 'params' | 'body' | 'response' => {
  if (
    (props.operation.templateParameters ?? []).length > 0 ||
    (props.operation.request?.queryParameters ?? []).length > 0
  ) {
    return 'params';
  } else if (requestBody.value) {
    return 'body';
  } else return 'response';
});
</script>

<template>
  <BlockContentTab
    group-name="operations_tabs"
    :header="operation.name"
    :initial-checked="initialChecked"
    :header-attributes="{ 'data-cy-id': 'ApiOperationHeader' }"
    class="SizeLimitOperationDetails p-4"
  >
    <template #header>
      <RouterLink
        class="underline"
        :to="{
          name: 'console',
          params: { selectedOperation: operation.name },
          query: { version: versionNumber },
        }"
      >
        <MaterialIcon aria-hidden="true" class="text-2xl text-gnist-blue-dark">
          link
        </MaterialIcon>
      </RouterLink>
    </template>

    <MarkdownRenderer
      v-if="operation?.description"
      :value="operation.description"
      blob-location="public"
    />
    <div class="my-4">
      <RouterLink
        v-slot="{ navigate }"
        :to="{
          name: 'console',
          params: { selectedOperation: operation.name },
          query: { version: versionNumber },
        }"
        custom
      >
        <button
          class="gnist-button"
          :disabled="!hasApiSubscription"
          data-cy-id="TryApiButton"
          @click="navigate"
        >
          {{ t('block.tryAPI') }}
        </button>
      </RouterLink>
    </div>
    <h4>{{ t('block.requestUrlHeader') }}</h4>
    <span class="text-monospace mb-4 text-wrap" :data-method="operation.method">
      {{ endpoint }}
    </span>
    <div
      role="tablist"
      class="tabs tabs-lifted [&>.tab-content>.tabs]:-m-2 [&>.tab-content]:p-2"
    >
      <BlockContentTab
        v-if="initialTab === 'params'"
        :group-name="`${operation.name}_details_tabs`"
        :header="t('block.params')"
        initial-checked
        header-type="none"
      >
        {{ initialTab }}
        <ApiParameterList
          :path-params="operation.templateParameters ?? []"
          :query-params="operation.request?.queryParameters ?? []"
        />
      </BlockContentTab>

      <!-- Not currently present in any of the source data we receive, but might be needed later (it is present in APIM) -->
      <BlockContentTab
        v-if="false"
        :group-name="`${operation.name}_details_tabs`"
        :header="t('block.requestHeaders')"
        header-type="none"
      >
        TODO
      </BlockContentTab>

      <BlockContentTab
        v-if="requestBody"
        :group-name="`${operation.name}_details_tabs`"
        :header="t('block.requestBody')"
        header-type="none"
        :initial-checked="initialTab === 'body'"
      >
        <p v-if="requestBody.description">{{ requestBody.description }}</p>
        <div class="my-4">
          <!-- TODO: does this make sense to have here? -->
          <div>
            <span class="font-bold">{{ t('block.typename') }}: </span>
            <span>{{ operation.schemaOperation }}</span>
          </div>
        </div>
        <ApiDataRepresentation
          :group-name="`${operation.name}_requestbody_tabs`"
          :schema="requestBody.schema"
          :example="requestBody.example"
          class="p-2"
        />
      </BlockContentTab>

      <BlockContentTab
        :group-name="`${operation.name}_details_tabs`"
        :header="t('block.responses')"
        header-type="none"
        :initial-checked="initialTab === 'response'"
      >
        <div role="tablist" class="tabs tabs-lifted pt-8">
          <BlockContentTab
            v-for="(resp, resp_index) in operation.responses"
            :key="resp.statusCode"
            :group-name="`${operation.name}_responses_tabs`"
            :header="`${t('block.statusCodeShort')} ${resp.statusCode}`"
            :initial-checked="resp_index === 0"
            header-type="none"
            class="p-2 [&>.tabs]:-m-2"
          >
            <div class="mb-4">
              <div>
                <span class="font-bold">{{ t('block.statusCodeLong') }}: </span>
                {{ resp.statusCode }}
              </div>
              <div>
                <span class="font-bold">
                  {{ t('block.statusDescription') }}:
                </span>
                {{ resp.description }}
              </div>
            </div>
            <div
              v-if="resp.representations.length != 0"
              role="tablist"
              class="tabs tabs-lifted"
            >
              <BlockContentTab
                v-for="(rep, rep_index) in resp.representations"
                :key="rep.typeName"
                :group-name="`${operation.name}_${resp.statusCode}_representations_tabs`"
                :header="`${rep.contentType}`"
                :initial-checked="rep_index === 0"
                header-type="none"
                class="p-2"
              >
                <div class="mt-2">
                  <div class="mb-4">
                    <div>
                      <span class="font-bold">
                        {{ t('block.statusCodeLong') }}:
                      </span>
                      <span>{{ resp.statusCode }}</span>
                    </div>
                    <div>
                      <span class="font-bold">
                        {{ t('block.contentType') }}:
                      </span>
                      <span>{{ rep.contentType }}</span>
                    </div>
                    <!-- TODO: does this make sense to have here? -->
                    <div>
                      <span class="font-bold">{{ t('block.typename') }}: </span>
                      <span>{{ rep.typeName }}</span>
                    </div>
                  </div>
                  <ApiDataRepresentation
                    :group-name="`${operation.name}_${resp.statusCode}_${rep.typeName}_example_tabs`"
                    :schema="apimSchema?.[rep.typeName]"
                    :example="rep.examples.default.value"
                    class="p-2"
                  />
                </div>
              </BlockContentTab>
            </div>
            <div v-else class="mt-6">
              <div>
                <p class="italic">
                  {{ t('block.noDescription') }}
                </p>
              </div>
            </div>
          </BlockContentTab>
        </div>
      </BlockContentTab>
    </div>
  </BlockContentTab>
</template>

<style>
/* Avoid operation details growing outside available space. The way DaisyUI tabs work, this sometimes fails. Therefore we do this bit of magic to ensure everything is correct. */
.SizeLimitOperationDetails {
  max-width: calc(var(--main-content-width) - var(--api-details-padding));
}
:root {
  --representations-tab-padding: 1rem;
  --example-tab-padding: 1rem;
}
.text-monospace {
  font-family: monospace;
  font-size: 1rem;
}
[data-method] {
  padding-left: 3rem;
  display: block;
}
[data-method]:before {
  position: absolute;
  left: 0px;
  /* For some reason, it  looks off by one pixel. This seems to fix it. */
  top: 1px;
  display: inline-block;
  font-size: 0.875rem;
  line-height: 1.5rem;
  vertical-align: middle;
  font-family: monospace;
  font-weight: 700;
  border-radius: 0.25rem;
  width: 3rem;
}
[data-method*='GET']:before {
  content: 'GET';
  color: #038a00;
}

[data-method*='POST']:before {
  content: 'POST';
  color: blue;
}

[data-method*='DELETE']:before {
  content: 'DEL';
  color: #d04040;
}

[data-method*='PUT']:before {
  content: 'PUT';
  color: #876344;
}

[data-method*='HEAD']:before,
[data-method*='PATCH']:before {
  content: 'PATCH';
  color: #696a6b;
}

[data-method*='OPTIONS']:before {
  content: 'OPT';
  color: #696a6b;
}

[data-method*='HEAD']:before {
  content: 'HEAD';
  color: #696a6b;
}

[data-method*='LINK']:before {
  content: 'LINK';
  color: #696a6b;
}

[data-method*='TRACE']:before {
  content: 'TRACE';
  color: #696a6b;
}
</style>
