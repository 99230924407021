<script setup lang="ts">
import { useNotificationCount } from '@/api/status';

const notificationCount = useNotificationCount();
</script>
<template>
  <div
    v-if="notificationCount > 0"
    class="rounded-2xl bg-gnist-red-dark p-1 text-sm leading-none text-white"
  >
    {{ notificationCount }}
  </div>
</template>
