<script lang="ts" setup>
import type { RouteItem, RouteItemDTO, PartialSome } from '@/api/types';
import ContentPage from '@/components/ContentPage.vue';
import RouteEditor from '@/components/RouteEditor.vue';
import { useI18n } from 'vue-i18n';
import { ref } from 'vue';
import {
  createRouteItem,
  deleteRouteItem,
  updateRouteItem,
  useRouteItems,
} from '@/api/menu';
import { useValidationTracking } from '@/utilities/useValidationTracking';
import { hardcodedStaticRoutes } from '@/router/routeUtils';
import TableComponent from '@/components/TableComponent.vue';

const { result: routeItems, refresh } = useRouteItems();

const selectedItem = ref<PartialSome<RouteItemDTO, 'id'> | null>(null);

function triggerValidationMessages() {
  showValidationMessages.value = true; // Show validation messages when editing (in case the validation requirements have changed)
  setTimeout(() => (showValidationMessages.value = false), 1000); // Stop forcing validation messages after a short while (to avoid changing focus each time another value is entered)
}

async function onSave() {
  if (!selectedItem.value) return;
  if (!isValid.value) {
    showValidationMessages.value = true;
    return;
  }
  showValidationMessages.value = false;
  if (selectedItem.value.id === undefined) {
    const updatedItem = await createRouteItem(selectedItem.value);
    routeItems.value?.push(updatedItem);
  } else {
    const updatedItem = await updateRouteItem(selectedItem.value);
    selectedItem.value.routeName = updatedItem.routeName;
  }
  refresh?.();
}

async function onCreateButtonClick() {
  if (!routeItems.value) return null;
  const newItem: PartialSome<RouteItemDTO, 'id'> &
    Pick<RouteItem, 'routeType'> = {
    routeName: null as unknown as string,
    text: { no: '' },
    routeType: 'page',
    targetMenu: 'main',
    id: undefined,
  };
  return newItem;
}

async function onDelete(itemToDelete: RouteItemDTO) {
  if (!itemToDelete.id) return;
  await deleteRouteItem(itemToDelete.id);
  refresh?.();
  routeItems.value =
    routeItems.value?.filter((i) => i.id !== itemToDelete.id) ?? [];
}

const { t } = useI18n();

const showValidationMessages = ref<boolean>(false);
const { onValidChanged, formRef, isValid } = useValidationTracking(
  selectedItem,
  showValidationMessages,
  true,
);
</script>
<template>
  <ContentPage :title="t('routeAdmin.title')" class="bg-gnist-gray-light-light">
    <div class="pb-8">
      <TableComponent
        size="table-xs"
        :rows="routeItems"
        show-spinner
        i18n-key="routeAdmin"
        :get-key="(routeItem) => routeItem.id"
        :columns="[
          'text',
          'parent',
          'routeName',
          'routeType.title',
          'sortOrder',
        ]"
        row-class="cursor-pointer"
        :create="{
          title: 'routeAdmin.create',
          idProperty: 'id',
          onCreateClick: onCreateButtonClick,
        }"
        :edit="{
          title: 'routeAdmin.viewRoute',
          onSave,
          isSelectedItemValid: isValid,
          modalProps: { size: 'big', showOverflow: true },
        }"
        :delete="{
          onDelete,
          getIdentificator: (row) => row.routeName,
          identificatorLabel: 'routeAdmin.routeName',
          title: 'routeAdmin.delete.title',
          warning: 'routeAdmin.delete.warning',
        }"
        @update:selected-item="(item) => (selectedItem = item)"
        @on:update-triggered="triggerValidationMessages"
      >
        <template #afterCreate>
          <RouterLink
            :to="{ path: '/usermanual/about_routes' }"
            target="_blank"
            class="mx-4 underline hover:bg-gnist-gray-light"
          >
            {{ t('routeAdmin.help') }}
          </RouterLink>
        </template>
        <template #columnHeader="{ item: routeItem }">
          {{ routeItem.text.no }}
        </template>
        <template #columns="{ item: routeItem }">
          <td>
            {{
              routeItems!.find((i) => i.routeName == routeItem.parent)?.text.no
            }}
          </td>
          <td>
            {{
              routeItem.routeType === 'hardcoded'
                ? t('routeAdmin.routeType.values.hardcoded') +
                  ': ' +
                  t(
                    hardcodedStaticRoutes.find(
                      (r) => r.name === routeItem.routeName,
                    )?.sourceTitle ?? '',
                  )
                : routeItem.routeName
            }}
          </td>
          <td>
            {{ t('routeAdmin.routeType.values.' + routeItem.routeType) }}
          </td>
          <td>
            {{ routeItem.sortOrder }}
          </td>
        </template>
        <template #editor>
          <form ref="formRef">
            <RouteEditor
              v-model="selectedItem"
              :all-items="routeItems ?? []"
              @report-validation-error="
                (name, hasError) => onValidChanged(name, !hasError)
              "
            />
          </form>
        </template>
      </TableComponent>
    </div>
  </ContentPage>
</template>

<style scoped>
:deep(th),
:deep(td) {
  text-align: left;
  padding: 1rem 0.5rem;
  text-overflow: ellipsis;
}
</style>
