import { onMounted } from 'vue';

/**
 * This helper can be used if different components loads conflicting CSS (e.g. different themes).
 * This will ensure that the CSS that can be identified by the given value in a attribute of either href (in prod) or data-vite-dev-id (in dev) will be put as the last one loaded in the head.
 */
export function useCssReorder(cssIdentifier: string) {
  onMounted(() => {
    const themeStyleNode = document.querySelector(
      `[href*=${cssIdentifier}],[data-vite-dev-id*=${cssIdentifier}]`,
    );
    if (themeStyleNode)
      document.querySelector('head')?.appendChild(themeStyleNode);
  });
}
