<script setup lang="ts">
import type { IInputLabelProps } from './InputLabel.vue';
import Picker from '@/components/forms/InputPicker.vue';
import { useInternalState } from '@/utilities/useInternalState';
import { type TranslatedBlock, useBlockListLazy } from '@/api/blocks';
import { useI18n } from 'vue-i18n';
import type { Guid } from '@/api/types';
import { toRef, watch } from 'vue';
import { nextTick } from 'vue';
import { sleep } from '@/utilities/sleep';

const props = defineProps<
  IInputLabelProps & {
    noChrome?: boolean;
    modelValue: Guid | undefined;
  }
>();
const emit = defineEmits<{
  (e: 'update:modelValue', value: typeof props.modelValue): void;
  (e: 'update:valid', valid: boolean): void;
  (e: 'changed', value: TranslatedBlock | null): void;
  (e: 'loaded'): void;
  (e: 'focus'): void;
  (e: 'blur'): void;
}>();
const { result: blocks } = useBlockListLazy();

const { t } = useI18n();

const value = useInternalState<typeof props.modelValue, TranslatedBlock | null>(
  toRef(props, 'modelValue'),
  null,
  (val) => emit('update:modelValue', val),
  (newExtState) => {
    if (!newExtState || !blocks.value) {
      return null;
    } else {
      return blocks.value.find((b) => b.blockId === newExtState) ?? null;
    }
  },
  (newIntState) => newIntState?.blockId,
  true,
  true,
);
watch(
  blocks,
  async () => {
    if (!blocks.value) return;
    if (props.modelValue) {
      value.value =
        blocks.value.find((b) => b.blockId === props.modelValue) ?? null;
    }
    await nextTick();
    emit('loaded');
  },
  { immediate: true },
);
watch(value, () => emit('changed', value.value));

async function searchBlocks(query: string): Promise<TranslatedBlock[]> {
  const maxWaitTime = 2000;
  let elapsed = 0;
  while (!blocks.value) {
    await sleep(100);
    elapsed += 100;
    if (elapsed > maxWaitTime) {
      break;
    }
  }
  if (!blocks.value) return [];
  const itemLimit = 10;
  const matches =
    query.trim() == ''
      ? blocks.value
      : blocks.value.filter((b) => b.name.toLowerCase().includes(query));
  return matches.slice(0, itemLimit);
}
</script>

<template>
  <Picker
    v-bind="props"
    v-model="value"
    :getkey="(val) => val?.blockId ?? ''"
    :gettext="
      (val) =>
        (val?.name ?? '') +
        (val?.status === 'Draft'
          ? ` (${t('block.schema.blockSelectorBlockIsDraft')})`
          : '')
    "
    :taglistclass="[
      'w-full grow [&>*]:grow',
      { '[&>*]:justify-between': !noChrome },
    ]"
    :filter="searchBlocks"
    :max-items="1"
    show-empty-suggestions
    class="row-span-2 md:mr-4"
    @focus="emit('focus')"
    @blur="emit('blur')"
  />
</template>
