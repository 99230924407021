<script setup lang="ts">
import ContentPage from '../components/ContentPage.vue';
import BlockList from '../components/BlockList.vue';
import DocumentsOverview from '@/components/DocumentsOverview.vue';
import { useI18n } from 'vue-i18n';
import CommentAssessmentOverview from '@/components/CommentAssessmentOverview.vue';
import { editBlockVersionsRouteId } from '@/config';
import { useStoreAbility } from '@/abilities';
import { ref } from 'vue';
import SimpleTabSelector from '@/components/SimpleTabSelector.vue';

const { t } = useI18n();
const { can } = useStoreAbility();
const tabs = [
  'mydocsListHeader',
  'mydocsForumHeader',
  'overviewToPublishHeader',
] as const;
const activeTab = ref<(typeof tabs)[number]>('mydocsListHeader');
</script>

<template>
  <ContentPage
    :title="t('admin.management.mydocsTitle')"
    :description="t('admin.management.mydocsDescription')"
    :hide-description-mobile="true"
    class="bg-gnist-gray-light-light"
  >
    <SimpleTabSelector
      v-model="activeTab"
      :tabs="tabs"
      i18n-key="admin.management"
    />
    <DocumentsOverview
      v-show="activeTab === 'mydocsListHeader'"
      :class="{ test: activeTab === 'mydocsListHeader' }"
      headerlabel="admin.management.mydocsListHeader"
      type="MyDocuments"
    />
    <CommentAssessmentOverview
      v-show="activeTab === 'mydocsForumHeader'"
      label="admin.management.mydocsForumHeader"
      :include-all-blocks="false"
      :get-non-block-comments="false"
      hide-type-prefix
      @loaded="
        (items) => {
          if (items.length > 0) activeTab = 'mydocsForumHeader';
        }
      "
    />
    <section
      v-if="can('read', 'MyDocumentsToPublish')"
      v-show="activeTab === 'overviewToPublishHeader'"
      class="py-8"
    >
      <h2 class="mb-4">
        {{ t('admin.management.overviewToPublishHeader') }}
      </h2>
      <div class="p-8">
        <BlockList
          :route-name="editBlockVersionsRouteId"
          :options="{ mode: 'approval' }"
          hide-all-filters
          card-template-id="searchCard"
        />
      </div>
    </section>
  </ContentPage>
</template>
