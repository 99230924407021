<script lang="ts">
import type { ArrayItem, VueClass } from '@/api/types';
import type { StyleValue } from 'vue';

type ClassList = ArrayItem<VueClass>[];

export function getOuterCssProperties(
  item: Translated<LayoutItem>,
): StyleValue {
  return {
    '--col-span': item.colSpan,
  };
}
export function getInnerCssProperties(
  item: Translated<LayoutItem>,
  isEditing?: boolean,
): StyleValue | undefined {
  if (item.mode === 'grid') {
    return {
      '--mobile-column-count': item.cols ?? 1,
      '--md-column-count': item.mdCols ?? 1,
    };
  } else if (forceToGridMode(item, isEditing)) {
    return { '--mobile-column-count': 1, '--md-column-count': 1 };
  } else {
    return undefined;
  }
}

/** Get the classes that should be added to both LayoutOuterBlock and LayoutBlock */
export function getOuterAndInnerLayoutClasses(
  item: Translated<LayoutItem>,
  addShrinkGrowData?: AddShrinkGrowData,
): ClassList {
  let classes: ClassList = [item.vAlign, item.hAlign];
  if (addShrinkGrowData) {
    classes = classes.concat([
      { 'shrink grow': !isAbsoluteFlexSize(item, addShrinkGrowData) },
    ]);
  }
  return classes;
}

/** Get the classes that are added to the link item (if there is a link), or to the main LayoutBlock if there is no link */
export function getLinkLevelClasses(item: Translated<LayoutItem>): ClassList {
  return [
    item.bgColor ? `bg-gnist-${item.bgColor}` : '',
    'min-w-0',
    { 'w-full': !item.width?.w },
    { 'h-full': !item.height?.h },
    { 'rounded-md shadow-md': item.boxStyle === 'gnistLink' },
    { 'alert alert-warning rounded': item.boxStyle === 'daisyUIwarning' },
  ];
}

/** Get the classes that should only be added to the LayoutBlock */
export function getLayoutClasses(
  item: Translated<LayoutItem>,
  isEditing?: boolean,
): ClassList {
  return getBlockAlignmentClasses(item)
    .concat([
      forceToGridMode(item, isEditing) ? 'grid' : item.mode,
      ...Object.values(item.width ?? {}),
      ...Object.values(item.height ?? {}),
      ...Object.values(item.padding ?? {}),
      ...Object.values(item.position ?? {}),
    ])
    .concat(getOuterAndInnerLayoutClasses(item));
}

/** Get classes related to block alignment. These will be added to both the LayoutBlock and the MarkdownRenderer (if one is present) */
function getBlockAlignmentClasses(
  item: Translated<LayoutItem>,
  addMode = false,
): ClassList {
  if (item.mode === 'block') return [];
  let classes: ClassList = [
    item.alignContent,
    item.alignItems,
    item.justifyContent,
    ...Object.values(item.gap ?? {}),
  ]
    .concat(item.mode === 'flex' ? [item.direction, item.wrap] : [])
    .concat(item.mode === 'grid' ? [item.justifyItems] : []);
  if (addMode) {
    classes = classes.concat([item.mode]);
  }
  return classes;
}

export type AddShrinkGrowData = {
  parentMode?: LayoutItem['mode'];
  parentDirection?: LayoutItem['direction'];
};

/** Render the block as a 1x1 grid (to take advantage of grid mode alignment),\
 *  given that one of these is true:\
 *  **1)** the block has "content alignment", or\
 *  **2)** we are in "inline editing" mode
 */
function forceToGridMode(
  item: Translated<LayoutItem>,
  isEditing: boolean | undefined,
): boolean {
  return !!isEditing || (!item.children && (!!item.vAlign || !!item.hAlign));
}
function isAbsoluteFlexSize(
  item: Translated<LayoutItem>,
  addShrinkGrowData: AddShrinkGrowData,
) {
  if (addShrinkGrowData.parentMode !== 'flex') return false;
  const verticalFlow =
    addShrinkGrowData.parentDirection === 'flex-col' ||
    addShrinkGrowData.parentDirection === 'flex-col-reverse';
  if (verticalFlow) return !!item.height?.h;
  else return !!item.width?.w;
}
</script>

<script setup lang="ts">
import type { LayoutItem, Translated } from '@/api/types';
import type { ComponentPublicInstance } from 'vue';
import type { IBlobUrlInput } from '@/api/blob';
import MarkdownRenderer from '@/components/block/MarkdownRenderer.vue';
import ImageRenderer from '@/components/block/ImageRenderer.vue';
import BlockList from '@/components/BlockList.vue';
import TagList from '@/components/TagList.vue';
import type { ConnectionInfo } from '@/components/BlockListConnectionHelper';
import SearchBar from '@/components/SearchBar.vue';
import { getDocumentRouteFromListRoute } from '@/utilities/routeUtils';

defineProps<{
  item: Translated<LayoutItem>;
  blobLocation: IBlobUrlInput;
  onRefCreated: (ref: Element | ComponentPublicInstance | null) => void;
  connection: ConnectionInfo | undefined;
  editorPreview?: boolean;
}>();
</script>

<template>
  <MarkdownRenderer
    v-if="item.contentType === 'markdown' && item.markdown !== undefined"
    :value="item.markdown"
    :blob-location="blobLocation"
    :class="getBlockAlignmentClasses(item, true)"
    :remove-link-href="editorPreview"
  />
  <BlockList
    v-else-if="item.contentType === 'doclist' && item.doclist !== undefined"
    :ref="onRefCreated"
    class="gnist-page-width pb-8"
    :style="item.doclist.style"
    :route-name="getDocumentRouteFromListRoute(item.doclist.doclistRoute)"
    :options="{
      mode: 'normal',
      includeBacklog: item.doclist.includeBacklog,
    }"
    :show-backlog-filter="item.doclist.includeBacklog"
    :category-id-filter="item.doclist.categoryIds"
    :hide-search="!item.doclist.showSearch"
    :hide-org-filter="!item.doclist.showOrgFilter"
    :hide-category-filter="!item.doclist.showCategoryFilter"
    :hide-tag-filter="!item.doclist.showTagFilter"
    :max-items="item.doclist.maxItems"
    :card-template-id="item.doclist.cardTemplateId"
    :config-search-text="item.doclist.searchText"
    :pre-selected-tags="
      item.doclist.tags.length === 0 ? undefined : item.doclist.tags
    "
    :exclude-tags="
      item.doclist.notTags?.length === 0 ? undefined : item.doclist.notTags
    "
    :pre-selected-org-ids="item.doclist.orgIds"
    :connection="connection"
  />
  <div
    v-else-if="item.contentType === 'searchbar' && item.searchbar !== undefined"
  >
    <SearchBar
      class="text-p2 my-2 w-full"
      :style="item.searchbar.style"
      :initial-value="item.searchbar.searchText"
      :target-route="connection ? undefined : item.searchbar.doclistRoute"
      :connection="connection"
    />
  </div>
  <div
    v-else-if="item.contentType === 'image' && item.image !== undefined"
    class="h-full w-full"
  >
    <ImageRenderer
      :value="item.image"
      :blob-location="blobLocation"
      class="h-full min-h-0 [&>img]:h-full"
      no-default-class
    />
  </div>
  <TagList
    v-else-if="item.contentType === 'taglist' && item.taglist !== undefined"
    :group="item.taglist.list"
    :max-items="item.taglist.maxItems"
    :show-all-tags="item.taglist.showAllTags"
    :show-group-label="item.taglist.showGroupLabel"
  />
</template>
