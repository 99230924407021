import { useCurrentUser, type RoleName } from '@/api/auth';
import type { TranslatedBlock } from '@/api/blocks';
import { computed, type ComputedRef, type Ref } from 'vue';

export function useAudienceTargeting(
  blocks: Ref<TranslatedBlock[] | null>,
  returnAll?: boolean,
): ComputedRef<TranslatedBlock[] | undefined> {
  const user = useCurrentUser();
  return computed(() => {
    if (returnAll) return blocks.value ?? undefined;
    const roles: RoleName[] = user.value?.roles ?? ['anonymous'];
    return blocks.value?.filter(
      (block) =>
        !block.audiences ||
        block.audiences.length === 0 ||
        block.audiences.some((audience) => roles.includes(audience)),
    );
  });
}
