export function blockValueEquals<T>(
  a: T,
  b: T,
  removeSourceProp = false,
): boolean {
  if (removeSourceProp && typeof a === 'object' && typeof b === 'object') {
    return (
      stringifyBlockValue({ ...a, _source: undefined }) ===
      stringifyBlockValue({ ...b, _source: undefined })
    );
  }
  return stringifyBlockValue(a) === stringifyBlockValue(b);
}
export function stringifyBlockValue<T>(
  val: T | null | undefined,
): string | undefined {
  if (val === null || val === undefined) return undefined;
  return JSON.stringify(val)
    ?.replace(/"localeUpdates":\{[^}]*\}/g, '')
    .replace(/"localeUpdates":"[^"]*"/g, '');
}
