<script lang="ts" setup>
import type { SingleItem } from '@/api/types';
import ContentPage from '@/components/ContentPage.vue';
import Dropdown from '@/components/forms/InputDropdown.vue';
import { useI18n } from 'vue-i18n';
import { ref, watch } from 'vue';
import SearchBar from '@/components/SearchBar.vue';
import { computed } from 'vue';
import { useRoles, useUsers, updateRoles } from '@/api/user';
import { mapValueList } from '@/utilities/mapValueList';
import type { RoleName } from '@/api/auth';
import TableComponent from '@/components/TableComponent.vue';

const users = useUsers();
type UserForAdmin = NonNullable<SingleItem<typeof users.value>>;
const { result: roles } = useRoles();
const searchText = ref<string>('');
const roleOptions = computed(() =>
  mapValueList(
    roles.value?.map((r) => r.name) ?? [],
    'userAdmin.manualRoles',
    true,
  ),
);
const roleFilter = ref<RoleName>();

const viewDetailsUser = ref<UserForAdmin | null>(null);
const rolesModel = ref<RoleName[]>([]);

watch(viewDetailsUser, () => {
  rolesModel.value = [...(viewDetailsUser.value?.roles ?? [])];
});

async function onSave() {
  const roleIds = roles.value
    ?.filter((v) => rolesModel.value.includes(v.name))
    .map((v) => v.id);
  if (roleIds && viewDetailsUser.value) {
    await updateRoles(viewDetailsUser.value.id, roleIds);
  }
  if (viewDetailsUser.value)
    viewDetailsUser.value.roles = [...rolesModel.value];
}

const { t } = useI18n();

const matchesSearch = (user: UserForAdmin) => {
  const searchLower = searchText.value.toLowerCase();
  return (
    (!roleFilter.value || user.roles?.includes(roleFilter.value)) &&
    (user.firstName?.toLowerCase().includes(searchLower) ||
      user.lastName?.toLowerCase().includes(searchLower) ||
      user.email?.toLowerCase().includes(searchLower) ||
      !user.roles?.every((role) => !role.toLowerCase().includes(searchLower)) ||
      !user.roles
        ?.map((r) => t(`userAdmin.manualRoles.values.${r}`))
        .every((role) => !role.toLowerCase().includes(searchLower)))
  );
};
const filteredUsers = computed(() => users.value?.filter(matchesSearch) ?? []);
</script>
<template>
  <ContentPage :title="t('userAdmin.title')" class="bg-gnist-gray-light-light">
    <div class="flex">
      <SearchBar
        class="my-4 w-full text-black lg:mx-auto lg:w-11/12 lg:max-w-4xl"
        @change="(text: string) => (searchText = text)"
      />
      <Dropdown
        v-model="roleFilter"
        :options="roleOptions"
        :placeholder="t('userAdmin.selectRolePlaceholder')"
        :getkey="(o) => o"
        class="flex items-center self-stretch"
      />
    </div>
    <div class="pb-8">
      <TableComponent
        size="table-lg"
        :rows="filteredUsers"
        show-spinner
        i18n-key="profile"
        :get-key="(user) => user.id"
        :columns="['email', 'fullName', 'roles', 'lastLogin']"
        :sort-fields="['email', 'fullName', 'roles', 'lastLogin']"
        row-class="cursor-pointer"
        column-header-class="font-normal break-all"
        :edit="{
          title: 'userAdmin.viewUser',
          onSave,
          isSelectedItemValid: true,
        }"
        @update:selected-item="(user) => (viewDetailsUser = user)"
      >
        <template #columnHeader="{ item: user }">
          {{ user.email }}
        </template>
        <template #columns="{ item: user }">
          <td>{{ `${user.firstName} ${user.lastName}` }}</td>
          <td class="w-1/4">
            {{
              user.roles
                ?.map((r) => t(`userAdmin.manualRoles.values.${r}`))
                .join(', ') ?? ''
            }}
          </td>
          <td class="w-max">
            {{
              user.lastLogin ? new Date(user.lastLogin).toLocaleString() : ''
            }}
          </td>
        </template>
        <template #editor>
          <div v-if="viewDetailsUser">
            {{ viewDetailsUser.fullName }}
            <h3 class="mt-8 text-lg">
              {{ t('profile.roles') }}
            </h3>
            <div v-for="role in roles" :key="role.id">
              <input
                :id="`role-${role.name}`"
                v-model="rolesModel"
                type="checkbox"
                class="mr-2 mt-2"
                :value="role.name"
              />
              <label :for="`role-${role.name}`">
                {{ t(`userAdmin.manualRoles.values.${role.name}`) }}
              </label>
            </div>
          </div>
        </template>
      </TableComponent>
    </div>
  </ContentPage>
</template>
<style scoped>
:deep(th),
:deep(td) {
  text-align: left;
  padding: 1rem 0.5rem;
  text-overflow: ellipsis;
}
</style>
