<script setup lang="ts">
import type { Link, PartialSome, Translated } from '@/api/types';
import { computed, ref, watchEffect } from 'vue';
import ModalComponent from '../ModalComponent.vue';
import ButtonComponent from '../ButtonComponent.vue';
import InputLinkBase from '../InputLinkBase.vue';
import { useI18n } from 'vue-i18n';
import InputLabel, {
  useDynamicLabelTexts,
  type IInputLabelProps,
} from './InputLabel.vue';
import { useNestedMVDefault } from '@/utilities/useInternalState';

const { t } = useI18n();

const props = defineProps<
  IInputLabelProps & {
    modelValue: PartialSome<Translated<Link>, 'text' | 'alt'> | undefined;
    /** Used for connecting label and input */
    forName: string;
    showValidationMessage?: boolean;
    inline?: boolean;
    hideText?: boolean;
    hideDescription?: boolean;
    canTargetBlocks?: boolean;
    canTargetHardcoded?: boolean;
    canTargetRoutes?: boolean;
    cannotTargetExternal?: boolean;
    alwaysShowTypeSelect?: boolean;
  }
>();

const emit = defineEmits<{
  (e: 'update:modelValue', value: typeof props.modelValue): void;
  (e: 'update:valid', valid: boolean): void;
}>();

const value = useNestedMVDefault(
  props,
  (val) => emit('update:modelValue', val),
  { url: '' },
);

const showLinkModal = ref<boolean>(false);

const oldLink = ref<typeof props.modelValue>({
  url: '',
  text: '',
  alt: '',
});

const isValid = ref(true);
watchEffect(() => emit('update:valid', isValid.value));

const disableSave = computed(() => {
  const unChanged =
    oldLink.value?.url == value.value?.url &&
    oldLink.value?.text == value.value?.text &&
    oldLink.value?.alt == value.value?.alt;
  return !isValid.value || !value.value?.url || unChanged;
});

const openModal = async () => {
  oldLink.value = Object.assign({}, value.value);
  showLinkModal.value = true;
};

const onSave = async () => {
  showLinkModal.value = false;
};
const onCancel = async () => {
  if (oldLink.value) value.value = oldLink.value;
  showLinkModal.value = false;
};

const showSelector = computed(() => {
  let optionCount = 0;
  if (props.canTargetBlocks) optionCount++;
  if (props.canTargetHardcoded) optionCount++;
  if (props.canTargetRoutes) optionCount++;
  return optionCount > 1 || (optionCount === 1 && props.alwaysShowTypeSelect);
});
const selectedType = ref<'block' | 'hardcoded' | 'route' | 'link'>();
const texts = useDynamicLabelTexts(props);
</script>

<template>
  <label :for="forName" class="flex flex-col whitespace-nowrap">
    <InputLabel v-bind="props" limit-label-start>
      <template #labelEnd>
        <div
          v-if="showSelector"
          class="flex max-w-max grow flex-wrap text-xl"
          role="listbox"
        >
          <ButtonComponent
            v-if="props.canTargetBlocks"
            class="!flex w-8 items-center justify-center hover:bg-gnist-gray-light"
            :class="{ 'bg-gnist-gray': selectedType === 'block' }"
            text="block.schema.linkTypeBlock"
            icon="article"
            icon-class="text-xl"
            :selected="selectedType === 'block'"
            @click="selectedType = 'block'"
          />
          <ButtonComponent
            v-if="props.canTargetHardcoded"
            class="!flex w-8 items-center justify-center hover:bg-gnist-gray-light"
            :class="{ 'bg-gnist-gray': selectedType === 'hardcoded' }"
            text="block.schema.linkTypeRoute"
            icon="apps"
            icon-class="text-xl"
            :selected="selectedType === 'hardcoded'"
            @click="selectedType = 'hardcoded'"
          />
          <ButtonComponent
            v-if="props.canTargetRoutes"
            class="!flex w-8 items-center justify-center hover:bg-gnist-gray-light"
            :class="{ 'bg-gnist-gray': selectedType === 'route' }"
            text="block.schema.linkTypeRouteItem"
            icon="route"
            icon-class="text-xl"
            :selected="selectedType === 'route'"
            @click="selectedType = 'route'"
          />
          <ButtonComponent
            v-if="!props.cannotTargetExternal"
            class="!flex w-8 items-center justify-center hover:bg-gnist-gray-light"
            :class="{ 'bg-gnist-gray': selectedType === 'link' }"
            text="block.schema.linkTypeLink"
            icon="language"
            icon-class="text-xl"
            :selected="selectedType === 'link'"
            @click="selectedType = 'link'"
          />
        </div>
      </template>
    </InputLabel>
    <InputLinkBase
      v-if="inline"
      v-model="value"
      :for-name="forName"
      :data-cy-id="`InputLinkInput_${texts.forName}`"
      :required="required"
      :hide-text="hideText"
      :hide-description="hideDescription"
      :show-validation-message="showValidationMessage"
      :can-target-blocks="canTargetBlocks"
      :can-target-hardcoded="canTargetHardcoded"
      :can-target-routes="canTargetRoutes"
      :cannot-target-external="cannotTargetExternal"
      :selected-type="selectedType"
      @update:valid="(valid) => (isValid = valid)"
      @update:selected-type="(type) => (selectedType = type)"
    />
    <div v-else class="flex items-center">
      <div>
        <button
          class="gnist-button"
          :data-cy-id="`InputLinkSelect_${texts.forName}`"
          @click.prevent="openModal"
        >
          {{
            value?.url
              ? t('block.schema.changeLink')
              : t('block.schema.chooseLink')
          }}
        </button>
      </div>
      <div v-if="value?.url" class="ml-4">
        <a
          :href="value.url"
          :alt="value.alt"
          target="_blank"
          class="underline"
          >{{ value.text != '' ? value.text : value.url }}</a
        >
      </div>
      <div v-else class="ml-4">
        <p>{{ t('block.schema.noLinkGiven') }}</p>
      </div>
    </div>

    <ModalComponent
      :show-modal="showLinkModal"
      :title="t('block.schema.editLink')"
      @close="onCancel"
    >
      <template #default>
        <label class="mt-6 block">{{ t('block.schema.linkUrlTitle') }}</label>
        <InputLinkBase
          v-model="value"
          :for-name="forName"
          :data-cy-id="`InputLinkInput_${texts.forName}`"
          :required="required"
          :hide-text="hideText"
          :hide-description="hideDescription"
          :show-validation-message="showValidationMessage"
          :can-target-blocks="canTargetBlocks"
          :can-target-hardcoded="canTargetHardcoded"
          :can-target-routes="canTargetRoutes"
          :cannot-target-external="cannotTargetExternal"
          :selected-type="selectedType"
          @update:valid="(valid) => (isValid = valid)"
          @update:selected-type="(type) => (selectedType = type)"
        />
      </template>
      <template #buttons>
        <div class="flex justify-end">
          <div class="gnist-button-group">
            <ButtonComponent :text="t('buttons.cancel')" @click="onCancel" />
            <ButtonComponent
              :disabled="disableSave"
              :text="t('admin.blockProduction.save')"
              type="primary"
              :data-cy-id="`InputLinkSave_${texts.forName}`"
              @click="onSave"
            />
          </div>
        </div>
      </template>
    </ModalComponent>
  </label>
</template>
