<script lang="ts">
export function getEndPointWithParams(
  endPoint: string,
  pathParams: Record<string, string>,
  queryParams: Record<string, string>,
) {
  let queryString = Object.entries(queryParams)
    .filter(([, value]) => !!value)
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
    )
    .join('&');
  if (queryString) {
    queryString = '?' + queryString;
  }
  return format(endPoint, pathParams) + queryString;
}
</script>
<script setup lang="ts">
import { computed, ref } from 'vue';
import { templates } from '@/utilities/templates';
import { Liquid } from 'liquidjs';
import type {
  ApiDTO,
  ConsoleOperation,
  ConsoleHeader,
  RequestHeader,
} from '@/api/types';
import format from 'string-template';
import { useI18n } from 'vue-i18n';
import hljsVuePlugin from '@highlightjs/vue-plugin';
import { useCssReorder } from '@/utilities/useCssReorder';
import CopyButton from './CopyButton.vue';

import('highlight.js/styles/stackoverflow-dark.css');

const HighlightJs = hljsVuePlugin.component;
useCssReorder('stackoverflow-dark');

const { t } = useI18n();

const props = defineProps<{
  method: string | undefined;
  headers: RequestHeader[] | undefined;
  apiData: ApiDTO | null;
  apiKey: string | null;
  endPoint: string;
  pathParams: Record<string, string>;
  queryParams: Record<string, string>;
  body: string;
  token: string | undefined;
}>();

const selectedLanguage = ref<keyof typeof templates>('HTTP');
const showSecrets = ref(false);
const languages = Object.keys(templates);
const engine = new Liquid();

const endPointWithParams = computed(() =>
  getEndPointWithParams(props.endPoint, props.pathParams, props.queryParams),
);

function getCodeSample(showKeys: boolean) {
  if (!props.headers || !props.apiData) return '';

  const authHeader = {
    name: props.apiData.subscriptionKeyHeader,
    value: props.apiKey,
    hiddenValue: props.apiKey
      ? '•••••••••••••••••••'
      : t('console.orderAccess'),
    secret: true,
  } as ConsoleHeader;

  const bearerHeader = {
    name: 'Authorization',
    value: 'Bearer ' + props.token,
    hiddenValue: 'Bearer •••••••••••••••••••',
    secret: true,
  } as ConsoleHeader;

  const requestHeaders = props.headers.map((h) => {
    return {
      name: h.name,
      value: h.parameterContractType,
    } as ConsoleHeader;
  });

  const headers = props.token
    ? [authHeader, bearerHeader, ...(requestHeaders ?? [])]
    : [authHeader, ...(requestHeaders ?? [])];

  const consoleOperation = {
    method: props.method,
    requestUrl: endPointWithParams.value,
    request: {
      meaningfulHeaders: headers,
      bodyFormat: 'raw',
      body: props.body,
    },
  } as ConsoleOperation;

  const languageKey: string = selectedLanguage.value;
  const template = templates[languageKey as keyof typeof templates];

  const sample = engine.parseAndRenderSync(template, {
    console: consoleOperation,
    showSecrets: showKeys,
  });

  return sample;
}

const codeSample = computed(() => getCodeSample(showSecrets.value));
const codeSampleWithKeys = computed(() => getCodeSample(true));
</script>

<template>
  <section class="rounded-t-2xl bg-gnist-gray-dark p-8">
    <div>
      <h2 class="mb-6 text-gnist-white">
        {{ t('console.httpRequest') }}
      </h2>
      <select
        v-model="selectedLanguage"
        class="mt-2 h-8 w-full rounded border text-gnist-black"
      >
        <option
          v-for="(language, index) in languages"
          :key="index"
          :value="language"
        >
          {{ language }}
        </option>
      </select>

      <div class="mb-10 mt-2 flex flex-col flex-wrap sm:flex-row">
        <a
          class="inline-flex w-32 cursor-pointer items-center text-sm text-gnist-white"
          role="button"
          @click="showSecrets = !showSecrets"
          >{{ showSecrets ? t('console.hideKeys') : t('console.showKeys') }}
          <span
            role="img"
            aria-hidden="true"
            class="material-symbols-rounded ml-1 mr-5 text-xl text-gnist-white"
          >
            key
          </span>
        </a>

        <CopyButton :text="codeSampleWithKeys" label="console.copy" />
        <CopyButton
          v-if="apiKey != undefined"
          :text="apiKey"
          label="console.copyKey"
        />
        <CopyButton
          v-if="token != undefined"
          :text="token"
          label="console.copyToken"
        />
      </div>
    </div>
    <div class="code-sample break-words text-sm">
      <HighlightJs autodetect :code="codeSample" />
    </div>
  </section>
</template>

<style>
.v-enter-active {
  transition: opacity 0.5s ease;
}

.v-enter-from {
  opacity: 0;
}

.code-sample pre code.hljs {
  overflow: hidden;
  white-space: pre-wrap;
  background-color: inherit;
  padding: 0;
}
</style>
