<script setup lang="ts">
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
</script>
<template>
  <div class="grow">
    <div class="gnist-page-width mx-2 mt-0 p-4 lg:p-16">
      <h1>{{ t('error.page.unauthorizedTitle') }}</h1>
    </div>
  </div>
</template>
