<script setup lang="ts">
import { deleteDraft, deleteVersion } from '@/api/versions';
import MaterialIcon from '@/components/MaterialIcon.vue';
import ModalComponent from '../ModalComponent.vue';
import type { Block, Version } from '@/api/types';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps<{
  version: Version;
  block: Block;
  deleteType: 'Draft' | 'Version';
  asButton?: boolean;
}>();

const emit = defineEmits<{
  (e: 'refreshBlock', noBubble?: boolean): void;
}>();

const versionToDelete = ref<Version | null>(null);

async function doDelete() {
  if (versionToDelete.value) {
    if (props.deleteType === 'Draft') {
      deleteDraft(props.block.blockId, versionToDelete.value.versionId);
    } else {
      deleteVersion(props.block.blockId, versionToDelete.value.versionId);
    }
  }
  emit('refreshBlock', true);
  versionToDelete.value = null;
}
</script>

<template>
  <MaterialIcon
    v-if="asButton"
    :title="t(`admin.blockProduction.delete${deleteType}`)"
    class="cursor-pointer text-gnist-red"
    @click.prevent="versionToDelete = version"
  >
    delete
  </MaterialIcon>
  <a v-else class="text-gnist-red" @click="versionToDelete = version">
    {{ t(`admin.blockProduction.delete${deleteType}`) }}
    <template v-if="deleteType === 'Version'">
      {{ version.versionNumber }}
    </template>
  </a>
  <ModalComponent
    :show-modal="versionToDelete !== null"
    :title="t(`admin.blockProduction.confirmDelete${deleteType}`)"
    size="auto"
    @close="versionToDelete = null"
    @handle-click="doDelete"
  >
    <template #default>
      <p>
        {{ t(`admin.blockProduction.deleteBlock${deleteType}Permanently`) }}
      </p>
    </template>
  </ModalComponent>
</template>
