<script lang="ts" setup>
import ContentPage from '../components/ContentPage.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import EditOrganizationComponent from '@/components/EditOrganizationComponent.vue';
import MaterialIcon from '@/components/MaterialIcon.vue';
import ModalComponent from '@/components/ModalComponent.vue';
import TextInput from '@/components/forms/InputText.vue';
import { ref, watch } from 'vue';
import {
  acceptMembershipRequest,
  addUserToOrganization,
  removeUserFromOrganization,
  useOrganization,
} from '@/api/organization';
import { useRoute } from 'vue-router';
import type { Guid, OrganizationUser } from '@/api/types';
import { useI18n } from 'vue-i18n';
import { editBlockVersionsRouteId } from '@/config';
import { translateStringOrLocale } from '@/i18n';
import { throwOnError } from '@/api/composable';
import { useCurrentUser } from '@/api/auth';
import { getRouteTitle } from '@/utilities/routeUtils';
import TableComponent from '@/components/TableComponent.vue';

const { t } = useI18n();
const route = useRoute();
const orgId = route.params.organizationId as Guid;
const loggedOnUser = useCurrentUser();

const userToDelete = ref<OrganizationUser | null>(null);
const isMembershipRequest = ref(false);
const showDeleteUserModal = ref(false);
const { result: organizationData } = throwOnError(useOrganization(orgId, true));
const addUserInput = ref('');
const addUserError = ref('');
const removeUserError = ref('');
const emailValid = ref(false);

watch(organizationData, () => {
  if (!organizationData.value) return;
  document.title = getRouteTitle(
    `${t('header.items.org')}: ${organizationData.value.name}`,
  );
});

const displayDeleteUserModal = (user: OrganizationUser, isRequest: boolean) => {
  showDeleteUserModal.value = true;
  userToDelete.value = user;
  isMembershipRequest.value = isRequest;
};

const addUser = async () => {
  addUserError.value = '';
  if (organizationData.value) {
    try {
      const r = await addUserToOrganization(
        orgId,
        addUserInput.value.toLowerCase(),
      );
      if (organizationData.value) {
        organizationData.value.users = r;
        addUserError.value = '';
      }
      addUserInput.value = '';
    } catch (err) {
      if ((err as Error).message) {
        addUserError.value = t((err as Error).message);
      } else {
        throw err;
      }
    }
  }
};

const removeUser = () => {
  if (!organizationData.value || !userToDelete.value) return;
  try {
    removeUserFromOrganization(orgId, userToDelete.value.userId);
    if (userToDelete.value.userId === loggedOnUser.value?.id) {
      window.location.reload();
    }
    const filterUsers = (users: OrganizationUser[]) =>
      users.filter(
        (user: OrganizationUser) => user.userId !== userToDelete.value?.userId,
      );

    if (isMembershipRequest.value) {
      organizationData.value.requestedUsers = filterUsers(
        organizationData.value.requestedUsers,
      );
    } else {
      organizationData.value.users = filterUsers(organizationData.value.users);
    }
  } catch {
    () => {
      removeUserError.value = t('organization.removeUserError');
    };
  }
  showDeleteUserModal.value = false;
  userToDelete.value = null;
};

const approveUserToOrganization = (requestedUser: OrganizationUser) => {
  try {
    acceptMembershipRequest(orgId, requestedUser);
    if (!organizationData.value) return;
    organizationData.value.requestedUsers =
      organizationData.value.requestedUsers.filter(
        (user) => user.userId !== requestedUser.userId,
      );
    organizationData.value.users.push(requestedUser);
    addUserError.value = '';
  } catch {
    addUserError.value = t('organization.unknownAddUserError');
  }
};
</script>

<template>
  <ContentPage
    v-if="organizationData"
    :title="organizationData.name"
    class="bg-gnist-white pb-4"
  >
    <div class="lg:px-8">
      <EditOrganizationComponent
        class="lg:max-w-1/2"
        :data="organizationData"
        @on-saved="
          (newData) => {
            if (!organizationData) return;
            organizationData = newData;
          }
        "
      />
      <div>
        <h2 class="mt-8">
          {{ t('organization.associatedUsers') }}
        </h2>

        <div class="pb-8">
          <div class="w-full max-w-full overflow-x-auto">
            <ModalComponent
              :show-modal="showDeleteUserModal"
              :title="t('organization.removeUser')"
              @handle-click="removeUser()"
              @close="showDeleteUserModal = false"
            >
              <span class="font-bold">{{ t('organization.name') }}:</span>
              {{ userToDelete?.name }}
            </ModalComponent>
            <TableComponent
              size="table-lg"
              :rows="organizationData?.users"
              show-spinner
              i18n-key="organization"
              :get-key="(user) => user.userId"
              :columns="[
                'name',
                'email',
                'removeUser' as keyof OrganizationUser,
              ]"
              column-header-class="font-normal"
            >
              <template #columnHeader="{ item: user }">
                {{ user.name }}
              </template>
              <template #columns="{ item: user }">
                <td>
                  {{ user.email }}
                </td>
                <td class="w-1 text-center">
                  <MaterialIcon
                    class="hover:cursor-pointer"
                    @click="displayDeleteUserModal(user, false)"
                  >
                    close
                  </MaterialIcon>
                </td>
              </template>
            </TableComponent>
            <template v-if="organizationData?.requestedUsers.length > 0">
              <h3 class="pt-8">Medlemsskapsforespørsler</h3>
              <TableComponent
                size="table-lg"
                :rows="organizationData.requestedUsers"
                i18n-key="organization"
                :get-key="(user) => user.userId"
                :columns="[
                  'name',
                  'email',
                  'approveUser' as keyof OrganizationUser,
                  'denyUser' as keyof OrganizationUser,
                ]"
                column-header-class="font-normal"
              >
                <template #columnHeader="{ item: requestedUser }">
                  {{ requestedUser.name }}
                </template>
                <template #columns="{ item: requestedUser }">
                  <td>
                    {{ requestedUser.email }}
                  </td>
                  <td class="w-1 text-center">
                    <MaterialIcon
                      class="hover:cursor-pointer"
                      @click="approveUserToOrganization(requestedUser)"
                      >check
                    </MaterialIcon>
                  </td>
                  <td class="w-1 text-center">
                    <MaterialIcon
                      class="hover:cursor-pointer"
                      @click="displayDeleteUserModal(requestedUser, true)"
                    >
                      close
                    </MaterialIcon>
                  </td>
                </template>
              </TableComponent>
            </template>
            <p class="mt-2 text-gnist-orange">
              {{ removeUserError }}
            </p>
          </div>
        </div>

        <div class="mb-8">
          <h3>{{ t('organization.addUserHeader') }}</h3>
          <p class="py-2">
            {{ t('organization.addUserDescription') }}
          </p>
          <fieldset
            class="flex min-w-0 flex-col items-end gap-4 border p-1 ps-2 md:flex-row"
          >
            <TextInput
              v-model="addUserInput"
              label="organization.addUserLabel"
              show-validation-message
              type="email"
              class="w-96 max-w-full"
              :placeholder="t('organization.email')"
              @update:valid="(valid) => (emailValid = valid)"
            />
            <ButtonComponent
              :text="t('organization.addUserButton')"
              :onclick="addUser"
              :disabled="!addUserInput || !emailValid"
              class="my-2"
            />
          </fieldset>
          <p class="text-gnist-orange">
            {{ addUserError }}
          </p>
        </div>
      </div>
      <div class="mb-4">
        <h2>{{ t('organization.documents') }}</h2>
        <div class="m-4 mb-0 ml-6">
          <RouterLink
            class="gnist-button gnist-button-primary"
            :to="{ name: 'new_block', query: { ownerOrg: orgId } }"
            data-cy-id="NewDocumentButton"
          >
            {{ t('admin.blockProduction.newBlock') }}
          </RouterLink>
        </div>
        <TableComponent
          v-if="organizationData.blocks?.length > 0"
          size="table-lg"
          :rows="organizationData.blocks"
          i18n-key="organization"
          :get-key="(block) => block.blockId"
          :columns="['name']"
        >
          <template #columns="{ item: block }">
            <td class="!py-0">
              <RouterLink
                class="block p-4"
                :to="{
                  name: editBlockVersionsRouteId,
                  params: { blockId: block.blockId },
                }"
              >
                {{ translateStringOrLocale(block.name).value }}
              </RouterLink>
            </td>
          </template>
        </TableComponent>
        <p v-else class="mt-4">{{ t('organization.noDocuments') }}</p>
      </div>
    </div>
  </ContentPage>
</template>

<style scoped>
:deep(th),
:deep(td) {
  padding: 1rem 0.5rem;
  text-overflow: ellipsis;
}
</style>
