import type {
  InteractiveImage,
  BlockImage,
  Translated,
  InteractiveImageItem,
} from '@/api/types';
import type { supported_locale } from '@/config';
import {
  translateBlockValue,
  type TranslatedWithSource,
  attachSource,
  setBlockLocaleValueInObject,
  setBlockLocaleValue,
} from '@/i18n';
import type { FieldInfo } from '@/views/BlockContentEditorView.vue';

export function translateInteractiveImage(
  blockValue: InteractiveImage,
  locale: supported_locale,
  addFallbackInfo = false,
): Translated<InteractiveImage> {
  return {
    initialText: translateBlockValue(
      blockValue.initialText ?? '',
      locale,
      'markdown',
      addFallbackInfo,
    ),
    background: translateBlockValue(
      blockValue.background,
      locale,
      'image',
      addFallbackInfo,
    ) as TranslatedWithSource<BlockImage>,
    items: blockValue.items.map((item): Translated<InteractiveImageItem> => {
      const translatedItem: Translated<InteractiveImageItem> = {
        ...item,
        label: translateBlockValue(
          item.label,
          locale,
          'singleline',
          addFallbackInfo,
        ),
        extendedText: translateBlockValue(
          item.extendedText ?? '',
          locale,
          'markdown',
          addFallbackInfo,
        ).trim(),
        image: translateBlockValue(
          item.image,
          locale,
          'image',
          addFallbackInfo,
        ) as Translated<BlockImage>,
      };
      return attachSource(translatedItem, item);
    }),
  };
}
export function setInteractiveImageBlockLocaleValues(
  newValue: Translated<InteractiveImage>,
  source: InteractiveImage | undefined,
  locale: supported_locale,
): InteractiveImage {
  return {
    initialText: setBlockLocaleValue(
      newValue.initialText,
      source?.initialText,
      locale,
      'markdown',
    ),
    background: setBlockLocaleValueInObject(
      newValue.background as TranslatedWithSource<BlockImage>,
      'image',
      locale,
    ),
    items: newValue.items.map((item) => {
      const newItem = { ...item };
      delete (newItem as { _source?: unknown })['_source'];
      return {
        ...newItem,
        label: setBlockLocaleValue(
          item.label,
          (item as unknown as TranslatedWithSource<InteractiveImageItem>)
            ._source?.label,
          locale,
          'singleline',
        ),
        extendedText: setBlockLocaleValue(
          item.extendedText,
          (item as unknown as TranslatedWithSource<InteractiveImageItem>)
            ._source?.extendedText,
          locale,
          'markdown',
        ),
        image: setBlockLocaleValueInObject(
          item.image as TranslatedWithSource<BlockImage>,
          'image',
          locale,
        ),
      };
    }),
  } as InteractiveImage;
}

export function getInteractiveImageLocaleUpdates(
  field: InteractiveImage,
): FieldInfo[] {
  return field.items
    .map((i): FieldInfo[] => [
      { field: i.extendedText, schemaType: 'markdown' },
      { field: i.image, schemaType: 'image' },
      { field: i.label, schemaType: 'singleline' },
    ])
    .flat()
    .concat([
      { field: field.background, schemaType: 'image' },
      { field: field.initialText, schemaType: 'markdown' },
    ]);
}
