<script lang="ts">
import type { PathParameter, PartialSome } from '@/api/types';
export type OperationParam = PartialSome<PathParameter, 'isRequired'>;
</script>

<script setup lang="ts">
import { watch } from 'vue';
import TextInput from '@/components/forms/InputText.vue';
import Dropdown, {
  stringToDropdownItems,
} from '@/components/forms/InputDropdown.vue';
import { useNestedMV } from '@/utilities/useInternalState';
import { useI18n } from 'vue-i18n';

const props = defineProps<{
  label: string;
  params: OperationParam[];
  modelValue: Record<string, string>;
}>();
const emit = defineEmits<{
  (e: 'update:modelValue', value: typeof props.modelValue): void;
}>();

const { t } = useI18n();

const value = useNestedMV(props, (val) => emit('update:modelValue', val));

/** This requires the description to contain this specific text, so it might not be a good permanent solution. However, it might work sometimes. */
function getExample(param: OperationParam): string | undefined {
  if (!param.description) return undefined;
  return param.description.match(/Example:\s*(`|'|")(?<example>.*)(`|'|")/)
    ?.groups?.['example'];
}
watch(
  props.params,
  () => {
    props.params
      .filter((p) => p.isRequired && (p.values?.length ?? 0) > 0)
      .forEach((p) => {
        value.value[p.name] = p.values![0]!;
      });
  },
  { once: true, immediate: true },
);
</script>

<template>
  <h3 v-if="params.length > 0">{{ t(label) }}</h3>
  <template v-for="param in params" :key="param.name">
    <TextInput
      v-if="(param.values?.length ?? 0) === 0"
      v-model="value[param.name]"
      :for-name="`param_${param.name}`"
      :label="param.name"
      :tooltip="param.description"
      :required="param.isRequired"
      :placeholder="getExample(param)"
      description-style="inline"
    />
    <Dropdown
      v-else
      v-model="value[param.name]"
      :for-name="`param_${param.name}`"
      :label="param.name"
      :tooltip="param.description"
      :required="param.isRequired"
      :placeholder="getExample(param)"
      description-style="inline"
      :options="stringToDropdownItems(param.values, !param.isRequired)"
      :getkey="(val) => val ?? 'empty'"
    />
  </template>
</template>
