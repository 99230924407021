import { AbilityBuilder, createMongoAbility, subject } from '@casl/ability';
import { useAbility } from '@casl/vue';
import type {
  ForcedSubject,
  MongoQueryOperators,
  PureAbility,
} from '@casl/ability';
import type { Block, Category, CurrentUser, KeyOfRecursive } from '@/api/types';

export type Action =
  | 'create'
  | 'read'
  | 'update'
  | 'delete'
  | 'publish'
  | 'manage'
  | 'own'
  | 'use'
  | 'import_export';
export type Subject =
  | 'Block'
  | 'CustomBlock'
  | 'BlockFromRoute'
  | 'News'
  | 'Comment'
  | 'Discussion'
  | 'User'
  | 'Analytics'
  | 'Organization'
  | 'Route'
  | 'Category'
  | 'Suggestion'
  | 'MyDocumentsToPublish'
  | 'ProducerTools'
  | 'AdminTools'
  | 'Bug'
  | 'ApiConnection'
  | 'Tags'
  | 'AlzOrders'
  | 'AlzArchetype'
  | 'AgreementReference'
  | 'MasterData';

type StoreAbility = PureAbility<[Action, Subject | ForcedSubject<Subject>]>;

/** See https://casl.js.org/v6/en/guide/conditions-in-depth#supported-operators for an explanation of what the operators does
 * @template TSubjectInstance The type of the instance the condition will be evaluated against
 * @template TValueProperty The property of `TSubjectInstance` that will be matched against the condition
 * @template MongoQueryOperators The operator and value that defines the condition to match
 */
type CanCondition<
  TSubjectInstance,
  TValueProperty extends KeyOfRecursive<TSubjectInstance>,
> = {
  [valueSelector in TValueProperty]: MongoQueryOperators;
};

export function defineAbilities(
  user: Pick<CurrentUser, 'id' | 'roles' | 'memberOrgs'> | null,
): StoreAbility {
  const { can, build } = new AbilityBuilder<StoreAbility>(createMongoAbility);

  if (user?.roles?.includes('administrator')) {
    can('read', 'AdminTools');
    can('create', 'News');
    can('update', 'Discussion');
    can('delete', 'Comment');
    can('read', 'Analytics');
    can('create', 'Block');
    can('create', 'CustomBlock');
    can('update', 'Block');
    can('delete', 'Block');
    can('import_export', 'Block');
    // This should follow rules in DocumentPublishRightsRequirement.cs
    can('publish', 'Block');
    can('read', 'Organization');
    can('update', 'User');
    can('update', 'Organization');
    can('update', 'Route');
    can('update', 'Category');
    can('update', 'Suggestion');
    can('delete', 'Suggestion');
    can('update', 'Tags');
    can('use', 'AlzOrders');
  }
  if (user?.roles?.includes('moderator')) {
    can('read', 'ProducerTools');
    can('create', 'News');
    can('update', 'Discussion');
    can('delete', 'Comment');
    can('read', 'Analytics');
  }
  if (user?.roles?.includes('editor')) {
    can('read', 'ProducerTools');
    can('create', 'Block');
    can('update', 'Block');
    // This should follow rules in DocumentPublishRightsRequirement.cs
    can('publish', 'Block');
    can('read', 'Organization');
    can('manage', 'Block');
    can('update', 'Tags');
  }
  if (user?.roles?.includes('orgmember')) {
    can('read', 'ProducerTools');
    can('create', 'Block');
    can('create', 'BlockFromRoute', {
      createRoles: { $in: user.roles },
    } satisfies CanCondition<Pick<Category, 'createRoles'>, 'createRoles'>);
    can('update', 'Block', {
      'ownerOrg.organizationId': { $in: user.memberOrgs },
    } satisfies CanCondition<Block, 'ownerOrg.organizationId'>);
  }
  if (user?.roles?.includes('docowner')) {
    can('read', 'ProducerTools');
    can('own', 'Block');
  }
  if (user?.roles?.includes('category_editor')) {
    can('read', 'ProducerTools');
    can('own', 'Block');
    can('read', 'MyDocumentsToPublish');
    can('publish', 'Block', {
      'category.editors': { $eq: user.id },
    } satisfies CanCondition<Block, 'category.editors'>);
  }
  if (user?.roles?.includes('api_editor')) {
    can('update', 'ApiConnection');
  }
  if (user?.roles?.includes('tester')) {
    can('create', 'Bug');
  }
  if (user?.roles?.includes('alz_order_form_tester')) {
    can('use', 'AlzOrders');
  }
  if (user?.roles?.includes('cpo_admin')) {
    can('use', 'AlzOrders');
    can('manage', 'AlzOrders');
    can('use', 'AgreementReference');
  }
  if (user?.roles?.includes('agreement_reference_admin')) {
    can('manage', 'AgreementReference');
  }
  if (user?.roles?.includes('masterdata_admin')) {
    can('manage', 'MasterData');
  }
  // Abilities that should be added regardless of the users roles
  if (user?.roles) {
    can('use', 'AlzArchetype', {
      roles: { $in: user.roles },
    });
  }
  return build();
}

export const useStoreAbility = () => useAbility<StoreAbility>();
export const useSubject = <T extends Record<PropertyKey, unknown>>(
  subjectType: Subject,
  subjectInstance: T,
): ForcedSubject<Subject> => {
  return subject<Subject, T>(subjectType, subjectInstance);
};
