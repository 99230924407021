import type { ApiMAnalyticsDTO, ContentAnalyticsDTO } from '@/api/types';

const dateOptions: Intl.DateTimeFormatOptions = {
  weekday: 'short',
  month: '2-digit',
  day: '2-digit',
};

function distinct<T>(array: T[]) {
  return [...new Set(array)];
}

function removePathPrefix(name: string) {
  return name.split('/').at(-1);
}

export function getTotalApiCalls(
  apiMAnalytics: ApiMAnalyticsDTO,
  label: string,
) {
  const apiReports = apiMAnalytics.apiMReportByOperation;
  const apiSet = distinct(apiReports.map((report) => report.apiId));

  const apiData = apiSet.map((apiId) => {
    const totalCalls = apiReports
      .filter((report) => report.apiId === apiId)
      .reduce((total, report) => total + report.callCountTotal, 0);
    return { apiId, totalCalls };
  });

  apiData.sort((a, b) => b.totalCalls - a.totalCalls);

  return {
    labels: apiData.map((data) => removePathPrefix(data.apiId)),
    datasets: [
      {
        label: label,
        data: apiData.map((data) => data.totalCalls),
      },
    ],
  };
}

export function getCallsByTimestamp(
  apiMAnalytics: ApiMAnalyticsDTO,
  label: string,
) {
  const labels = apiMAnalytics.apiMReportByTimestamp
    .map((report) => report.timestamp)
    .map((date) =>
      new Date(date)
        .toLocaleDateString(undefined, dateOptions)
        .replace(/\.$/, ''),
    );
  const values = apiMAnalytics.apiMReportByTimestamp.map(
    (report) => report.callCountTotal,
  );
  return {
    labels: labels,
    datasets: [
      {
        label: label,
        data: values,
      },
    ],
  };
}

export function getCallsByOperation(
  apiMAnalytics: ApiMAnalyticsDTO,
  label: string,
) {
  const apiIds = distinct(
    apiMAnalytics.apiMReportByOperation.map((report) => report.apiId),
  );
  return apiIds.map((id) => {
    const data = apiMAnalytics.apiMReportByOperation
      .filter((report) => report.apiId === id)
      .map((report) => ({
        label: report.apiOperation,
        value: report.callCountTotal,
      }));
    return {
      key: id,
      hasNonZeroEntries: data.some((v) => v.value !== 0),
      data: {
        labels: data.map((v) => removePathPrefix(v.label)),
        datasets: [
          {
            label: label + ' ' + removePathPrefix(id),
            data: data.map((v) => v.value),
          },
        ],
      },
    };
  });
}

export function getSubscriptionCounts(
  apiMAnalytics: ApiMAnalyticsDTO,
  label: string,
) {
  const apiIds = apiMAnalytics.subscriptionCounts.map((report) => report.apiId);
  const counts = apiMAnalytics.subscriptionCounts.map((report) => report.count);
  return {
    labels: apiIds,
    datasets: [
      {
        label: label,
        data: counts,
      },
    ],
  };
}

export function getBlocksCategoryGraph(
  analytics: ContentAnalyticsDTO,
  label: string,
) {
  const blockNames = Object.keys(analytics.blockAnalytics.categoryCounts);
  const counts = Object.values(analytics.blockAnalytics.categoryCounts);

  return {
    labels: blockNames,
    datasets: [
      {
        label: label,
        data: counts,
      },
    ],
  };
}

export function getBlocksFavoriteGraph(
  analytics: ContentAnalyticsDTO,
  label: string,
) {
  const blockNames = Object.keys(analytics.blockAnalytics.favoriteCounts);
  const counts = Object.values(analytics.blockAnalytics.favoriteCounts);

  return {
    labels: blockNames,
    datasets: [
      {
        label: label,
        data: counts,
      },
    ],
  };
}

export function getPageCommentsGraph(
  analytics: ContentAnalyticsDTO,
  label: string,
) {
  const blockNames = Object.keys(analytics.commentsAnalytics.commentsCounts);
  const counts = Object.values(analytics.commentsAnalytics.commentsCounts);

  return {
    labels: blockNames,
    datasets: [
      {
        label: label,
        data: counts,
      },
    ],
  };
}
