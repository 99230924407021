/** Can be used to throw an error with extended info. The `cause`-part of the source-error will be saved in a "details"-property and logged in a collapsed group by `onErrorCaptured`. */
export class ExtendedError extends Error {
  details?: unknown;
  constructor(message?: string, source?: Error) {
    const _details = source?.cause;
    delete source?.cause;
    super(message, { cause: source });
    if (_details) {
      this.details = _details;
    }
    this.name = 'Gnist extended error';
  }
}
