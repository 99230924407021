/**
 * Will compare if two arrays are equal, e.g. have the same elements in the same place
 * For arrays of objects, this will use a referential comparison and not a deep compare
 */
export function arrayEquals<T, V = T>(
  a?: T[],
  b?: T[],
  getCompareValue?: (item: T) => V,
): boolean {
  if (!a && !b) return true;
  else if (!a || !b) return false;
  if (!getCompareValue) {
    return (
      a.length === b.length && a.every((element, index) => element === b[index])
    );
  }
  const bb = b.map((i) => getCompareValue(i));
  return (
    a.length === b.length &&
    a.every((element, index) => getCompareValue(element) === bb[index])
  );
}
