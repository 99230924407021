import { type Ref, ref } from 'vue';
import type { News } from './types';
import { apiUrl } from '@/config';

const newsUrl = apiUrl + '/news';

export const useNews = (): Ref<News[]> => {
  const news = ref<News[]>([]);

  getNews().then((result) => (news.value = result));

  return news;
};

export const getNews = async (): Promise<News[]> => {
  return fetch(newsUrl, {
    method: 'GET',
    credentials: 'include',
  })
    .then((response) => response.json())
    .then((news) => news);
};

export const updateNewsArtice = async (news: News): Promise<News> => {
  return fetch(`${newsUrl}/${news.newsArticleId}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    body: JSON.stringify(news),
  })
    .then((response) => response.json())
    .then((news) => news);
};

export const addNewsArticle = async (news: News): Promise<News> => {
  return fetch(newsUrl, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    body: JSON.stringify(news),
  })
    .then((response) => response.json())
    .then((news) => news);
};

export const deleteNewsArticle = async (
  newsArticleId: string,
): Promise<void> => {
  await fetch(`${newsUrl}/${newsArticleId}`, {
    method: 'DELETE',
    credentials: 'include',
  });
};
