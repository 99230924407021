import { ref, watch, type UnwrapRef, type Ref, unref, toRef } from 'vue';
import { deepClone } from '@/utilities/deepClone';
import { blockValueEquals } from './blockValueEquals';

export function useNestedMVDefault<T>(
  props: { modelValue: T | undefined },
  onChange: (value: T | undefined) => void,
  defaultValue: T,
  /** If a function is supplied, it will be evaluated for *all* updates (not just replaced state). A boolean will only have an impact if state was replaced. */
  triggerOnInternalStateReplaced?: boolean | TriggerLogicDelegate<T>,
) {
  return useInternalState<T>(
    toRef(props, 'modelValue'),
    defaultValue,
    onChange,
    undefined,
    undefined,
    triggerOnInternalStateReplaced ?? false,
    false,
  );
}
type TriggerLogicDelegate<T> = (
  stateReplaced: boolean,
  newVal: UnwrapRef<T>,
  oldVal: UnwrapRef<T>,
) => 'skipUpdate' | 'applyUpdate';
export function useNestedMV<T>(
  props: { modelValue: T },
  onChange: (value: T) => void,
  /** If a function is supplied, it will be evaluated for *all* updates (not just replaced state). A boolean will only have an impact if state was replaced. */
  triggerOnInternalStateReplaced?: boolean | TriggerLogicDelegate<T>,
) {
  return useInternalState(
    toRef(props, 'modelValue'),
    props.modelValue,
    onChange,
    undefined,
    undefined,
    triggerOnInternalStateReplaced ?? false,
    true,
  );
}

/** Should always be called synchronously to ensure watchers will be cleaned up */
export function useInternalState<TExternal, TInternal = TExternal>(
  extState: Ref<TExternal | null | undefined>,
  defaultState: TInternal,
  onChange: (value: TExternal) => void,
  /** Must be supplied if TExternal is not the same as TInternal */
  getInternalState?: (
    newExtState: TExternal,
    internalState: TInternal,
  ) => TInternal | null,
  /** Must be supplied if TInternal is not the same as TExternal */
  getExternalState?: (newIntState: TInternal) => TExternal,
  /** If a function is supplied, it will be evaluated for *all* updates (not just replaced state). A boolean will only have an impact if state was replaced. */
  triggerOnInternalStateReplaced:
    | boolean
    | TriggerLogicDelegate<TInternal> = false,
  updateOnNullUndefined = false,
) {
  const state = ref<TInternal>(defaultState);

  // Update internal state if external state has changed
  watch(
    () => unref(extState),
    (newExtState) => {
      if (!newExtState && !updateOnNullUndefined) return;
      const newState = !newExtState
        ? newExtState
        : getInternalState?.(newExtState, state.value as TInternal) ??
          newExtState;
      if (!newState && !updateOnNullUndefined) return;
      state.value = newState as UnwrapRef<TInternal>;
    },
    { immediate: true },
  );

  const oldState = ref(deepClone(state.value) as TInternal);
  const replacedState = ref(oldState.value as TInternal);
  function setOldState(newState: UnwrapRef<TInternal>) {
    replacedState.value = oldState.value;
    oldState.value = deepClone(newState);
  }
  const stateReplaced = ref(false);
  // This extra bit is to handle blockvalues containing "helper properties" that we don't want to trigger updates
  watch(
    () => state.value,
    (newVal, oldVal) => {
      stateReplaced.value = newVal !== oldVal;
      if (!blockValueEquals(state.value, oldState.value, true)) {
        setOldState(state.value);
      }
    },
    { deep: true },
  );

  // Update external state if internal state has changed
  watch(
    () => oldState.value,
    () => {
      if (!state.value && !updateOnNullUndefined) return;
      const shouldReturn =
        typeof triggerOnInternalStateReplaced === 'function'
          ? triggerOnInternalStateReplaced(
              stateReplaced.value,
              state.value,
              replacedState.value,
            ) === 'skipUpdate'
          : !triggerOnInternalStateReplaced && stateReplaced.value;
      if (shouldReturn) return; // Internal state was updated due to changes in external state (thus the entire object was replaced). DO NOT update external state (as that will trigger a cascade).
      onChange(
        getExternalState
          ? getExternalState(state.value as TInternal)
          : (state.value as unknown as TExternal),
      );
    },
    { deep: true },
  );

  return state;
}
