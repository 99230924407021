<script lang="ts" setup>
import { useRoute } from 'vue-router';
import { computed, onBeforeUnmount, onMounted, ref, watchEffect } from 'vue';
import { getBlock } from '@/api/blocks';
import { callApi, getApiSubscriptions, getApiData } from '@/api/apiM';
import ContentPage from '@/components/ContentPage.vue';
import Dropdown, {
  toDropdownItems,
} from '@/components/forms/InputDropdown.vue';
import TextInput from '@/components/forms/InputText.vue';
import MaterialIcon from '@/components/MaterialIcon.vue';
import HttpRequest, {
  getEndPointWithParams,
} from '@/components/HttpRequest.vue';
import HttpResponse from '@/components/HttpResponse.vue';
import ApiOperationsHeader from '@/components/ApiOperationsHeader.vue';
import {
  type Block,
  type ApiOperation,
  type ApiDTO,
  type ApiMSubscriptionDTO,
  type Guid,
  getApimDetails,
  type RequestHeader,
} from '@/api/types';
import { firstParam } from '@/utilities/firstParam';
import { computeEndpoint } from '@/utilities/computeEndpoint';
import { useI18n } from 'vue-i18n';
import ButtonComponent from '@/components/ButtonComponent.vue';
import { ParagraphShimmer } from 'vue3-shimmer';
import { apiUrl } from '@/config';
import { useCurrentUser } from '@/api/auth';
import ApiParameterInput, {
  type OperationParam,
} from '@/components/ApiParameterInput.vue';
import { apimproductsRouteId } from '@/config';
import { clamp } from '@/utilities/clamp';

const { t } = useI18n();

const route = useRoute();
const authUrl = `${apiUrl}/apim/auth`;
const userInfo = useCurrentUser();
const pathParams = ref<Record<string, string>>({});
const queryParams = ref<Record<string, string>>({});
const body = ref('');
const validBody = ref(true);
const responseData = ref<string>();
const token = ref<string | undefined>(undefined);

const block = ref<Block | null>(null);
const apiData = ref<(ApiDTO & { useAuth: boolean }) | null>(null);
const operation = ref<
  | (Pick<ApiOperation, 'name' | 'method' | 'description' | 'templateUrl'> & {
      headers: RequestHeader[];
      pathParams: OperationParam[];
      queryParams: OperationParam[];
      bodyExamples:
        | {
            /** Not used for now, but we could add a representation selector later if there are multiple representations available. */
            id: string;
            value: string;
          }[]
        | undefined;
    })
  | null
>(null);
const selectedOperation = firstParam(route.params.selectedOperation) || '';
const subscriptions = ref<ApiMSubscriptionDTO[] | null>(null);
const selectedKey = ref<string | null>(null);

const title = ref(t('header.items.console', 'API-konsoll'));
const subTitle = ref<string>();

function validateBody() {
  try {
    body.value = JSON.stringify(JSON.parse(body.value), null, 2);
    validBody.value = true;
  } catch {
    validBody.value = false;
  }
}

watchEffect(async () => {
  if (route?.name !== 'console') return;

  const blockId = route.params.blockId as Guid;
  const queryVersionNumber = firstParam(route.query['version']);
  block.value = await getBlock(blockId, queryVersionNumber ?? undefined);
  const apimConfig = getApimDetails(block.value.content);

  if (!apimConfig?.apimId) throw new Error('error.missing_api_param');

  title.value = apimConfig?.apimId;
  const data = await getApiData(apimConfig?.apimId);
  apiData.value = { ...data, useAuth: !!apimConfig.useAuthentication };
  title.value = apiData.value.displayName;
  const op = apiData.value.operations.find(
    (op) => op.name == selectedOperation,
  );
  if (!op) operation.value = null;
  else {
    operation.value = {
      ...op,
      headers: op.request?.headers ?? [],
      pathParams: op.templateParameters ?? [],
      queryParams: op.request?.queryParameters ?? [],
      bodyExamples: op.request?.representations?.map((r) => ({
        id: r.typeName,
        value: r.examples?.default?.value,
      })),
    };
    if (operation.value?.bodyExamples?.length === 0) {
      operation.value.bodyExamples = undefined;
    }
    if (operation.value?.bodyExamples) {
      body.value = operation.value.bodyExamples[0]?.value ?? body.value;
      validateBody();
    }
  }
  if (!operation.value) throw new Error('error.unknown_operation');
  subTitle.value = operation.value.name;
  if (userInfo.value) {
    subscriptions.value = await getApiSubscriptions(apimConfig.apimId);
    selectedKey.value = subscriptions.value?.[0]?.primaryKey ?? null;
  }
});

const keyOptions = computed(() =>
  toDropdownItems(
    subscriptions.value
      ?.map((s) => [
        [s.primaryKey, `${s.displayName}: ${t('console.primarySecurityKey')}`],
        [
          s.secondaryKey,
          `${s.displayName}: ${t('console.secondarySecurityKey')}`,
        ],
      ])
      .flat(1),
    ([key, text]) => [key, text],
  ),
);

const endPoint = computed(() =>
  computeEndpoint(apiData.value, operation.value),
);

const callingApi = ref(false);
const onSubmit = async () => {
  if (!selectedKey.value) {
    console.warn('Could not get selected key');
    return;
  }
  if (!apiData.value || !operation.value) return;

  const url = getEndPointWithParams(
    endPoint.value,
    pathParams.value,
    queryParams.value,
  );
  try {
    callingApi.value = true;
    const { data, resp, dataErr } = await callApi(
      url,
      operation.value.method,
      selectedKey.value,
      body.value,
      token.value,
    );
    responseData.value = `Status: ${resp.status}\n\n`;
    if (data) {
      responseData.value +=
        typeof data === 'string' ? data : JSON.stringify(data, null, 2);
      return;
    }
    if (dataErr) {
      responseData.value +=
        t('error.missing_data_from_api') + '\nError: ' + dataErr;
    } else {
      responseData.value += t('error.missing_data_from_api');
    }
    callingApi.value = false;
  } catch (e) {
    if (`${e}`.toLowerCase().includes('failed to fetch')) {
      responseData.value = t('error.api_failed_to_fetch') + '\nError: ' + e;
    } else {
      responseData.value = t('error.unknown_error_from_api') + '\nError: ' + e;
    }
    callingApi.value = false;
  }
};

const receiveMessage = async (event: MessageEvent): Promise<void> => {
  if (event.data['token']) {
    token.value = event.data['token'];
  }
};

onMounted(() => {
  window.addEventListener('message', receiveMessage, false);
});

onBeforeUnmount(() => {
  window.removeEventListener('message', receiveMessage);
});

function openLoginWindow() {
  window.open(authUrl, '_blank', 'width=400,height=500');
}
</script>

<template>
  <ContentPage :title="title" :sub-title="subTitle ?? selectedOperation">
    <template #banner>
      <ApiOperationsHeader
        v-if="operation"
        :operation="operation"
        :end-point="endPoint"
      />
    </template>

    <template #default>
      <ParagraphShimmer
        v-if="operation === null"
        :lines="10"
        :random-size="true"
        class="mx-auto w-full max-w-5xl py-8"
      />
      <section
        v-if="operation != null"
        class="mb-16 mt-8 grid grid-cols-1 gap-6 lg:grid-cols-2"
      >
        <section class="html-render rounded-2xl bg-gnist-white p-8">
          <h2 class="mb-4">{{ t('console.testYourself') }}</h2>
          <form @submit.prevent="onSubmit">
            <div class="mt-2 flex flex-col py-4">
              <Dropdown
                v-model="selectedKey"
                label="console.chooseSecurityKey"
                for-name="select-key"
                :options="keyOptions"
                :getkey="(val) => val"
              />
            </div>
            <div v-if="apiData?.useAuth" class="mt-3">
              <ButtonComponent
                :text="
                  token
                    ? t('console.reAuthenticate')
                    : t('console.authenticate')
                "
                @click="openLoginWindow"
              />
            </div>
            <div class="mb-8 flex gap-12">
              <input
                class="gnist-button gnist-button-primary mt-8 min-w-[8rem]"
                type="submit"
                :value="t('console.tryItOut')"
                :disabled="!selectedKey || callingApi"
              />
              <div
                v-if="subscriptions && subscriptions.length === 0"
                class="flex flex-col justify-between"
              >
                <span class="italic">
                  {{ t('console.orderAccess') }}
                </span>
                <RouterLink :to="{ name: apimproductsRouteId }">
                  <ButtonComponent
                    class="self-center"
                    type="primary"
                    :text="t('block.requestApiMAccess')"
                  />
                </RouterLink>
              </div>
            </div>
            <ApiParameterInput
              v-model="pathParams"
              label="console.pathParams"
              :params="operation.pathParams"
            />
            <ApiParameterInput
              v-model="queryParams"
              label="console.queryParams"
              :params="operation.queryParams"
            />
            <!-- TORGST, 11.3.24: Not sure if body should be visible always, but I believe this makes more sense. Might need to change it later. -->
            <div v-if="operation.bodyExamples">
              <MaterialIcon
                v-if="!validBody"
                class="absolute right-6 z-10 w-6 bg-gnist-red text-center text-gnist-white"
                :title="t('console.invalidBody')"
              >
                report
              </MaterialIcon>
              <button
                class="absolute right-0 z-10"
                :title="t('console.validateBody')"
                @click.prevent="validateBody"
              >
                <MaterialIcon> published_with_changes </MaterialIcon>
              </button>
              <TextInput
                v-model="body"
                for-name="request_body"
                label="console.requestBody"
                description-style="inline"
                multiline
                :rows="
                  clamp(
                    5,
                    operation.bodyExamples[0]?.value.split('\n').length,
                    10,
                  )
                "
              />
            </div>
          </form>
        </section>

        <div>
          <HttpRequest
            :api-operation="operation"
            :method="operation?.method"
            :headers="operation?.headers"
            :api-data="apiData"
            :api-key="selectedKey"
            :end-point="endPoint"
            :path-params="pathParams"
            :query-params="queryParams"
            :body="body"
            :token="token"
          />

          <HttpResponse
            :response-data="responseData"
            :show-spinner="callingApi"
          />
        </div>
      </section>
    </template>
  </ContentPage>
</template>
