import { ref, onMounted, type Ref } from 'vue';

export function useStepScrolling(
  scrollSize: number,
  el: Ref<HTMLElement | undefined>,
  waitBeforeScrollMs = 50,
  waitBeforeNextMs = 250,
) {
  const nativeScrollSize = 4;
  const nextScrollPos = ref<number>();
  const activeTimeouts = ref<number[]>([]);
  onMounted(() => {
    if (el.value) {
      el.value.addEventListener('scroll', (ev: Event) => {
        if (!ev.target) return;
        const target = ev.target as HTMLDivElement & { scrollPos?: number };
        const direction =
          target.scrollTop > (target.scrollPos ?? 0)
            ? 'down'
            : target.scrollTop < (target.scrollPos ?? 0)
              ? 'up'
              : 'none';
        //#region Not sure if this is needed, keeping it for now
        // If scrolls are happening too fast to keep track of
        if (
          (direction == 'down' &&
            target.scrollTop % scrollSize > nativeScrollSize) ||
          (direction == 'up' &&
            target.scrollTop % scrollSize < nativeScrollSize)
        ) {
          nextScrollPos.value = undefined;
          const timeouts = activeTimeouts.value;
          activeTimeouts.value = [];
          for (const id of timeouts) window.clearTimeout(id);
        }
        //#endregion
        if (nextScrollPos.value !== undefined) return;
        if (direction === 'down') {
          nextScrollPos.value = (target.scrollPos ?? 0) + scrollSize;
        } else if (direction === 'up') {
          nextScrollPos.value = (target.scrollPos ?? 0) - scrollSize;
        }
        const id1 = window.setTimeout(() => {
          if (nextScrollPos.value === undefined) return;
          target.scrollTop = nextScrollPos.value;
          target.scrollPos = nextScrollPos.value;
        }, waitBeforeScrollMs);
        activeTimeouts.value.push(id1);
        const id2 = window.setTimeout(() => {
          nextScrollPos.value = undefined;
        }, waitBeforeNextMs);
        activeTimeouts.value.push(id2);
      });
    }
  });
}
