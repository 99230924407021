<script setup lang="ts">
import ButtonComponent from '@/components/ButtonComponent.vue';
import CommentAssessmentOverview from '@/components/CommentAssessmentOverview.vue';
import ContentPage from '../components/ContentPage.vue';
import MaterialIcon from '@/components/MaterialIcon.vue';
import ModalComponent from '@/components/ModalComponent.vue';
import TextInput from '@/components/forms/InputText.vue';

import { useI18n } from 'vue-i18n';
import { type ArrayItem } from '@/api/types';
import { formatDate } from '@/utilities/formatDate';
import {
  stringCompareCaseInsensitive,
  undefinedCompare,
} from '@/utilities/order';
import {
  commentModeratorDelete,
  commentModeratorVerify,
  useUnassessedComments,
} from '@/api/commentoClient';
import { computed, ref } from 'vue';
import { translateStringOrLocale } from '@/i18n';
import { defaultBlocksRouteName } from '@/utilities/routeUtils';
import {
  getChatPages,
  suggestionPageParam,
  suggestionPageIdPrefix,
} from '@/api/commentoTypes';
import { socialPageRouteId } from '@/config';
import SimpleTabSelector from '@/components/SimpleTabSelector.vue';
import TableComponent from '@/components/TableComponent.vue';

const { t } = useI18n();
const { result: discussions } = useUnassessedComments('Moderator');
const chatPages = getChatPages();

const comments = computed(() =>
  discussions.value?.map((d) => ({
    ...d,
    block: d.block
      ? { ...d.block, name: translateStringOrLocale(d.block.name).value }
      : undefined,
    suggestion: d.suggestion
      ? {
          ...d.suggestion,
          title: translateStringOrLocale(d.suggestion.title).value,
        }
      : undefined,
    chatPage: chatPages.find((p) => d.pagePath?.endsWith(p.id)),
  })),
);

type ColumnKey = keyof ArrayItem<typeof comments.value>;
type ActionKey = 'OK' | 'Delete';
const commentColumns: (ColumnKey | ActionKey)[] = [
  'block',
  'date',
  'user',
  'html',
  'OK',
  'Delete',
];

let onCloseModal: (() => Promise<string>) | (() => string) | null = null;
const showModal = ref(false);
const modalTitle = ref<string>('');
const modalButtonText = ref<string>('');
const reason = ref<string>('');

function deleteComment(comment: { commentHex: string; html: string }) {
  reason.value = '';
  modalTitle.value = t('discussion.deleteCommentConfirmation');
  modalButtonText.value = t('buttons.delete');
  onCloseModal = async () => {
    await commentModeratorDelete(comment, reason.value);
    return comment.commentHex;
  };
  showModal.value = true;
}
function verifyComment(commentHex: string) {
  reason.value = '';
  modalTitle.value = t('discussion.verifyCommentConfirmation');
  modalButtonText.value = t('buttons.ok');
  onCloseModal = async () => {
    await commentModeratorVerify({ commentHex }, reason.value);
    return commentHex;
  };
  showModal.value = true;
}
const tabs = [
  'moderatorList',
  'notdocsForumHeader',
  'moderatordocsForumHeader',
] as const;
const activeTab = ref<(typeof tabs)[number]>('moderatorList');
</script>

<template>
  <ContentPage
    :title="t('social.moderator.title')"
    :description="t('social.moderator.description')"
    :hide-description-mobile="true"
    class="bg-gnist-gray-light-light"
  >
    <SimpleTabSelector
      v-model="activeTab"
      :tabs="tabs"
      i18n-key="admin.management"
    />
    <section v-show="activeTab === 'moderatorList'" class="py-8">
      <h2 class="mb-4">
        {{ t('admin.management.moderatorList') }}
      </h2>
      <TableComponent
        size="table-xs"
        column-header-class="max-w-20 md:max-w-full"
        :rows="comments"
        show-spinner
        i18n-key="admin.management.commenttable"
        :get-key="(comment) => comment.commentHex"
        :columns="commentColumns as ColumnKey[]"
        sort-fields="block"
        :comparers="{
          block: undefinedCompare((a, b) =>
            stringCompareCaseInsensitive(a.name ?? '', b.name ?? ''),
          ),
        }"
      >
        <template #columnHeader="{ item: comment }">
          <template v-if="comment.block">
            <RouterLink
              :to="{
                name: socialPageRouteId,
                params: {
                  pageId: defaultBlocksRouteName,
                  blockId: comment.block.blockId,
                  commentId: comment.commentHex,
                },
              }"
              class="break-words font-normal underline hover:bg-gnist-gray-light"
              target="_blank"
            >
              <MaterialIcon class="no-underline" aria-hidden="true">
                tag
              </MaterialIcon>
              {{ t('social.documents') }}: {{ comment.block.name }}
            </RouterLink>
          </template>
          <template v-if="comment.chatPage">
            <RouterLink
              class="break-words font-normal underline hover:bg-gnist-gray-light"
              :to="{
                name: socialPageRouteId,
                params: {
                  pageId: comment.chatPage.id,
                  blockId: 'comment', // Hack to match correct route when linking directly to a comment for a chatPage
                  commentId: comment.commentHex,
                },
              }"
              target="_blank"
            >
              <MaterialIcon class="no-underline" aria-hidden="true">
                tag
              </MaterialIcon>
              {{ t('social.forum') }}:
              {{ t(comment.chatPage.chatPageName) }}
            </RouterLink>
          </template>
          <template v-if="comment.suggestion">
            <RouterLink
              class="break-words font-normal underline hover:bg-gnist-gray-light"
              :to="{
                name: socialPageRouteId + suggestionPageParam,
                params: {
                  pageId: suggestionPageParam,
                  discussionId: suggestionPageIdPrefix + comment.suggestion.id,
                  commentId: comment.commentHex,
                },
              }"
              target="_blank"
            >
              <MaterialIcon class="no-underline" aria-hidden="true">
                tag
              </MaterialIcon>
              {{ t('social.suggestions') }}:
              {{ comment.suggestion.title }}
            </RouterLink>
          </template>
        </template>
        <template #columns="{ item: comment }">
          <td class="whitespace-nowrap">
            {{ formatDate(comment.date) }}
          </td>
          <td class="whitespace-nowrap">
            {{ comment.user.name }}
          </td>
          <td>
            <!-- eslint-disable vue/no-v-html -->
            <div v-html="comment.html" />
          </td>
          <td>
            <ButtonComponent
              type="primary"
              :text="t('discussion.verify')"
              @click="() => verifyComment(comment.commentHex)"
            />
          </td>
          <td>
            <ButtonComponent
              :text="t('discussion.remove')"
              @click="() => deleteComment(comment)"
            />
          </td>
        </template>
      </TableComponent>

      <ModalComponent
        :show-modal="showModal"
        :title="modalTitle"
        :button-text="modalButtonText"
        primary-button-type="primary"
        @close="() => (showModal = false)"
        @handle-click="
          async () => {
            showModal = false;
            const commentHex = await onCloseModal?.();
            if (!discussions || !commentHex) return;
            discussions = discussions.filter(
              (i) => i.commentHex !== commentHex,
            );
            onCloseModal = null;
          }
        "
      >
        <template #default>
          <TextInput
            v-model="reason"
            label="social.moderator.reasonLabel"
            multiline
          />
        </template>
      </ModalComponent>
    </section>
    <CommentAssessmentOverview
      v-show="activeTab === 'notdocsForumHeader'"
      label="admin.management.notdocsForumHeader"
      :include-all-blocks="false"
      :get-non-block-comments="true"
      use-moderator-endpoint
    />
    <CommentAssessmentOverview
      v-show="activeTab === 'moderatordocsForumHeader'"
      label="admin.management.moderatordocsForumHeader"
      :include-all-blocks="true"
      :get-non-block-comments="false"
      use-moderator-endpoint
    />
  </ContentPage>
</template>
