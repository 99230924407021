<script setup lang="ts">
import { Status, type Tag, type Block, type localeValue } from '@/api/types';
import MaterialIcon from '../MaterialIcon.vue';
import PageTitle from '../PageTitle.vue';
import SuggestionStatusBadgeVue from '../SuggestionStatusBadge.vue';
import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue';
import { getBlocksRouteLink } from '@/utilities/routeUtils';
import { computed, toRef } from 'vue';
import { useCategoriesLazy, useCategoryHomePageLink } from '@/api/category';
import { bannerClasses } from '../PageBanner.vue';
import { useI18n } from 'vue-i18n';
import {
  addFavorite,
  removeFavoriteByBlockId,
  useBlockFavorite,
} from '@/api/favorite';
import { translateStringOrLocale } from '@/i18n';
import { compose, throwOnError } from '@/api/composable';
import { getVersions } from '@/api/versions';
import { useCurrentUser } from '@/api/auth';
import OptionalRouterLink from '../OptionalRouterLink.vue';

const { t } = useI18n();
const props = defineProps<{
  title: string | localeValue;
  showVersionSelector?: boolean;
  block: Block;
  description?: string | localeValue;
  tags: Tag[];
  isPreview: boolean;
  noLeftMargin?: boolean;
  rightMargin?: boolean;
}>();
const _title = translateStringOrLocale(() => props.title);
const _description = translateStringOrLocale(() => props.description);

const { result: versions } = throwOnError(
  compose(getVersions(props.block.blockId, false, false, props.isPreview)),
);

const { result: categories } = useCategoriesLazy();

const categoryBorderColorClass = computed(
  () =>
    'border-gnist-' +
    (categories.value?.find(
      (c) => c.categoryId === props.block.category?.categoryId,
    )?.color ?? ''),
);

const user = useCurrentUser();
const { result: favorite } = useBlockFavorite(toRef(props.block));

const toggleFavorite = async () => {
  if (!props.block) return;
  if (favorite.value) {
    removeFavoriteByBlockId(props.block.blockId);
    favorite.value = null;
  } else {
    const newFavorite = await addFavorite(props.block.blockId);
    favorite.value = newFavorite;
  }
};

const selectedVersion = computed(() =>
  versions.value?.find(
    (i) => i.versionNumber === props.block.currentVersionNumber,
  ),
);
const category = translateStringOrLocale(() => props.block.category?.name);
</script>

<template>
  <div role="banner">
    <div
      class="gnist-page-width w-full lg:grid lg:gap-10"
      :class="[
        ...bannerClasses,
        noLeftMargin ? rightMargin ? 'lg:grid-cols-4' : 'lg:grid-cols-3' : 'lg:grid-cols-4',
      ]"
    >
      <div v-if="!noLeftMargin" class="col-span-1 w-full 2xl:mr-auto">
        <slot name="leftMargin" />
      </div>
      <div class="relative col-span-3 mx-5 inline-block 2xl:mr-auto">
        <p>
          <OptionalRouterLink
            class="inline border-b-4 text-xl italic"
            :class="categoryBorderColorClass"
            outer-class="!inline"
            :to="useCategoryHomePageLink(block.category)"
          >
            {{ category }}
          </OptionalRouterLink>
        </p>
        <PageTitle class="mt-2 lg:min-w-[36rem] lg:pt-3" :underline="false">
          {{ _title }}
        </PageTitle>
        <p class="text-subtitle mt-4 max-w-2xl text-gnist-black">
          <span v-if="_description">{{ _description }}</span>
          <slot name="description" />
        </p>
        <div v-if="showVersionSelector && versions?.length" class="mt-4 flex">
          <Menu as="div" class="gnist-dropdown">
            <MenuButton class="gnist-button block">
              {{ t('block.version') }}:
              {{ block.currentVersionNumber ?? '' }}
              <span v-if="selectedVersion?.isBacklog">
                {{ t('block.versionInBacklogSuffix') }}
              </span>
              <span v-else-if="selectedVersion?.status == Status.Archived">
                {{ t('block.versionArchivedSuffix') }}
              </span>
            </MenuButton>
            <MenuItems class="gnist-dropdown-content min-w-[16ch]">
              <MenuItem
                v-for="version in versions"
                :key="version.versionId"
                v-slot="{ active, close }"
              >
                <RouterLink
                  :to="
                    getBlocksRouteLink(block.blockId, {
                      version: version.versionNumber,
                      preview: isPreview ? 1 : undefined,
                    })
                  "
                  :class="{
                    'outline-2': true,
                    outline: active,
                    'align-stretch block rounded-md p-4 text-left': true,
                  }"
                  @click.prevent="close"
                >
                  {{ version.versionNumber }}
                  <span v-if="version.isBacklog">
                    {{ t('block.versionInBacklogSuffix') }}
                  </span>
                  <span v-else-if="version.status == Status.Archived">
                    {{ t('block.versionArchivedSuffix') }}
                  </span>
                </RouterLink>
              </MenuItem>
            </MenuItems>
          </Menu>
          <div
            v-if="block.suggestion"
            class="ml-4 flex items-center gap-2 text-gnist-black"
          >
            <h4>{{ t('block.suggestionLinkHeader') }}:</h4>
            <p
              class="badge badge-outline badge-sm border-gnist-gray text-gnist-black"
              :class="[
                block.suggestion.type === 'Suggestion'
                  ? 'bg-gnist-green'
                  : 'bg-gnist-orange',
              ]"
            >
              {{ t(`suggestions.types.${block.suggestion.type}`) }}
            </p>
            <SuggestionStatusBadgeVue :suggestion="block.suggestion" />
            <RouterLink
              class="underline"
              :to="{
                name: 'suggestion_details',
                params: { suggestionId: block.suggestion.id },
              }"
            >
              {{ translateStringOrLocale(block.suggestion.title).value }}
            </RouterLink>
          </div>
        </div>
        <button
          v-if="favorite !== null && user !== null"
          class="flex items-center hover:cursor-pointer"
          @click="toggleFavorite()"
        >
          <MaterialIcon class="pr-2 text-3xl" aria-hidden="true">
            {{ favorite !== undefined ? 'star' : 'grade' }}
          </MaterialIcon>
          <span>
            {{
              favorite !== undefined ? t('favorite.remove') : t('favorite.add')
            }}
          </span>
        </button>
        <div v-if="favorite?.notes" class="p-2">
          <label class="font-bold">{{ t('favorite.myNote') }}</label>
          <div class="italic text-gnist-gray">
            {{ favorite.notes }}
          </div>
        </div>
      </div>

      <div v-if="rightMargin" class="col-span-1 w-full 2xl:ml-auto">
        <slot name="rightMargin" />
      </div>
    </div>
  </div>
</template>
