<script setup lang="ts">
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
</script>
<template>
  <footer
    class="text-p2 flex min-h-[16rem] w-full flex-col items-center bg-gnist-sp-gray p-12 text-center text-gnist-white"
  >
    <img
      alt="Sykehuspartner logo"
      src="../assets/sykehuspartner_logo_neg.svg"
      class="h-11 fill-white stroke-white"
    />
    <ul class="mt-8 leading-loose">
      <li>{{ t('footer.orgNumber') }}</li>
      <li>
        {{ t('footer.phone') }} |
        <a class="underline" href="mailto:gnist@sykehuspartner.no">{{
          t('footer.contact')
        }}</a>
      </li>
      <li>{{ t('footer.address') }} {{ t('footer.po') }}</li>
      <li>
        <a
          class="underline"
          href="https://uustatus.no/nb/erklaringer/publisert/0e3854f3-6f45-479b-a17e-1df01c2dd01e"
          target="_blank"
          >{{ t('footer.accessibility') }}</a
        >
      </li>
    </ul>
  </footer>
</template>
<style>
.st1 {
  fill: white !important;
}
</style>
