import { apiUrl } from '@/config';
import {
  composeLazy,
  defaultComputed,
  type LazyComposableResult,
} from './composable';
import { translateCategory, type Category, type PartialSome } from './types';
import { computed, unref, type Ref } from 'vue';
import { useRouter } from 'vue-router';
import { getBlocksRouteLink } from '@/utilities/routeUtils';

const categoryUrl = apiUrl + '/categories';

const allCategoriesLazy = composeLazy(() => getCategories());
export function useCategoriesLazy(
  delayInit?: boolean,
): LazyComposableResult<Category[]> {
  if (!delayInit) allCategoriesLazy.init();
  return allCategoriesLazy;
}
export function useBlockListCategories(
  categoryIdFilter?: number[] | Ref<number[] | undefined>,
) {
  const { result: categoriesNullable } = useCategoriesLazy();
  const _categories = defaultComputed(categoriesNullable, []);
  const categories = computed(() => {
    const _categoryIdFilter = unref(categoryIdFilter);
    return _categories.value
      .filter(
        (c) =>
          _categoryIdFilter === undefined ||
          _categoryIdFilter.includes(c.categoryId),
      )
      .map((c) => translateCategory(c));
  });
  return categories;
}
export function useCategoryHomePageLink(c: Category | undefined) {
  if (!c) return undefined;
  const router = useRouter();
  return router.resolve(
    getBlocksRouteLink(c.homePageId, { categories: c.categoryId }),
  );
}

const getCategories = async (): Promise<Category[]> => {
  const response = await fetch(categoryUrl, {
    method: 'GET',
    credentials: 'include',
  });
  if (!response.ok) throw new Error('error.get_categories_failed');
  return response.json();
};
export async function updateCategory(
  item: PartialSome<Category, 'categoryId'>,
): Promise<void> {
  if (!item?.categoryId) throw 'Missing primary key';
  const resp = await fetch(`${categoryUrl}/${item.categoryId}`, {
    method: 'PUT',
    credentials: 'include',
    body: JSON.stringify(item),
    headers: { 'Content-Type': 'application/json' },
  });
  if (!resp.ok) throw new Error('error.update_category_failed');
}
export async function createCategory(
  item: Omit<Category, 'categoryId'>,
): Promise<Category> {
  const resp = await fetch(`${categoryUrl}`, {
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify(item),
    headers: { 'Content-Type': 'application/json' },
  });
  if (!resp.ok) throw new Error('error.create_category_failed');
  return await resp.json();
}
export async function deleteCategory(categoryId: string) {
  fetch(`${categoryUrl}/${categoryId}`, {
    method: 'DELETE',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
  });
}
