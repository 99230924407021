<script setup lang="ts">
import {
  onErrorCaptured,
  ref,
  watch,
  type Component,
  shallowRef,
  watchEffect,
  computed,
} from 'vue';
import { RouterView, useRoute, useRouter } from 'vue-router';
import FooterBar from './components/FooterBar.vue';
import HeaderBar from './components/HeaderBar.vue';
import ErrorMessagePopup from './components/ErrorMessagePopup.vue';
import { error_pages, type ErrorMessage } from './api/types';
import NotificationPrompt from './components/NotificationPrompt.vue';
import { useUserSessionTimeout, useNewAppVersion } from './api/status';
import { useI18n } from 'vue-i18n';
import NotFoundView from '@/views/Errors/NotFoundView.vue';
import UnauthorizedView from './views/Errors/UnauthorizedView.vue';
import ForbiddenView from './views/Errors/ForbiddenView.vue';
import { setupResizeListener } from './utilities/useResizeListener';
import { waitForRouterReady } from './router/routeUtils';
import { getRouteTitle } from './utilities/routeUtils';
import { ExtendedError } from './utilities/ExtendedError';
import { set_locale_from_query } from './i18n';

const { t } = useI18n();

const errorMessage = ref<ErrorMessage | null>(null);
const errorComponent = shallowRef<Component | null>(null);

onErrorCaptured((error) => {
  const errorPage = Object.values(error_pages).find(
    (value) => value === error.message,
  );
  if (errorPage !== undefined) {
    if (errorPage === error_pages[404]) errorComponent.value = NotFoundView;
    if (errorPage === error_pages[403]) errorComponent.value = ForbiddenView;
    if (errorPage === error_pages[401]) errorComponent.value = UnauthorizedView;
    return false;
  }
  errorMessage.value = {
    message: error.message,
    autoClose: true,
    closeable: true,
  } as ErrorMessage;

  console.error(error);
  if (error instanceof ExtendedError && error.details) {
    console.groupCollapsed('Extended error cause info');
    console.log(error.details);
    console.groupEnd();
  }
  return false;
});

function onErrorClose() {
  errorMessage.value = null;
}

const route = useRoute();

watch(
  [() => route.path, () => route.meta?.title, () => route.query.fullscreen],
  () => {
    document.title = getRouteTitle(t(route.meta?.title ?? 'Gnist'));
    document.body.setAttribute('tabindex', '-1');
    document.body.focus();
    document.body.removeAttribute('tabindex');
    errorComponent.value = null;
    if (route.query.fullscreen) route.meta.fullScreen = true;
  },
);
watch([() => route.query], () => {
  set_locale_from_query(route);
});

const router = useRouter();
const routerReady = ref(false);
watchEffect(async () => {
  await waitForRouterReady();
  routerReady.value = true;
});

useUserSessionTimeout(() => {
  router.push('/');

  errorMessage.value = {
    message: 'error.logged_out',
    autoClose: false,
    closeable: true,
  } as ErrorMessage;
});

useNewAppVersion(() => {
  errorMessage.value = {
    message: 'error.new_version_available',
    autoClose: false,
    closeable: false,
  } as ErrorMessage;
});

setupResizeListener();
/** Changing the key of a vue component forces it to be replaced.
 * This is useful to ensure components are reloaded when navigating where we have the same component, but we don't want it to happen if we only change the hash (inter-page-movement).
 * See https://github.com/SPHF-Moderne-Tjenesteutvikling/butikken/pull/328#discussion_r1229503171
 */
const routeKey = computed(() => route.fullPath.replace(route.hash, ''));
</script>

<template>
  <div
    class="flex min-h-screen flex-col items-stretch"
    :data-fullscreen="route.meta.fullScreen"
  >
    <div
      role="navigation"
      aria-labelledby="skip-to-main"
      class="text-center focus-within:py-2"
    >
      <a
        id="skip-to-main"
        href="#main-content"
        class="sr-only focus:not-sr-only"
      >
        <span>{{ t('header.skipLink') }}</span>
      </a>
    </div>
    <HeaderBar v-if="!route.meta.fullScreen && routerReady" />
    <main
      id="main-content"
      class="flex grow flex-col bg-gnist-blue-light-light text-gnist-sp-blue-dark"
    >
      <NotificationPrompt />
      <ErrorMessagePopup
        v-if="errorMessage"
        :error-message="errorMessage"
        @close="onErrorClose"
      />
      <RouterView
        v-if="errorComponent === null && routerReady"
        :key="routeKey"
      />
      <component :is="errorComponent" v-else />
    </main>

    <FooterBar v-if="!route.meta.fullScreen && routerReady" />
  </div>
</template>
