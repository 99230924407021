import { sleep } from '@/utilities/sleep';
import {
  computed,
  ref,
  watchEffect,
  type ComputedRef,
  type Ref,
  watch,
  type WatchSource,
} from 'vue';

export type ComposableResult<T> = {
  result: Ref<T | null>;
  error: Ref<Error | null>;
  loaded: Ref<boolean>;
};
export type ComposableResultWithRefresh<T> = ComposableResult<T> & {
  refresh?: () => Promise<void>;
};
export type LazyComposableResult<T> = ComposableResultWithRefresh<T> & {
  init: () => Promise<void>;
};
type AnyComposableResult<T> =
  | ComposableResult<T>
  | ComposableResultWithRefresh<T>;

type ComposableData<TComposable> =
  TComposable extends AnyComposableResult<infer T> ? T : never;

export type ThrowingComposable<
  TComposable extends AnyComposableResult<ComposableData<TComposable>>,
> = Omit<TComposable, 'error'>;

export function compose<T>(
  asyncFunctionResult: Promise<T | null> | T | null,
): ComposableResult<T> {
  const composableResult = initComposable<T>();
  loadComposable(asyncFunctionResult, composableResult);
  return composableResult;
}

export function composeWithRefresh<TResult>(
  asyncFunction: (isRefreshing: boolean) => Promise<TResult> | TResult,
  autoRefreshTrigger?: WatchSource | WatchSource[],
  resetBeforeRefresh = true,
): ComposableResultWithRefresh<TResult> {
  return composeWithRefreshInternal(
    asyncFunction,
    autoRefreshTrigger,
    resetBeforeRefresh,
  );
}
function composeWithRefreshInternal<TResult>(
  asyncFunction: (isRefreshing: boolean) => Promise<TResult> | TResult,
  autoRefreshTrigger?: WatchSource | WatchSource[],
  resetBeforeRefresh = true,
  delayLoad = false,
): ReturnType<typeof initComposable<TResult>> {
  const composable = initComposable<TResult>(
    asyncFunction,
    resetBeforeRefresh,
    delayLoad,
  );
  if (autoRefreshTrigger) {
    watch(autoRefreshTrigger, () => composable.refresh?.());
  }
  return composable;
}
export function composeLazy<TResult>(
  asyncFunction: (isRefreshing: boolean) => Promise<TResult> | TResult,
  autoRefreshTrigger?: WatchSource | WatchSource[],
  resetBeforeRefresh = true,
): LazyComposableResult<TResult> {
  const composable = composeWithRefreshInternal(
    asyncFunction,
    autoRefreshTrigger,
    resetBeforeRefresh,
    true,
  );
  const loading = ref(false);
  const lazyInit = async () => {
    while (loading.value && !composable.error.value) {
      await sleep(250);
    }
    if (!composable.loaded.value) {
      loading.value = true;
      await composable.init?.();
      loading.value = false;
    }
  };
  return { ...composable, init: lazyInit };
}

async function loadComposable<T>(
  asyncFunctionResult: Promise<T | null> | T | null,
  composableResult: ComposableResult<T>,
): Promise<void> {
  try {
    composableResult.loaded.value = false;
    const r = await asyncFunctionResult;
    composableResult.result.value = r;
    composableResult.loaded.value = true;
  } catch (e) {
    composableResult.error.value = e as Error;
  }
}

function initComposable<T>(
  refreshFn?: (isRefreshing: boolean) => Promise<T> | T,
  resetBeforeRefresh = true,
  noLoad = false,
): ComposableResultWithRefresh<T> & { init?: () => Promise<void> } {
  const result = ref<T | null>(null) as Ref<T | null>;
  const error = ref<Error | null>(null);
  const loaded = ref(false);
  let refresh: (() => Promise<void>) | undefined;
  let init: (() => Promise<void>) | undefined;
  if (refreshFn) {
    const load = async (isRefreshing: boolean) => {
      if (resetBeforeRefresh) {
        loaded.value = false;
        result.value = null;
        error.value = null;
      }
      try {
        result.value = await refreshFn(isRefreshing);
        loaded.value = true;
      } catch (err) {
        loaded.value = false;
        result.value = null;
        error.value = err as Error;
      }
    };
    if (!noLoad) load(false);
    refresh = async () => load(true);
    init = async () => load(false);
  }
  return {
    result,
    error,
    refresh,
    loaded,
    init,
  };
}

export function throwOnError<
  TComposable extends AnyComposableResult<ComposableData<TComposable>>,
>(composable: TComposable): ThrowingComposable<TComposable> {
  watchEffect(
    () => {
      if (composable.error.value) throw composable.error.value;
    },
    { flush: 'sync' },
  );
  return { ...composable, error: undefined };
}

export function defaultComputed<T>(
  composable: Ref<T | null>,
  defaultValue: T,
): ComputedRef<T> {
  return computed(() =>
    composable.value === null ? defaultValue : composable.value,
  );
}
