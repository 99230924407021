<script setup lang="ts">
import ButtonComponent from '@/components/ButtonComponent.vue';
import { ParagraphShimmer } from 'vue3-shimmer';
import TextInput from '@/components/forms/InputText.vue';
import MarkdownInput from '@/components/forms/InputMarkdown.vue';
import Dropdown, {
  toDropdownItems,
} from '@/components/forms/InputDropdown.vue';
import TagPicker from '@/components/forms/InputTagPicker.vue';
import Checkbox from '@/components/forms/InputCheckbox.vue';
import InputLabel from '@/components/forms/InputLabel.vue';
import VersionCopy from '@/components/admin/VersionCopy.vue';
import ModalComponent from '@/components/ModalComponent.vue';
import { computed, ref, watch, watchEffect } from 'vue';
import { useRoute } from 'vue-router';
import { throwOnError } from '@/api/composable';
import { useI18n } from 'vue-i18n';
import {
  closeSuggestion,
  connectWorkItem,
  createSuggestion,
  createWorkItem,
  deleteSuggestion,
  disconnectWorkItem,
  updateSuggestion,
  useSuggestion,
} from '@/api/suggestion';
import { useStoreAbility } from '@/abilities';
import {
  getEmptyLocaleValue,
  type Guid,
  type Suggestion,
  type Version,
} from '@/api/types';
import { useValidationTracking } from '@/utilities/useValidationTracking';
import { useRouter } from 'vue-router';
import { useVersions } from '@/api/versions';
import { deepClone } from '@/utilities/deepClone';
import {
  getSuggestionStatus,
  suggestionManualStatuses,
} from '@/utilities/getSuggestionStatus';
import { md } from '@/utilities/markdown/MarkdownIt';
import { translateStringOrLocale } from '@/i18n';
import InputBlockPicker from '@/components/forms/InputBlockPicker.vue';
import { getMdEnv } from '@/utilities/markdown/utilities';

const { t } = useI18n();
const { can } = useStoreAbility();

const route = useRoute();
const router = useRouter();
const suggestionId = can('update', 'Suggestion')
  ? parseInt(route.params.suggestionId as string)
  : undefined;
const fromDevOps = ref(route.query.fromDevOps !== undefined);
const handleCleanup = async (value: string) => {
  if (!suggestion.value) return;
  fromDevOps.value = false;
  suggestion.value.description.no = value;
  suggestion.value.description.en = value;
  await onSave();
  await router.push({ query: undefined });
};

const suggestionType: Suggestion['type'] =
  (route.params.type as string)?.toLowerCase() == 'bug' ? 'Bug' : 'Suggestion';
if (suggestionType === 'Bug' && !can('create', 'Bug')) {
  const targetType: Suggestion['type'] = 'Suggestion';
  router.push({ path: `/suggestions/add/${targetType}` });
}

const isEdit = !!suggestionId;

const { result: _suggestion } = throwOnError(useSuggestion(suggestionId, true));
const suggestion = ref<(Suggestion & { workItemId?: number }) | null>(
  isEdit
    ? null
    : {
        tempId: crypto.randomUUID(),
        title: getEmptyLocaleValue(),
        description: getEmptyLocaleValue(),
        type: suggestionType,
      },
);
watchEffect(() => {
  if (!_suggestion.value) return;
  (_suggestion.value as { workItemId?: number }).workItemId =
    _suggestion.value.workItem?.id;
  if (_suggestion.value.blockVersion?.blockName) {
    _suggestion.value.blockVersion.blockName = {
      no:
        translateStringOrLocale(_suggestion.value.blockVersion?.blockName)
          .value ?? '',
    };
  }
  suggestion.value = deepClone(_suggestion.value);
});
const texts = computed(() =>
  isEdit ? 'suggestions.admin.edit' : 'suggestions.admin.new',
);

const showValidationMessages = ref<boolean>(isEdit);
const triggerFormValidation = ref<boolean>(isEdit);
const { onValidChanged, formRef, isValid } = useValidationTracking(
  suggestion,
  triggerFormValidation,
  true,
  isEdit,
);

const canSave = computed(() => {
  if (!_suggestion.value && !suggestion.value) return false;
  if (!_suggestion.value) return isValid.value;
  const validDevOpsConnections =
    suggestion.value?.workItem !== undefined || !suggestion.value?.workItemId;
  return (
    isValid.value &&
    validDevOpsConnections &&
    JSON.stringify(_suggestion.value) !== JSON.stringify(suggestion.value)
  );
});

const onSave = async () => {
  if (!suggestion.value) return;
  if (!isValid.value) {
    showValidationMessages.value = true;
    triggerFormValidation.value = true;
    setTimeout(() => (triggerFormValidation.value = false), 250);
    return;
  }
  if (!isEdit) {
    suggestion.value.title.en = suggestion.value.title.no;
    suggestion.value.description.en = suggestion.value.description.no;
    const updatedValue = await createSuggestion(suggestion.value);
    suggestion.value.id = updatedValue.id;
    suggestion.value.tempId = undefined;
    router.push({ path: '/suggestions' });
  } else {
    await updateSuggestion({ ...suggestion.value, suggestedBy: undefined });
    _suggestion.value = suggestion.value;
    suggestion.value = deepClone(_suggestion.value);
  }
};
const maxDescriptionLength = 4000;
const editorHeight = isEdit ? '30rem' : '20rem';
const typeOptions = toDropdownItems(
  ['Suggestion', 'Bug'] satisfies Suggestion['type'][],
  (i) => [i, t(`suggestions.types.${i}`)],
);
const selectedBlockId = ref<Guid>();
const selectedBlockName = ref<string>();
watch(_suggestion, () => {
  if (!_suggestion.value || selectedBlockId.value) return;
  if (!_suggestion.value.blockVersion?.blockId) return;
  selectedBlockId.value = _suggestion.value.blockVersion?.blockId;
});
const selectedVersion = ref<Version | null>(null);
watchEffect(() => {
  if (!suggestion.value) return;
  if (!selectedVersion.value) {
    suggestion.value.blockVersion = undefined;
    return;
  }
  if (!suggestion.value.blockVersion) {
    suggestion.value.blockVersion = { id: selectedVersion.value.versionId };
  } else {
    suggestion.value.blockVersion.id = selectedVersion.value.versionId;
  }
  // Important: order of adding properties should match what comes from the API when getting blockVersion property
  suggestion.value.blockVersion.blockId = selectedBlockId.value;
  suggestion.value.blockVersion.blockName = {
    no: selectedBlockName.value ?? '',
  };
  suggestion.value.blockVersion.versionNumber =
    selectedVersion.value.versionNumber;
});
const { result: versions } = useVersions(selectedBlockId, true, false);
watch(
  () => [_suggestion.value?.blockVersion?.id, versions.value],
  () => {
    if (!versions.value || !_suggestion.value || selectedVersion.value) return;
    if (_suggestion.value.blockVersion?.id) {
      selectedVersion.value =
        versions.value.find(
          (b) => b.versionId === _suggestion.value?.blockVersion?.id,
        ) ?? null;
    }
  },
);

const versionOptions = computed(() =>
  toDropdownItems(
    versions.value,
    (v) => [v.versionId.toString(), v.versionNumber, v],
    true,
  ),
);
const statusOptions = computed(() => {
  const statuses = [...suggestionManualStatuses];
  if (_suggestion.value?.status === 'Done') statuses.push('Done');
  return toDropdownItems(statuses, (i) => [i, t(`suggestions.status.${i}`)]);
});
const connectDevOps = async () => {
  if (!suggestion.value) return;
  if (!suggestion.value.workItemId) {
    if (!_suggestion.value) return;
    const description = md.render(
      suggestion.value.description.no,
      getMdEnv(
        _suggestion.value.id
          ? { suggestionId: _suggestion.value.id }
          : 'public',
        window.location.origin,
      ),
    );

    const workItem = await createWorkItem({
      type: suggestion.value.type == 'Bug' ? 'Bug' : 'User Story',
      title: suggestion.value.title.no,
      description,
    });
    suggestion.value.workItemId = workItem.id;
  }
  try {
    const result = await connectWorkItem(
      { workItem: { id: suggestion.value.workItemId } },
      _suggestion.value?.id,
    );
    if (_suggestion.value?.id === undefined) {
      if (result.id) {
        router.push({
          name: 'suggestion_edit',
          params: { suggestionId: result.id },
          query: { fromDevOps: true.toString() },
        });
      }
      return;
    }
    for (const item of [_suggestion.value, suggestion.value]) {
      item.workItem = { ...result.workItem };
      item.status = result.status;
    }
  } catch {
    throw new Error('error.invalid_workitem');
  }
};
const disconnectDevOps = async () => {
  if (!_suggestion.value?.id || !suggestion.value) return;
  try {
    await disconnectWorkItem(
      { workItem: { id: suggestion.value.workItemId } },
      _suggestion.value.id,
    );
    for (const item of [_suggestion.value, suggestion.value]) {
      item.workItem = undefined;
      item.status = 'UnderConsideration';
    }
  } catch {
    throw new Error('error.invalid_workitem');
  }
};
const setDone = async () => {
  if (!_suggestion.value?.id || !suggestion.value) return;
  await closeSuggestion(_suggestion.value.id);
  suggestion.value.status = 'Done';
  _suggestion.value.status = 'Done';
};
const createFromDevOps = ref<boolean | null>(
  can('update', 'Suggestion') && !isEdit ? false : null,
);
const showDeleteModal = ref(false);
async function doDelete() {
  if (!_suggestion.value?.id) return;
  await deleteSuggestion(_suggestion.value.id);
  router.push({ name: 'suggestion_list' });
}
</script>

<template>
  <div class="w-full bg-gnist-gray-light-light text-gnist-black">
    <ParagraphShimmer
      v-if="suggestion === null"
      :lines="6"
      :random-size="true"
      class="mx-auto w-full max-w-5xl"
    />

    <div
      v-else
      class="mx-2 py-8 md:mx-auto"
      :class="[isEdit ? 'md:max-w-[100rem]' : 'md:w-[50rem]']"
    >
      <h1 class="mb-16">
        {{ t(`${texts}.header${suggestion.type}`) }}
      </h1>
      <form
        ref="formRef"
        class="flex grid-cols-2 flex-col items-stretch gap-x-4 md:grid"
      >
        <Checkbox
          v-if="createFromDevOps !== null"
          v-model="createFromDevOps"
          label="suggestions.admin.createFromDevOps"
          direction="horizontal"
          mode="toggle"
          class="col-span-2"
        />
        <TextInput
          v-if="!createFromDevOps"
          v-model="suggestion.title.no"
          :label="`${texts}.titleNo`"
          required
          :minlength="1"
          :maxlength="80"
          placeholder="suggestions.admin.titlePlaceholder"
          :class="{ 'col-span-2': !isEdit }"
        />
        <TextInput
          v-if="isEdit"
          v-model="suggestion.title.en"
          label="suggestions.admin.titleEn"
          required
          :minlength="1"
          :maxlength="80"
          placeholder="suggestions.admin.titlePlaceholder"
        />
        <template v-if="isEdit">
          <Dropdown
            v-model="suggestion.type"
            label="suggestions.admin.type"
            :options="typeOptions"
            :getkey="(t) => t"
            :disabled="suggestion.workItem !== undefined"
          />
          <Dropdown
            v-if="suggestion.workItem === undefined"
            v-model="suggestion.status"
            label="suggestions.admin.status"
            tooltip="suggestions.admin.statusTooltip"
            :options="statusOptions"
            :getkey="(t) => t"
          />
          <TextInput
            v-else
            :model-value="
              t(
                `suggestions.status.${getSuggestionStatus(suggestion)}`,
                getSuggestionStatus(suggestion) ?? '',
              )
            "
            label="suggestions.admin.status"
            tooltip="suggestions.admin.statusTooltip"
            disabled
          />
          <TagPicker
            v-model="suggestion.tags"
            label="suggestions.admin.tags"
            suggestedlabel="admin.blockProduction.suggestedTags"
            :taglistclass="['max-h-24 overflow-y-auto']"
            class="col-span-2"
          />

          <div class="col-span-2 flex grid-cols-3 flex-col gap-x-4 md:grid">
            <InputBlockPicker
              v-model="selectedBlockId"
              label="suggestions.admin.select_block"
              @changed="(val) => (selectedBlockName = val?.name)"
            />
            <Dropdown
              v-if="selectedBlockId"
              v-model="selectedVersion"
              label="suggestions.admin.select_version"
              :options="versionOptions"
              :getkey="(t) => t?.versionId"
            />
            <VersionCopy
              v-if="selectedBlockId && !selectedVersion"
              :block-id="selectedBlockId"
              :versions="versions"
              class="my-2 self-end border-[1px] border-transparent py-1"
              @created="
                (version) => {
                  if (!suggestion) return;
                  if (versions) versions.push(version);
                  selectedVersion = version;
                }
              "
            />
            <ButtonComponent
              v-if="
                suggestion.workItem?.status === 'Closed' &&
                suggestion.status !== 'Done'
              "
              :text="t('suggestions.admin.finish_backlog')"
              class="my-2 w-auto self-end justify-self-start"
              @click.prevent="setDone"
            />
          </div>
        </template>
        <template v-if="isEdit || createFromDevOps === true">
          <div class="col-span-2 flex grid-cols-3 flex-col gap-x-4 md:grid">
            <div class="flex items-stretch">
              <TextInput
                v-model="suggestion.workItemId"
                label="suggestions.admin.workitem"
                tooltip="suggestions.admin.workitemTooltip"
                :disabled="suggestion.workItem !== undefined"
                class="basis-full"
              />
              <ButtonComponent
                v-if="suggestion.workItem"
                :text="t('suggestions.admin.disconnect_workitem')"
                class="my-2 w-auto self-end justify-self-start"
                @click.prevent="disconnectDevOps"
              />
            </div>
            <label
              for="devopsLink"
              class="flex flex-col whitespace-nowrap"
              :class="{ invisible: suggestion.workItem === undefined }"
            >
              <InputLabel label="suggestions.admin.workitemLink" />
              <div
                class="my-2 overflow-hidden text-ellipsis border-[1px] border-transparent py-2"
              >
                <a
                  v-if="!!suggestion.workItem"
                  :href="suggestion.workItem.url"
                  :alt="suggestion.workItem.title"
                  target="_blank"
                  class="ml-1 underline"
                >
                  {{ suggestion.workItem.title }}
                </a>
              </div>
            </label>
            <TextInput
              :model-value="suggestion.workItem?.status"
              label="suggestions.admin.workitemStatus"
              :disabled="true"
              :class="{ hidden: suggestion.workItem === undefined }"
            />
            <ButtonComponent
              v-if="!suggestion.workItem"
              :text="
                createFromDevOps === true
                  ? t('suggestions.admin.createFromDevOps')
                  : suggestion.workItemId
                    ? t('suggestions.admin.connect_workitem')
                    : t('suggestions.admin.create_workitem')
              "
              class="my-2 w-auto self-end justify-self-start"
              :disabled="createFromDevOps === true && !suggestion.workItemId"
              @click.prevent="connectDevOps"
            />
          </div>
        </template>
        <MarkdownInput
          v-if="!createFromDevOps"
          v-model="suggestion.description.no"
          for-name="description_no"
          :label="`${texts}.descriptionNo`"
          required
          :maxlength="maxDescriptionLength"
          :tooltip="`${texts}.descriptionTooltip`"
          placeholder="suggestions.admin.descriptionPlaceholder"
          :blob-location="
            suggestion.id
              ? { suggestionId: suggestion.id }
              : suggestion.tempId
                ? { suggestionId: suggestion.tempId }
                : undefined
          "
          :editor-height="editorHeight"
          :hide-filepicker="!isEdit"
          class="mt-4"
          :class="{ 'col-span-2': !isEdit }"
          :show-validation-message="showValidationMessages"
          :request-cleanup="fromDevOps"
          @report-validation-error="
            (name, hasError) => onValidChanged(name, !hasError)
          "
          @cleanup:model-value="handleCleanup"
        />
        <MarkdownInput
          v-if="isEdit"
          v-model="suggestion.description.en"
          for-name="description_en"
          label="suggestions.admin.descriptionEn"
          required
          :maxlength="maxDescriptionLength"
          :tooltip="`${texts}.descriptionTooltip`"
          placeholder="suggestions.admin.descriptionPlaceholder"
          :blob-location="
            suggestion.id
              ? { suggestionId: suggestion.id }
              : suggestion.tempId
                ? { suggestionId: suggestion.tempId }
                : undefined
          "
          :editor-height="editorHeight"
          class="mt-4"
          :show-validation-message="showValidationMessages"
          @report-validation-error="
            (name, hasError) => onValidChanged(name, !hasError)
          "
        />
        <div
          v-if="createFromDevOps !== true"
          className="flex justify-end col-span-2 p-2"
        >
          <div class="gnist-button-group">
            <ButtonComponent
              v-if="isEdit && can('delete', 'Suggestion')"
              type="danger"
              :text="t('buttons.delete')"
              @click="() => (showDeleteModal = true)"
            />
            <RouterLink :to="{ path: '/suggestions' }">
              <ButtonComponent :text="t('buttons.cancel')" class="w-full" />
            </RouterLink>
            <ButtonComponent
              type="primary"
              :text="
                isEdit
                  ? t('buttons.save')
                  : t(`suggestions.admin.submit.${suggestionType}`)
              "
              :disabled="isEdit && !canSave"
              @click="onSave"
            />
            <ModalComponent
              :show-modal="showDeleteModal"
              :title="t('suggestions.admin.confirmDelete')"
              @close="() => (showDeleteModal = false)"
              @handle-click="doDelete"
            >
              <template #default>
                <p>{{ t('suggestions.admin.deletePermanently') }}</p>
              </template>
            </ModalComponent>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
