<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { computed, ref, watch } from 'vue';
import type { supported_locale } from '@/config';
import ComponentPreviewMdEditor from '@/components/ComponentPreviewMdEditor.vue';
import BlockToc from '@/components/block/BlockToc.vue';
import ContentPage from '@/components/ContentPage.vue';
import MaterialIcon from '@/components/MaterialIcon.vue';

const { t, locale } = useI18n();
const isNorwegian = computed(() => (locale.value as supported_locale) === 'no');
const container = ref<HTMLElement | null>(null);
const containers = ref<{ idx: number; el: HTMLElement }[]>([]);
watch(container, () => {
  if (!container.value) return;
  containers.value = [{ idx: 0, el: container.value }];
});
</script>
<template>
  <ContentPage
    :title="t('header.items.usermanual.rich_text')"
    class="bg-gnist-blue-light-light [&_.max-w-screen-xl]:max-w-screen-2xl"
    :banner-classes="[
      'w-full max-w-screen-2xl px-8 before:block lg:grid lg:grid-cols-4 lg:gap-10 [&>div]:col-span-3 [&>div]:w-full [&>div]:lg:px-0',
    ]"
  >
    <div class="grow">
      <div class="mx-2 mt-0">
        <div class="w-full text-gnist-black">
          <div
            :class="[
              'pb-16',
              '2xl:mx-auto',
              'grow lg:grid lg:grid-cols-4 lg:gap-10',
            ]"
          >
            <div class="relative col-span-1 ml-auto mt-4 lg:pb-24">
              <BlockToc :containers="containers" />
            </div>
            <div ref="container" class="html-render col-span-3 mt-4 pt-5">
              <h2 v-if="isNorwegian" id="edit_modes">
                Forskjellige redigeringsmoduser
              </h2>
              <h2 v-else id="edit_modes">Different editing modes</h2>
              <p v-if="isNorwegian">
                Redigering av rik tekst er basert på standarden
                <a href="https://commonmark.org/help/">Markdown</a>, men med
                støtte fra en WySiWyG-editor (What You See Is What You Get) som
                gjør det enklere å formatere teksten. Editoren har to
                <span class="italic">moduser</span> som du kan velge med fanene
                nederst:
              </p>
              <p v-else>
                Editing rich text is based on the
                <a href="https://commonmark.org/help/">Markdown</a> standard,
                with added support from a WySiWyG editor (What You See Is What
                You Get) which makes it easier to format the text. The editor
                has two <span class="italic">modes</span> which you can choose
                with the tabs at the bottom:
              </p>
              <ComponentPreviewMdEditor
                visible-part-selector=".toastui-editor-mode-switch"
                markers=".toastui-editor-mode-switch .tab-item"
                class="max-w-full"
              />
              <template v-if="isNorwegian">
                <p>
                  <span class="italic">Formatert</span> er standardmodusen, og
                  viser teksten stort sett som den vil se ut når den publiseres.
                  I denne modusen må du bruke verktøylinjen øverst for å
                  formatere teksten.
                </p>
                <p>
                  <span class="italic">Markdown</span> er en visning hvor du
                  skriver markdown, men også ser omtrent hvordan resultatet vil
                  bli. Du kan fortsatt bruke verktøylinjen i denne modusen.
                </p>
              </template>
              <template v-else>
                <p>
                  <span class="italic">WYSIWYG</span> is the default mode, and
                  shows the text mostly as it will look when published. In this
                  mode you have to use the toolbar at the top to format the
                  text.
                </p>
                <p>
                  <span class="italic">Markdown</span> is a view where you write
                  markdown, but also see approximately how the result will look.
                  You can still use the toolbar in this mode.
                </p>
              </template>
              <p v-if="isNorwegian">
                Hvis du vil skrive markdown direkte uten noen innblanding fra
                editoren, trykk på kildekodeknappen
                <span class="inline-flex">
                  (
                  <MaterialIcon>data_object</MaterialIcon>
                  ):
                </span>
              </p>
              <p v-else>
                If you want to write markdown directly without any interference
                from the editor, press the source code button
                <span class="inline-flex">
                  (
                  <MaterialIcon>data_object</MaterialIcon>
                  ):
                </span>
              </p>
              <ComponentPreviewMdEditor
                visible-part-selector=".toastui-editor-toolbar-group:has(.ViewSourceButton)"
                markers=".ViewSourceButton"
                class="min-w-[63rem]"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </ContentPage>
</template>
