import { apiUrl } from '@/config';
import { isRef, type ComputedRef, type Ref } from 'vue';
import { Status, type Guid, type Version, type VersionUpdate } from './types';
import {
  compose,
  throwOnError,
  composeWithRefresh,
  type ThrowingComposable,
  type ComposableResultWithRefresh,
} from './composable';

const blockUrl = apiUrl + '/blocks';

export function useVersions(
  blockID:
    | Guid
    | undefined
    | Ref<Guid | undefined>
    | ComputedRef<Guid | undefined>,
  useDraftReaderEndpoint = false,
  includeContent = false,
): ThrowingComposable<ComposableResultWithRefresh<Version[]>> {
  if (isRef(blockID)) {
    return throwOnError(
      composeWithRefresh(() => {
        if (!blockID.value) return [];
        return getVersions(
          blockID.value,
          useDraftReaderEndpoint,
          includeContent,
        );
      }, blockID),
    );
  } else {
    return throwOnError(
      compose(getVersions(blockID, useDraftReaderEndpoint, includeContent)),
    );
  }
}

export async function updateVersion(
  blockId: Guid,
  versionId: number,
  version: Partial<VersionUpdate>,
): Promise<void> {
  const publish = version.status == Status.Published;
  const resp = await fetch(
    `${blockUrl}/${blockId}/versions/${versionId}${publish ? '/publish' : ''}`,
    {
      method: 'PUT',
      credentials: 'include',
      body: JSON.stringify(version),
      headers: { 'Content-Type': 'application/json' },
    },
  );
  if (!resp.ok) throw new Error('error.update_version_failed');
}

export async function getVersions(
  blockId: Guid | undefined,
  useDraftReaderEndpoint: boolean,
  includeContent = true,
  includeUnpublished = true,
): Promise<Version[]> {
  if (blockId === undefined) return [];
  let query = '';
  if (!includeContent) query += '?includeContent=false';
  if (!includeUnpublished) {
    query += query ? '&' : '?';
    query += 'includeUnpublished=false';
  }
  const response = await fetch(
    `${blockUrl}/${blockId}/versions${
      useDraftReaderEndpoint ? '/withdraftversions' : ''
    }${query}`,
    {
      method: 'GET',
      credentials: 'include',
    },
  );
  return await response.json();
}

export async function getVersion(
  blockId: Guid | undefined,
  versionId: number,
): Promise<Version | null> {
  if (blockId === undefined) return null;
  const response = await fetch(
    `${blockUrl}/${blockId}/versions/withdraftversions/${versionId}`,
    {
      method: 'GET',
      credentials: 'include',
    },
  );
  return (await response.json()) ?? null;
}

export const createVersion = async (
  blockId: Guid,
  versionNumber: string,
  copyFromVersionId?: number,
): Promise<Version> => {
  const body = {
    versionNumber: versionNumber,
    copyFromVersionId: copyFromVersionId,
  };

  const response = await fetch(`${blockUrl}/${blockId}/versions`, {
    method: 'POST',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  });
  if (response.ok) return await response.json();
  throw new Error('error.create_version_failed');
};

export async function deleteVersion(
  blockId: Guid,
  versionId: number,
): Promise<void> {
  await fetch(`${blockUrl}/${blockId}/versions/${versionId}`, {
    method: 'DELETE',
    credentials: 'include',
  });
}
export async function deleteDraft(
  blockId: Guid,
  versionId: number,
): Promise<void> {
  await fetch(`${blockUrl}/${blockId}/versions/${versionId}/draft`, {
    method: 'DELETE',
    credentials: 'include',
  });
}

export async function setCurrentVersion(
  blockId: Guid,
  versionId: number,
): Promise<void> {
  const response = await fetch(`${blockUrl}/${blockId}/currentversion`, {
    method: 'PUT',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ currentVersionId: versionId }),
  });
  if (!response.ok) throw new Error('error.set_current_version_failed');
}
