import MarkdownIt from 'markdown-it';
import MarkdownItContainer from 'markdown-it-container';
import type StateInline from 'markdown-it/lib/rules_inline/state_inline.mjs';
import type Token from 'markdown-it/lib/token.mjs';
import {
  calculateUrl,
  getOnelineContainerAttributes,
  getGroupRe,
  getInlineContainerAttributes,
  type MdExtensionAttributesPartial,
  type MdExtensionAttributes,
  type mdEnvironment,
} from './utilities';
import type { cardTemplateIds } from '@/renderTemplates';
import type { Guid, PartialSome, Tag } from '@/api/types';

/** Based on https://github.com/markdown-it/markdown-it/blob/master/lib/rules_inline/html_inline.mjs and https://github.com/markdown-it/markdown-it/blob/master/lib/rules_inline/link.mjs, but due to the specific nature of this thing most stuff is removed. */
function tokenizeWhitelistedHtml(state: StateInline, silent: boolean) {
  const tags: (keyof HTMLElementTagNameMap)[] = ['u'];
  for (const tag of tags) {
    const src = state.src.substring(state.pos);
    const tagRe = new RegExp(`(<${tag}>)(.*)(<\\/${tag}>)`);
    const match = src.match(tagRe);
    if (!match) continue;
    const max = state.posMax;
    const labelStart = state.pos + `<${tag}>`.length;
    const labelEnd = labelStart + match[2].length;
    if (!silent) {
      const open_token = state.push('gnist_html_open', '', 0);
      open_token.content = `<${tag}>`;
      state.pos = labelStart;
      state.posMax = labelEnd;
      state.md.inline.tokenize(state); // Handle tag content using normal rules
      const close_token = state.push('gnist_html_close', '', 0);
      close_token.content = `</${tag}>`;
    }
    state.pos = labelEnd + `</${tag}>`.length;
    state.posMax = max;
    return true;
  }
  return false;
}
export type CustomLinkTokenType = 'custom_link';
export function tokenizeCustomLink(state: StateInline, silent: boolean) {
  const src = state.src.substring(state.pos, state.posMax);
  const result = customLinkRenderer.parse(src, customLinkRenderer.re);
  if (result.failedMatch !== undefined) {
    return false;
  }
  if (!silent) {
    const matchPos = src.indexOf(result.matchText);
    if (matchPos > 0) {
      const max = state.posMax;
      state.posMax = state.pos + matchPos;
      state.md.inline.tokenize(state); // Handle stuff before link using normal rules
      state.posMax = max;
    }
    const open_token = state.push('custom_link' as CustomLinkTokenType, '', 0);
    open_token.meta = result;
  }
  state.pos += result.matchText.length;
  return true;
}
export function tokenizeInlineContainer(state: StateInline, silent: boolean) {
  const src = state.src.substring(state.pos, state.posMax);
  const attrs = getInlineContainerAttributes(src);
  if (!attrs) {
    return false;
  }
  if (!silent) {
    const matchPos =
      attrs.fullMatch === undefined ? 0 : src.indexOf(attrs.fullMatch);
    if (matchPos > 0) {
      const max = state.posMax;
      state.posMax = state.pos + matchPos;
      state.md.inline.tokenize(state); // Handle stuff before match using normal rules
      state.posMax = max;
    }
    const token = state.push('gnist_inline_container', '', 0);
    token.content = attrs.fullMatch ?? '';
    token.info = attrs.mode;
  }
  state.pos += attrs.fullMatch?.length ?? 0;
  return true;
}

export function WhitelistHtmlTags(md: MarkdownIt) {
  // Important insert rule *after* markdownItBr (which must be added earlier as well), to avoid duplicate results from tokenize
  md.inline.ruler.after('emphasis', 'gnist_html', tokenizeWhitelistedHtml);
  // If the rule above added one of these tokens, render the token content raw
  md.renderer.rules['gnist_html_open'] = (tokens, idx) => tokens[idx].content;
  md.renderer.rules['gnist_html_close'] = (tokens, idx) => tokens[idx].content;
}
export function InlineContainers(md: MarkdownIt) {
  md.inline.ruler.after(
    'emphasis',
    'gnist_inline_container',
    tokenizeInlineContainer,
  );
  md.renderer.rules['gnist_inline_container'] = (tokens, idx) => {
    const rendered = renderContainer(
      tokens[idx].content,
      tokens[idx].info as MdItContainerContainerType,
      'inline',
    );
    return typeof rendered === 'string' ? rendered : rendered.outerHTML;
  };
}

export function CustomLink(md: MarkdownIt) {
  md.inline.ruler.after('emphasis', 'custom_link', tokenizeCustomLink);
  md.renderer.rules['custom_link'] = (tokens, idx, _, env) =>
    customLinkRenderer.render(tokens[idx].meta as LinkAttributes, env).container
      .outerHTML;
}

export function CustomImageRenderer(
  token: Token,
  env: mdEnvironment,
): string | null {
  const content = `![${token.content}](${token.attrs?.[token.attrIndex('src')][1] ?? ''})`;
  const attrs = customImageRenderer.parse(content, customImageRenderer.re);
  if (attrs.failedMatch !== undefined) {
    return token.content;
  }
  return customImageRenderer.render(attrs, env).container.outerHTML;
}
function handleMdItContainer(tokens: Token[], idx: number): string {
  const token = tokens[idx];
  const type: 'open' | 'close' | null = token.type.endsWith('_open')
    ? 'open'
    : token.type.endsWith('_close')
      ? 'close'
      : null;
  if (type === 'close') return ''; // We only support markdown-it-containers in scenarios where the whole "container" is on the same line, thus there should never be anything rendered for the closing token (the closing tag should be rendered with the open token)
  const name = token.type.split('_')[1]; // markdown-it-container will always name tokens on the form container_<name>_<open/close>
  const rendered = renderContainer(
    token.info,
    name as MdItContainerContainerType,
    type,
  );
  return typeof rendered === 'string' ? rendered : rendered.outerHTML;
}

export function renderContainer(
  info: string,
  name: MdItContainerContainerType,
  type: 'open' | 'inline' | null,
): HTMLElement | string {
  name = name.toLowerCase() as MdItContainerContainerType;
  const renderer = containerRenderers[name] ?? {};
  if (!renderer) return info; // Unsupported container
  const { openHandler } = renderer;
  const attrs =
    type === 'inline'
      ? getInlineContainerAttributes(info)
      : getOnelineContainerAttributes(':::' + info);
  if (attrs === null) {
    throw new Error('Failed to parse container data');
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return openHandler(attrs[name as keyof typeof attrs] as any); // This typing is too much for typescript to handle - but it works out in the end!
}

export type RenderLinkBasedResult<M extends LinkbasedExtensionType> =
  ReturnType<linkbasedRender<MdExtensionAttributeType<M>>['render']> & {
    attrs?: MdExtensionAttributes<M>;
    clickTarget?: HTMLElement;
  };
export function renderLinkBasedItem<
  M extends LinkbasedExtensionType = LinkbasedExtensionType,
>(
  attrs: MdExtensionAttributes<M>,
  mode: M,
  env?: mdEnvironment,
  prependNode?: HTMLElement,
): RenderLinkBasedResult<M> {
  const renderer = linkbasedRenderers[mode] as linkbasedRender<
    MdExtensionAttributeType<M>
  >;
  return {
    ...renderer.render(
      attrs[mode] as MdExtensionAttributeType<M>,
      env,
      prependNode,
    ),
    attrs,
    clickTarget: prependNode,
  };
}

export function AddGnistMarkdownContainers(md: MarkdownIt) {
  for (const containerKey in containerRenderers) {
    const name =
      containerKey.substring(0, 1).toUpperCase() + containerKey.substring(1);
    md.use(MarkdownItContainer, name, {
      render: function (tokens: Token[], idx: number) {
        return handleMdItContainer(tokens, idx);
      },
      /** @returns false if this should not be considered a container, true otherwise (see https://github.com/markdown-it/markdown-it-container/blob/master/index.mjs#L53) */
      validate: function (
        /** The remainder of the current line, after the markup ("initial colons") */
        params: string,
      ) {
        // return true;
        if (params.match(/:::/)) {
          return false; // "inline container", should not be handled by MdItContainers
        } else {
          return params.trim().split(' ', 2)[0] === name; // default validation from https://github.com/markdown-it/markdown-it-container/blob/master/index.mjs#L7
        }
      },
    });
  }
}

function getRe(
  render_id: MdExtensionType,
  marker: string,
  type: 'text' | 'number' | 'complexText' = 'text',
  groupName = marker,
): string {
  groupName = `${render_id}_${groupName}`;
  return getGroupRe(marker, type, groupName);
}

type ReId = `${MdExtensionType}_${string}`;

type containerRender<T = unknown> = {
  openHandler: (data: T) => HTMLElement;
  /** Used for converting actual attribute values to the text representation we store in markdown */
  getNewAttributes?: (attrs: T) => string[];
  /** Use this to define what regexes should be used to read attributes from markdown */
  reList?: string[];
  /** Use this to parse the regex groups read from the markdown and return the actual attribute values */
  parseRegexMatch?: (groups: Record<ReId, string> | undefined) => T;
};

type inlineContainerRender = containerRender<InlineContainerAttributes>;

const iconRenderer: inlineContainerRender = {
  openHandler: ({ data }) => {
    const span = document.createElement('span');
    span.className = 'material-symbols-rounded';
    span.ariaHidden = 'true';
    span.role = 'img';
    span.innerText = data?.replace(/\\_/g, '_');
    return span;
  },
  getNewAttributes: (attrs) => [attrs.data],
};

export type YoutubeAttributes = {
  src: string;
  controls: boolean;
  width: number;
  height: number;
  embedCode?: string;
};
export function getYoutubeEmbedCode({
  src,
  controls,
  height,
  width,
}: YoutubeAttributes) {
  const iframe = document.createElement('iframe');
  iframe.width = `${width}`;
  iframe.height = `${height}`;
  iframe.src = `${src}${controls ? '' : ';controls=0'}`;
  iframe.title = 'YouTube video player';
  iframe.frameBorder = '0';
  iframe.allowFullscreen = true;
  iframe.allow =
    'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share';
  iframe.style.maxWidth = '100%';
  return iframe;
}
export function interpretYoutubeEmbedCode(code: string): YoutubeAttributes {
  const [src, controls] = (
    code.match(/(?:src=")([^"]*)(?:")/)?.[1] ?? ''
  ).split(';');
  const width = parseInt(code.match(/(?:width=")([^"]*)(?:")/)?.[1] ?? '');
  const height = parseInt(code.match(/(?:height=")([^"]*)(?:")/)?.[1] ?? '');
  return {
    src: src,
    controls: controls?.toLowerCase() !== 'controls=0',
    width: isNaN(width) ? 560 : width,
    height: isNaN(height) ? 315 : height,
    embedCode: code,
  } satisfies YoutubeAttributes;
}
const youtubeRenderer: containerRender<YoutubeAttributes> = {
  openHandler: (attrs) => getYoutubeEmbedCode(attrs),
  getNewAttributes: (attrs) => [
    `src=${encodeURIComponent(attrs.src)}`,
    `controls=${attrs.controls}`,
    `width=${attrs.width}`,
    `height=${attrs.height}`,
  ],
  reList: [
    getRe('youtube', 'src'),
    getRe('youtube', 'controls'),
    getRe('youtube', 'width', 'number'),
    getRe('youtube', 'height', 'number'),
  ],
  parseRegexMatch: (groups) => {
    const attrs: YoutubeAttributes = {
      src: decodeURIComponent(groups?.['youtube_src'] ?? ''),
      controls: groups?.['youtube_controls'] !== 'false',
      height: groups?.['youtube_height']
        ? parseInt(groups?.['youtube_height'])
        : 315,
      width: groups?.['youtube_width']
        ? parseInt(groups?.['youtube_width'])
        : 560,
    };
    attrs.embedCode = getYoutubeEmbedCode(attrs).outerHTML;
    return attrs;
  },
};

type FailedType = {
  failedMatch: RegExpMatchArray | null;
  re: RegExp;
};
export type linkbasedRender<T, R extends HTMLElement = HTMLElement> = {
  parse: (
    text: string,
    re: RegExp,
  ) =>
    | (T & { matchText: string } & {
        [key in keyof FailedType]: undefined;
      })
    | (Partial<T> & FailedType);
  render: (
    attrs: T,
    env?: { url: string },
    settingsBox?: HTMLElement,
  ) => { rendered: R; container: HTMLElement };
  re: RegExp;
  /** Used for converting actual attribute values to the text representation we store in markdown */
  getNewAttributes?: (attrs: T) => string[];
};

export const linkStyles = [
  'default',
  'DefaultButton',
  'PrimaryButton',
  'DangerButton',
  'SpecialButton',
] as const;
type LinkStyle = (typeof linkStyles)[number];
export type LinkAttributes = {
  label: string;
  link: string;
  style: LinkStyle;
  justify: JustifyOption;
  largeButton?: boolean;
};
const noBangBefore = '(?<!!)';
const notEmpty = '(?=[^\\]])';
const lineDoesNotStartWithTable = '(?<!\\|.*)';
export const customLinkRenderer: linkbasedRender<LinkAttributes> = {
  re: new RegExp(
    `${lineDoesNotStartWithTable}${noBangBefore}\\[${notEmpty}(?<data>(` +
      getGroupRe('alt', 'complexText') +
      '|((?<title>[^\\]]*?)\\s?))' +
      getGroupRe('style') +
      getGroupRe('justify') +
      '(?<large>large)?' +
      ')\\]\\((?<link>[^)]*?)\\)',
  ),
  parse(text, re) {
    const match = text.match(re);
    if (!match?.groups) {
      return { failedMatch: match, re };
    }
    const { alt, title, link, style, justify, large } = match.groups;
    return {
      label: alt ?? title,
      link,
      style: linkStyles.includes(style as LinkStyle)
        ? (style as LinkStyle)
        : 'default',
      justify: justifyOptions.includes(justify as JustifyOption)
        ? (justify as JustifyOption)
        : 'none',
      largeButton: !!large,
      matchText: match[0],
      failedMatch: undefined,
      re: undefined,
    };
  },
  render(data, env) {
    const linkBox = document.createElement('a');
    linkBox.href = env ? calculateUrl(data.link, env) : data.link;
    // If we are embedded inside another page, open links in a new tab to avoid issues
    if (window !== window.top) {
      linkBox.target = '_blank';
    }
    linkBox.textContent = data.label;
    if (data.style === 'DefaultButton') {
      linkBox.className = 'gnist-button';
    } else if (data.style === 'PrimaryButton') {
      linkBox.className = 'gnist-button gnist-button-primary';
    } else if (data.style === 'DangerButton') {
      linkBox.className = 'gnist-button gnist-button-danger';
    } else if (data.style === 'SpecialButton') {
      linkBox.className = 'gnist-button gnist-button-special';
    } else if (data.style !== 'default' && data.style !== undefined) {
      linkBox.className = data.style;
    }
    linkBox.className += ' border-none';
    if (data.largeButton) {
      linkBox.className += ' gnist-button-large';
    }
    if (data.justify !== 'none' || data.largeButton) {
      const container = document.createElement('div');
      container.className = `flex ${data.justify}`;
      container.appendChild(linkBox);
      return { rendered: linkBox, container };
    }
    return { rendered: linkBox, container: linkBox };
  },
  getNewAttributes: (attrs) => {
    const attributeList: string[] = [];
    attributeList.push(`alt="${attrs.label}"`);
    if (attrs.style && attrs.style !== 'default') {
      attributeList.push(`style=${attrs.style}`);
    }
    if (attrs.justify && attrs.justify !== 'none') {
      attributeList.push(`justify=${attrs.justify}`);
    }
    if (attrs.largeButton) {
      attributeList.push('large');
    }
    if (attributeList.length === 1) {
      return [attrs.label];
    } else {
      return attributeList;
    }
  },
};

export const floatOptions = ['left', 'right', 'none'] as const;
export type FloatOption = (typeof floatOptions)[number];
export const justifyOptions = [
  'none',
  'justify-start',
  'justify-center',
  'justify-end',
] as const;
type JustifyOption = (typeof justifyOptions)[number];
export function justifyToAlign(justify: JustifyOption): FloatOption {
  if (justify === 'justify-start') {
    return 'left';
  } else if (justify === 'justify-end') {
    return 'right';
  } else {
    return 'none';
  }
}

export type ImageAttributes = {
  maxWidth?: number;
  align: FloatOption;
  alt: string;
  link: string;
  decorative?: boolean;
};
export const customImageRenderer: linkbasedRender<ImageAttributes> = {
  re: new RegExp(
    `${lineDoesNotStartWithTable}!\\[${notEmpty}((` +
      getGroupRe('alt', 'complexText') +
      '|((?<title>[^\\]]*?)\\s?))' +
      getGroupRe('maxwidth', 'number') +
      getGroupRe('align') +
      '(?<decorative>decorative)?' +
      ')\\]\\((?<link>[^)]*?)\\)',
  ),
  parse: (text, re) => {
    const match = text.match(re);
    if (!match?.groups) {
      return { failedMatch: match, re };
    }
    const { alt, title, link, maxwidth, align, decorative } = match.groups;
    return {
      maxWidth: maxwidth ? parseInt(maxwidth) : undefined,
      align: floatOptions.includes(align as FloatOption)
        ? (align as FloatOption)
        : 'none',
      alt: alt ?? title,
      link,
      matchText: match[0],
      failedMatch: undefined,
      re: undefined,
      decorative: !!decorative,
    };
  },
  render: (data, env, settingsBox) => {
    if (!env) {
      throw new Error('missing mdEnv!');
    }
    const container = document.createElement('div');
    if (settingsBox) {
      container.appendChild(settingsBox);
    }
    const img = document.createElement('img');
    img.src = calculateUrl(data.link, env);
    img.alt = data.decorative ? '' : data.alt;
    img.contentEditable = 'false';
    img.className = 'WidgetImage';
    if (data.maxWidth) {
      container.style.maxWidth = `${data.maxWidth}%`;
      container.style.minWidth = '7rem';
    }
    if (data.align) {
      container.style.float = data.align;
    }
    if (data.decorative) {
      img.role = 'presentation';
    }

    container.appendChild(img);
    return { rendered: img, container };
  },
  getNewAttributes: (attrs) => [
    `alt="${attrs.alt}"`,
    attrs.maxWidth ? `maxwidth=${attrs.maxWidth}` : '',
    attrs.align && attrs.align !== 'none' ? `align=${attrs.align}` : '',
    attrs.decorative ? 'decorative' : '',
  ],
};

export type DoclistVueAttributes = {
  categoryIds?: number[];
  doclistRoute: string;
  includeBacklog: boolean;
  style?: string;
  showOrgFilter: boolean;
  showSearch: boolean;
  showCategoryFilter: boolean;
  showTagFilter: boolean;
  maxItems?: number;
  cardTemplateId?: cardTemplateIds;
  tags: PartialSome<Tag, 'text'>[];
  notTags?: PartialSome<Tag, 'text'>[];
  searchText?: string;
  orgIds?: Guid[];
};

export type SearchbarVueAttributes = {
  doclistRoute: string;
  style?: string;
  searchText?: string;
};

export type InlineContainerAttributes = { data: string };
const inlineRenderers = {
  icon: iconRenderer,
} as const satisfies Record<string, inlineContainerRender>;
export const linkbasedRenderers = {
  image: customImageRenderer,
  link: customLinkRenderer,
} as const;
const onelineRenderers = {
  youtube: youtubeRenderer,
} as const;
const containerRenderers = {
  ...inlineRenderers,
  ...onelineRenderers,
} as const;
export const renderers = {
  ...inlineRenderers,
  ...linkbasedRenderers,
  ...onelineRenderers,
} as const;

export type InlineContainerType = keyof typeof inlineRenderers;
export type OnelineContainerType = keyof typeof onelineRenderers;
type MdItContainerContainerType = keyof typeof containerRenderers;
export type LinkbasedExtensionType = keyof typeof linkbasedRenderers;
export type MdExtensionType = keyof typeof renderers;
export const LinkbasedExtensionTypes = Object.keys(
  linkbasedRenderers,
) as LinkbasedExtensionType[];
export const InlineContainerTypes = Object.keys(
  inlineRenderers,
) as InlineContainerType[];
export const OnelineContainerTypes = Object.keys(
  onelineRenderers,
) as OnelineContainerType[];

export type MdExtensionAttributeType<M extends MdExtensionType> = Record<
  string,
  unknown
> &
  (M extends keyof typeof inlineRenderers
    ? InlineContainerAttributes
    : M extends 'image'
      ? ImageAttributes
      : M extends 'link'
        ? LinkAttributes
        : M extends 'youtube'
          ? YoutubeAttributes
          : unknown);
export function isAttrsType<
  M extends MdExtensionType,
  A extends MdExtensionAttributeType<M> = MdExtensionAttributeType<M>,
>(attrs: unknown, mode: M): attrs is MdExtensionAttributes<M, A> {
  return (attrs as MdExtensionAttributesPartial).mode == mode;
}
export function isLinkbasedsAttrsType(
  attrs: unknown,
): attrs is MdExtensionAttributes<
  LinkbasedExtensionType,
  MdExtensionAttributeType<LinkbasedExtensionType>
> {
  return LinkbasedExtensionTypes.includes(
    (attrs as MdExtensionAttributesPartial).mode as LinkbasedExtensionType,
  );
}
