import { getMenuItems } from '@/api/menu';
import type {
  Guid,
  RouteItem,
  RouteItemDTO,
  RouteItemWithChildren,
  headerType,
} from '@/api/types';
import { DEFAULT_LOCALE } from '@/config';
import type { ComposerTranslation } from 'vue-i18n';
import type {
  LocationQueryRaw,
  RouteComponent,
  RouteLocationRaw,
  RouteMeta,
  RouteRecordName,
  RouteRecordRaw,
} from 'vue-router';

const docsRouteIdentifier = 'DocumentRoute';
const docsListRouteIdentifier = 'DocumentListRoute';

export const defaultBlocksRouteName = 'blocks';
const defaultBlocksSearchPageId = '00000000-0000-0000-0000-111111111111';
const blocksRouteData = {
  routeType: 'page',
  targetPath: '/blocks',
  routeBaseName: defaultBlocksRouteName,
  text: 'header.items.catalog',
  meta: { targetDoc: defaultBlocksSearchPageId },
  isDocsListRoute: true,
} as const satisfies routeData & { routeBaseName: string };
export function getEmptyDocRoute(
  t: ComposerTranslation,
): Omit<RouteItemDTO, 'id'> & Pick<RouteItem, 'routeType'> {
  return {
    ...blocksRouteData,
    text: { [DEFAULT_LOCALE]: t(blocksRouteData.text) },
    routeName: defaultBlocksRouteName,
  };
}
export function getBlocksRouteLink(
  blockId: Guid | undefined,
  query?: LocationQueryRaw,
  hash?: string,
): RouteLocationRaw {
  return blockId
    ? {
        name: getDocumentRouteFromListRoute(blocksRouteData.routeBaseName),
        params: { blockId },
        query,
        hash,
      }
    : {
        name: getDocumentRouteName(blocksRouteData.routeBaseName, true),
        query,
        hash,
      };
}

type routeData = Pick<
  RouteItem,
  | 'routeType'
  | 'aliases'
  | 'targetPath'
  | 'meta'
  | 'text'
  | 'routeBaseName'
  | 'isDocsListRoute'
>;

export async function getRouteItems(): Promise<routeData[]> {
  const { menuItems, nonMenuItems } = await getMenuItems();
  return [
    ...flattenMenu(menuItems),
    blocksRouteData,
    ...flattenMenu(nonMenuItems),
  ];
}
function flattenMenu(items: RouteItem[]): routeData[] {
  const menuHeaderTypes: (headerType | undefined)[] = ['menu', 'menuOnly'];
  const result: routeData[] = items.filter(
    (i) => !menuHeaderTypes.includes(i.headerType),
  );
  for (const item of items) {
    if (isRouteItemWithChildren(item)) result.push(...flattenMenu(item.items));
  }
  return result;
}

export const isRouteItemWithChildren = (
  item: RouteItem,
): item is RouteItemWithChildren => {
  return 'items' in item && (item as RouteItemWithChildren).items !== undefined;
};
export function isBlockDocumentRoute(
  routeName: string | RouteRecordName | null | undefined,
): boolean {
  const rn = routeName?.toString() ?? '';
  return (
    rn.endsWith(docsRouteIdentifier) || rn.endsWith(docsListRouteIdentifier)
  );
}
export function getDocumentRouteFromListRoute(listRouteName?: string): string {
  if (!listRouteName) {
    listRouteName = blocksRouteData.routeBaseName + docsListRouteIdentifier;
  }
  return getDocumentRouteName(listRouteName, false);
}

export function removeRouteIdentifier(routeName: string) {
  return routeName
    .replace(docsListRouteIdentifier, '')
    .replace(docsRouteIdentifier, '');
}
export function getDocumentRouteName(
  baseRouteName: string | undefined,
  isListRoute: boolean,
): string {
  if (!baseRouteName) {
    throw new Error('baseRouteName should not be undefined');
  }
  return (
    removeRouteIdentifier(baseRouteName) +
    (isListRoute ? docsListRouteIdentifier : docsRouteIdentifier)
  );
}
export function getRouteTitle(menuText: string | string[]): string {
  if (Array.isArray(menuText)) menuText = menuText.join(' | ');
  return `${menuText} | Gnist`;
}
export function getRoute(
  s: routeData,
  component: RouteComponent,
  options: {
    addAliases?: boolean;
    isListRoute?: boolean;
    pathSuffix?: string;
    meta?: Partial<RouteMeta>;
  },
): RouteRecordRaw {
  const route: RouteRecordRaw = {
    path: `${s.targetPath}${options.pathSuffix ?? ''}`,
    meta: { ...s.meta, ...options.meta, title: `${s.text}` },
    name: getDocumentRouteName(s.routeBaseName, !!options.isListRoute),
    component,
  };
  if (options.addAliases && s.aliases) {
    route.alias = s.aliases?.map((i) => (i.startsWith('/') ? i : '/' + i));
  }
  return route;
}
