<script setup lang="ts">
import { useStoreAbility } from '@/abilities';
import { deleteNewsArticle } from '@/api/news';
import type { News } from '@/api/types';
import { computed, ref } from 'vue';
import MaterialIcon from './MaterialIcon.vue';
import ModalComponent from './ModalComponent.vue';
import MarkdownRenderer from '@/components/block/MarkdownRenderer.vue';
import { useI18n } from 'vue-i18n';
import { translateStringOrLocale } from '@/i18n';
import { useImageClick } from '@/utilities/useImageClick';

const { t } = useI18n();

const { can } = useStoreAbility();

const props = defineProps<{
  newsItem: News;
  right?: boolean;
}>();

const emits = defineEmits<{
  (e: 'edit'): void;
  (e: 'update'): void;
}>();

const showModal = ref(false);
const showImageModal = ref(false);
const imageModalSource = ref<string | null>(null);

const handleClick = async () => {
  if (props.newsItem.newsArticleId) {
    await deleteNewsArticle(props.newsItem.newsArticleId);
    emits('update');
  }
};
const _title = translateStringOrLocale(props.newsItem.title);
const _content = translateStringOrLocale(props.newsItem.content);
const articleItemId = computed(() => `article-${props.newsItem.newsArticleId}`);
useImageClick(articleItemId, (imageElement) => {
  imageModalSource.value = imageElement.src;
  showImageModal.value = true;
});
</script>

<template>
  <article
    :id="articleItemId"
    class="rounded-sm border border-gnist-gray-light bg-gnist-gray-light-light p-2 text-gnist-black sm:p-6"
    :class="{ 'min-h-[20rem]': newsItem.imageSource }"
  >
    <div v-if="can('create', 'News')" class="flex justify-end">
      <div
        :title="t('news.edit')"
        :class="[
          'flex h-10 w-10 items-center justify-center rounded-full text-4xl hover:cursor-pointer hover:bg-gnist-gray-light',
        ]"
      >
        <MaterialIcon class="" @click="$emit('edit')"> edit_note </MaterialIcon>
      </div>
      <div
        :title="t('news.delete')"
        :class="[
          'flex h-10 w-10 items-center justify-center rounded-full text-4xl hover:cursor-pointer hover:bg-gnist-gray-light',
        ]"
      >
        <MaterialIcon
          class="text-4xl hover:cursor-pointer"
          @click="showModal = true"
        >
          delete
        </MaterialIcon>
      </div>
    </div>

    <h2>
      {{ _title }}
    </h2>
    <p class="text-p4 my-4">
      {{
        newsItem.datePublished
          ? new Date(newsItem.datePublished).toLocaleDateString(undefined, {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
            })
          : new Date().toLocaleDateString(undefined, {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
            })
      }}
    </p>
    <MarkdownRenderer
      :value="_content"
      blob-location="/news"
      class="text-p2 my-2"
    />

    <ModalComponent
      :show-modal="showImageModal"
      title=""
      :size="'full'"
      :show-close-icon="true"
      @close="showImageModal = false"
    >
      <template #default>
        <img
          v-if="imageModalSource"
          :src="imageModalSource"
          tabindex="0"
          @click="showImageModal = false"
        />
      </template>
      <template #buttons>&nbsp;</template>
    </ModalComponent>
    <ModalComponent
      :show-modal="showModal"
      :title="t('news.deleteArticle')"
      @close="showModal = false"
      @handle-click="handleClick"
    >
      <template #default>
        <p>{{ t('news.deleteArticleConfirmation') }}</p>
      </template>
    </ModalComponent>
  </article>
</template>

<style scoped>
:deep(img) {
  /* Add z-index to ensure click works when floating images */
  z-index: 1;
}
</style>
