<script setup lang="ts">
import { getApiMAnalytics, useApim } from '@/api/apiM';
import { ref } from 'vue';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import {
  getTotalApiCalls,
  getCallsByTimestamp,
  getCallsByOperation,
  getSubscriptionCounts,
} from '../utilities/analytics';
import { Bar, Line } from 'vue-chartjs';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Colors,
} from 'chart.js';
import ModalComponent from '@/components/ModalComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import { useI18n } from 'vue-i18n';
import TableComponent from '@/components/TableComponent.vue';

const { t } = useI18n();

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Colors,
);

const getOffsetDateAsString = (offset: number) => {
  const date = new Date();
  date.setDate(date.getDate() + offset);
  return date.toISOString().split('T')[0];
};

const setDateOffset = async (offset: number) => {
  start.value = getOffsetDateAsString(offset);
  end.value = getOffsetDateAsString(0);
  apimAnalytics.value = null;
  apimAnalytics.value = await getApiMAnalytics(start.value, end.value);
};

const startDate = new Date();
startDate.setMonth(new Date().getMonth() - 3);

const start = ref<string>(startDate.toISOString().split('T')[0]);
const end = ref<string>(new Date().toISOString().split('T')[0]);

const apimAnalytics = useApim(start.value, end.value);

const applyDateInterval = async () => {
  showDateModal.value = false;
  apimAnalytics.value = null;
  apimAnalytics.value = await getApiMAnalytics(start.value, end.value);
};

const showDateModal = ref<boolean>(false);
</script>

<template>
  <div v-if="apimAnalytics" class="max-w-[95vw] p-2">
    <div class="flex justify-end">
      <p>{{ t('analytics.filter') }}:</p>
      <p
        class="mx-2"
        :class="[
          start === getOffsetDateAsString(-7)
            ? 'font-bold'
            : 'hover:cursor-pointer',
        ]"
        @click="() => setDateOffset(-7)"
      >
        {{ t('analytics.7days') }}
      </p>
      <p
        class="mr-2"
        :class="[
          start === getOffsetDateAsString(-30)
            ? 'font-bold'
            : 'hover:cursor-pointer',
        ]"
        @click="setDateOffset(-30)"
      >
        {{ t('analytics.30days') }}
      </p>
      <!-- <p
          class="mr-2"
          :class="[start !== getOffsetDateAsString(-7) && start !== getOffsetDateAsString(-30) ? 'font-bold' : 'hover:cursor-pointer']"
          @click="() => showDateModal = true"
        >
          {{ t('analytics.customInterval') }}
        </p> -->
      <ModalComponent
        :show-modal="showDateModal"
        :title="''"
        @close="showDateModal = false"
      >
        <template #default>
          <div>
            {{ t('analytics.from') }}
            <input v-model="start" type="date" class="border" />
          </div>
          <div>
            {{ t('analytics.to') }}
            <input v-model="end" type="date" class="border" />
          </div>
        </template>
        <template #buttons>
          <div class="gnist-button-group">
            <ButtonComponent
              :text="t('admin.blockProduction.cancel')"
              @click="showDateModal = false"
            />
            <ButtonComponent
              :text="t('analytics.search')"
              type="primary"
              @click="applyDateInterval"
            />
          </div>
        </template>
      </ModalComponent>
    </div>
    <div
      class="mb-4 flex w-full auto-rows-fr grid-cols-2 flex-col gap-2 lg:grid"
    >
      <div class="min-h-80">
        <Bar
          :options="{ maintainAspectRatio: false }"
          class="h-full w-full border bg-white p-2"
          :data="getTotalApiCalls(apimAnalytics, t('analytics.apiCallsTotal'))"
        />
      </div>
      <div class="min-h-80">
        <Line
          :options="{ maintainAspectRatio: false }"
          :data="
            getCallsByTimestamp(apimAnalytics, t('analytics.callsByTimestamp'))
          "
          class="h-full w-full border bg-white p-2"
        />
      </div>
      <div class="min-h-80">
        <Bar
          :options="{ maintainAspectRatio: false }"
          :data="
            getSubscriptionCounts(
              apimAnalytics,
              t('analytics.numSubscriptions'),
            )
          "
          class="h-full w-full border bg-white p-2"
        />
      </div>
    </div>
    <TableComponent
      :columns="['apiId', 'apiOperation', 'callCountSuccess', 'callCountTotal']"
      sort-fields="apiId"
      group-column-header
      column-header-class="font-normal break-words"
      i18n-key="analytics"
      :rows="apimAnalytics.apiMReportByOperation"
      :get-key="
        (reportByOperation) =>
          reportByOperation.apiId + reportByOperation.apiOperation
      "
      class="my-8"
    >
      <template #columnHeader="{ item: reportByOperation }">
        {{ reportByOperation.apiId }}
      </template>
      <template #columns="{ item: reportByOperation }">
        <td>
          {{
            reportByOperation.apiOperation
              .replace(reportByOperation.apiId, '')
              .replace('/operations', '')
          }}
        </td>
        <td>{{ reportByOperation.callCountSuccess }}</td>
        <td>{{ reportByOperation.callCountTotal }}</td>
      </template>
    </TableComponent>
    <div
      class="mb-4 flex w-full auto-rows-fr grid-cols-2 flex-col gap-2 lg:grid"
    >
      <div
        v-for="data in getCallsByOperation(
          apimAnalytics,
          t('analytics.numCallsForOperationsIn'),
        ).filter((data) => data.hasNonZeroEntries)"
        :key="data.key"
        class="min-h-80"
      >
        <Bar
          :options="{ maintainAspectRatio: false }"
          :data="data.data"
          class="h-full w-full border bg-white p-2"
        />
      </div>
    </div>
  </div>
  <div v-else>
    <div class="flex w-full flex-col items-center">
      <LoadingSpinner class="h-16 w-16" />
    </div>
  </div>
</template>
