<script lang="ts" setup>
import { getNews, useNews } from '@/api/news';
import NewsArticle from '@/components/NewsArticle.vue';
import EditNewsCard from '@/components/EditNewsCard.vue';
import ContentPage from '../components/ContentPage.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import { ref } from 'vue';
import { useStoreAbility } from '@/abilities';
import newsLogo from '@/assets/images/news.svg';
import { useI18n } from 'vue-i18n';
import { useScrollToIdWhenPresent } from '@/utilities/useScrollToIdWhenPresent';
import { useRoute } from 'vue-router';

const { t } = useI18n();

const { can } = useStoreAbility();
const news = useNews();
const newArticle = ref(false);

const route = useRoute();
useScrollToIdWhenPresent('article-' + route.params.articleId);
</script>
<template>
  <ContentPage :title="t('news.title')" class="bg-gnist-white">
    <template #banner>
      <div class="mb-4 hidden min-h-[6rem] flex-row md:flex">
        <p class="text-p1 block pb-4 pt-1">
          {{ t('news.description') }}
        </p>
        <div class="my-[-4rem] ml-16 mr-6 px-[53px] flex items-start">
          <img
            alt=""
            :src="newsLogo"
            width="150"
            class="w-[150px] min-w-[150px]"
          />
        </div>
      </div>
    </template>
    <template #default>
      <div class="mb-12 mt-4 flex flex-col gap-12 md:mt-0 lg:px-8">
        <div v-if="can('create', 'News')" class="my-4 flex w-full lg:px-2">
          <ButtonComponent
            :text="t('news.publish')"
            class="ml-auto mr-8"
            @click="newArticle = true"
          />
        </div>
        <EditNewsCard
          v-if="newArticle"
          @edit="newArticle = false"
          @update="() => getNews().then((n) => (news = n))"
        />
        <NewsArticle
          v-for="n in news"
          :key="n.newsArticleId"
          :news-item="n"
          :right="true"
          @update="() => getNews().then((n) => (news = n))"
        />
      </div>
    </template>
  </ContentPage>
</template>

<style></style>
