<script setup lang="ts">
import { md } from '@/utilities/markdown/MarkdownIt';
import MaterialIcon from './MaterialIcon.vue';
import { useCssReorder } from '@/utilities/useCssReorder';

useCssReorder('stackoverflow-light');

const props = defineProps<{
  content: object;
}>();

function renderSchema(text: string): string {
  const start = '``` json\n';
  const end = '\n```';

  text = start.concat(text);
  text = text.concat(end);

  return md.render(text);
}

const copyToClipboard = () => {
  navigator.clipboard.writeText(JSON.stringify(props.content, null, 2) || '');
};
</script>
<template>
  <div class="flex justify-between bg-gnist-gray-light p-2">
    <span>JSON</span>
    <MaterialIcon
      class="hover:cursor-pointer"
      aria-hidden="true"
      @click="copyToClipboard"
    >
      content_copy
    </MaterialIcon>
  </div>
  <!-- eslint-disable vue/no-v-html -->
  <div
    class="max-h-96 overflow-y-auto bg-gray-100 p-4 [&_code.hljs]:bg-[inherit]"
    v-html="renderSchema(JSON.stringify(content, null, 2))"
  />
</template>
