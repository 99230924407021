<script setup lang="ts">
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue';
import { removeFavorite, updateFavorite, useFavorites } from '@/api/favorite';
import BlockNote from '@/components/BlockNote.vue';
import MaterialIcon from '@/components/MaterialIcon.vue';
import { throwOnError } from '@/api/composable';
import { getBlocksRouteLink } from '@/utilities/routeUtils';
import { useI18n } from 'vue-i18n';
import type { Favorite } from '@/api/types';
import { translateStringOrLocale } from '@/i18n';

const { t } = useI18n();

const { result: favorites } = throwOnError(useFavorites());

const updateNote = (favorite: Favorite, note: string) => {
  favorite.notes = note;
  updateFavorite(favorite.blockId, note, favorite.favoriteId);
};

const unFavorite = (favoriteId: number) => {
  removeFavorite(favoriteId).then(() => {
    favorites.value =
      favorites.value?.filter((f) => f?.favoriteId !== favoriteId) ?? null;
  });
};
</script>

<template>
  <div class="my-10 w-full">
    <h2 class="p-8">
      {{ t('profile.favorite') }}
    </h2>
    <div
      v-for="favorite in favorites"
      :key="favorite.blockId"
      class="border bg-gnist-white"
    >
      <Disclosure v-if="favorite" v-slot="{ open }">
        <div class="m-2 flex flex-row items-center p-2 sm:mx-4 sm:px-4">
          <RouterLink
            class="flex grow basis-3/12 flex-row items-center"
            :to="getBlocksRouteLink(favorite.blockId)"
          >
            <p class="text-a1">
              {{ translateStringOrLocale(favorite.blockName).value }}
            </p>
          </RouterLink>
          <DisclosureButton
            class="basis-1/16"
            :title="t('profile.editFavoriteNote')"
            :class="[
              'flex h-10 w-10 items-center justify-center rounded-full',
              { 'bg-gnist-gray-light-light': open },
            ]"
          >
            <MaterialIcon class="text-4xl" aria-hidden="true">
              edit_note
            </MaterialIcon>
          </DisclosureButton>
          <button
            class="basis-1/16 ml-2"
            :title="t('profile.removeFavorite')"
            @click="() => unFavorite(favorite.favoriteId)"
          >
            <MaterialIcon
              class="text-4xl hover:cursor-pointer"
              aria-hidden="true"
            >
              star
            </MaterialIcon>
          </button>
        </div>
        <div
          v-if="!open && favorite.notes"
          class="m-2 p-2 italic text-gnist-gray sm:mx-4 sm:px-4"
        >
          {{ favorite.notes }}
        </div>
        <DisclosurePanel>
          <BlockNote
            :notes="favorite.notes"
            @add-note="(note: string) => updateNote(favorite, note)"
          />
        </DisclosurePanel>
      </Disclosure>
    </div>
  </div>
</template>
