<script setup lang="ts">
import { ref } from 'vue';
import { useRoute } from 'vue-router';
import { useBlock } from '@/api/blocks';
import {
  Status,
  type Block,
  type Guid,
  EMPTY_GUID,
  getEmptyLocaleValue,
} from '@/api/types';
import DetailsEditor from '@/components/admin/DetailsEditor.vue';
import { throwOnError } from '@/api/composable';
import { useI18n } from 'vue-i18n';
import { editBlocksRouteId, editBlockVersionsRouteId } from '@/config';

const { t } = useI18n();

const route = useRoute();
const isExistingBlock = route.params.blockId !== undefined;
const blockId = route.params.blockId as Guid;
const { result: block } = isExistingBlock
  ? throwOnError(useBlock(blockId, true))
  : {
      result: ref<Block | null>({
        blockId: EMPTY_GUID,
        currentVersionNumber: undefined,
        currentVersionId: -1,
        category: undefined,
        content: {},
        status: Status.Draft,
        description: getEmptyLocaleValue(),
        name: getEmptyLocaleValue(),
        tags: [],
        isBacklog: true,
        hasBacklog: true,
      }),
    };
</script>

<template>
  <div class="w-full bg-gnist-gray-light-light">
    <div class="mx-2 py-8 md:mx-auto md:w-[50rem]">
      <h1 class="mb-16">
        {{
          isExistingBlock
            ? t('admin.blockProduction.editBlock')
            : t('admin.blockProduction.createNewToolTitle')
        }}
      </h1>
      <DetailsEditor
        v-if="block"
        :source="block"
        :is-new-block="!isExistingBlock"
        :cancel-route="
          isExistingBlock
            ? {
                name: editBlockVersionsRouteId,
                params: { blockId: block.blockId },
              }
            : { name: editBlocksRouteId }
        "
      />
    </div>
  </div>
</template>
