<script setup lang="ts">
import ButtonComponent from '@/components/ButtonComponent.vue';
import TextInput from '@/components/forms/InputText.vue';
import Dropdown from '@/components/forms/InputDropdown.vue';
import { useI18n } from 'vue-i18n';
import {
  type KeyOfRecursiveBelow,
  removeParentKey,
  type KeyOfRecursive,
  type TagGroup,
  colorList,
} from '@/api/types';
import { ref, watch } from 'vue';
import { SUPPORTED_LOCALES } from '@/config';
import { useNestedMV } from '@/utilities/useInternalState';
import InputBlockPicker from './forms/InputBlockPicker.vue';
import { mapValueList } from '@/utilities/mapValueList';

const props = defineProps<{
  modelValue: TagGroup;
  localeKeys: KeyOfRecursiveBelow<TagGroup, 'label'>[];
}>();
const emit = defineEmits<{
  (e: 'update:modelValue', value: typeof props.modelValue): void;
  (e: 'update:changed', changed: boolean): void;
  (e: 'remove'): void;
}>();

const value = useNestedMV(props, (val) => emit('update:modelValue', val));

function getSource(): Omit<TagGroup, 'id'> {
  return {
    label: { ...value.value.label },
    homePageId: value.value.homePageId,
    color: value.value.color,
  };
}
const source = ref<Omit<TagGroup, 'id'>>(getSource());
defineExpose({
  resetSource: () => {
    source.value = getSource();
  },
});

const { t } = useI18n();

const editing = ref<KeyOfRecursive<TagGroup>>();
const changed = ref(false);
watch(
  [value, source],
  () => {
    changed.value =
      !!SUPPORTED_LOCALES.find(
        (l) => value.value.label[l] !== source.value.label[l],
      ) ||
      value.value.homePageId != source.value.homePageId ||
      value.value.color != source.value.color;
  },
  { deep: true },
);
watch(changed, () => emit('update:changed', changed.value));

const colorOptions = mapValueList(colorList, 'categoryAdmin.colors', true);

function getLocaleKey(locale: KeyOfRecursive<TagGroup>) {
  return removeParentKey(locale, 'label');
}
</script>

<template>
  <component
    :is="index === 0 ? 'th' : 'td'"
    v-for="(localeKey, index) in localeKeys"
    :key="localeKey"
    class="bg-[inherit] pl-8 md:max-w-full"
    :class="{
      'ColumnHeader z-10': index === 0,
      'material-symbols-rounded-before': index === 0 && changed,
    }"
    @click.self="editing = undefined"
  >
    <form class="flex h-9 items-center" @click.prevent="editing = localeKey">
      <TextInput
        v-model="value.label[getLocaleKey(localeKey)]"
        :label="
          t(`language.values.${getLocaleKey(localeKey)}`) +
          t('tagEditor.tagGroupColumns.label')
        "
        sr-only
        class="w-full"
        input-class="my-0 truncate"
        :no-chrome="editing !== localeKey"
        required
        @focus="editing = localeKey"
        @blur="editing = undefined"
      />
    </form>
  </component>
  <td class="whitespace-nowrap bg-[inherit] hover:border hover:border-black">
    <InputBlockPicker
      v-model="value.homePageId"
      label="tagEditor.tagGroupColumns.homePageId"
      sr-only
      class="[&_[data-combobox]]:my-0 [&_[data-combobox]]:min-h-0"
      :no-chrome="editing !== 'homePageId'"
      @focus="editing = 'homePageId'"
      @blur="editing = undefined"
    />
  </td>
  <td class="bg-[inherit] hover:border hover:border-black">
    <Dropdown
      v-model="value.color"
      label="tagEditor.tagGroupColumns.color"
      :multiple="{ emptyLabel: '', maxItems: 1 }"
      sr-only
      class="[&_button]:my-0"
      :no-chrome="editing !== 'color'"
      :options="colorOptions"
      :getkey="(val) => val ?? 'empty'"
      @focus="editing = 'color'"
      @blur="editing = undefined"
    >
      <template #button="{ selectedItems: [selColor] }">
        <span
          class="decoration items-center whitespace-nowrap underline decoration-4 underline-offset-4"
          :class="[`decoration-gnist-${selColor}`]"
        >
          {{ selColor ? t('categoryAdmin.colors.values.' + selColor) : '' }}
        </span>
      </template>
      <template #option="{ item: color }">
        <span
          class="mx-4 my-1 flex items-center whitespace-nowrap"
          :class="[
            color.value
              ? `decoration underline decoration-4 underline-offset-4 decoration-gnist-${color.value}`
              : '',
          ]"
        >
          {{ color.text ? t(color.text) : '&nbsp;' }}
        </span>
      </template>
    </Dropdown>
  </td>
  <td class="bg-[inherit]">
    <div class="flex justify-center">
      <ButtonComponent
        text="tagEditor.deleteTagGroup"
        icon="delete"
        type="danger"
        icon-class="text-2xl leading-6"
        @click="emit('remove')"
      />
    </div>
  </td>
</template>

<style scoped>
.material-symbols-rounded-before::before {
  content: 'pending_actions';
  @apply absolute;
  @apply z-10;
  @apply text-3xl;
  @apply left-0;
  @apply text-gnist-red;
}
</style>
