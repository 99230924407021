import {
  getPropertyRecursive,
  type ArrayItem,
  type RecurChildType,
  type KeyOfRecursive,
} from '@/api/types';
import {
  computed,
  ref,
  type ComputedRef,
  type Ref,
  type UnwrapRef,
  watch,
} from 'vue';

export type Comparator<T> = (a: T, b: T) => number;
export type SortDirection = 'asc' | 'desc';

export function undefinedCompare<T>(
  comparator: Comparator<T>,
): Comparator<T | undefined> {
  return (a: T | undefined, b: T | undefined) => {
    if (
      (typeof a === 'undefined' || a === null) &&
      (typeof b === 'undefined' || b === null)
    )
      return 0;
    if (typeof a === 'undefined' || a === null) return -1;
    if (typeof b === 'undefined' || b === null) return 1;
    return comparator(a, b);
  };
}

/**
 * Implements the default compare function as used by Array.sort without parameters
 * @param a The first value to compare
 * @param b The second value to compare
 */
export function defaultCompare<T>(a: T, b: T): number {
  if (a < b) return -1;
  if (b < a) return 1;
  return 0;
}

/**
 * Compares two strings case-insensitive
 * @param a The first string value
 * @param b The second string value
 */
export const stringCompareCaseInsensitive: Comparator<string> = (
  a: string,
  b: string,
): number => {
  return defaultCompare(a?.toLowerCase() ?? '', b?.toLowerCase() ?? '');
};

/**
 * Creates a new Comparator function that compares a property of two objects using the specified Comparator function.
 * @param property The property to extract from the objects
 * @param comparator The Comparator function that should be used to compare the properties.
 */
export function propertyCompare<T, K extends KeyOfRecursive<T, true>>(
  property: K,
  comparator: Comparator<RecurChildType<T, K>>,
): Comparator<T> {
  return (a: T, b: T) =>
    comparator(
      getPropertyRecursive(a, property),
      getPropertyRecursive(b, property),
    );
}

/**
 * Orders an array, similar to sort, but does not mutate the input.
 * @param input The array to be sorted
 * @param comparator Compare function used in the same way as in sort
 * @param direction Sort ascending ('asc') or descending ('desc'). Default is 'asc'.
 */
export function order<T>(
  input: Array<T>,
  comparator: Comparator<T>,
  direction: SortDirection = 'asc',
) {
  const copy = [...input];
  copy.sort(comparator);
  if (direction === 'asc') return copy;
  else return copy.reverse();
}

export function zip<T>(
  a: Array<T>,
  b: Array<T>,
): Array<[T | undefined, T | undefined]> {
  return a.length >= b.length
    ? a.map((aVal, i) => [aVal, b[i]])
    : b.map((bVal, i) => [a[i], bVal]);
}

/**
 * Compares two Arrays, by first ordering each array, and then compare each corresponding elements of the arrays
 * @param comparator Comparator used to compare each element of the array
 */
export function arrayCompare<T>(
  comparator: Comparator<T>,
): Comparator<Array<T>> {
  return (a: Array<T>, b: Array<T>): number => {
    const aSorted = order(a, comparator, 'asc');
    const bSorted = order(b, comparator, 'asc');
    const undefComp = undefinedCompare(comparator);
    for (const [aVal, bVal] of zip(aSorted, bSorted)) {
      const elementCompare = undefComp(aVal, bVal);
      if (elementCompare !== 0) return elementCompare;
    }
    return 0;
  };
}

type Grouped<T> = T & { isGroupHeader?: boolean; groupSize?: number };
export function useSort<T, SortField extends KeyOfRecursive<T, true>>(
  values: Ref<T[] | null | undefined> | ComputedRef<T[] | null | undefined>,
  sortFields: Readonly<SortField[]> | undefined,
  defaultSortfield?: SortField,
  defaultSortDirection: SortDirection = 'asc',
  comparers?: {
    [key in SortField]?: Comparator<
      RecurChildType<T, key> extends Array<unknown> | undefined
        ? ArrayItem<RecurChildType<T, key>>
        : RecurChildType<T, key>
    >;
  },
  skipSortOnContentChange = false,
  useGroupCountOnSortField?: (currentSortField: SortField) => boolean,
): {
  sortOnField: (field: KeyOfRecursive<T, true>) => void;
  getHeaderClasses: (key: KeyOfRecursive<T, true>) => string;
  sortedValues: Ref<Grouped<T>[] | undefined>;
  isGrouped: Ref<boolean>;
} {
  const isGrouped = ref(false);
  if (sortFields === undefined || sortFields.length === 0) {
    return {
      sortOnField: () => null,
      getHeaderClasses: () => '',
      sortedValues: computed(() => (values.value as Grouped<T>[]) ?? undefined),
      isGrouped,
    };
  }
  const sortFieldRef = ref<SortField>(defaultSortfield ?? sortFields[0]);
  const sortDirection = ref<SortDirection>(defaultSortDirection);
  const headerClasses = computed(() => {
    return Object.fromEntries(
      sortFields.map((field) => [
        field,
        sortFieldRef.value === field ? sortDirection.value : '',
      ]),
    ) as { [key in SortField]: string };
  });
  function getHeaderClasses(key: KeyOfRecursive<T, true>) {
    return key in headerClasses.value
      ? 'cursor-pointer ' + headerClasses.value[key as SortField]
      : 'cursor-default';
  }

  function sortOnField(field: KeyOfRecursive<T, true>): void {
    if (!sortFields!.includes(field as SortField)) return;
    if (sortFieldRef.value === field) {
      sortDirection.value = sortDirection.value === 'asc' ? 'desc' : 'asc';
    } else {
      sortDirection.value = 'asc';
      sortFieldRef.value = field as UnwrapRef<SortField>;
    }
  }

  const sortedValues = ref<Grouped<T>[] | undefined>();
  watch(
    [sortFieldRef, sortDirection, values],
    ([newField, newDirection], [oldField, oldDirection]) => {
      const orderChanged =
        newField !== oldField || newDirection !== oldDirection;
      const countChanged = values.value?.length !== sortedValues.value?.length;
      if (!orderChanged && !countChanged && skipSortOnContentChange) {
        return;
      }
      sortedValues.value = sortValues();
    },
    { immediate: true, deep: true },
  );
  function sortValues(): Grouped<T>[] | undefined {
    if (!values.value) return;
    const sortField = sortFieldRef.value as SortField;
    const testValue = getPropertyRecursive(
      values.value?.find((val) => !!getPropertyRecursive(val, sortField)),
      sortField,
    );
    if (!testValue) return values.value as Grouped<T>[]; // If all values are falsy, we don't need to try sorting
    let sorted: Grouped<T>[];
    if (Array.isArray(testValue)) {
      const itemComparer = (comparers?.[sortField] ??
        (typeof testValue[0] === 'string'
          ? stringCompareCaseInsensitive
          : defaultCompare)) as Comparator<
        ArrayItem<RecurChildType<T, SortField>>
      >;
      sorted = order(
        values.value,
        propertyCompare(
          sortField,
          undefinedCompare(arrayCompare(itemComparer)) as Comparator<
            RecurChildType<T, SortField>
          >,
        ),
        sortDirection.value,
      ) as Grouped<T>[];
    } else {
      const itemComparer = (comparers?.[sortField] ??
        (typeof testValue === 'string'
          ? stringCompareCaseInsensitive
          : defaultCompare)) as Comparator<RecurChildType<T, SortField>>;
      sorted = order<T>(
        values.value,
        propertyCompare(sortField, itemComparer),
        sortDirection.value,
      ) as Grouped<T>[];
    }
    isGrouped.value = !!useGroupCountOnSortField?.(sortField);
    if (isGrouped.value) {
      let previousItem: Grouped<T> | undefined = undefined;
      let previousValue: RecurChildType<T, SortField> | undefined = undefined;
      let count = 0;
      for (let i = sorted.length - 1; i >= 0; i--) {
        const currentValue = getPropertyRecursive(sorted[i] as T, sortField);
        if (previousItem) {
          if (previousValue !== currentValue) {
            previousItem.isGroupHeader = true;
            previousItem.groupSize = count;
            count = 0;
          } else {
            delete previousItem.isGroupHeader;
            delete previousItem.groupSize;
          }
        }
        count++;
        previousValue = currentValue;
        previousItem = sorted[i];
      }
      if (previousItem) {
        previousItem.isGroupHeader = true;
        previousItem.groupSize = count;
      }
    }
    return sorted;
  }

  return {
    sortOnField,
    getHeaderClasses,
    sortedValues,
    isGrouped,
  };
}
