export async function sleep(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export async function sleepUntil(
  predicate: () => boolean,
  ms: number,
  retryCount: number,
): Promise<void> {
  let count = 0;
  while (!predicate() && count < retryCount) {
    await sleep(ms);
    count++;
  }
}
