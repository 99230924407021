<script setup lang="ts">
import ButtonComponent from '@/components/ButtonComponent.vue';
import { useStoreAbility, useSubject } from '@/abilities';
import { useCurrentUser } from '@/api/auth';
import { getVersion, updateVersion } from '@/api/versions';
import { Status, type Block, type Version } from '@/api/types';
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

const props = defineProps<{
  version?: Version;
  block: Block;
  asButton?: boolean;
  disabled?: boolean;
  hideButtonIfDisabled?: boolean;
}>();

const _version = ref<Version | null>(null);

watch(
  () => [props.version, props.block],
  async () => {
    if (props.version) _version.value = props.version;
    else {
      _version.value = await getVersion(
        props.block.blockId,
        props.block.currentVersionId,
      );
    }
  },
  { immediate: true },
);

const emit = defineEmits<{
  (e: 'refreshBlock', noBubble?: boolean): void;
}>();

const { t } = useI18n();
const { can } = useStoreAbility();

const changeStatus = async (version: Version | null, status: Status) => {
  if (!version) return;
  if (status === Status.AwaitingApproval) version.draftStatus = status;
  else version.status = status;
  await updateVersion(props.block.blockId, version.versionId, { status });
  emit('refreshBlock');
};

const isDraft = computed(
  () => !!_version.value?.draftStatus || _version.value?.status == Status.Draft,
);

const user = useCurrentUser();
const canPublish = computed(() => {
  const roles = user.value?.roles ?? [];
  return (
    props.block.category?.publishRoles?.some((role) => roles.includes(role)) ||
    can('publish', useSubject('Block', props.block))
  );
});

const targetOperation = computed(
  (): 'changeDraftStatus' | 'submitDraft' | 'changePublishedStatus' =>
    isDraft.value
      ? canPublish.value
        ? 'changeDraftStatus'
        : 'submitDraft'
      : 'changePublishedStatus',
);

const availableStatuses = computed(() => {
  const list: Status[] = [];
  if (
    _version.value?.draftStatus === Status.AwaitingApproval &&
    canPublish.value
  )
    return [Status.Published];
  if (targetOperation.value === 'changeDraftStatus') {
    if (_version.value?.draftStatus != Status.AwaitingApproval) {
      list.push(Status.Published);
    }
  } else if (targetOperation.value === 'submitDraft') {
    if (_version.value?.draftStatus != Status.AwaitingApproval) {
      list.push(Status.AwaitingApproval);
    }
  } else {
    list.push(Status.Draft);
    if (_version.value?.status != Status.Published) list.push(Status.Published);
    list.push(Status.Archived); // You can only archive a published version that has no draft
  }
  return list;
});

const disableButton = computed(
  () =>
    targetOperation.value === 'changePublishedStatus' ||
    (_version.value?.draftStatus === Status.AwaitingApproval &&
      !canPublish.value) ||
    props.disabled,
);
</script>
<template>
  <span v-if="!_version" class="hidden">Laster...</span>
  <ButtonComponent
    v-else-if="asButton"
    :text="
      t(
        `admin.blockProduction.${
          targetOperation === 'changePublishedStatus'
            ? 'changeDraftStatus'
            : targetOperation
        }.imperative`,
      )
    "
    type="primary"
    :disabled="disableButton"
    :class="{ hidden: disableButton && hideButtonIfDisabled }"
    data-cy-id="PublishButton"
    @click="() => changeStatus(_version, availableStatuses[0])"
  />
  <template v-else-if="availableStatuses.length > 0">
    <div
      class="font-bold text-gnist-black hover:cursor-default hover:bg-inherit"
    >
      <span>
        {{ t(`admin.blockProduction.${targetOperation}.header`) }}
      </span>
    </div>
    <ul>
      <li
        v-for="status in availableStatuses"
        :key="status"
        :class="{
          hidden: !_version?.draftStatus && _version?.status === status,
        }"
        data-cy-id="StatusSelector"
      >
        <a @click="() => changeStatus(_version, status)">
          {{ t(`admin.blockProduction.${targetOperation}.values.${status}`) }}
        </a>
      </li>
    </ul>
  </template>
</template>
