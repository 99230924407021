<script setup lang="ts">
import { useI18n } from 'vue-i18n';

import {
  isBlockImage,
  type BlockImage,
  type CardTemplateProps,
  type Translated,
} from '@/api/types';
import BlockStatusBar from '@/components/BlockStatusBar.vue';
import ImageRenderer from '@/components/block/ImageRenderer.vue';
import { computed, type ComputedRef } from 'vue';
import { getBlobUrl } from '@/api/blob';
import { translateBlockValue } from '@/i18n';
import { type supported_locale } from '@/config';
import TagListRenderer from '@/components/TagListRenderer.vue';

const { t, locale } = useI18n();

const props = defineProps<CardTemplateProps>();

const productImage: ComputedRef<
  (Pick<Translated<BlockImage>, 'alt'> & { url: string }) | undefined
> = computed(() => {
  const productImageField = props.block.content?.['productimage'];

  if (productImageField && isBlockImage(productImageField)) {
    const translatedField = translateBlockValue(
      productImageField,
      locale.value as supported_locale,
      'image',
    );
    return {
      ...translatedField,
      url: getBlobUrl(
        {
          blockId: props.block.blockId,
          versionId: props.block.currentVersionId,
        },
        productImageField.name,
      ),
    };
  }
  return props.block.ownerOrg?.logo
    ? {
        alt: t('block.ownerLogo'),
        url: props.block.ownerOrg.logo,
      }
    : undefined;
});
</script>
<template>
  <div
    :class="[
      'flex flex-col break-words rounded-md bg-gnist-white text-gnist-black shadow-md',
      'w-full items-stretch px-8 pb-2 pt-8 lg:w-[34rem]',
    ]"
  >
    <div class="flex max-h-14 flex-row">
      <ImageRenderer
        v-if="block.ownerOrg"
        :value="{ fullUrl: block.ownerOrg.logo }"
        class="contents [&>div]:contents [&_img]:max-h-10 [&_img]:max-w-[25%] [&_img]:grow-0 [&_img]:self-center"
      />
      <div class="ml-4 grow">
        <p class="line-clamp-2 max-h-9 text-lg italic leading-none">
          {{ block.name }}
        </p>
        <p v-if="block.ownerOrg" class="mt-0.5 text-xs italic">
          {{ `${t('productCard.suppliedBy')} ${block.ownerOrg.name}` }}
        </p>
      </div>
    </div>
    <TagListRenderer :tags="block.tags" class="min-h-4" />
    <hr
      class="h-2 border-none"
      :class="`bg-gnist-${block.category?.color ?? 'gray'}`"
    />
    <div
      class="mt-4 flex min-h-[8rem] flex-col-reverse items-start gap-2 lg:h-32 lg:max-h-32 lg:flex-row"
    >
      <p class="text-box grow">
        {{ block.description }}
      </p>
      <img
        v-if="productImage"
        class="max-h-32 min-w-[33.333%] grow-0 object-contain lg:w-1/3"
        :src="productImage.url"
        :alt="productImage.alt"
      />
    </div>
    <hr
      class="mt-4 h-1 border-none"
      :class="`bg-gnist-${block.category?.color ?? 'gray'}`"
    />
    <p class="text-a1 mt-4 text-center">
      {{ t('productCard.view') }}
    </p>
    <BlockStatusBar class="mx-[-2rem] mt-2" :block="block" />
  </div>
</template>
