import type {
  RouteItemDTO,
  RouteItem,
  RouteItemWithChildren,
  PartialSome,
} from './types';
import { type ComposableResult, composeWithRefresh } from './composable';
import { addLocaleData, type LocaleMessageSet } from '@/i18n';
import { sleep } from '@/utilities/sleep';
import { apiUrl } from '@/config';
import { ref } from 'vue';

export const titleMsgId = 'menuitems.title';
export const descMsgId = 'menuitems.description';
export const disclaimerMsgId = 'menuitems.disclaimer';
const routeItemsUrl = apiUrl + '/RouteItems';

async function getRouteItemDTOs(): Promise<RouteItemDTO[]> {
  const response = await fetch(routeItemsUrl, {
    method: 'GET',
  });
  if (!response.ok) throw new Error('error.get_menu_failed');
  return response.json();
}
let menuData: RouteItemDTO[] | null | undefined = undefined;
const menuItems = ref<RouteItem[]>();
let nonMenuItems: RouteItem[];
export const menuLocaleData: LocaleMessageSet = {
  no: {},
  en: {},
};
/** Reads the menu and loads data into both the `menuData` and `menuItems` fields in this file. \
 * It will also populate the `menuLocaleData` field through the use of `rawMenuDataToMenuTree`.
 */
async function readMenu(wait: boolean, refresh = false): Promise<void> {
  // This ensures that we only read the menu once
  while (menuData === null || (wait && menuData === undefined)) {
    await sleep(250);
  }
  if (menuData === undefined || refresh) {
    menuData = null;
    menuData = (await getRouteItemDTOs()).sort(
      (a, b) => (a.sortOrder ?? 0) - (b.sortOrder ?? 0),
    );
    menuItems.value = buildMenuTree(
      menuData.filter((i) => i.targetMenu == 'main'),
      rawMenuDataToMenuTree,
    );
    nonMenuItems = buildMenuTree(
      menuData.filter((i) => !i.targetMenu),
      rawMenuDataToMenuTree,
    );
  }
}
type withBranches<T> = { items?: T[] };
type tree<T> = T & withBranches<T>;
function flat<T extends withBranches<T>>(items: T[]): T[] {
  const res: T[] = [];
  for (const i of items) {
    res.push(i);
    if (i.items) res.push(...(flat(i.items) as T[]));
  }
  return res;
}
export function useRouteItems() {
  return composeWithRefresh(async (isRefreshing) => {
    await readMenu(true, isRefreshing);
    return flat(
      buildMenuTree<tree<RouteItemDTO & Pick<RouteItem, 'routeType'>>>(
        menuData,
        (i, _, ch) => ({ ...i, items: ch }),
        true,
      ),
    );
  });
}
export async function getMenuItems(): Promise<{
  menuItems: RouteItem[];
  nonMenuItems: RouteItem[];
}> {
  await readMenu(false);
  return { menuItems: menuItems.value ?? [], nonMenuItems };
}

export function useMenu(): ComposableResult<RouteItem[]> {
  return composeWithRefresh(async () => {
    await readMenu(true);
    return menuItems.value ?? [];
  }, menuItems);
}
function buildMenuTree<T>(
  items: RouteItemDTO[] | null | undefined,
  handler: (item: RouteItemDTO, parentPath: string, children?: T[]) => T,
  withHeaders = false,
  parentPath = '',
  parentRoute?: string | undefined,
): T[] {
  if (!items) return [];
  let parentRouteName = parentRoute;
  if (!parentRouteName) parentRouteName = parentPath.split('/').splice(-1)[0];
  if (!parentRouteName) parentRouteName = undefined;
  return items
    .filter((item) => item.parent == parentRouteName)
    .map((item): T | T[] => {
      if (!item.routeName) return handler(item, parentPath);
      const children = buildMenuTree(
        items,
        handler,
        withHeaders,
        parentPath +
          (item.headerType == 'menuOnly' ? '' : '/' + item.routeName),
        item.headerType ? item.routeName : undefined,
      );
      if (item.headerType == 'routeOnly' && !withHeaders) return children;
      return handler(item, parentPath, children);
    })
    .flat() as T[];
}
function rawMenuDataToMenuTree(
  data: RouteItemDTO,
  parentPath: string,
  children: RouteItem[] = [],
): RouteItem {
  const targetPath =
    data.routeType === 'hardcoded'
      ? parentPath
      : parentPath + '/' + encodeURIComponent(data.routeName);
  addLocaleData(menuLocaleData, data.text, titleMsgId, data.routeName);
  const routeBaseName =
    data.isDocsListRoute || data.routeType == 'hardcoded'
      ? data.routeName
      : data.targetDoc
        ? `page${data.targetDoc}`
        : undefined;
  const item: RouteItem = {
    ...data,
    text:
      typeof data.text === 'object'
        ? `${titleMsgId}.${data.routeName}`
        : data.text,
    targetPath,
    meta: {
      targetDoc: data.targetDoc,
      translationid: data.routeName,
      customOptions: data.customOptions,
      fullScreen: data.customOptions?.fullscreen,
    },
    routeBaseName,
  };
  const actualAliases = data.aliases?.filter((i) => i != '') ?? [];
  if (actualAliases.length > 0) {
    item.aliases = actualAliases.slice(1);
    if (data.routeType !== 'hardcoded') item.aliases.push(item.targetPath);
    item.targetPath = actualAliases[0].startsWith('/')
      ? actualAliases[0]
      : '/' + actualAliases[0];
    if (item.aliases.length == 0) delete item.aliases;
  } else item.aliases = undefined;
  if (children.length > 0) {
    (item as RouteItemWithChildren).items = children;
  }
  return item;
}
export async function updateRouteItem(
  item: PartialSome<RouteItemDTO, 'id'>,
): Promise<RouteItemDTO> {
  if (!item?.id) throw 'Missing primary key';
  const resp = await fetch(`${routeItemsUrl}/${encodeURIComponent(item.id)}`, {
    method: 'PUT',
    credentials: 'include',
    body: JSON.stringify(item),
    headers: { 'Content-Type': 'application/json' },
  });
  if (!resp.ok) throw new Error('error.update_menu_failed');
  return await resp.json();
}
export async function createRouteItem(
  item: Omit<RouteItemDTO, 'id'>,
): Promise<RouteItemDTO> {
  const resp = await fetch(`${routeItemsUrl}`, {
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify(item),
    headers: { 'Content-Type': 'application/json' },
  });
  if (!resp.ok) throw new Error('error.create_menu_failed');
  return await resp.json();
}
export async function deleteRouteItem(id: number) {
  fetch(`${routeItemsUrl}/${id}`, {
    method: 'DELETE',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
  });
}
