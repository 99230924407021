import { ref, type Ref, unref, watch, computed } from 'vue';

export function useWaitForObservedElement(
  elementId: string | Ref<string>,
  target?: HTMLElement | Ref<HTMLElement | undefined | null>,
  useQuerySelector = false,
  watchVisibility?: 'waitOnce' | 'watch',
): Ref<HTMLElement | null> {
  target ??= document.getElementById('app') ?? undefined;
  const el = ref<HTMLElement | null>(null);
  if (!target) return el;
  let disconnectCreationObserver: (() => void) | null = null;
  watch(
    [() => unref(elementId), () => unref(target)],
    () => {
      disconnectCreationObserver?.();
      const _target = unref(target);
      if (!_target) return;
      disconnectCreationObserver = startObserveCreation(
        _target,
        el,
        elementId,
        useQuerySelector,
      );
    },
    { immediate: true },
  );
  const isVisible = ref(false);
  let disconnectVisibilityObserver: (() => void) | null = null;
  watch(el, () => {
    if (watchVisibility === undefined) return;
    disconnectVisibilityObserver?.();
    if (!el.value) return;
    disconnectVisibilityObserver = startObserveVisibility(
      el.value,
      isVisible,
      watchVisibility,
    );
  });
  return computed(() => {
    if (!el.value) {
      return null;
    } else if (watchVisibility !== undefined && !isVisible.value) {
      return null;
    } else {
      return el.value;
    }
  });
}

function getElement(
  target: HTMLElement,
  elementId: string | Ref<string>,
  useQuerySelector: boolean,
): HTMLElement | null {
  const _elementId = unref(elementId);
  if (_elementId) {
    if (useQuerySelector) {
      return target.querySelector(_elementId);
    } else {
      return document.getElementById(_elementId);
    }
  } else {
    return target;
  }
}

function startObserveCreation(
  target: HTMLElement,
  el: Ref<HTMLElement | null>,
  elementId: string | Ref<string>,
  useQuerySelector: boolean,
) {
  const element = getElement(target, elementId, useQuerySelector);
  if (element) {
    el.value = element;
    return () => null;
  }
  const observer = new MutationObserver(function () {
    const element = getElement(target, elementId, useQuerySelector);
    if (element) {
      el.value = element;
      observer.disconnect();
    }
  });
  observer.observe(target, { childList: true, subtree: true });
  return () => observer.disconnect();
}

function startObserveVisibility(
  element: HTMLElement,
  isVisible: Ref<boolean>,
  watchVisibility: 'waitOnce' | 'watch',
) {
  isVisible.value = element.checkVisibility();
  const attrObserver = new MutationObserver(function () {
    isVisible.value = element.checkVisibility();
    if (isVisible.value && watchVisibility !== 'watch') {
      attrObserver.disconnect();
    }
  });
  attrObserver.observe(element, {
    attributes: true,
    attributeFilter: ['style', 'class'],
    childList: false,
    characterData: false,
  });
  return () => attrObserver.disconnect();
}
