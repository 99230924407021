<script setup lang="ts">
import { setCurrentVersion, updateVersion } from '@/api/versions';
import { Status, type Version, type Block } from '@/api/types';
import { computed, onMounted, ref } from 'vue';
import ButtonComponent from '../ButtonComponent.vue';
import MaterialIcon from '../MaterialIcon.vue';
import ModalComponent from '../ModalComponent.vue';
import PublishSelector from '@/components/admin/PublishSelector.vue';
import VersionDeletor from '@/components/admin/VersionDeletor.vue';
import { useStoreAbility } from '@/abilities';
import { getBlocksRouteLink } from '@/utilities/routeUtils';
import { useI18n } from 'vue-i18n';
import { isValidVersionNumber } from './VersionCopy.vue';

const { t } = useI18n();

const props = defineProps<{
  version: Version;
  allVersions: Version[] | null;
  idx: number;
  block: Block;
}>();

const emit = defineEmits<{
  (e: 'refreshBlock', noBubble?: boolean): void;
}>();

const { can } = useStoreAbility();

const versionNumber = ref(
  props.version.versionNumber ?? props.block.currentVersionNumber ?? '',
);

const existingVersionNumber = computed(
  () =>
    !!props.allVersions?.find((v) => v.versionNumber == versionNumber.value),
);

const showEditVersionNumberModal = ref(false);
const validVersionNumber = computed(() =>
  isValidVersionNumber(versionNumber.value),
);

const editVersionNumber = async (
  version: Version,
  newVersionNumber: string,
) => {
  await updateVersion(props.block.blockId, version.versionId, {
    versionNumber: newVersionNumber,
  });
  emit('refreshBlock');
};

async function updateCurrentVersion(version: Version) {
  await setCurrentVersion(props.block.blockId, version.versionId);
  emit('refreshBlock');
}

const hasWorkingCopy = computed(() => !!props.version.draftStatus);

// Because we made the parent table scrollable on small screens, we need to make everything below the table parent static, which breaks the relative positioning. This bit ensures it works after all.
const buttonRef = ref<HTMLElement>();
const menuRef = ref<HTMLElement>();
onMounted(() => {
  if (buttonRef.value && menuRef.value) {
    menuRef.value.style.right =
      Math.floor(buttonRef.value.offsetWidth).toString() + 'px';
    menuRef.value.style.bottom =
      (
        ((menuRef.value.offsetParent as HTMLElement)?.offsetHeight ?? 0) -
        menuRef.value.offsetTop +
        buttonRef.value.offsetHeight
      ).toString() + 'px';
  }
});
</script>
<template>
  <!-- Version context menu -->
  <div class="dropdown static justify-self-end">
    <!-- The Daisy UI dropdown component uses advanced CSS to show the dropdown menu when the label has focus... -->
    <label
      ref="buttonRef"
      tabindex="0"
      class="static block h-8 w-8 rounded text-center hover:cursor-pointer hover:bg-gnist-gray-light-light"
      data-cy-id="VersionContextMenuButton"
    >
      <MaterialIcon class="text-2xl">more_vert</MaterialIcon>
    </label>
    <ul
      ref="menuRef"
      tabindex="0"
      class="menu dropdown-content z-10 rounded-box bg-gnist-white p-2 shadow [&_*]:relative [&_li>*]:grow"
    >
      <li>
        <RouterLink
          :to="{
            name: 'edit_version',
            params: { blockId: block.blockId, versionId: version.versionId },
          }"
        >
          {{ t('admin.blockProduction.edit') }}
        </RouterLink>
      </li>
      <li>
        <RouterLink
          :to="
            getBlocksRouteLink(block.blockId, {
              version: version.versionNumber,
              preview: 1,
            })
          "
        >
          {{ t('admin.blockProduction.preview') }}
        </RouterLink>
      </li>
      <li>
        <a @click="showEditVersionNumberModal = true">{{
          t('admin.blockProduction.editVersionNumber')
        }}</a>
      </li>
      <li
        v-if="
          version.status === Status.Published &&
          block.currentVersionNumber !== version.versionNumber
        "
      >
        <a @click="() => updateCurrentVersion(version)">
          {{ t('admin.blockProduction.setCurrent') }}
        </a>
      </li>
      <li class="hide-empty">
        <PublishSelector
          :version="version"
          :block="block"
          @refresh-block="() => emit('refreshBlock')"
        />
      </li>
      <li v-if="hasWorkingCopy">
        <VersionDeletor
          :block="block"
          :version="version"
          delete-type="Draft"
          @refresh-block="() => emit('refreshBlock')"
        />
      </li>
      <li v-if="version.status === Status.Archived && can('delete', 'Block')">
        <VersionDeletor
          :block="block"
          :version="version"
          delete-type="Version"
          @refresh-block="() => emit('refreshBlock')"
        />
      </li>
    </ul>
  </div>

  <!--
              Change version number modal(s)
            -->
  <ModalComponent
    :title="t('admin.blockProduction.editVersionNumber')"
    :show-modal="showEditVersionNumberModal"
    @close="showEditVersionNumberModal = false"
  >
    <input v-model="versionNumber" class="mt-4 w-full border p-2" />
    <p class="min-h-16">
      <span v-if="existingVersionNumber">{{
        t('admin.blockProduction.existingVersion')
      }}</span>
      <span v-if="!validVersionNumber">{{
        t('admin.blockProduction.invalidVersionName')
      }}</span>
    </p>
    <template #buttons>
      <div class="flex justify-end">
        <div class="gnist-button-group">
          <ButtonComponent
            :text="t('buttons.cancel')"
            @click="showEditVersionNumberModal = false"
          />
          <ButtonComponent
            :text="t('admin.blockProduction.save')"
            type="primary"
            :disabled="
              !validVersionNumber ||
              !!allVersions?.find((v) => v.versionNumber == versionNumber)
            "
            @click="() => editVersionNumber(version, versionNumber)"
          />
        </div>
      </div>
    </template>
  </ModalComponent>
</template>
