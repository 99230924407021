<script setup lang="ts">
import { computed, ref, type Ref } from 'vue';
import { Line, Bar } from 'vue-chartjs';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Colors,
} from 'chart.js';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import { useAnalytics } from '@/api/analytics';
import type { ApplicationInsightsDTO } from '@/api/types';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Colors,
);

const fromDate = ref(30);
const dateOptions: Intl.DateTimeFormatOptions = {
  weekday: 'short',
  month: '2-digit',
  day: '2-digit',
};
const analyticsData: Ref<ApplicationInsightsDTO | null> = useAnalytics();

const userCount = computed(() => {
  if (analyticsData.value) {
    const filtered = analyticsData.value?.maxUserCount.slice(-fromDate.value);
    return {
      labels: filtered.map((row) =>
        new Date(row.timestamp)
          .toLocaleDateString(undefined, dateOptions)
          .replace(/\.$/, ''),
      ),
      datasets: [
        {
          data: filtered.map((row) => row.count),
          label: t('analytics.maxUsers'),
        },
      ],
    };
  }
  return null;
});

const pageViewCount = computed(() => {
  if (analyticsData.value) {
    const filtered = analyticsData.value?.pageViewCount.slice(-fromDate.value);
    return {
      labels: filtered.map((row) =>
        new Date(row.timestamp)
          .toLocaleDateString(undefined, dateOptions)
          .replace(/\.$/, ''),
      ),
      datasets: [
        {
          data: filtered.map((row) => row.count),
          label: t('analytics.pageViews'),
        },
      ],
    };
  }
  return null;
});

const loginCount = computed(() => {
  if (analyticsData.value) {
    const filtered = analyticsData.value?.loginCount.slice(-fromDate.value);
    return {
      labels: filtered.map((row) =>
        new Date(row.timestamp)
          .toLocaleDateString(undefined, dateOptions)
          .replace(/\.$/, ''),
      ),
      datasets: [
        {
          data: filtered.map((row) => row.count),
          label: t('analytics.loginCount'),
        },
      ],
    };
  }
  return null;
});

const newsViewCount = computed(() => {
  if (analyticsData.value) {
    const filtered = analyticsData.value?.newsViewCount.slice(-fromDate.value);
    return {
      labels: filtered.map((row) =>
        new Date(row.timestamp)
          .toLocaleDateString(undefined, dateOptions)
          .replace(/\.$/, ''),
      ),
      datasets: [
        {
          data: filtered.map((row) => row.count),
          label: t('analytics.newsPageViews'),
        },
      ],
    };
  }
  return null;
});

const commentViewCount = computed(() => {
  if (analyticsData.value) {
    const filtered = analyticsData.value?.commentViewCount.slice(
      -fromDate.value,
    );
    return {
      labels: filtered.map((row) =>
        new Date(row.timestamp)
          .toLocaleDateString(undefined, dateOptions)
          .replace(/\.$/, ''),
      ),
      datasets: [
        {
          data: filtered.map((row) => row.count),
          label: t('analytics.commentPageViews'),
        },
      ],
    };
  }
  return null;
});

const blockViewCount = computed(() => {
  if (analyticsData.value) {
    return {
      labels: analyticsData.value.blockViewCount.map(
        (row) => row.blockName ?? row.blockId,
      ),
      datasets: [
        {
          data: analyticsData.value.blockViewCount.map((row) =>
            fromDate.value === 30 ? row.count30d : row.count7d,
          ),
          label: t('analytics.blockViews'),
        },
      ],
    };
  }
  return null;
});
</script>

<template>
  <div class="w-full">
    <div class="flex justify-end">
      <p>{{ t('analytics.filter') }}:</p>
      <p
        class="mx-2"
        :class="[fromDate === 7 ? 'font-bold' : 'hover:cursor-pointer']"
        @click="() => (fromDate = 7)"
      >
        {{ t('analytics.7days') }}
      </p>
      <p
        class="mr-2"
        :class="[fromDate === 30 ? 'font-bold' : 'hover:cursor-pointer']"
        @click="() => (fromDate = 30)"
      >
        {{ t('analytics.30days') }}
      </p>
    </div>
    <div
      v-if="analyticsData"
      class="mb-8 flex w-full grid-cols-2 flex-col gap-2 lg:grid"
    >
      <div class="min-h-80">
        <Line
          v-if="userCount"
          :options="{ maintainAspectRatio: false }"
          class="h-full w-full border bg-white p-2"
          :data="userCount"
        />
      </div>
      <div class="min-h-80">
        <Line
          v-if="pageViewCount"
          :options="{ maintainAspectRatio: false }"
          class="h-full w-full border bg-white p-2"
          :data="pageViewCount"
        />
      </div>
      <div class="min-h-80">
        <Line
          v-if="loginCount"
          :options="{ maintainAspectRatio: false }"
          class="h-full w-full border bg-white p-2"
          :data="loginCount"
        />
      </div>
      <div class="min-h-80">
        <Line
          v-if="newsViewCount"
          :options="{ maintainAspectRatio: false }"
          class="h-full w-full border bg-white p-2"
          :data="newsViewCount"
        />
      </div>
      <div class="min-h-80">
        <Line
          v-if="commentViewCount"
          :options="{ maintainAspectRatio: false }"
          class="h-full w-full border bg-white p-2"
          :data="commentViewCount"
        />
      </div>
      <div class="min-h-80">
        <Bar
          v-if="blockViewCount"
          :options="{ maintainAspectRatio: false }"
          class="h-full w-full border bg-white p-2"
          :data="blockViewCount"
        />
      </div>
    </div>
    <div v-else class="flex w-full flex-col items-center">
      <LoadingSpinner class="h-16 w-16" />
    </div>
  </div>
</template>
