<script lang="ts" setup>
import type { PageTemplateProps } from '@/api/types';
import BlockPageBanner from '@/components/block/BlockPageBanner.vue';
import BlockRenderer from '@/components/block/BlockRenderer.vue';
import BlockToc from '@/components/block/BlockToc.vue';
import BlockDiscussion from '@/components/block/BlockDiscussion.vue';
import { ref } from 'vue';

defineProps<PageTemplateProps>();
const containers = ref<{ idx: number; el: HTMLElement }[]>([]);
</script>

<template>
  <BlockPageBanner
    :title="block.name ?? ''"
    :block="block"
    :description="block.description ?? ''"
    :tags="block.tags ?? []"
    show-version-selector
    :is-preview="!!isPreview"
  />
  <div class="w-full text-gnist-black" :class="[`bg-gnist-${bgColor}`]">
    <div class="gnist-page-width grow pb-16 lg:grid lg:grid-cols-4 lg:gap-10">
      <div class="relative col-span-1 ml-auto pt-8 lg:pb-24">
        <BlockToc v-if="block" :containers="containers" />
      </div>
      <div class="col-span-3">
        <!-- Content -->
        <div
          class="mx-5 mt-10"
          data-cy-id="contentContainer"
          :data-cy-state="!!block.content ? 'loaded' : 'not_loaded'"
        >
          <BlockRenderer
            v-model:containers="containers"
            :block="block"
            class="mb-4"
          />
        </div>
      </div>
    </div>
  </div>

  <BlockDiscussion :block="block" />
</template>

<style>
/* Store a variable that can be used to calculate the width of this container:
   * remove 17px for the scrollbar
   * remove the "mx-5" above
   * on large screens, calculate col-span-3 with a gap of 2.5rem
*/
:root {
  --main-content-width: calc(100vw - 17px - 2.5rem);
  @media screen(lg) {
    /* prettier-ignore */
    --main-content-width: calc((((100vw - 17px) - 7.5rem) / 4 * 3) + 5rem - 2.5rem);
  }
}
</style>
