<script setup lang="ts">
import {
  Dialog,
  DialogTitle,
  DialogPanel,
  TransitionRoot,
  TransitionChild,
} from '@headlessui/vue';
import ButtonComponent from './ButtonComponent.vue';
import MaterialIcon from '@/components/MaterialIcon.vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

withDefaults(
  defineProps<{
    showModal: boolean;
    title: string;
    size?: 'small' | 'big' | 'full' | 'auto';
    showCloseIcon?: boolean;
    showOverflow?: boolean;
    buttonText?: string;
    primaryButtonType?: 'primary' | 'danger' | 'special' | 'none';
    primaryButtonDisabled?: boolean;
    /** Set this if you want to handle scrolling yourself */
    noScroll?: boolean;
    titleUpperCase?: boolean;
    embedded?: { topOffset: number; box?: boolean };
  }>(),
  {
    size: 'small',
    showCloseIcon: false,
    showOverflow: false,
    buttonText: undefined,
    primaryButtonType: 'danger',
    noScroll: false,
    titleUpperCase: true,
    embedded: undefined,
  },
);

const emit = defineEmits<{
  (e: 'close'): void;
  /** Called when the "OK" button (named by props.title) is clicked */
  (e: 'handleClick'): void;
}>();
</script>

<template>
  <TransitionRoot appear :show="showModal" as="template">
    <Dialog
      as="div"
      class="relative z-dialog"
      @close="emit('close')"
      @keyup.esc="emit('close')"
    >
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gnist-black bg-opacity-25" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div
          class="flex min-h-full justify-center text-center"
          :class="[
            embedded
              ? 'items-start'
              : size === 'full'
                ? 'items-center'
                : 'items-end',
            embedded?.box ? 'md:p-2' : 'md:p-4',
            { 'md:items-center': !embedded },
          ]"
        >
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="rounded-b-0 flex transform flex-col overflow-auto rounded-t-2xl bg-gnist-white p-8 text-left shadow-xl transition-all"
              :class="[
                embedded?.box ? 'h-[calc(100vh_-_1rem)]' : 'max-h-[90vh]',
                {
                  'w-full md:max-w-md': size === 'small',
                  'w-full max-w-screen-xl': size === 'big',
                  'w-auto': size === 'full' || size === 'auto',
                  'md:rounded-2xl': true,
                  'rounded-2xl': size === 'full',
                  'overflow-hidden': !showOverflow,
                  'align-middle': !embedded,
                  'px-6 pb-2': !noScroll,
                },
              ]"
              :style="{
                'margin-top':
                  embedded !== undefined
                    ? `${embedded.topOffset}px`
                    : undefined,
              }"
            >
              <div
                v-if="showCloseIcon"
                class="absolute right-6 top-2 z-10 flex justify-end"
              >
                <MaterialIcon
                  aria-hidden="true"
                  class="cursor-pointer self-baseline bg-gnist-white text-2xl leading-none text-gnist-gray hover:bg-gnist-gray-light"
                  @click="emit('close')"
                >
                  close
                </MaterialIcon>
              </div>
              <div
                :class="[
                  noScroll
                    ? 'contents'
                    : 'overflow-y-auto overflow-x-hidden px-2 pb-6',
                ]"
              >
                <DialogTitle
                  as="h2"
                  class="mb-2 text-lg font-bold"
                  :class="{ uppercase: titleUpperCase }"
                >
                  {{ t(title) }}
                </DialogTitle>
                <slot />
                <div class="mt-6" />
                <slot name="buttons">
                  <div className="flex justify-end">
                    <div class="gnist-button-group">
                      <ButtonComponent
                        :text="
                          primaryButtonType === 'none'
                            ? t('buttons.close')
                            : t('buttons.cancel')
                        "
                        @click="emit('close')"
                      />

                      <ButtonComponent
                        v-if="primaryButtonType !== 'none'"
                        :type="primaryButtonType"
                        :text="buttonText ? t(buttonText) : t(title)"
                        :disabled="primaryButtonDisabled"
                        data-cy-id="modal-primary-button"
                        @click="emit('handleClick')"
                      />
                    </div>
                  </div>
                </slot>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<style>
#headlessui-portal-root > div[data-headlessui-portal] > button {
  position: fixed !important;
}
</style>
