import { apiUrl } from '@/config';
import {
  compose,
  composeWithRefresh,
  type ComposableResult,
  type ComposableResultWithRefresh,
  composeLazy,
} from './composable';
import {
  translateCategory,
  type Block,
  type BlockCreateDTO,
  type Guid,
  type TranslatedCategory,
  type UpdateBlock,
} from './types';
import { translateStringOrLocaleWithDefault } from '@/i18n';
import { throwErrorPageTrigger } from '@/utilities/throwErrorPageTrigger';

const blockUrl = apiUrl + '/blocks';

const allBlocksLazy = composeLazy(() =>
  getTranslatedBlocks({ mode: 'adminview' }, true),
);

export function useBlock(
  id: Guid,
  useDraftReaderEndpoint = false,
): ComposableResultWithRefresh<Block> {
  const refresh = async () => getBlock(id, undefined, useDraftReaderEndpoint);
  return composeWithRefresh(refresh);
}

export async function getBlock(
  id: Guid,
  versionNumber?: string,
  useDraftReaderEndpoint?: boolean,
): Promise<Block> {
  const search =
    typeof versionNumber === 'string' ? `?versionNumber=${versionNumber}` : '';
  const response = await fetch(
    `${blockUrl}/${id}${useDraftReaderEndpoint ? '/withdrafts' : ''}${search}`,
    {
      method: 'GET',
      credentials: 'include',
    },
  );
  throwErrorPageTrigger(response);
  if (!response.ok) throw new Error(await response.text());
  return await response.json();
}

export type TranslatedBlock = Omit<
  Block,
  'name' | 'description' | 'category'
> & {
  name: string;
  description: string;
  category?: TranslatedCategory;
};
export function translateBlock(block: Block): TranslatedBlock {
  return {
    ...block,
    name: translateStringOrLocaleWithDefault(
      block.name,
      'language.unknownValue',
    ).value,
    description: translateStringOrLocaleWithDefault(
      block.description,
      'language.unknownValue',
    ).value,
    category: block.category ? translateCategory(block.category) : undefined,
  };
}

export type GetBlocksOptions =
  | { mode: 'normal'; includeBacklog?: boolean }
  | {
      mode: 'adminview';
      forManagementList?: boolean;
      forMyDocumentsList?: boolean;
    }
  | { mode: 'approval' };

export function useBlocks(
  options: GetBlocksOptions,
): ComposableResult<TranslatedBlock[]> {
  return compose(getTranslatedBlocks(options));
}
export function useBlockListLazy(): ComposableResult<TranslatedBlock[]> {
  allBlocksLazy.init();
  return allBlocksLazy;
}

async function getTranslatedBlocks(
  options: GetBlocksOptions,
  sort = false,
): Promise<TranslatedBlock[]> {
  const blocks = await getBlocks(options);
  const translatedBlocks = blocks.map((block) => translateBlock(block));
  if (sort) translatedBlocks.sort((a, b) => a.name.localeCompare(b.name));
  return translatedBlocks;
}

async function getBlocks(options: GetBlocksOptions): Promise<Block[]> {
  const url =
    options.mode == 'approval'
      ? `${blockUrl}/approvals/list`
      : options.mode === 'adminview'
        ? `${blockUrl}/editlist`
        : `${blockUrl}`;
  const opts = { ...options } as Partial<GetBlocksOptions>;
  delete opts.mode;
  const query =
    Object.keys(options).length > 0
      ? `?${new URLSearchParams(options as Record<string, string>)}`
      : '';
  const response = await fetch(`${url}${query}`, {
    method: 'GET',
    credentials: 'include',
  });
  throwErrorPageTrigger(response);
  if (!response.ok) throw new Error(await response.text());
  return await response.json();
}

export const createBlock = async (block: BlockCreateDTO): Promise<Block> => {
  const response = await fetch(blockUrl, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    body: JSON.stringify(block),
  });
  if (!response.ok) throw new Error('error.create_block_failed');
  return await response.json();
};

export const updateBlock = async (block: UpdateBlock): Promise<void> => {
  const response = await fetch(blockUrl + '/' + block.blockId, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    body: JSON.stringify(block),
  });
  if (!response.ok) throw new Error('error.update_block_failed');
};

export async function deleteBlock(blockId: Guid): Promise<void> {
  const response = await fetch(`${blockUrl}/${blockId}`, {
    method: 'DELETE',
    credentials: 'include',
  });
  if (!response.ok) throw new Error('error.delete_block_failed');
}
