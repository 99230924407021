<script setup lang="ts">
import {
  getBlocksCategoryGraph,
  getBlocksFavoriteGraph,
  getPageCommentsGraph,
} from '../utilities/analytics';
import LoadingSpinner from '@/components/LoadingSpinner.vue';

import { Bar } from 'vue-chartjs';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Colors,
} from 'chart.js';
import { useContentAnalytics } from '@/api/analytics';
import type { ContentAnalyticsDTO } from '@/api/types';
import { useI18n } from 'vue-i18n';
const { t } = useI18n();
ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Colors,
);

const analytics = useContentAnalytics();

const asPercentage = (value: number, precision: number) => {
  return `${(value * 100).toFixed(precision)}%`;
};

const scalarData = (analytics: ContentAnalyticsDTO) => [
  {
    title: 'analytics.numRegisteredUsers',
    value: `${analytics.userAnalytics.userCount}`,
  },
  {
    title: 'analytics.numNewsNotification',
    value: `${analytics.userAnalytics.newsNotificationCount} (${asPercentage(
      analytics.userAnalytics.newsNotificationCount /
        analytics.userAnalytics.userCount,
      1,
    )})`,
  },
  {
    title: 'analytics.numPublishedBlocks',
    value: `${analytics.blockAnalytics.publishedBlocksCount}`,
  },
];

const getTruncatedLabel = (index: number) => {
  if (analytics.value) {
    return Object.keys(analytics.value.blockAnalytics.favoriteCounts)[index]
      .length > 25
      ? Object.keys(analytics.value.blockAnalytics.favoriteCounts)[
          index
        ].substring(0, 25) + '...'
      : Object.keys(analytics.value.blockAnalytics.favoriteCounts)[index];
  }
};
</script>

<template>
  <div v-if="analytics">
    <div class="mx-2 mb-8 flex w-full grid-flow-col flex-col gap-2 lg:grid">
      <div
        v-for="data in scalarData(analytics)"
        :key="data.title"
        class="rounded-lg border bg-gnist-white p-8"
      >
        <h2 class="mb-8">
          {{ t(data.title) }}
        </h2>
        <p class="text-center text-xl text-black">
          {{ data.value }}
        </p>
      </div>
    </div>
    <div class="mb-4 flex w-full grid-cols-2 flex-col gap-2 lg:grid">
      <div class="min-h-80 min-w-0 max-w-full">
        <Bar
          :options="{ maintainAspectRatio: false }"
          :data="getBlocksCategoryGraph(analytics, t('analytics.numBlocks'))"
          class="h-full w-full border bg-white p-2"
        />
      </div>
      <div class="min-h-80 min-w-0 max-w-full">
        <Bar
          :data="getBlocksFavoriteGraph(analytics, t('analytics.numFavorites'))"
          :options="{
            maintainAspectRatio: false,
            scales: {
              x: {
                ticks: {
                  callback: (value) => getTruncatedLabel(value as number),
                },
              },
            },
          }"
          class="h-full w-full border bg-white p-2"
        />
      </div>
      <div class="min-h-80 min-w-0 max-w-full">
        <Bar
          :options="{ maintainAspectRatio: false }"
          :data="getPageCommentsGraph(analytics, t('analytics.numComments'))"
          class="h-full w-full border bg-white p-2"
        />
      </div>
    </div>
  </div>
  <div v-else>
    <div class="flex w-full flex-col items-center">
      <LoadingSpinner class="h-16 w-16" />
    </div>
  </div>
</template>
