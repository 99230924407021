<script setup lang="ts">
import type { ErrorMessage } from '@/api/types';
import slugify from 'slugify';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const props = defineProps<{
  errorMessage: ErrorMessage;
}>();

const emit = defineEmits<{
  (e: 'close'): void;
}>();

if (props.errorMessage.autoClose) {
  setTimeout(function () {
    emit('close');
  }, 10000);
}

const { t, te } = useI18n();

const localizedErrorMessage = computed(() => {
  const translationKey = isValidTranslationKey(props.errorMessage.message)
    ? props.errorMessage.message
    : createTranslationKey(props.errorMessage.message);

  if (te(translationKey)) {
    return t(translationKey);
  } else if (te(props.errorMessage.message)) {
    return t(props.errorMessage.message);
  } else {
    console.warn('Unknow translation key: ' + translationKey);
    return t('error.unknown_error');
  }
});

// Valid translation keys are of the form error.somename_someothername
function isValidTranslationKey(str: string) {
  const pattern = /^error\.[a-z0-9]+(?:_[a-z0-9]+)*$/;
  return pattern.test(str);
}

function createTranslationKey(str: string) {
  return 'error.' + slugify(str, { lower: true, strict: true });
}
</script>

<template>
  <div role="alert" class="toast toast-end toast-top z-error mt-28">
    <div class="alert alert-warning">
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6 flex-shrink-0 stroke-current"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
          />
        </svg>
        <span class="text-wrap">{{ localizedErrorMessage }}</span>
        <span
          v-if="errorMessage.closeable"
          class="inline-flex cursor-pointer items-center"
          @click="$emit('close')"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-4 w-4 text-gray-600"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </span>
      </div>
    </div>
  </div>
</template>
