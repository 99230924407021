<script setup lang="ts">
import {
  computed,
  onUnmounted,
  ref,
  watchEffect,
  type WatchStopHandle,
} from 'vue';
import { useBlock } from '@/api/blocks';
import {
  getChatPage,
  type commentPageId,
  type commentPageParam,
  suggestionPageParam,
  type suggestionPageId,
  suggestionPageIdPrefix,
  type ChatPage,
} from '@/api/commentoTypes';
import {
  EMPTY_GUID,
  type Guid,
  type Suggestion,
  type Block,
  type localeValue,
} from '@/api/types';
import DiscussionBoard from '@/components/discussion/DiscussionBoard.vue';
import { throwOnError } from '@/api/composable';
import { useI18n } from 'vue-i18n';
import { defaultBlocksRouteName } from '@/utilities/routeUtils';
import { useSuggestion } from '@/api/suggestion';
import { translateStringOrLocale } from '@/i18n';
import { useScrollToIdWhenPresent } from '@/utilities/useScrollToIdWhenPresent';

const { t } = useI18n();

const props = withDefaults(
  defineProps<{
    pageId?: commentPageParam;
    blockId?: Guid;
    commentId?: string;
    discussionId?: suggestionPageId;
  }>(),
  {
    pageId: 'general',
    blockId: EMPTY_GUID,
    commentId: undefined,
    discussionId: 'suggestion0',
  },
);

// We creates new watches asynchronously when the useBlock result changes. These
// must be cleaned up manually to prevent memory leak.
let previousWatcher: WatchStopHandle | null = null;
const unregisterWatcher = () => {
  if (previousWatcher) {
    previousWatcher();
    previousWatcher = null;
  }
};
onUnmounted(unregisterWatcher);

type ForumSuggestion = Omit<Suggestion, 'title'> & {
  name: localeValue;
};
type TranslatablePage = Block | ForumSuggestion;
type Page = TranslatablePage | ChatPage;
const page = ref<TranslatablePage | Page | null>();
watchEffect(() => {
  unregisterWatcher();

  if (props.pageId === defaultBlocksRouteName) {
    const { result: block } = throwOnError(useBlock(props.blockId as Guid));
    previousWatcher = watchEffect(() => {
      page.value = block.value;
    });
  } else if (props.pageId === suggestionPageParam) {
    const suggestionId = parseInt(
      props.discussionId?.replace(suggestionPageIdPrefix, ''),
    );
    if (isNaN(suggestionId)) throw new Error('error.missing_discussion_param');
    const { result: suggestion } = throwOnError(useSuggestion(suggestionId));
    previousWatcher = watchEffect(() => {
      page.value = !suggestion.value
        ? null
        : { ...suggestion.value, name: suggestion.value.title };
    });
  } else {
    page.value = getChatPage(props.pageId);
  }
});

const pageSettings = computed(() => {
  if (!page.value) return undefined;

  const blockId = props.blockId != EMPTY_GUID ? props.blockId : '0';

  return {
    pageName:
      (page.value as ChatPage).chatPageName ??
      translateStringOrLocale((page.value as TranslatablePage).name).value,
    pageDescription:
      (page.value as ChatPage).chatPageDescription ??
      translateStringOrLocale((page.value as TranslatablePage).description)
        .value,
    commentPageId: (props.pageId === defaultBlocksRouteName
      ? blockId
      : props.pageId === suggestionPageParam
        ? props.discussionId
        : '/' + props.pageId) as commentPageId,
  };
});

useScrollToIdWhenPresent('comment-' + props.commentId);
</script>

<template>
  <div class="p-2">
    <div role="banner">
      <h2>
        {{ t(pageSettings?.pageName ?? '') }}
      </h2>
      <p class="py-4">
        {{ t(pageSettings?.pageDescription ?? '') }}
      </p>
    </div>
    <div v-if="pageSettings?.commentPageId">
      <DiscussionBoard
        :key="pageSettings.commentPageId"
        :discussion-id="pageSettings.commentPageId"
        class="mt-10"
      />
    </div>
  </div>
</template>
