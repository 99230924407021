<script lang="ts" setup>
import { useCurrentUser } from '@/api/auth';

const userInfo = useCurrentUser();
</script>
<template>
  <div>
    <span>{{ userInfo?.firstName }}</span>
    <span class="ml-2">{{ userInfo?.lastName }}</span>
  </div>
</template>
