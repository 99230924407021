<script setup lang="ts">
import BlockList from '../components/BlockList.vue';
import ContentPage from '../components/ContentPage.vue';
import { useI18n } from 'vue-i18n';
import { editBlockVersionsRouteId } from '@/config';

const { t } = useI18n();
</script>

<template>
  <ContentPage
    :title="t('admin.blockProduction.title')"
    :description="t('admin.blockProduction.titleText')"
    :hide-description-mobile="true"
    class="bg-gnist-gray-light-light"
  >
    <div class="p-8 pt-0">
      <RouterLink
        class="gnist-button gnist-button-primary"
        :to="{ name: 'new_block' }"
        data-cy-id="NewDocumentButton"
      >
        {{ t('admin.blockProduction.newBlock') }}
      </RouterLink>
    </div>
    <BlockList
      :route-name="editBlockVersionsRouteId"
      show-empty-cat-filter
      :options="{ mode: 'adminview' }"
      card-template-id="searchCard"
      class="px-8"
    />
  </ContentPage>
</template>
