<script setup lang="ts" generic="T extends string">
import { useI18n } from 'vue-i18n';

defineProps<{
  tabs: Readonly<T[]>;
  modelValue: T;
  i18nKey: string;
}>();
const emit = defineEmits<{
  (e: 'update:modelValue', value: T): void;
}>();
const { t } = useI18n();
</script>

<!--
  * This component uses Daisy UI Tabs (https://daisyui.com/components/tab/), but *without* the radio-button selector.
  * To use the radio-button selector, take a look at how that is used in relation to BlockContentTab.vue.
-->
<template>
  <div role="tablist" class="tabs tabs-lifted">
    <a
      v-for="tab in tabs"
      :key="tab"
      role="tab"
      class="tab block truncate"
      :class="{ 'tab-active': modelValue === tab }"
      @click="emit('update:modelValue', tab)"
    >
      {{ t(`${i18nKey}.${tab}`) }}
    </a>
  </div>
</template>
