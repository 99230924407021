<script lang="ts">
export type BlockMenuItem = {
  id: string | null;
  title: string;
  items?: BlockMenuItem[];
  level: number;
};
export const headingLevels = ['h2', 'h3', 'h4'] as const;
</script>
<script setup lang="ts">
import MaterialIcon from './../MaterialIcon.vue';
import BlockTocItem from './BlockTocItem.vue';
import { ref, watch, watchEffect } from 'vue';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';

const { t, locale } = useI18n();

const props = withDefaults(
  defineProps<{
    containers: { idx: number; el: HTMLElement }[];
    /** Markdown is rendered with all headings reduced one level, therefore you can choose h2-h4 */
    levels?: [(typeof headingLevels)[number], (typeof headingLevels)[number]?];
  }>(),
  {
    readyBlockContainer: null,
    readyApimContainer: null,
    steps: null,
    levels: () => ['h2', 'h3'],
  },
);

const route = useRoute();
const menuItems = ref<BlockMenuItem[]>([]);

function extractMenuItems(nodes: Element[]): BlockMenuItem[] {
  const TEXT_NODE = 3;
  const getText = (e: Element): string | null => {
    if ((e as HTMLElement).innerText) {
      return (e as HTMLElement).innerText;
    } else if (e.textContent) {
      return e.textContent;
    } else {
      return Array.from(e.childNodes ?? []).filter(
        (i) => i.nodeType === TEXT_NODE,
      )[0]?.textContent;
    }
  };
  return nodes.map(
    (h): BlockMenuItem => ({
      id: h.getAttribute('id'),
      title: getText(h) ?? '-',
      level: parseInt(h.nodeName.match(/\d/)?.[0] ?? '20'),
    }),
  );
}
const updateMenu = (containers: typeof props.containers) => {
  menuItems.value = [...containers]
    .sort((a, b) => a.idx - b.idx)
    .map((container) => exctractToc(container.el))
    .flat();
};
watchEffect(() => updateMenu(props.containers));
watch(locale, () => setTimeout(() => updateMenu(props.containers), 250)); // We need to allow for a bit of time for other changes to propagate before rebuilding the menu

function exctractToc(container: HTMLElement): BlockMenuItem[] {
  const nodes = container.querySelectorAll(
    props.levels.map((l) => `.html-render ${l}`).join(','),
  );
  if (!nodes) return [];
  const flatmenu = extractMenuItems(Array.from(nodes));
  if (flatmenu.length === 0) return flatmenu;
  let parent = flatmenu.shift() as BlockMenuItem;
  const result = [parent];
  for (const item of flatmenu) {
    if (parent.level < item.level) {
      if (!parent.items) parent.items = [];
      parent.items.push(item);
    } else {
      result.push(item);
      parent = item;
    }
  }
  return result;
}

watchEffect(() => {
  if (route.hash) {
    const target = route.hash.replace('#', '');
    if (target.length > 0) {
      const element = document.getElementById(target);
      if (element) element.scrollIntoView();
    }
  }
});

const scrollToTop = () => {
  window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
};
</script>

<template>
  <ul class="sticky top-0 z-10 ml-10 hidden p-2 text-gnist-black lg:block">
    <BlockTocItem
      v-for="(item, index) in menuItems"
      :key="index"
      :scrollto="item.id"
      :title="item.title"
      :items="item.items"
      :level="levels[0]"
    />

    <li>
      <button
        class="gnist-button gnist-icon-button gnist-button-primary m-2 mr-2 mt-6 hover:cursor-pointer"
        :title="t('gettingStarted.scrollToTop')"
        @click="scrollToTop"
      >
        <MaterialIcon aria-hidden="true"> expand_less </MaterialIcon>
      </button>
    </li>
  </ul>
</template>
