export enum KnownHttpHeaders {
  Authorization = 'Authorization',
  CacheControl = 'Cache-Control',
  ContentType = 'Content-Type',
  OcpApimSubscriptionKey = 'Ocp-Apim-Subscription-Key',
  OcpApimTrace = 'Ocp-Apim-Trace',
  OcpApimTraceLocation = 'Ocp-Apim-Trace-Location',
  OcpApimSasToken = 'Ocp-Apim-Sas-Token',
  SoapAction = 'SOAPAction',
  IfMatch = 'If-Match',
  XMsApiName = 'X-Ms-Api-Name',
}
