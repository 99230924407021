<script lang="ts">
export interface ITagBase {
  /** Name of the item. */
  name: string;
  /** Unique key for the item. */
  key: string | number | null;
}
interface ITag<T> {
  /** Stores the actual object value. */
  value: T;
}
function listContainsItem<T extends ITagBase>(item: T, selected?: T[]) {
  if (!selected || !selected.length || selected.length === 0) {
    return false;
  }
  return (
    selected.filter((compareTag) => compareTag.key === item.key).length > 0
  );
}
</script>

<script setup lang="ts" generic="T">
import {
  ref,
  watchEffect,
  type UnwrapRef,
  watch,
  toRef,
  computed,
  onMounted,
} from 'vue';
import {
  Combobox,
  ComboboxInput,
  ComboboxOptions,
  ComboboxOption,
} from '@headlessui/vue';
import InputLabel, {
  useDynamicLabelTexts,
  type IInputLabelProps,
} from './InputLabel.vue';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import MaterialIcon from '@/components/MaterialIcon.vue';
import { useI18n } from 'vue-i18n';
import { useInternalState } from '@/utilities/useInternalState';
import { arrayEquals } from '@/utilities/arrayEquals';
import { useStepScrolling } from '@/utilities/useStepScrolling';
import type { SingleItem, VueClass } from '@/api/types';

type InternalState = (ITag<SingleItem<T>> & ITagBase)[];
type InternalStateItem = ITag<UnwrapRef<SingleItem<T>>> &
  ITagBase &
  SingleItem<InternalState>;

const props = defineProps<
  IInputLabelProps & {
    modelValue: T;
    getkey: (val: SingleItem<T>) => string | number;
    gettext: (val: SingleItem<T>) => string;
    suggestedlabel?: string;
    taglistclass?: VueClass;
    /** The filterText will always be supplied in lowercase */
    filter: (filterText: string) => Promise<SingleItem<T>[]> | SingleItem<T>[];
    add?: (item: ITagBase) => Promise<SingleItem<T>> | SingleItem<T>;
    addPrefix?: string;
    maxItems?: number;
    showEmptySuggestions?: boolean;
    /** If supplied, will be used to get the actual object when only ids are supplied in the taglist */
    getById?: (
      id: string | number,
    ) => SingleItem<T> | undefined | Promise<SingleItem<T> | undefined>;
    noChrome?: boolean;
  }
>();
const emit = defineEmits<{
  (e: 'update:modelValue', value: T): void;
  (e: 'focus'): void;
  (e: 'blur'): void;
}>();
defineSlots<{
  tagItem(props: { item: InternalStateItem }): unknown;
  tagOption(props: { item: ITagBase }): unknown;
}>();

const { t } = useI18n();

const newItem = ref<ITagBase>();
const filteredItems = ref<ITagBase[] | null>([]);

function onModelValueChanged(
  newExtState: Partial<T>,
  internalState: InternalState,
): InternalState | null {
  const arr = Array.isArray(newExtState)
    ? (newExtState as SingleItem<T>[])
    : [newExtState as SingleItem<T>];
  const modelKeys = arr.map((i) => props.getkey(i));
  const selectedKeys = internalState
    .filter((i) => i.key !== null)
    .map((i) => props.getkey(i.value as SingleItem<T>));
  if (arrayEquals(modelKeys, selectedKeys)) return internalState;
  return arr.map((i) => ({
    key: props.getkey(i),
    name: props.gettext(i),
    value: i,
  }));
}

function onSelectedItemsChanged(newIntState: InternalState) {
  if (tagListRef.value) {
    tagListRef.value.scrollTo(0, tagListRef.value.scrollHeight);
  }
  const values = newIntState.filter((i) => i.key !== null).map((i) => i.value);
  if (Array.isArray(props.modelValue)) return values as T;
  else {
    if (selectedItems.value.length > 1) {
      selectedItems.value = [selectedItems.value[0]];
    }
    return values[0] as T;
  }
}

const selectedItems = useInternalState<T, InternalState>(
  toRef(props, 'modelValue'),
  [],
  (value) => emit('update:modelValue', value),
  onModelValueChanged,
  onSelectedItemsChanged,
  true,
);

watchEffect(async () => {
  if (!selectedItems.value || !props.getById) return;
  for (let i = 0; i < selectedItems.value.length; i++) {
    const tag = selectedItems.value[i];
    if (tag.name === '') {
      const sourceTag = tag.key === null ? null : await props.getById(tag.key);
      if (!sourceTag) continue;
      let newText = props.gettext(sourceTag);
      if (!newText) newText = t('picker.unknown_value');
      selectedItems.value[i] = {
        key: props.getkey(sourceTag),
        name: newText,
        value: sourceTag as UnwrapRef<SingleItem<T>>,
      };
    }
  }
});

const query = ref('');
const onFilterChanged = async (filterText: string): Promise<void> => {
  if (filterText) query.value = filterText;
  else window.setTimeout(() => (query.value = filterText), 500); // Wait a few seconds before emptying to avoid deleting a tag when deleting the last character
  newItem.value = undefined;
  if (!filterText && !props.showEmptySuggestions) {
    filteredItems.value = [];
    return;
  }
  filteredItems.value = null;
  const filter = filterText.toLowerCase();
  try {
    const _items = await props.filter(filter);
    const items: InternalState = _items.map(
      (i): SingleItem<InternalState> => ({
        key: props.getkey(i),
        name: props.gettext(i),
        value: i,
      }),
    );
    newItem.value =
      !props.add ||
      !query.value ||
      items.find((i) => i.name.toLowerCase() == filter)
        ? undefined
        : { key: null, name: filterText };
    filteredItems.value = items.filter(
      (i) => !listContainsItem(i, selectedItems.value as InternalState),
    );
  } catch (err) {
    console.warn('Failed to search:' + err);
    filteredItems.value = [];
  }
};

const removeSelectedItem = (item: ITagBase) => {
  selectedItems.value = selectedItems.value.filter((i) => i.key != item.key);
};

watchEffect(() => {
  if (!filteredItems.value) return;
  const updated = filteredItems.value?.filter(
    (i) => !listContainsItem(i, selectedItems.value),
  );
  if (updated.length !== filteredItems.value.length) {
    filteredItems.value = updated;
  }
});

const showOptions = ref(false);
watch(selectedItems, async (newList, oldList) => {
  if (inputRef.value && newList.length > oldList.length) {
    inputRef.value.el.value = '';
    query.value = '';
    newItem.value = undefined;
    filteredItems.value = [];
  }
  onFilterChanged(query.value); // Make sure to reset filter, both when adding and removing options
  // Below code is for when an option is added. This will create the option, remove the selected item from current selection and add the selected item back (with key returned at option creation).
  if (!props.add) return;
  const addedOption = selectedItems.value?.find((i) => i.key == null);
  if (addedOption) {
    newItem.value = undefined;
    const createdOption = await props.add(addedOption);
    selectedItems.value = selectedItems.value.filter((i) => i.key !== null);
    selectedItems.value.push({
      key: props.getkey(createdOption),
      name: props.gettext(createdOption),
      value: createdOption as UnwrapRef<SingleItem<T>>,
    });
  }
});

const inputRef = ref<{ el: HTMLInputElement }>();
const tagListRef = ref<HTMLDivElement>();
useStepScrolling(32, tagListRef); // Scroll tag list in steps of 2rem
const isFull = computed(() => {
  if (!props.maxItems) return false;
  return selectedItems.value.length >= props.maxItems;
});
onMounted(() => {
  onFilterChanged('');
});
const timeoutId = ref<number | undefined>();
function hideOptionsList() {
  // Some (strange) behaviour in Combobox makes it so that selections does not work if the list is not visible. So we need to wait a little bit after blur before actually hiding it, for selections to work...
  timeoutId.value = window.setTimeout(() => (showOptions.value = false), 250);
  emit('blur');
}
function showOptionsList() {
  if (timeoutId.value) {
    clearTimeout(timeoutId.value);
    timeoutId.value = undefined;
  }
  showOptions.value = true;
  emit('focus');
}
const texts = useDynamicLabelTexts(props);
</script>

<template>
  <label
    :for="texts.forName"
    class="GnistPicker flex flex-col whitespace-nowrap"
  >
    <InputLabel v-bind="props" />
    <Combobox v-model="selectedItems" multiple nullable>
      <div
        :id="texts.forName"
        :name="texts.forName"
        :class="[
          noChrome ? undefined : 'border bg-gnist-white',
          'my-2 flex max-h-full min-h-[2.75rem] flex-wrap items-center text-gnist-black focus-within:outline focus-within:outline-2',
        ]"
        data-combobox
        :data-cy-id="`Picker_${texts.forName}`"
      >
        <div
          ref="tagListRef"
          class="flex min-h-0 max-w-full grow flex-wrap scroll-auto"
          :class="taglistclass"
        >
          <span
            v-for="item in selectedItems"
            :key="item.key ?? 'new_item'"
            class="m-1 mr-0 flex max-w-[calc(100%_-_0.5rem)] items-center p-1 py-px text-sm text-gnist-black"
            :class="{ 'gap-2 border bg-gnist-gray-light-light': !noChrome }"
          >
            <span
              class="shrink overflow-hidden text-ellipsis"
              :title="item.name"
            >
              <slot name="tagItem" :item="item as InternalStateItem">
                {{ item.name }}
              </slot>
            </span>
            <MaterialIcon
              aria-hidden="true"
              class="cursor-pointer self-baseline text-xl leading-none text-gnist-gray hover:bg-gnist-gray-light"
              @click="removeSelectedItem(item)"
            >
              close
            </MaterialIcon>
          </span>
        </div>
        <ComboboxInput
          v-if="!isFull"
          ref="inputRef"
          :display-value="(person) => (person as ITagBase).name"
          class="min-h-0 w-full grow items-center justify-between self-end border-none p-2 pt-1.5 text-gnist-black focus:outline-0"
          :class="{ 'bg-transparent': noChrome }"
          :placeholder="texts.placeholder ? t(texts.placeholder) : undefined"
          data-cy-id="PickerInput"
          @keyup.delete="
            () => {
              if (!query) selectedItems = selectedItems.slice(0, -1);
            }
          "
          @change="async (ev) => await onFilterChanged(ev.target.value)"
          @focus="showOptionsList"
          @blur="hideOptionsList"
        />
        <div v-if="!isFull && maxItems !== 1" class="min-h-0 grow">&nbsp;</div>
      </div>
      <div>
        <ComboboxOptions
          v-show="
            showOptions &&
            (!!newItem || filteredItems == null || filteredItems.length > 0)
          "
          class="absolute inset-x-0 z-30 mx-1 my-1 min-w-max rounded bg-gnist-white shadow-md"
          static
          :data-cy-id="`PickerOptions_${texts.forName}`"
        >
          <span class="block w-full border-b p-1 text-gnist-gray">
            {{ t(suggestedlabel ?? 'picker.suggestions') }}
          </span>
          <div class="p-2">
            <LoadingSpinner v-if="filteredItems === null" class="h-8 w-8" />
            <template v-else>
              <ComboboxOption
                v-if="newItem"
                v-slot="{ active }"
                :value="newItem"
                :data-cy-id="`PickerNewItem_${texts.forName}`"
              >
                <div
                  :class="[
                    'p-2',
                    'text-gnist-black hover:bg-gnist-gray-light-light focus:bg-gnist-gray-light-light',
                    'flex items-center gap-2',
                    active ? 'bg-gnist-gray-light-light' : '',
                  ]"
                >
                  {{ t(addPrefix ?? 'picker.create') }}
                  <slot name="tagOption" :item="newItem">
                    "{{ newItem.name }}"
                  </slot>
                </div>
              </ComboboxOption>
              <ComboboxOption
                v-for="item in filteredItems"
                v-slot="{ active }"
                :key="item.key ?? 'new_item'"
                :value="item"
                :data-cy-value="item.name"
              >
                <div
                  :class="[
                    'p-2',
                    'text-gnist-black hover:bg-gnist-gray-light-light focus:bg-gnist-gray-light-light',
                    'flex items-center gap-2',
                    active ? 'bg-gnist-gray-light-light' : '',
                  ]"
                >
                  <slot name="tagOption" :item="item">
                    {{ item.name }}
                  </slot>
                </div>
              </ComboboxOption>
            </template>
          </div>
        </ComboboxOptions>
      </div>
    </Combobox>
  </label>
</template>
