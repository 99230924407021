import {
  ref,
  type ComputedRef,
  type Ref,
  type WatchSource,
  watch,
  nextTick,
} from 'vue';

export function useValidationTracking(
  validate: WatchSource | object | (WatchSource | object)[],
  triggerFormValidation?: Ref<boolean> | ComputedRef<boolean>,
  deepWatch = false,
  initialState = true,
) {
  const errors = ref<{ [key: string]: boolean }>({});
  const formRef = ref<HTMLFormElement | null>();
  const isValid = ref(initialState);
  const runValidation = async () => {
    await nextTick();
    if (!triggerFormValidation || triggerFormValidation.value) {
      formRef.value?.reportValidity();
    }
    isValid.value =
      (!formRef.value || formRef.value.checkValidity()) &&
      Object.values(errors.value).find((value) => value === true) === undefined;
  };
  watch(validate, () => runValidation(), { deep: deepWatch });
  watch(formRef, () => runValidation(), { deep: deepWatch });
  if (triggerFormValidation) {
    watch(triggerFormValidation, () => runValidation(), { deep: true });
  }
  watch(errors, () => runValidation(), { deep: true });

  function onValidChanged(name: string, isValid: boolean) {
    errors.value[name] = !isValid;
  }
  return { onValidChanged, formRef, isValid };
}
