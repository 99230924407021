<script lang="ts" setup>
import type { PageTemplateProps } from '@/api/types';
import BlockRenderer from '@/components/block/BlockRenderer.vue';
import PageBanner from '@/components/PageBanner.vue';
import BlockDiscussion from '@/components/block/BlockDiscussion.vue';
import { useRoute } from 'vue-router';
import { translateStringOrLocale } from '@/i18n';

const props = defineProps<PageTemplateProps>();
const _title = translateStringOrLocale(() => props.block.name);
const _description = translateStringOrLocale(() => props.block.description);
const route = useRoute();
</script>

<template>
  <div
    v-if="route.meta.fullScreen"
    class="min-h-screen w-full grid-cols-2 items-start justify-center gap-8 p-2 [&_h2]:mb-4"
    :class="[
      `bg-gnist-${bgColor}`,
      fgColor ? `text-gnist-${fgColor}` : undefined,
    ]"
  >
    <BlockRenderer :block="block" />
  </div>
  <template v-else>
    <div class="flex grow flex-col items-center" :class="`bg-gnist-${bgColor}`">
      <div class="mb-2 w-full lg:pt-16">
        <PageBanner
          :title="_title"
          :description="_description"
          :text-color="fgColor"
        />
      </div>

      <!-- Content -->
      <div class="gnist-page-width h-full w-full px-6 lg:px-8">
        <div
          :class="fgColor ? `text-gnist-${fgColor}` : 'text-gnist-black'"
          data-cy-id="contentContainer"
          :data-cy-state="!!block.content ? 'loaded' : 'not_loaded'"
        >
          <BlockRenderer :block="block" class="mb-4" />
        </div>
      </div>
    </div>
    <BlockDiscussion v-if="showDiscussion" :block="block" />
  </template>
</template>
