import { createApp } from 'vue';
import { abilitiesPlugin } from '@casl/vue';

import App from '@/App.vue';
import router, { setupRouter } from '@/router';
import {
  addRouterTitleTranslation,
  loadDynamicLocaleMessages,
  loadLocaleMessages,
  setupI18n,
} from '@/i18n';

import '@/assets/main.css';
import { refreshCurrentUser } from '@/api/auth';
import { defineAbilities } from '@/abilities';
import { registerLanguagesForHighlight } from '@/utilities/templates';
import { DEFAULT_LOCALE, SUPPORTED_LOCALES } from '@/config';
import { menuLocaleData } from '@/api/menu';

registerLanguagesForHighlight();

(async () => {
  console.time('Startup');
  const user = await refreshCurrentUser();
  const abilities = defineAbilities(user.value);
  const i18n = setupI18n();

  const app = createApp(App);
  app.use(abilitiesPlugin, abilities);
  app.use(router);
  app.use(i18n);
  addRouterTitleTranslation(router);
  console.timeLog('Startup', 'mounting');
  app.mount('#app');

  // Load stuff that might take some time
  // make sure messages for the user's current locale are loaded
  const localePromise = loadLocaleMessages(i18n, DEFAULT_LOCALE);
  // load messages for fallback locales
  const fallbackLocalePromises: Promise<void>[] = SUPPORTED_LOCALES.filter(
    (l) => l != DEFAULT_LOCALE,
  ).map((l) => loadLocaleMessages(i18n, l));
  const routerPromise = setupRouter();
  await Promise.all([localePromise, ...fallbackLocalePromises, routerPromise]);
  console.timeLog('Startup', 'routed around');
  loadDynamicLocaleMessages(menuLocaleData, router);
})();
