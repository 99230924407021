<script setup lang="ts">
import { useRouter } from 'vue-router';
import { notFoundRouteName } from '@/config';
import type { LayoutItem, Translated } from '@/api/types';
import { computed } from 'vue';
import type { IBlobUrlInput } from '@/api/blob';
import OptionalRouterLink from '@/components/OptionalRouterLink.vue';
import LayoutContentRenderer, {
  getInnerCssProperties,
  getLayoutClasses,
  getLinkLevelClasses,
  getOuterCssProperties,
  getOuterAndInnerLayoutClasses,
  type AddShrinkGrowData,
} from '@/components/block/LayoutContentRenderer.vue';
import {
  useBlockListConnections,
  type TranslatedConnectionItem,
} from '@/components/BlockListConnectionHelper';
import { toRef } from 'vue';

const props = defineProps<
  {
    item: Translated<LayoutItem>;
    blobLocation: IBlobUrlInput;
    flatList?: TranslatedConnectionItem[];
  } & AddShrinkGrowData
>();

const router = useRouter();
const targetRoute = computed(() => {
  if (!props.item.url) return undefined;
  const target = router.resolve(props.item.url);
  return target.name === notFoundRouteName ? undefined : target;
});

const { onRefCreated, connection, flattened } = useBlockListConnections(
  toRef(props, 'item'),
  toRef(props, 'flatList'),
);
</script>

<template>
  <div
    class="LayoutOuterBlock min-h-0"
    :class="getOuterAndInnerLayoutClasses(item, props)"
    :style="getOuterCssProperties(item)"
  >
    <OptionalRouterLink
      :href="!!targetRoute ? undefined : item.url"
      :to="targetRoute"
      class="LayoutBlock"
      :class="getLayoutClasses(item)"
      :outer-class="getLinkLevelClasses(item)"
      :style="getInnerCssProperties(item)"
    >
      <LayoutContentRenderer
        :item="item"
        :blob-location="blobLocation"
        :on-ref-created="onRefCreated"
        :connection="connection"
      />
      <template v-if="item.children">
        <LayoutRenderer
          v-for="(_, child_index) in item.children"
          :key="item.children[child_index].id"
          :item="item.children[child_index]"
          :blob-location="blobLocation"
          :flat-list="flattened"
          :parent-mode="item.mode"
          :parent-direction="item.direction"
        />
      </template>
    </OptionalRouterLink>
  </div>
</template>
