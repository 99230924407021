<script lang="ts" setup>
import { ref } from 'vue';
import { useRoute } from 'vue-router';
import { useCurrentUser } from '@/api/auth';
import {
  getChatPages,
  suggestionPageIdPrefix,
  suggestionPageParam,
} from '@/api/commentoTypes';
import { useBlockPages } from '@/api/commentoClient';
import ContentPage from '@/components/ContentPage.vue';
import MaterialIcon from '@/components/MaterialIcon.vue';
import { defaultComputed, throwOnError } from '@/api/composable';
import meetingImage from '@/assets/images/meeting.svg';
import { defaultBlocksRouteName } from '@/utilities/routeUtils';
import { useI18n } from 'vue-i18n';
import { translateStringOrLocale } from '@/i18n';
import { useSuggestionPages } from '@/api/suggestion';
import { socialPageRouteId } from '@/config';

const { t } = useI18n();

const userInfo = useCurrentUser();
const { result: blockPages } = throwOnError(useBlockPages());
const blockPagesWithDefault = defaultComputed(blockPages, []);
const { result: suggestionPages } = throwOnError(useSuggestionPages());
const suggestionPagesWithDefault = defaultComputed(suggestionPages, []);
const chatPages = getChatPages();
const route = useRoute();
const checked = ref(false);
</script>

<template>
  <ContentPage :title="t('social.title')" :hide-banner="true">
    <template #list>
      <div class="drawer lg:drawer-open">
        <input
          id="my-drawer"
          v-model="checked"
          type="checkbox"
          class="drawer-toggle"
          tabindex="-1"
        />

        <!-- Sidebar -->
        <div class="drawer-side z-40 lg:z-auto">
          <label for="my-drawer" class="drawer-overlay" />
          <nav
            class="flex h-full w-80 flex-col overflow-y-auto bg-gnist-gray-light-light px-2 py-4 pt-8 text-gnist-blue-dark md:overflow-y-visible"
            :aria-label="t('social.menu')"
            role="navigation"
          >
            <div class="text-h2 absolute right-2 top-2 lg:hidden">
              <button @click="() => (checked = !checked)">
                <MaterialIcon>close</MaterialIcon>
              </button>
            </div>
            <h1 class="my-2 hidden px-4 text-gnist-blue-dark lg:block">
              {{ t('social.title') }}
            </h1>
            <ul class="mt-8 flex flex-col">
              <li class="flex flex-col">
                <RouterLink
                  v-if="userInfo"
                  :to="{ name: 'threads' }"
                  class="rounded-huge px-4 py-2 text-lg hover:cursor-pointer hover:bg-gnist-gray-light"
                  :class="
                    route.name === 'threads' ? 'text-gnist-black underline' : ''
                  "
                  @click="() => (checked = false)"
                >
                  <MaterialIcon class="no-underline" aria-hidden="true">
                    face
                  </MaterialIcon>
                  <p class="inline pl-3">
                    {{ t('social.myThreads') }}
                  </p>
                </RouterLink>
              </li>
              <li class="mt-8">
                <h2
                  class="mb-2 cursor-auto select-text px-4 hover:bg-inherit focus:bg-inherit active:bg-inherit"
                >
                  {{ t('social.forum') }}
                </h2>
                <ul class="flex flex-col">
                  <li
                    v-for="chatPage in chatPages"
                    :key="chatPage.id"
                    class="flex flex-col"
                  >
                    <RouterLink
                      class="rounded-huge px-4 py-2 text-lg hover:cursor-pointer hover:bg-gnist-gray-light"
                      :class="
                        route.params.pageId === chatPage.id
                          ? 'text-gnist-black underline'
                          : ''
                      "
                      :to="{
                        name: socialPageRouteId,
                        params: { pageId: chatPage.id },
                      }"
                      @click="() => (checked = false)"
                    >
                      <MaterialIcon class="no-underline" aria-hidden="true">
                        tag
                      </MaterialIcon>
                      <p class="inline pl-2">
                        {{ t(chatPage.chatPageName) }}
                      </p>
                    </RouterLink>
                  </li>
                </ul>
              </li>
              <li class="mt-8">
                <h2
                  class="mb-2 cursor-auto select-text px-4 hover:bg-inherit focus:bg-inherit active:bg-inherit"
                >
                  {{ t('social.documents') }}
                </h2>
                <ul class="flex flex-col">
                  <li
                    v-for="blockPage in blockPagesWithDefault"
                    :key="blockPage.blockId"
                    class="flex flex-col"
                  >
                    <RouterLink
                      class="rounded-huge px-4 py-2 text-lg hover:cursor-pointer hover:bg-gnist-gray-light"
                      :class="
                        route.params.pageId === defaultBlocksRouteName &&
                        route.params.blockId === blockPage.blockId.toString()
                          ? 'text-gnist-black underline'
                          : ''
                      "
                      :to="{
                        name: socialPageRouteId,
                        params: {
                          pageId: defaultBlocksRouteName,
                          blockId: blockPage.blockId,
                        },
                      }"
                      @click="() => (checked = false)"
                    >
                      <MaterialIcon class="no-underline" aria-hidden="true">
                        tag
                      </MaterialIcon>
                      <p class="inline pl-2">
                        {{ translateStringOrLocale(blockPage.name) ?? '' }}
                      </p>
                    </RouterLink>
                  </li>
                </ul>
              </li>
              <li class="mt-8">
                <h2
                  class="mb-2 cursor-auto select-text px-4 hover:bg-inherit focus:bg-inherit active:bg-inherit"
                >
                  {{ t('social.suggestions') }}
                </h2>
                <ul class="flex flex-col">
                  <li
                    v-for="suggestionPage in suggestionPagesWithDefault"
                    :key="suggestionPage.suggestionId"
                    class="flex flex-col"
                  >
                    <RouterLink
                      class="rounded-huge px-4 py-2 text-lg hover:cursor-pointer hover:bg-gnist-gray-light"
                      :class="
                        route.params.pageId === suggestionPageParam &&
                        route.params.discussionId ===
                          suggestionPage.suggestionId.toString()
                          ? 'text-gnist-black underline'
                          : ''
                      "
                      :to="{
                        name: socialPageRouteId + suggestionPageParam,
                        params: {
                          pageId: suggestionPageParam,
                          discussionId:
                            suggestionPageIdPrefix +
                            suggestionPage.suggestionId,
                        },
                      }"
                      @click="() => (checked = false)"
                    >
                      <MaterialIcon class="no-underline" aria-hidden="true">
                        tag
                      </MaterialIcon>
                      <p class="inline pl-2">
                        {{ translateStringOrLocale(suggestionPage.name) ?? '' }}
                      </p>
                    </RouterLink>
                  </li>
                </ul>
              </li>
            </ul>
            <div
              class="hidden w-full grow flex-col items-center justify-end p-4 md:flex"
            >
              <img :src="meetingImage" alt="" class="w-full max-w-[200px]" />
            </div>
          </nav>
        </div>
        <!-- Page content -->
        <div class="drawer-content bg-gnist-white p-8 text-gnist-black">
          <label
            for="my-drawer"
            class="text-a1 drawer-button my-8 p-2 hover:cursor-pointer lg:hidden"
            >&lt; {{ t('social.showChannels') }}</label
          >
          <RouterView />
        </div>
      </div>
    </template>
  </ContentPage>
</template>

<style scoped>
a {
  cursor: pointer;
}

.drawer-toggle ~ .drawer-side {
  max-height: 100% !important;
}

.drawer-toggle:checked ~ .drawer-side {
  overflow-y: unset;
}

.drawer-toggle ~ .drawer-content {
  overflow-y: unset;
}

:where(.drawer-toggle ~ .drawer-content) {
  height: fit-content;
}
</style>
