<script lang="ts" setup>
import { type PageTemplateProps } from '@/api/types';
import BlockPageBanner from '@/components/block/BlockPageBanner.vue';
import BlockRenderer from '@/components/block/BlockRenderer.vue';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import TagListGroup from '@/components/TagListGroup.vue';
import { useOrganization } from '@/api/organization';
import { throwOnError } from '@/api/composable';
import { translateStringOrLocale } from '@/i18n';
import { getDocumentRouteFromListRoute } from '@/utilities/routeUtils';

const { t } = useI18n();

const props = defineProps<PageTemplateProps>();
const containers = ref<{ idx: number; el: HTMLElement }[]>([]);

const routeName = getDocumentRouteFromListRoute();
const orgId = props.block.ownerOrg?.organizationId ?? '';
const { result: organizationData } = throwOnError(useOrganization(orgId, true));
</script>

<template>
  <div class="px-4 py-12 sm:px-6 lg:px-8" :class="[`bg-gnist-${bgColor}`]">
    <div class="max-w-screen-md sm:max-w-2xl md:max-w-4xl lg:max-w-5xl">
      <BlockPageBanner
        :title="block.name ?? ''"
        :block="block"
        :tags="block.tags ?? []"
        :class="[`bg-gnist-${bgColor}`]"
        :is-preview="!!isPreview"
        :no-left-margin="true"
        :right-margin="false"
      >
        <template #description>
          <div
            v-for="block in organizationData?.blocks.map((b) => b)"
            :key="block.blockId"
          >
            <RouterLink
              v-if="
                block.category &&
                translateStringOrLocale(block.category.name).value ==
                  t(`supplierPage.supplier`)
              "
              :to="{ name: routeName, params: { blockId: block.blockId } }"
            >
              <div class="flex gap-1">
                <p>{{ t('productPage.suppliedBy') }}</p>
                <p class="underline">
                  {{ translateStringOrLocale(block.name).value }}
                </p>
              </div>
            </RouterLink>
          </div>
        </template>
      </BlockPageBanner>

      <TagListGroup
        v-if="block.category"
        class="ml-6"
        :category-name="block.category.name.no"
        :tags="block.tags"
      />

      <div
        class="mx-4 mt-6 md:mt-12"
        data-cy-id="contentContainer"
        :data-cy-state="!!block.content ? 'loaded' : 'not_loaded'"
      >
        <BlockRenderer
          v-model:containers="containers"
          :block="block"
          class="mt-8"
        />
      </div>
    </div>
  </div>
</template>
