<script setup lang="ts">
import type { ApiSchema } from '@/api/types';
import { ref, watchEffect } from 'vue';
import MaterialIcon from './MaterialIcon.vue';
import JsonCodeBlock from './JsonCodeBlock.vue';
import { useI18n } from 'vue-i18n';
import TableComponent from './TableComponent.vue';

const { t } = useI18n();

const showAs = ref<'table' | 'json'>('table');
const hideTable = ref<boolean>(false);
const flattenedSchema = ref<FlattenProperty[]>();

const props = defineProps<{
  schema: ApiSchema;
}>();

type Property = SimpleProperty | ArrayProperty | ObjectProperty;

type SimpleProperty = {
  type: string;
  description?: string;
  xApimInline?: boolean;
};

type ObjectProperty = {
  type: 'object';
  properties: {
    [key: string]: Property;
  };
  required?: string[];
  example?: [];
  xApimInline?: boolean;
};

type ArrayProperty = {
  type: 'array';
  items: Property;
};

type FlattenProperty = {
  path: string;
  required: boolean;
  type: string;
  description?: string;
};

function isArrayProperty(property: Property): property is ArrayProperty {
  return property.type === 'array';
}

function isObjectProperty(property: Property): property is ObjectProperty {
  return property.type === 'object';
}

function flatten(
  property: Property,
  prefix = '',
  required = false,
): FlattenProperty[] {
  let result: FlattenProperty[] = [];
  if (isObjectProperty(property)) {
    for (const key in property.properties) {
      const newPrefix = prefix ? `${prefix}.${key}` : key;
      const childRequired = property.required?.includes(key) || false;
      const childResult = flatten(
        property.properties[key],
        newPrefix,
        childRequired,
      );
      result = result.concat(childResult);
    }
  } else if (isArrayProperty(property)) {
    if (property.items.type === 'object' || property.items.type === 'array') {
      const childResult = flatten(property.items, `${prefix}[]`);
      result = result.concat(childResult);
    } else {
      result.push({
        path: prefix,
        required: required,
        type: property.items.type + '[]',
      });
    }
  } else {
    result.push({
      path: prefix,
      required: required,
      type: property.type,
      description: property.description ?? undefined,
    });
  }
  return result;
}

function removeExample(schema: ApiSchema) {
  const newSchema: Property = { ...schema };
  if (isObjectProperty(newSchema) && newSchema.example) {
    newSchema.example = undefined;
  }
  return newSchema;
}

watchEffect(async () => {
  flattenedSchema.value = flatten(props.schema);

  if (flattenedSchema.value.length == 0) {
    hideTable.value = true;
    showAs.value = 'json';
  }
});
</script>
<template>
  <div v-show="!hideTable" class="mb-4 flex">
    <button
      class="gnist-icon-button gnist-button mr-2"
      :class="{
        'gnist-button-primary gnist-button-primary bg-gnist-sp-blue-dark':
          showAs === 'table',
      }"
      :title="t('block.tableView')"
      @click="() => (showAs = 'table')"
    >
      <MaterialIcon aria-hidden="true"> format_list_bulleted </MaterialIcon>
    </button>
    <button
      class="gnist-icon-button gnist-button"
      :class="{
        'gnist-button-primary gnist-button-primary bg-gnist-sp-blue-dark':
          showAs === 'json',
      }"
      :title="t('block.rawView')"
      @click="() => (showAs = 'json')"
    >
      <MaterialIcon aria-hidden="true"> data_object </MaterialIcon>
    </button>
  </div>
  <div v-if="showAs === 'json'" class="SizeLimitSchema">
    <JsonCodeBlock :content="removeExample(schema)" />
  </div>
  <TableComponent
    v-else-if="showAs === 'table'"
    class="SizeLimitSchema max-h-96 border"
    no-zebra
    column-header-class="font-normal"
    :rows="flattenedSchema"
    i18n-key="block.response"
    :get-key="(line) => line.path"
    :columns="['path', 'required', 'type', 'description']"
  >
    <template #columnHeader="{ item: line }">
      {{ line.path }}
    </template>
    <template #columns="{ item: line }">
      <td>
        {{ line.required }}
      </td>
      <td>
        {{ line.type }}
      </td>
      <td class="p-2">
        {{ line.description ?? '' }}
      </td>
    </template>
  </TableComponent>
</template>
<style scoped>
td:first-child {
  line-break: anywhere;
}
/* For some reason the tab component sometimes fails to calculate the correct width for the tabs,
   if the content is too big and has not a fixed sixe (e.g. 90% won't work even if it actually limits the size).
   Thus this complicated calculation to limit the size. */
.SizeLimitSchema {
  --extra-padding-for-good-measure: 3rem;
  max-width: calc(
    var(--main-content-width) - var(--api-details-padding) -
      var(--representations-tab-padding) - var(--example-tab-padding) -
      var(--extra-padding-for-good-measure)
  );
  @apply xl:max-w-[45rem];
}
</style>
