<script setup lang="ts" generic="T = string">
import { batRoleTypes, type AlzSystem, type CustomProduct } from '@/api/types';
import TextInput from '@/components/forms/InputText.vue';
import { useNestedMV } from '@/utilities/useInternalState';
import { useI18n } from 'vue-i18n';
import TableComponent from './TableComponent.vue';
import Checkbox from './forms/InputCheckbox.vue';
import ButtonComponent from './ButtonComponent.vue';
import Dropdown, { toDropdownItems } from './forms/InputDropdown.vue';
import { computed, ref, watch } from 'vue';
import { mapValueList } from '@/utilities/mapValueList';
import { assertDefined } from '@/utilities/debugUtils';
import { translateStringOrLocale } from '@/i18n';
import { standardGithubTeams, standardLzRoles } from '@/api/masterdata';
import type { allFieldSets } from '@/api/field_labels';
import type { IInputLabelProps } from './forms/InputLabel.vue';
import { useTeamGroupsForSystem } from '@/api/github';

const props = defineProps<{
  modelValue: AlzSystem;
  getDynamicLabelProps: <
    TGroup extends keyof typeof allFieldSets,
    TKey extends keyof (typeof allFieldSets)[TGroup]['fields'],
  >(
    group: TGroup,
    key: TKey,
  ) => Pick<IInputLabelProps, 'dynamicTextKey' | 'mdDescription' | 'link'>;
}>();
const emit = defineEmits<{
  (e: 'update:modelValue', value: typeof props.modelValue): void;
}>();

const { t } = useI18n();

const value = useNestedMV(props, (val) => emit('update:modelValue', val));

const systemId = computed(() => value.value.systemId);

const { result: systemTeamGroups } = useTeamGroupsForSystem(systemId);

const environmentList = computed(() =>
  toDropdownItems(
    value.value.environments ?? [],
    (c) => [c.fileIdentifier, c.name],
    true,
  ),
);
const repoList = computed(() =>
  toDropdownItems(value.value.repos ?? [], (c) => [c.name, c.name], true),
);

const lzRoleOptions = toDropdownItems(
  standardLzRoles.result.value,
  (role) => [role.key, translateStringOrLocale(role.text).value],
  true,
);
const ghTeamOptions = computed(() =>
  Object.fromEntries(
    Object.entries(systemTeamGroups.value ?? {}).map(([repo, group]) => [
      repo,
      toDropdownItems(
        standardGithubTeams.result.value?.map((team) => `${group}-${team.key}`),
        (team) => [team, team],
      ),
    ]),
  ),
);

type EnvironmentRoleData = {
  name: string;
  roles: { name: string; canOrder: boolean }[];
};
const envRoles = ref<EnvironmentRoleData[]>([]);
function change(envName: string, role: string, canOrder: boolean) {
  const env = value.value.environments.find((e) => e.name === envName);
  if (!assertDefined(env)) return;
  env.orderRoles ??= [];
  if (canOrder && !env.orderRoles.find((r) => r === role)) {
    env.orderRoles.push(role);
  } else if (!canOrder) {
    env.orderRoles = env.orderRoles.filter((r) => r !== role);
  }
}
watch(
  () => value.value,
  () => {
    const newList: EnvironmentRoleData[] = [];
    for (const env of value.value.environments) {
      let data = envRoles.value.find((e) => e.name === env.name);
      data ??= {
        name: env.name,
        roles: [],
      };
      data.roles =
        standardLzRoles.result.value?.map((role) => ({
          name: role.key,
          canOrder: !!env.orderRoles?.find((r) => r === role.key),
        })) ?? [];
      newList.push(data);
    }
    envRoles.value = newList;
  },
  { deep: true, immediate: true },
);
const roleTypeOptions = mapValueList(
  batRoleTypes,
  'orders.bat.fields.productColumns.type',
  true,
);
watch(
  value.value,
  () => {
    if (value.value.customProducts.length > 0) return;
    value.value.customProducts.push({
      title: 'Batprodukt#1',
      roles: [
        {
          type: 'lz_role',
          roleId: 'reader',
          objectId: 'prediksjonbemanningakuttmottakdev.json',
        },
        {
          type: 'lz_role',
          roleId: 'contributor',
          objectId: 'prediksjonbemanningakuttmottakdev.json',
        },
        {
          type: 'lz_role',
          roleId: 'reader',
          objectId: 'prediksjonbemanningakuttmottaktest.json',
        },
      ],
    });
  },
  { immediate: true },
);

function deleteCustomProduct(product: CustomProduct) {
  const index = value.value.customProducts.indexOf(product);
  if (index === -1) {
    console.error(`Failed to find index of ${product.title}`);
    return;
  }
  value.value.customProducts.splice(index, 1);
}
</script>

<template>
  <div class="alert alert-warning">
    DETTE ER EN PROTOTYPE OG HAR INGEN STØTTE I BACKEND!
  </div>
  <div class="mt-8 flex gap-32 bg-gnist-gray-light p-4">
    <div class="flex flex-col gap-8">
      <h3 class="whitespace-nowrap">{{ t('orders.bat.roleProducts') }}</h3>
      <div
        v-for="(lz, idx) in envRoles"
        :key="idx"
        class="border border-black p-2"
      >
        <h4 class="my-2 mb-4">
          {{ t('orders.bat.standardRoles') }}
          {{ lz.name.toUpperCase() }}
        </h4>
        <TableComponent
          :rows="lz.roles"
          :get-key="(row) => row.name"
          i18n-key="orders.bat.fields.roleColumns"
          uses-dynamic-text-keys
          :columns="['name', 'canOrder']"
        >
          <template #columns="{ item }">
            <td>
              {{ item.name }}
            </td>
            <td>
              <Checkbox
                :model-value="item.canOrder"
                v-bind="
                  getDynamicLabelProps('batFieldKeys', 'roleColumns.canOrder')
                "
                direction="vertical"
                mode="toggle"
                sr-only
                @update:model-value="(val) => change(lz.name, item.name, val)"
              />
            </td>
          </template>
        </TableComponent>
      </div>
    </div>
    <div class="flex flex-col gap-8">
      <h3>{{ t('orders.bat.customProducts') }}</h3>
      <ButtonComponent
        text="orders.bat.buttons.values.addProduct"
        class="self-start"
        @click="value.customProducts.push({ title: '', roles: [] })"
      />
      <div
        v-for="(product, idx) in value.customProducts"
        :key="idx"
        class="border border-black p-2"
      >
        <ButtonComponent
          icon="delete"
          text="orders.bat.buttons.values.deleteProduct"
          class="absolute right-0 z-10"
          type="danger"
          @click="deleteCustomProduct(product)"
        />

        <TextInput
          v-model="product.title"
          v-bind="getDynamicLabelProps('batFieldKeys', 'customProduct')"
          required
        />
        <TableComponent
          :rows="product.roles"
          :get-key="(row) => `${row.objectId}.${row.roleId}`"
          i18n-key="orders.bat.fields.productColumns"
          uses-dynamic-text-keys
          :columns="['type', 'objectId', 'roleId']"
        >
          <template #columns="{ item }">
            <td>
              <Dropdown
                v-model="item.type"
                v-bind="
                  getDynamicLabelProps('batFieldKeys', 'productColumns.type')
                "
                :options="roleTypeOptions"
                :getkey="(t) => t"
                required
                sr-only
              />
            </td>
            <td>
              <Dropdown
                v-model="item.objectId"
                v-bind="
                  getDynamicLabelProps(
                    'batFieldKeys',
                    'productColumns.objectId',
                  )
                "
                :options="item.type === 'lz_role' ? environmentList : repoList"
                :getkey="(t) => t"
                required
                sr-only
              />
            </td>
            <td>
              <Dropdown
                v-if="item.objectId"
                v-model="item.roleId"
                v-bind="
                  getDynamicLabelProps('batFieldKeys', 'productColumns.roleId')
                "
                :options="
                  item.type === 'lz_role'
                    ? lzRoleOptions
                    : (ghTeamOptions[item.objectId] ?? [])
                "
                :getkey="(t) => t"
                required
                sr-only
              />
            </td>
          </template>
        </TableComponent>
      </div>
    </div>
  </div>
</template>
