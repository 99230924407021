import 'vue-router';
declare module 'vue-router' {
  interface RouteMeta {
    title: string;
    /** Used by blocks view */
    titleTranslations?: localeValue;
    translationid?: string;
    hideSearchBar?: boolean;
    targetDoc?: Guid;
    isDocsListRoute?: boolean;
    fullScreen?: boolean;
    customOptions?: RouteCustomOptions;
  }
}

import {
  createRouter,
  createWebHistory,
  type RouteRecordRaw,
} from 'vue-router';
import {
  hardcodedStaticRoutes,
  hasOnlyOptionalParamsRe,
  setRouter,
  setStaticRoutes,
} from './routeUtils';
import ProfileView from '../views/ProfileView.vue';
import FavouriteView from '../views/FavouriteView.vue';
import SocialView from '@/views/SocialView.vue';
import SocialThreadView from '@/views/SocialThreadView.vue';
import ModeratorView from '@/views/ModeratorView.vue';
import SocialPageView from '@/views/SocialPageView.vue';
import AnalyticsView from '@/views/AnalyticsView.vue';
import MyOrganizationsView from '@/views/MyOrganizationsView.vue';
import SuggestionsListView from '@/views/SuggestionsListView.vue';
import SuggestionDetailsView from '@/views/SuggestionDetailsView.vue';
import SuggestionEditView from '@/views/SuggestionEditView.vue';
import MasterDataEditorView from '@/views/MasterDataEditorView.vue';
import AlzOrderView from '@/views/AlzOrderView.vue';
import AlzMySystemsView from '@/views/AlzMySystemsView.vue';

import AccountView from '../views/AccountView.vue';
import SubscriptionsView from '@/views/SubscriptionsView.vue';
import ApimProductsView from '@/views/ApimProductsView.vue';
import NewsView from '@/views/NewsView.vue';
import LoadingView from '@/views/LoadingView.vue';
import NotFoundView from '@/views/Errors/NotFoundView.vue';
import NotificationsView from '@/views/NotificationsView.vue';

import AdminView from '@/views/AdminView.vue';
import BlockAdminView from '@/views/BlockAdminView.vue';
import MyDocumentsView from '@/views/MyDocumentsView.vue';
import BlockDetailsEditorView from '@/views/BlockDetailsEditorView.vue';
import BlockVersionsView from '@/views/BlockVersionsView.vue';
import UserAdministrationView from '@/views/UserAdministration.vue';
import RouteAdministrationView from '@/views/RouteAdministration.vue';
import CategoryAdministrationView from '@/views/CategoryAdministration.vue';

import ApiConsoleView from '@/views/ApiConsoleView.vue';
import ApiMAnalyticsView from '@/views/ApiMAnalyticsView.vue';
import ViewCountAnalyticsview from '@/views/ViewCountAnalyticsview.vue';
import ContentAnalyticsView from '@/views/ContentAnalyticsView.vue';
import OrganizationAdministrationView from '@/views/OrganizationAdministrationView.vue';
import { getRoute, getRouteItems } from '@/utilities/routeUtils';
import type { Guid, localeValue, RouteCustomOptions } from '@/api/types';
import TokenReceiver from '@/views/TokenReceiver.vue';
import { suggestionPageParam } from '@/api/commentoTypes';
import AboutRichtextView from '@/views/Documentation/AboutRichtextView.vue';
import MarkdownDocumentationView from '@/views/Documentation/MarkdownDocumentationView.vue';
import { setSocialPageBasePath } from '@/enums/SocialPageType';
import {
  apimproductsRouteId,
  editBlocksRouteId,
  editBlockVersionsRouteId,
  notFoundRouteName,
  socialPageRouteId,
  mySubscriptionsRouteId,
} from '@/config';
import TagsEditorView from '@/views/TagsEditorView.vue';
import TagGroupEditorView from '@/views/TagGroupEditorView.vue';

// these are dynamically loaded since they otherwise increase the main bundle
// size a lot
const BlockView = () => import('../views/BlockView.vue');
const BlockContentEditorView = () =>
  import('@/views/BlockContentEditorView.vue');

const routes: RouteRecordRaw[] = [
  {
    path: '/social',
    name: 'social',
    meta: { title: 'header.items.social' },
    component: SocialView,
    children: [
      {
        path: 'threads',
        name: 'threads',
        component: SocialThreadView,
      },
      {
        path: '',
        name: 'social_startpage',
        meta: { title: 'header.items.socialStartpage' },
        component: SocialPageView,
        props: true,
      },
      {
        path: 'page/:pageId/:blockId?/:commentId?',
        name: socialPageRouteId,
        component: SocialPageView,
        props: true,
      },
      {
        path: `page/:pageId(${suggestionPageParam})/:discussionId?/:commentId?`,
        name: socialPageRouteId + suggestionPageParam,
        component: SocialPageView,
        props: true,
      },
    ],
  },
  {
    path: '/usermanual',
    meta: { title: 'header.items.usermanual.rich_text' },
    children: [
      {
        path: 'rich_text',
        component: AboutRichtextView,
      },
      {
        path: ':filename',
        component: MarkdownDocumentationView,
      },
    ],
  },
  {
    path: '/social/moderator',
    name: 'social_moderator',
    meta: { title: 'header.items.moderator' },
    component: ModeratorView,
  },
  {
    path: '/admin',
    meta: {
      title: 'admin.blockProduction.title',
      cardTemplateId: 'searchCard',
    },
    children: [
      {
        path: '',
        name: editBlocksRouteId,
        component: AdminView,
        alias: [''],
      },
      {
        path: 'editor_management',
        name: 'editor_management',
        meta: {
          title: 'admin.management.overviewTitle',
          cardTemplateId: 'searchCard',
        },
        component: BlockAdminView,
      },
      {
        path: 'my_documents',
        name: 'owner_management',
        meta: { title: 'admin.management.mydocsTitle' },
        component: MyDocumentsView,
      },
      {
        path: 'block',
        name: 'new_block',
        component: BlockDetailsEditorView,
      },
      {
        path: 'block/:blockId/details',
        name: 'edit_block',
        meta: { title: 'admin.blockProduction.detailsTitle' },
        component: BlockDetailsEditorView,
      },
      {
        path: 'block/:blockId/versions',
        name: editBlockVersionsRouteId,
        meta: { title: 'admin.blockProduction.versionsTitle' },
        component: BlockVersionsView,
      },
      {
        path: 'block/:blockId/versions/:versionId',
        name: 'edit_version',
        component: BlockContentEditorView,
      },
    ],
  },
  {
    path: '/users',
    name: 'userAdmin',
    meta: { title: 'header.items.userAdmin' },
    component: UserAdministrationView,
  },
  {
    path: '/routes',
    name: 'route_edit',
    component: RouteAdministrationView,
    meta: { title: 'routeAdmin.title' },
  },
  {
    path: '/categories',
    name: 'category_edit',
    component: CategoryAdministrationView,
    meta: { title: 'categoryAdmin.title' },
  },
  {
    path: '/tags',
    children: [
      {
        path: 'edit',
        name: 'tags_edit',
        meta: { title: 'tagEditor.title' },
        component: TagsEditorView,
      },
      {
        path: 'tagGroups',
        name: 'tagGroup_edit',
        meta: { title: 'tagEditor.tagGroupTitle' },
        component: TagGroupEditorView,
      },
    ],
  },
  {
    path: '/analytics',
    name: 'analytics',
    meta: { title: 'header.items.analytics' },
    component: AnalyticsView,
    children: [
      {
        path: '',
        meta: { title: 'analytics.siteUsage' },
        name: 'viewCount',
        component: ViewCountAnalyticsview,
      },
      {
        path: 'apim',
        meta: { title: 'analytics.apiM' },
        name: 'apiM',
        component: ApiMAnalyticsView,
      },
      {
        path: 'content',
        meta: { title: 'analytics.siteContent' },
        name: 'siteContent',
        component: ContentAnalyticsView,
      },
    ],
  },
  {
    path: '/profile',
    component: ProfileView,
    children: [
      {
        path: '',
        meta: { title: 'header.items.mypage' },
        name: 'profile',
        component: AccountView,
      },
      {
        path: 'favourites',
        meta: { title: 'profile.favorite' },
        name: 'favourites',
        component: FavouriteView,
      },
      {
        path: 'subscriptions',
        meta: { title: 'profile.subscriptions' },
        name: mySubscriptionsRouteId,
        component: SubscriptionsView,
      },
    ],
  },
  {
    path: '/apimproducts',
    name: apimproductsRouteId,
    component: ApimProductsView,
    meta: { title: 'apimproducts.title' },
  },
  {
    path: '/notifications',
    name: 'notifications',
    component: NotificationsView,
    meta: { title: 'header.items.notifications' },
  },
  {
    path: '/console',
    meta: { title: 'header.items.console' },
    name: 'apiconsole',
    children: [
      {
        path: 'block/:blockId/:selectedOperation',
        meta: { title: 'header.items.console' },
        name: 'console',
        component: ApiConsoleView,
      },
    ],
  },
  {
    path: '/news/:articleId?',
    meta: { title: 'header.items.news' },
    name: 'news',
    component: NewsView,
  },
  {
    path: '/organizations',
    meta: { title: 'header.items.myOrgs' },
    name: 'myorganizations',
    component: MyOrganizationsView,
  },
  {
    path: '/organization/:organizationId',
    meta: { title: 'header.items.org' },
    name: 'organization',
    component: OrganizationAdministrationView,
  },
  {
    path: '/suggestions', // Used by email templates, don't change unless templates are also changed
    meta: { title: 'header.items.suggestions' },
    name: 'suggestion_list',
    component: SuggestionsListView,
  },
  {
    path: '/suggestions/edit/:suggestionId',
    meta: { title: 'header.items.suggestions' },
    name: 'suggestion_edit',
    component: SuggestionEditView,
  },
  {
    path: '/suggestions/add/:type?',
    meta: { title: 'header.items.suggestions' },
    name: 'suggestion_add',
    component: SuggestionEditView,
  },
  {
    path: '/suggestions/:suggestionId',
    name: 'suggestion_details',
    component: SuggestionDetailsView,
  },
  {
    path: '/masterdata',
    children: [
      {
        path: 'edit',
        meta: { title: 'header.items.masterdataEdit' },
        name: 'masterdata_edit',
        component: MasterDataEditorView,
      },
    ],
  },
  {
    path: '/orders/alz',
    children: [
      {
        path: '',
        meta: { title: 'header.items.orders.my_alz_applications' },
        name: 'my_alz_applications',
        component: AlzMySystemsView,
      },
      {
        path: 'application_form/:systemId?',
        meta: { title: 'header.items.orders.alz_order_form' },
        name: 'alz_order_form',
        component: AlzOrderView,
      },
    ],
  },
];
setStaticRoutes(routes);

export async function setupRouter() {
  const routeItems = await getRouteItems();
  // Update hardcoded routes
  for (const route of hardcodedStaticRoutes) {
    if (!route.name) continue; // If the route has no name, we should not try to match it against routeItems
    const item = routeItems.find((r) => r.routeBaseName === route.name);
    if (!item) continue;

    route.meta = { ...route.meta, ...item.meta, title: item.text };
    route.alias = [route.path]
      .concat(item.aliases ?? [])
      .concat(route.alias ?? []);
    const optionalParam = route.path.match(hasOnlyOptionalParamsRe)?.[1]; // This only captures the last param, if there are routes with multiple we need another solution
    route.fullPath = item.targetPath + route.fullPath;
    item.targetPath = route.fullPath.replace(optionalParam ?? '', '');
  }
  // Add page routes
  routes.push(
    ...routeItems
      .filter((i) => i.routeType == 'page')
      .map((s): RouteRecordRaw[] => [
        getRoute(s, BlockView, { addAliases: true }),
      ])
      .flat(),
  );
  // Add block routes
  routes.push(
    ...routeItems
      .filter((i) => i.isDocsListRoute)
      .map((s) => [
        getRoute(s, BlockView, {
          isListRoute: true,
          meta: { hideSearchBar: true, isDocsListRoute: true },
        }),
        getRoute(s, BlockView, { pathSuffix: '/:blockId' }),
      ])
      .flat(),
  );

  router.removeRoute(notFoundRouteName);
  router.addRoute({
    path: '/:pathMatch(.*)*',
    name: notFoundRouteName,
    meta: { title: 'header.items.notFound' },
    component: NotFoundView,
  });
  router.addRoute({
    path: '/token_reply',
    name: 'token',
    meta: { title: 'header.items.token' },
    component: TokenReceiver,
  });
  for (const route of routes) {
    router.addRoute(route);
  }
  setSocialPageBasePath(
    router
      .resolve({ name: socialPageRouteId, params: { pageId: 'marker' } })
      .fullPath.replace('/marker', ''),
  );
  await router.isReady();
  setRouter(router);
  router.replace(router.currentRoute.value.fullPath);
  const finalRoutes = router.getRoutes();
  const missingRoutes = routeItems.filter((i) => {
    if (i.routeType !== 'hardcoded') return false;
    return !finalRoutes.find((r) => r.name === i.routeBaseName);
  });
  if (missingRoutes.length > 0) {
    console.warn(
      'The following routes points to hardcoded routes that could not be found in the list of final routes',
      missingRoutes,
    );
  }
}
const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/:pathMatch(.*)*',
      name: notFoundRouteName,
      meta: { title: 'Laster...' },
      component: LoadingView,
    },
  ],
  scrollBehavior(_to, _from, savedPosition) {
    if (_to.hash) {
      return {
        el: _to.hash,
      };
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

export default router;
