<script lang="ts" setup>
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const showHelp = ref(false);
</script>
<template>
  <div class="border-1 border-solid border-black">
    <button
      class="discussion-markdown-button"
      type="button"
      @click="() => (showHelp = !showHelp)"
    >
      <b>M ↓</b> &nbsp; Markdown
    </button>
    <div
      v-if="showHelp"
      class="discussion-markdown-help grid grid-cols-2 grid-cols-[max-content_auto] gap-x-8 border p-2"
    >
      <div>
        <i>{{ t('discussion.markdown.italics') }}</i>
      </div>
      <div>
        {{ t('discussion.markdown.surroundText') }}
        <pre>*{{ t('discussion.markdown.asterisks') }}*</pre>
      </div>

      <div>
        <b>{{ t('discussion.markdown.bold') }}</b>
      </div>
      <div>
        {{ t('discussion.markdown.surroundText') }}
        <pre>**{{ t('discussion.markdown.doubleAsterisks') }}**</pre>
      </div>

      <div>
        <a href="https://example.com" class="underline">{{
          t('discussion.markdown.hyperlink')
        }}</a>
      </div>
      <div>
        <pre>[hyperlink](https://example.com)</pre>
        {{ t('discussion.markdown.bareUrl') }}
      </div>

      <div>
        <pre>{{ t('discussion.markdown.code') }}</pre>
      </div>
      <div>
        {{ t('discussion.markdown.surroundText') }}
        <pre>`{{ t('discussion.markdown.backticks') }}`</pre>
      </div>

      <div>
        <s>{{ t('discussion.markdown.strikethrough') }}</s>
      </div>
      <div>
        {{ t('discussion.markdown.surroundText') }}
        <pre>~~{{ t('discussion.markdown.tildes') }}~~</pre>
      </div>

      <div>
        <blockquote>{{ t('discussion.markdown.quote') }}</blockquote>
      </div>
      <div>
        {{ t('discussion.markdown.prefix') }}
        <pre>&gt;</pre>
      </div>
    </div>
  </div>
</template>
<style scoped>
.discussion-markdown-help pre {
  display: inline;
}
</style>
