<script setup lang="ts">
import { useCurrentUser } from '@/api/auth';
import type { Guid, Schema, Step, Translated } from '@/api/types';
import { computed, ref } from 'vue';
import BlockStep from './BlockStep.vue';
import ButtonComponent from './ButtonComponent.vue';
import MarkdownRenderer from '@/components/block/MarkdownRenderer.vue';
import { useI18n } from 'vue-i18n';
import { apimproductsRouteId, mySubscriptionsRouteId } from '@/config';

const getStartedId = 'kom-i-gang';

const { t } = useI18n();

const props = defineProps<{
  steps: Translated<Step[]>[];
  schema: Schema;
  hasApiSubscription?: boolean;
  apiId?: string;
  blockId: Guid;
  versionId: number;
}>();

const userInfo = useCurrentUser();

const activeStep = ref(0);

const shownDescription = computed(() => {
  return props.steps[activeStep.value].description;
});
</script>

<template>
  <div data-cy-id="BlockSteps">
    <h2 :id="getStartedId">
      {{ t('gettingStarted.title') }}
    </h2>
    <div class="m-auto mt-10">
      <div class="m-auto flex flex-wrap justify-evenly">
        <div
          v-for="(step, idx) in steps"
          :key="step.order"
          class="mb-6 hover:cursor-pointer"
          data-cy-id="BlockStepButton"
        >
          <BlockStep
            :step="step"
            :index="idx"
            :is-active="activeStep === idx"
            @click="activeStep = idx"
          />
        </div>
      </div>
      <div
        class="text-p1 bg-gnist-blue-light-light px-12 pb-8 pt-9 text-gnist-black"
        data-cy-id="BlockStepDescription"
      >
        <MarkdownRenderer
          :schema="schema"
          :value="shownDescription"
          :blob-location="{ blockId: blockId, versionId: versionId }"
        />
        <div v-if="props.steps[activeStep].showApimOrderButton">
          <div class="m-auto flex justify-center">
            <p v-if="!userInfo">
              {{ t('block.requireLogin') }}
            </p>
            <RouterLink
              v-else
              :to="{
                name: hasApiSubscription
                  ? mySubscriptionsRouteId
                  : apimproductsRouteId,
                query: hasApiSubscription ? { api: apiId } : undefined,
              }"
            >
              <ButtonComponent
                class="self-center"
                type="primary"
                :text="
                  hasApiSubscription
                    ? t('block.seeSubscriptions')
                    : t('block.requestApiMAccess')
                "
              />
            </RouterLink>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
