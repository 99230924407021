<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import LoadingSpinner from './LoadingSpinner.vue';

const { t } = useI18n();

defineProps<{
  responseData: string | undefined;
  showSpinner: boolean;
}>();
</script>

<template>
  <section
    class="rounded-b-2xl border border-t-0 border-gnist-gray-dark bg-gnist-gray-light-light p-8 shadow-md"
  >
    <h2 class="mb-6">
      {{ t('console.httpResponse') }}
    </h2>
    <div v-if="showSpinner" class="flex justify-center">
      <LoadingSpinner class="h-16 w-16" />
    </div>
    <div v-else>
      <p
        class="whitespace-pre-wrap break-words font-mono text-sm text-gnist-black"
      >
        {{ responseData }}
      </p>
    </div>
  </section>
</template>
