<script setup lang="ts">
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';

defineProps<{
  menu: { routeName: string; label: string; disabled?: boolean }[];
}>();

const { t } = useI18n();

const route = useRoute();
</script>

<template>
  <nav
    class="my-4 flex flex-wrap gap-1 p-4 after:grow-[2] after:content-['']"
    :aria-label="t('profile.tabs')"
    role="navigation"
  >
    <RouterLink
      v-for="{ routeName, label, disabled } in menu"
      :key="routeName"
      class="ring-offset-primary-blueDark grow basis-1 whitespace-nowrap rounded-lg px-4 py-2.5 text-sm font-medium text-gnist-black ring-white ring-opacity-60 ring-offset-2 focus:outline-none focus:ring-2"
      :class="[
        route.name === routeName
          ? 'cursor-default bg-gnist-green underline hover:bg-gnist-green/[0.9]'
          : disabled
            ? 'cursor-default bg-gnist-white hover:bg-gnist-white/[0.9]'
            : 'bg-gnist-gray-light hover:bg-gnist-gray-light/[0.9]',
      ]"
      :to="{ name: routeName }"
    >
      {{ t(label) }}
    </RouterLink>
  </nav>
</template>
