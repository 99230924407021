import {
  frontendUrl,
  apiUrl,
  type supported_locale,
  B2C_LOCALE,
} from '@/config';
import { ref, type Ref } from 'vue';
import { type CurrentUser } from '@/api/types';
import { throwUnauthorized } from '@/utilities/throwErrorPageTrigger';

export const roleNames = [
  'anonymous',
  'administrator',
  'moderator',
  'editor',
  'category_editor',
  'api_editor',
  'internal',
  'external',
  'orgmember',
  'docowner',
  'tester',
  'cpo_admin',
  'alz_order_form_tester',
  'masterdata_admin',
  'agreement_reference_admin',
] as const;
const roleTypes = [
  'Computed',
  'AudienceTargeting',
  'AccessControl',
  'DocumentAdmin',
] as const;
export type RoleType = (typeof roleTypes)[number];
export type RoleName = (typeof roleNames)[number];

const userInfo = ref<CurrentUser | null>(null);

export function useCurrentUser(): Ref<CurrentUser | null> {
  return userInfo;
}
export function useCurrentUserThrowIfNotLoggedOn(): Ref<CurrentUser> {
  if (!userInfo.value) throwUnauthorized();
  return userInfo as Ref<CurrentUser>;
}

export async function refreshCurrentUser(): Promise<Ref<CurrentUser | null>> {
  try {
    const response = await fetch(`${apiUrl}/users/current`, {
      credentials: 'include',
    });

    if (response.ok && response.status === 200) {
      userInfo.value = await response.json();
    }
  } catch (e) {
    console.warn(`Error fetching login status: ${e}`);
    userInfo.value = null;
  }
  return userInfo;
}

export function signIn(locale: supported_locale) {
  let signInUrl = `${apiUrl}/auth/login`;
  let rd = '';
  if (!frontendUrl) {
    rd = window.location.href.replace(`${window.location.origin}`, '');
  } else {
    // Used in DEV if redirect should not point to same origin
    rd = window.location.href.replace(
      `${window.location.origin}/`,
      frontendUrl,
    );
  }
  signInUrl += `?rd=${encodeURIComponent(rd)}&locale=${B2C_LOCALE[locale]}`;
  window.location.assign(signInUrl);
}

export function editProfile() {
  window.location.assign(
    `${apiUrl}/auth/edit_profile?rd=${encodeURIComponent(
      window.location.href.replace(window.location.origin, ''),
    )}`,
  );
}

export function getSignoutUrl(): string {
  let signOutUrl = `${apiUrl}/auth/logout`;

  if (frontendUrl) {
    signOutUrl += `?rd=${encodeURIComponent(frontendUrl)}`;
  }
  return signOutUrl;
}
