<script lang="ts" setup>
import { computed, ref } from 'vue';
import ButtonComponent from '../ButtonComponent.vue';
import MarkdownHelp from './MarkdownHelp.vue';
import type { Comment } from '@/api/commentoTypes';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps<{
  existingComment?: Comment;
}>();

const emit = defineEmits<{
  (e: 'submitComment', content: string): void;
}>();

const commentContent = ref<string>(props.existingComment?.markdown ?? '');

const canSave = computed(() => {
  return commentContent.value != '';
});

function submit() {
  emit('submitComment', commentContent.value);
  commentContent.value = '';
}
</script>

<template>
  <form class="block pt-1" @submit.prevent="submit">
    <div class="relative flex items-center justify-center">
      <label for="comment" class="sr-only">{{
        t('discussion.addComment')
      }}</label>
      <textarea
        id="comment"
        v-model="commentContent"
        name="comment"
        class="min-h inline-block h-32 w-full overflow-auto whitespace-pre-wrap rounded border border-[rgba(50,50,93,.1)] p-2 text-[#525f7f] outline-0 placeholder:text-center placeholder:leading-[6rem]"
        :placeholder="t('discussion.addComment')"
      />
    </div>
    <div class="flex w-full items-start py-4">
      <MarkdownHelp />
      <div class="grow" />
      <ButtonComponent
        :submit="true"
        type="primary"
        :disabled="!canSave"
        :text="
          props.existingComment
            ? t('discussion.saveChanges')
            : t('discussion.addComment')
        "
      />
    </div>
  </form>
</template>
