import type { Point } from '@/api/types';

export function handleDrag(
  ev: MouseEvent,
  onMove: (cursor: Point) => void,
  onStart?: (cursor: Point) => void,
) {
  const cursor: Point = { x: 0, y: 0 };
  function updateCursorPos(ev: MouseEvent) {
    if (ev.pageX || ev.pageY) {
      cursor.x = ev.pageX;
      cursor.y = ev.pageY;
    } else {
      const de = document.documentElement;
      const db = document.body;
      cursor.x =
        ev.clientX + (de.scrollLeft || db.scrollLeft) - (de.clientLeft || 0);
      cursor.y =
        ev.clientY + (de.scrollTop || db.scrollTop) - (de.clientTop || 0);
    }
    return cursor;
  }
  updateCursorPos(ev);
  onStart?.(cursor);
  document.onmousemove = (ev_move) => {
    updateCursorPos(ev_move);
    onMove(cursor);
  };
  document.onmouseup = () => {
    document.onmousemove = null;
  };
  return cursor;
}
