<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import { ref } from 'vue';
import type {
  Dimensions,
  InteractiveImageItem,
  Point,
  Translated,
} from '@/api/types';
import { handleDrag } from '@/utilities/dragUtils';
import ImageRenderer from '@/components/block/ImageRenderer.vue';
import type { IBlobUrlInput } from '@/api/blob';
import { watch } from 'vue';

const props = defineProps<{
  value: Translated<InteractiveImageItem>;
  editMode: boolean | undefined;
  focusIndex: number | undefined;
  index: number;
  zoomFactor: number;
  blobLocation?: IBlobUrlInput;
}>();
const emit = defineEmits<{
  (e: 'popup', label: string, text: string): void;
  (e: 'resize', size: Dimensions): void;
  (e: 'move', position: Point): void;
}>();

const { t } = useI18n();

function startDrag(ev: MouseEvent) {
  function getClientXY(el: Element | null) {
    const rect = el?.getBoundingClientRect();
    return { x: Math.trunc(rect?.x ?? 0), y: Math.trunc(rect?.y ?? 0) };
  }
  function diffXY(a: Point, b: Point) {
    return { x: a.x - b.x, y: a.y - b.y };
  }
  function sumXY(a: Point, b: Point) {
    return { x: a.x + b.x, y: a.y + b.y };
  }
  function divXY(point: Point, factor: number) {
    return { x: Math.trunc(point.x / factor), y: Math.trunc(point.y / factor) };
  }
  let offset: Point = { x: 0, y: 0 };
  function getParentWithClass(
    element: HTMLElement | null | undefined,
    className: string,
  ) {
    if (!element) return null;
    if (element.parentElement?.classList.contains(className)) {
      return element.parentElement;
    } else {
      return getParentWithClass(element.parentElement, className);
    }
  }
  function onStart(cursor: Point) {
    offset = sumXY(
      getClientXY(
        getParentWithClass(el.value, 'InteractiveImageVisualBoxesContainer'),
      ),
      diffXY(cursor, getClientXY(ev.target as Element | null)),
    );
  }
  function onMove(cursor: Point) {
    emit('move', divXY(diffXY(cursor, offset), props.zoomFactor));
  }
  handleDrag(ev, onMove, onStart);
}

const el = ref<HTMLDivElement | undefined>();
watch(el, () => {
  if (!el.value) return;
  const resizeObserver = new ResizeObserver((entries) => {
    const entry = entries[0]?.borderBoxSize[0];
    if (!entry) return;
    const newSize = {
      width: Math.trunc(entry.inlineSize),
      height: Math.trunc(entry.blockSize),
    };
    if (
      props.value.size.width !== newSize.width ||
      props.value.size.height !== newSize.height
    ) {
      emit('resize', newSize);
    }
  });
  resizeObserver.observe(el.value);
});
</script>

<template>
  <div
    v-if="!value.textOnly"
    ref="el"
    class="absolute overflow-hidden"
    :class="{
      'cursor-pointer': value.extendedText,
      border: editMode,
      'border-gnist-red': focusIndex === index,
      'border-4': focusIndex === index,
      'bg-gnist-white/50': editMode,
      resize: editMode,
      'hover:bg-gnist-white/50': !editMode && value.extendedText,
      'focus-within:bg-gnist-white/50': !editMode && value.extendedText,
      'rounded-3xl': !editMode,
    }"
    :style="{
      width: `${value.size.width}px`,
      height: `${value.size.height}px`,
      top: `${value.position.y}px`,
      left: `${value.position.x}px`,
    }"
  >
    <button
      class="flex h-full w-full flex-col items-center justify-end focus-visible:outline-none"
      :class="{
        'cursor-default': !value.extendedText && !editMode,
        'cursor-move': editMode,
      }"
      :tabindex="0"
      :aria-disabled="!value.extendedText"
      :aria-description="t('interactiveImage.readMore')"
      :aria-labelledby="
        value.showLabelInVisual ? `button-label-${index}` : undefined
      "
      :aria-label="value.showLabelInVisual ? value.label : undefined"
      @click.prevent="
        if (value.extendedText && !editMode) {
          emit('popup', value.label, value.extendedText);
        }
      "
      @mousedown="
        (ev) => {
          if (editMode) startDrag(ev);
        }
      "
    >
      <ImageRenderer
        v-if="value.image.name && !value.hideImageInVisual"
        :schema="{
          type: 'image',
          name: 'image',
          title: 'Bildefil',
          showTitle: false,
        }"
        :value="{ name: value.image.name, alt: value.label }"
        :blob-location="blobLocation"
        class="w-full"
        decorative
        no-drag
      />
      <label
        :id="`button-label-${index}`"
        class="text-xl font-bold"
        :class="{
          'cursor-pointer': value.extendedText,
          'cursor-move': editMode,
          hidden: !value.showLabelInVisual,
          'text-gnist-black': editMode,
          'text-gnist-blue': !editMode,
        }"
      >
        {{ value.label }}
      </label>
    </button>
  </div>
</template>
