<script setup lang="ts">
import type { Colors, Suggestion } from '@/api/types';
import {
  getSuggestionStatus,
  type SuggestionStatus,
} from '@/utilities/getSuggestionStatus';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const colorMapping: { [key in SuggestionStatus]: Colors } = {
  New: 'white',
  UnderConsideration: 'gray',
  Planned: 'sp-blue-light',
  InProgress: 'purple',
  Done: 'green',
  Closed: 'orange',
};

const props = defineProps<{
  status?: SuggestionStatus | null;
  suggestion?: Pick<Suggestion, 'status' | 'workItem'> | null | undefined;
}>();
const statusValue = computed(
  (): SuggestionStatus | null =>
    props.status ?? getSuggestionStatus(props.suggestion),
);
</script>

<template>
  <p
    v-if="statusValue"
    class="badge badge-outline badge-lg min-w-[8rem] rounded border-gnist-gray px-4"
    :class="[`bg-gnist-${colorMapping[statusValue]}`]"
  >
    {{ t(`suggestions.status.${statusValue}`, statusValue ?? '') }}
  </p>
</template>
