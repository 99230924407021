<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import InputLabel, {
  useDynamicLabelTexts,
  type IInputLabelProps,
} from './InputLabel.vue';
import { computed } from 'vue';

const props = defineProps<
  IInputLabelProps & {
    modelValue?: Date | string | null;
    disabled?: boolean;
    /** Used for connecting label and input */
    forName?: string;
    inputClass?: string | string[];
    min?: Date;
    max?: Date;
  }
>();
const emit = defineEmits<{
  (e: 'update:modelValue', value: Date): void;
}>();
const { t } = useI18n();
const value = computed(() =>
  !props.modelValue
    ? null
    : new Date(props.modelValue)?.toISOString().split('T')[0],
);

function onChange(val: string) {
  const parsedVal = new Date(val);
  emit('update:modelValue', parsedVal);
}
const texts = useDynamicLabelTexts(props);
</script>
<template>
  <label :for="forName" class="flex flex-col whitespace-nowrap">
    <InputLabel v-bind="props" />
    <input
      :id="forName"
      :value="value"
      type="date"
      :name="forName"
      :title="texts.label"
      class="my-2 grow border p-2"
      :class="inputClass"
      :required="required"
      :placeholder="t(texts.placeholder ?? '')"
      :disabled="disabled"
      :min="
        min?.toISOString().split('T')[0] ??
        new Date().toISOString().split('T')[0]
      "
      :max="max?.toISOString().split('T')[0]"
      @input="(ev) => onChange((ev.target as HTMLInputElement).value)"
    />
  </label>
</template>
