<script setup lang="ts">
import { computed } from 'vue';
import InputLabel, {
  useDynamicLabelTexts,
  type IInputLabelProps,
} from './InputLabel.vue';
import type { VueClass } from '@/api/types';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = withDefaults(
  defineProps<
    IInputLabelProps & {
      modelValue?: boolean | null;
      checkboxClass?: VueClass;
      mode?: 'checkbox' | 'toggle';
      direction?: 'horizontal' | 'vertical';
      disabled?: boolean;
      inverted?: boolean;
    }
  >(),
  {
    modelValue: undefined,
    checkboxClass: undefined,
    mode: 'checkbox',
    direction: 'vertical',
  },
);
const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void;
}>();
const value = computed({
  get() {
    return props.inverted ? !props.modelValue : !!props.modelValue;
  },
  set(newValue) {
    emit('update:modelValue', props.inverted ? !newValue : newValue);
  },
});
const texts = useDynamicLabelTexts(props);
</script>
<template>
  <label
    class="flex"
    :class="{
      'md:flex-col': direction === 'vertical',
      'grow flex-row-reverse items-center justify-end':
        direction !== 'vertical',
    }"
  >
    <InputLabel v-bind="props">
      <slot>
        <span
          v-if="texts.label"
          class="block min-w-0 text-left"
          :class="[labelClass, { 'sr-only': srOnly }]"
        >
          {{ t(texts.label) }}
        </span>
      </slot>
    </InputLabel>
    <div
      class="flex items-center"
      :class="{
        'my-2 grow p-2': direction == 'vertical',
        'h-8 self-start': direction !== 'vertical',
      }"
    >
      <input
        :value="value"
        :checked="value"
        type="checkbox"
        :class="[
          {
            'gnist-checkbox self-center': mode === 'checkbox',
            toggle: mode === 'toggle',
            'mr-2': direction === 'horizontal',
          },
          checkboxClass,
        ]"
        :disabled="disabled"
        :data-cy-id="`InputCheckbox_${texts.forName}`"
        @change="(ev) => (value = !!(ev.target as HTMLInputElement).checked)"
      />
    </div>
  </label>
</template>

<style scoped>
.toggle:checked,
.toggle[checked='true'],
.toggle[aria-checked='true'] {
  --tglbg: rgb(var(--gnist-color-blue-dark) / var(--tw-bg-opacity));
  @apply bg-gnist-white;
}
</style>
