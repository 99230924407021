import { computed, onBeforeUnmount, onMounted, ref, type Ref } from 'vue';

const windowWidth = ref(window.innerWidth);
const windowHeight = ref(window.innerHeight);
const isLarge = computed(() => windowWidth.value >= 1024); // This should equal the "lg" breakpoint in tailwind.config.js
const isMedium = computed(() => windowWidth.value >= 768); // This should equal the "md" breakpoint in tailwind.config.js
const isBelowSmall = computed(() => windowWidth.value < 640); // This should equal the "sm" breakpoint in tailwind.config.js

function handleResize() {
  windowWidth.value = window.innerWidth;
  windowHeight.value = window.innerHeight;
}

export function setupResizeListener() {
  onMounted(() => {
    window.addEventListener('resize', handleResize);
  });
  onBeforeUnmount(() => {
    window.removeEventListener('resize', handleResize);
  });
}
export function useResizeListener(): {
  windowWidth: Ref<number>;
  windowHeight: Ref<number>;
  /** Above or equal to the "lg" breakpoint in tailwind.config.js */
  isLarge: Ref<boolean>;
  /** Above or equal to the "md" breakpoint in tailwind.config.js */
  isMedium: Ref<boolean>;
  /** Below the "sm" breakpoint in tailwind.config.js */
  isBelowSmall: Ref<boolean>;
} {
  return { windowWidth, windowHeight, isLarge, isMedium, isBelowSmall };
}
