import { type Ref } from 'vue';
import {
  type ComposableResult,
  composeWithRefresh,
  compose,
} from './composable';
import type { Block, Favorite, Guid } from './types';
import { apiUrl } from '@/config';

const favoriteUrl = apiUrl + '/favorites';

export function useBlockFavorite(
  block: Ref<Block | null | undefined>,
): ComposableResult<Favorite | null | undefined> {
  return composeWithRefresh(
    async () => (block.value ? getFavorite(block.value.blockId) : null),
    block,
  );
}

const getFavorite = async (blockId: Guid): Promise<Favorite | undefined> => {
  const response = await fetch(`${favoriteUrl}/block/${blockId}`, {
    method: 'GET',
    credentials: 'include',
  });
  if (!response.ok || response.status === 204) return undefined;
  return await response.json();
};

export function useFavorites(): ComposableResult<Favorite[]> {
  return compose(getFavorites());
}

const getFavorites = async (): Promise<Favorite[]> => {
  return fetch(favoriteUrl, {
    method: 'GET',
    credentials: 'include',
  })
    .then((response) => response.json())
    .then((favorites) => favorites);
};

export const addFavorite = async (blockId: Guid): Promise<Favorite> => {
  const resp = await fetch(favoriteUrl, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    body: JSON.stringify({ blockId }),
  });
  return await resp.json();
};

export const removeFavorite = async (favoriteId: number): Promise<void> => {
  fetch(`${favoriteUrl}/${favoriteId}`, {
    method: 'DELETE',
    credentials: 'include',
  });
};

export const removeFavoriteByBlockId = async (blockId: Guid): Promise<void> => {
  fetch(
    favoriteUrl + '?' + new URLSearchParams({ blockId: blockId.toString() }),
    {
      method: 'DELETE',
      headers: {},
      credentials: 'include',
    },
  );
};

export const updateFavorite = async (
  blockId: Guid,
  notes: string,
  favoriteId: number,
): Promise<void> => {
  fetch(favoriteUrl + '/' + favoriteId, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    body: JSON.stringify({ blockId, notes, favoriteId }),
  });
};
