<script setup lang="ts">
import ContentPage from '@/components/ContentPage.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import TagGroupEditor from '@/components/TagGroupEditor.vue';
import { useI18n } from 'vue-i18n';
import {
  createTagGroup,
  deleteTagGroup,
  updateTagGroup,
  useTagGroups,
} from '@/api/tags';
import {
  removeParentKey,
  type KeyOfRecursive,
  type KeyOfRecursiveBelow,
  type TagGroup,
  getEmptyLocaleValue,
} from '@/api/types';
import { SUPPORTED_LOCALES, type supported_locale } from '@/config';
import { computed, ref } from 'vue';
import TableComponent from '@/components/TableComponent.vue';

const { t, locale } = useI18n();

const { result: tagGroups } = useTagGroups();

const tagGroupEditors = ref<InstanceType<typeof TagGroupEditor>[]>([]);

const changeList = ref<{ [id: number]: boolean }>({});
const hasChanges = computed(
  () =>
    Object.values(changeList.value).includes(true) ||
    removedTagGroups.value.length > 0,
);

async function save() {
  if (!hasChanges.value) return;
  if (!tagGroups.value) return;
  for (const [id, hasChange] of Object.entries(changeList.value)) {
    if (!hasChange) continue;
    const tagGroupToSave = tagGroups.value.find((u) => `${u.id}` === id);
    if (!tagGroupToSave) throw new Error('Should not happen');
    if (tagGroupToSave.id === -1) {
      const newTagGroup = await createTagGroup(tagGroupToSave);
      tagGroupToSave.id = newTagGroup.id;
    } else {
      await updateTagGroup(tagGroupToSave.id, tagGroupToSave);
    }
  }
  changeList.value = {};
  for (const tagGroupToDelete of removedTagGroups.value) {
    await deleteTagGroup(tagGroupToDelete);
  }
  removedTagGroups.value.length = 0;
  tagGroupEditors.value?.forEach((e) => e.resetSource());
}

const textPropPrefix: `${keyof Pick<TagGroup, 'label'>}.` = 'label.';
const locales = [...SUPPORTED_LOCALES]
  .sort((a, b) => (a === locale.value ? -1 : b === locale.value ? 1 : 0))
  .map((i): KeyOfRecursiveBelow<TagGroup, 'label'> => `${textPropPrefix}${i}`);

const columns = computed(() => {
  const cols: (KeyOfRecursive<TagGroup> | null)[] = [...locales];
  cols.push('homePageId');
  cols.push('color');
  cols.push(null);
  return cols;
});

function getColumnHeader(column: KeyOfRecursive<TagGroup>) {
  if (!locales.includes(column as KeyOfRecursiveBelow<TagGroup, 'label'>)) {
    return t(`tagEditor.tagGroupColumns.${column}`);
  } else {
    return (
      t(`language.values.${removeParentKey(column, 'label')}`) +
      t('tagEditor.tagGroupColumns.label')
    );
  }
}

function onCreateButtonClick() {
  if (!tagGroups.value) return;
  const minId = Math.min(...tagGroups.value.map((u) => u.id), 0);
  tagGroups.value.push({
    id: minId - 1,
    label: getEmptyLocaleValue(),
  });
}

const removedTagGroups = ref<number[]>([]);
function removeTagGroup(tagGroupId: number) {
  if (!tagGroups.value) return;
  removedTagGroups.value.push(tagGroupId);
  tagGroups.value = tagGroups.value.filter((u) => u.id !== tagGroupId);
}
</script>

<template>
  <ContentPage
    :title="t('tagEditor.tagGroupTitle')"
    class="bg-gnist-gray-light-light"
  >
    <section class="py-8">
      <div class="w-full max-w-full">
        <div class="my-6 flex justify-between">
          <ButtonComponent
            :text="t('tagEditor.createTagGroup')"
            @click="onCreateButtonClick"
          />
          <ButtonComponent
            :text="t('tagEditor.save')"
            type="primary"
            :disabled="!hasChanges"
            @click="() => save()"
          />
        </div>
        <TableComponent
          size="table-xs"
          :rows="tagGroups"
          show-spinner
          i18n-key="tagEditor.tagGroupColumns"
          :get-key="(tagGroup) => tagGroup.id"
          :columns="columns"
          :get-column-header="getColumnHeader"
          :sort-fields="locales as KeyOfRecursive<TagGroup>[]"
          :default-sortfield="`label.${locale as supported_locale}`"
          skip-sort-on-content-change
        >
          <template #columns="{ item: tagGroup, rowIndex: idx, items }">
            <TagGroupEditor
              :ref="
                (ref) =>
                  tagGroupEditors.push(ref as InstanceType<typeof TagGroupEditor>)
              "
              v-model="items[idx]"
              :locale-keys="locales"
              @update:changed="(val) => (changeList[tagGroup.id] = val)"
              @remove="removeTagGroup(tagGroup.id)"
            />
          </template>
        </TableComponent>
      </div>
    </section>
  </ContentPage>
</template>
