<script setup lang="ts">
import NotificationToast from './NotificationToast.vue';
import { useRoute, useRouter } from 'vue-router';
import { useStoreAbility } from '@/abilities';
import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue';
import { computed } from 'vue';
import type { RouteItem, RouteItemWithChildren } from '@/api/types';
import { isRouteItemWithChildren } from '@/utilities/routeUtils';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = withDefaults(
  defineProps<{
    items: (RouteItem & { showNotificationToast?: boolean })[];
    level?: number;
    menuItemsClass?: string[];
  }>(),
  { level: 0, align: 'left', menuItemsClass: () => [] },
);
const isTopLevel = computed(() => props.level === 0);

defineSlots<{
  button(props: { open: boolean }): unknown;
  header(props: unknown): unknown;
}>();
const emit = defineEmits<{
  (e: 'close'): void;
}>();

const { can } = useStoreAbility();

const itemsWithClickHandler = computed(() =>
  props.items
    .filter(
      (item) => !item.action || !item.subject || can(item.action, item.subject),
    )
    .map((item) => {
      if (isRouteItemWithChildren(item) || item.onClick) {
        return item;
      } else if (item.href) {
        return {
          ...item,
          onClick: () => window.location.assign(item.href!),
        };
      } else {
        const targetRoute = router.resolve({
          path: item.targetPath,
          query: item.query,
        });
        return {
          ...item,
          onClick: () => {
            if (route.name !== targetRoute?.name) router.push(targetRoute);
          },
          href: targetRoute.fullPath,
        };
      }
    }),
);

const route = useRoute();
const router = useRouter();

const isActivePath = (item: RouteItem) => {
  if (!item.href) return false;
  return item.targetPath != '/' && route.fullPath.startsWith(item.href);
};
</script>
<template>
  <div>
    <Menu v-slot="{ open: menuOpen, close }">
      <template v-if="isTopLevel">
        <MenuButton
          :class="[
            'p-2',
            'inline-flex items-center justify-center',
            'text-h4',
            'rounded-full',
            'hover:bg-gnist-black hover:bg-opacity-10',
            'focus-visible:ring-gray focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75',
          ]"
          data-cy-id="MenuButton"
        >
          <slot name="button" :open="menuOpen" />
        </MenuButton>
      </template>
      <template v-else>
        <slot name="header" />
      </template>
      <Transition
        enter-active-class="transition duration-100 ease-out"
        enter-from-class="transform scale-95 opacity-0"
        enter-to-class="transform scale-100 opacity-100"
        leave-active-class="transition duration-75 ease-in"
        leave-from-class="transform scale-100 opacity-100"
        leave-to-class="transform scale-95 opacity-0"
      >
        <MenuItems
          :static="isTopLevel ? false : true"
          :class="[
            isTopLevel ? 'bg-gnist-white shadow-lg' : '', // Background and border
            'flex grow gap-x-2 text-gnist-black', // Flex (common)
            ...menuItemsClass,
          ]"
          :style="`z-index: ${level}`"
        >
          <template
            v-for="item in itemsWithClickHandler"
            :key="item.targetPath"
          >
            <!-- Menu items with links -->
            <MenuItem
              v-if="item.routeType !== 'header'"
              class="my-0.5 block focus:outline focus:outline-black"
            >
              <a
                :href="item.href"
                :class="[
                  'px-4 py-4 text-left',
                  'rounded-md',
                  'hover:bg-gnist-blue-light-light hover:bg-opacity-50',
                  item.isSelected || isActivePath(item)
                    ? 'bg-gnist-blue-light-light'
                    : 'bg-gnist-white',
                ]"
                :data-cy-value="item.text"
                @click="
                  (e) => {
                    e.preventDefault();
                    emit('close');
                    close();
                    item.onClick?.();
                  }
                "
              >
                {{ t(item.text) }}
                <NotificationToast
                  v-if="item.showNotificationToast"
                  class="ml-2 inline-block"
                />
              </a>
            </MenuItem>
            <!-- Menu items that are headers with sub items -->
            <DropdownMenu
              v-else
              :items="(item as RouteItemWithChildren).items ?? []"
              :level="level + 1"
              class="flex flex-col"
              :class="[isTopLevel ? 'lg:basis-60' : 'pl-6']"
              :menu-items-class="['flex-col justify-start']"
              @close="
                () => {
                  emit('close');
                  close();
                }
              "
            >
              <!-- Actually the header-->
              <template #header>
                <h3
                  :class="[
                    'my-2 mr-10 px-4 py-2',
                    'text-gnist-sp-blue-dark-dark',
                    'rounded-md border-b',
                  ]"
                >
                  {{ t(item.text) }}
                </h3>
              </template>
            </DropdownMenu>
          </template>
        </MenuItems>
      </Transition>
    </Menu>
  </div>
</template>
