import hljs from 'highlight.js/lib/core';
import * as curlTemplate from '@/assets/templates/curl.liquid?raw';
import shell from 'highlight.js/lib/languages/shell';
import * as csharpTemplate from '@/assets/templates/csharp.liquid?raw';
import csharp from 'highlight.js/lib/languages/csharp';
import * as httpTemplate from '@/assets/templates/http.liquid?raw';
import http from 'highlight.js/lib/languages/http';
import * as javaTemplate from '@/assets/templates/java.liquid?raw';
import java from 'highlight.js/lib/languages/java';
import * as javascriptTemplate from '@/assets/templates/javascript.liquid?raw';
import javascript from 'highlight.js/lib/languages/javascript';
import * as phpTemplate from '@/assets/templates/php.liquid?raw';
import php from 'highlight.js/lib/languages/php';
import * as pythonTemplate from '@/assets/templates/python.liquid?raw';
import python from 'highlight.js/lib/languages/python';
import * as rubyTemplate from '@/assets/templates/ruby.liquid?raw';
import ruby from 'highlight.js/lib/languages/ruby';
import * as swiftTemplate from '@/assets/templates/swift.liquid?raw';
import swift from 'highlight.js/lib/languages/swift';
import rust from 'highlight.js/lib/languages/rust'; // TODO
import json from 'highlight.js/lib/languages/json';

export const templates = {
  Curl: curlTemplate.default,
  'C#': csharpTemplate.default,
  HTTP: httpTemplate.default,
  Java: javaTemplate.default,
  Javascript: javascriptTemplate.default,
  PHP: phpTemplate.default,
  Python: pythonTemplate.default,
  Ruby: rubyTemplate.default,
  Swift: swiftTemplate.default,
};

export function registerLanguagesForHighlight() {
  hljs.registerLanguage('shell', shell);
  hljs.registerLanguage('csharp', csharp);
  hljs.registerLanguage('http', http);
  hljs.registerLanguage('java', java);
  hljs.registerLanguage('javascript', javascript);
  hljs.registerLanguage('php', php);
  hljs.registerLanguage('python', python);
  hljs.registerLanguage('ruby', ruby);
  hljs.registerLanguage('rust', rust);
  hljs.registerLanguage('swift', swift);
  hljs.registerLanguage('json', json);
}
