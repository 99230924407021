<script setup lang="ts">
import { useRoute } from 'vue-router';

const route = useRoute();

if (route.query['token']) {
  window.opener.postMessage(
    {
      token: route.query['token'],
    },
    window.location.origin,
  );
  window.close();
}
</script>

<template>
  <h1>Kun for teknisk bruk</h1>
</template>
