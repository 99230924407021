import {
  toDropdownItems,
  type IDropdownItem,
} from '@/components/forms/InputDropdown.vue';

export const mapValueList = <T extends string | null | undefined>(
  values: T[] | readonly T[],
  i18nKey: string,
  includeEmpty = false,
  emptyValue: T = '' as T,
  emptyLabel?: string,
): IDropdownItem<T>[] => {
  return toDropdownItems(
    values,
    (c) => [c ?? '', `${i18nKey}.values.${c}`, c],
    includeEmpty,
    emptyValue,
    emptyLabel ? `${i18nKey}.values.${emptyLabel}` : undefined,
  );
};
