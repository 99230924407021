// Source: https://github.com/martinstark/throttle-ts
/** Runs the given function and returns the result. If a new request comes in before a given cool-down period, just return undefined and don't run the function. */
export function throttle<R, A extends unknown[]>(
  delay: number,
  fn: (...args: A) => R,
): (...args: A) => R | undefined {
  return throttleWithCancel(delay, fn).throttledFn;
}
/** Runs the given function and returns the result. If a new request comes in before a given cool-down period, just return undefined and don't run the function. */
export function throttleWithCancel<R, A extends unknown[]>(
  delay: number,
  fn: (...args: A) => R,
): { throttledFn: (...args: A) => R | undefined; cancel: () => void } {
  let wait = false;
  let timeout: undefined | number;
  let cancelled = false;

  return {
    throttledFn: (...args: A) => {
      if (cancelled) return undefined;
      if (wait) return undefined;

      const val = fn(...args);

      wait = true;

      timeout = window.setTimeout(() => {
        wait = false;
      }, delay);

      return val;
    },
    cancel: () => {
      cancelled = true;
      clearTimeout(timeout);
    },
  };
}

/** Fires the given function and starts a cool-down timer. If another request comes in before the timer has elapsed, a rerun is requested and will be fired at the end of the waiting time.*/
export function throttleFireOnly<A extends unknown[]>(
  delay: number,
  fn: (...args: A) => void,
): (...args: A) => void {
  let wait = false;
  let newRunRequested = false;
  return (...args: A) => {
    if (wait) {
      newRunRequested = true;
      return;
    }
    wait = true;
    fn(...args);
    window.setTimeout(() => {
      wait = false;
      if (newRunRequested) {
        newRunRequested = false;
        fn(...args);
      }
    }, delay);
  };
}
