<script setup lang="ts">
import type { OperationParam } from '@/components/ApiParameterInput.vue';
import TableComponent from '@/components/TableComponent.vue';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const props = defineProps<{
  pathParams: OperationParam[];
  queryParams: OperationParam[];
}>();

const { t } = useI18n();

function enrichParam(
  param: OperationParam,
  position: 'inTemplate' | 'inQuery',
) {
  const typeI18nId = `block.apiParams.types.${param.parameterContractType}`;
  let translatedType = t(typeI18nId);
  if (translatedType === typeI18nId) {
    translatedType = param.parameterContractType;
  }
  return { ...param, in: t(`block.apiParams.${position}`), translatedType };
}

const allParams = computed(() =>
  props.pathParams
    .map((i) => enrichParam(i, 'inTemplate'))
    .concat(props.queryParams.map((i) => enrichParam(i, 'inQuery'))),
);
</script>

<template>
  <TableComponent
    v-if="pathParams.length > 0 || queryParams.length > 0"
    class="SizeLimitTable max-h-[36rem] border"
    column-header-class="font-normal"
    :rows="allParams"
    i18n-key="block.apiParams"
    :get-key="(param) => param.name"
    :columns="['name', 'in', 'isRequired', 'translatedType', 'description']"
  >
    <template #columnHeader="{ item: param }">
      {{ param.name }}
    </template>
    <template #columns="{ item: param }">
      <td>
        {{ param.in }}
      </td>
      <td>
        {{ t(`booleans.${param.isRequired ?? false}`).toLowerCase() }}
      </td>
      <td>
        {{ param.translatedType }}
      </td>
      <td class="whitespace-pre-wrap leading-tight">
        {{ param.description }}
      </td>
    </template>
  </TableComponent>
</template>

<style scoped>
/* For some reason the tab component sometimes fails to calculate the correct width for the tabs,
   if the content is too big and has not a fixed sixe (e.g. 90% won't work even if it actually limits the size).
   Thus this complicated calculation to limit the size. */
.SizeLimitTable {
  --extra-padding-for-good-measure: 4rem;
  max-width: calc(
    var(--main-content-width) - var(--api-details-padding) -
      var(--extra-padding-for-good-measure)
  );
  @apply xl:max-w-[45rem];
}
</style>
