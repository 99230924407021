<script setup lang="ts">
import { useI18n } from 'vue-i18n';

defineProps<{
  /** Sets the icon to support WCAG SC 2.5.5: The size of the target for pointer inputs is at least 44 by 44 CSS pixels */
  accessibleButton?: boolean;
  label?: string;
  icon?: string;
}>();

const { t } = useI18n();
</script>

<template>
  <div
    v-if="accessibleButton"
    class="flex h-11 w-11 items-center justify-center"
    :class="{ 'tooltip before:text-wrap': label }"
    :aria-label="label ? t(label) : undefined"
    :data-tip="label ? t(label) : undefined"
  >
    <span role="img" class="material-symbols-rounded">
      <slot>{{ icon }}</slot>
    </span>
  </div>
  <span
    v-else
    role="img"
    class="material-symbols-rounded"
    :class="{ 'tooltip before:text-wrap before:font-gnist': label }"
    :aria-label="label ? t(label) : undefined"
    :data-tip="label ? t(label) : undefined"
  >
    <slot>{{ icon }}</slot>
  </span>
</template>

<style scoped>
.material-symbols-rounded.text-3xl {
  @apply leading-8;
}
</style>
