<script lang="ts" setup>
import { ref, watchEffect } from 'vue';
import { useCategoriesLazy } from '@/api/category';
import { useTagGroups } from '@/api/tags';
import TagList from './TagList.vue';
import type { Tag, TagGroup } from '@/api/types';

const props = defineProps<{
  categoryName: string;
  tags?: Tag[];
  //   maxItems: number;
}>();

// const { t } = useI18n();

const { result: categories } = useCategoriesLazy(false);

const { result: tagGroups } = useTagGroups();

const filtered_groups = ref<TagGroup[]>();
// const filtered_tags = ref<any>();

watchEffect(() => {
  const category = categories.value?.find(
    (c) => c.name.no == props.categoryName,
  );
  const groups = tagGroups.value?.filter((g) =>
    category?.tagGroups?.includes(g.id.toString()));
	// const filtered = groups?.forEach((g) => {
	// 	g.tags?.filter((t) => props.tags?.some((p) => p.id === t));
	// });
	if(!groups) return;
	// filtered_groups.value = groups.filter((g) => props.tags?.some((t) => t.id === g.id));
	filtered_groups.value = groups.filter((g) => g.tags?.some(gt => props.tags?.some(pt => pt.id === gt)));
	// filtered_groups.value = groups.map((group) => {
	// 	group.tags?.filter((g) => props.tags?.some((t) => t.id == g)), group;
	// });

	filtered_groups.value.forEach(group => {
		group.tags = group.tags?.filter(t => props.tags?.some(pt => pt.id === t));
	});

	// if(!filtered_groups.value) return;
	// filtered_tags.value = filtered_groups.value.map((group) => group.tags?.filter((t) => props.tags?.some((p) => p.id == t)));
});
</script>

<template>
  <div class="">
    <div v-for="tagGroup in filtered_groups" :key="tagGroup.id" class="">
      <TagList
        v-if="tagGroup"
        :group="tagGroup.id"
				:list="tagGroup.tags"
        :size="'badge-sm'"
        :show-all="true"
        :show-group-label="true"
      />
    </div>
  </div>
</template>
