import { compose } from '@/api/composable';
import { apiUrl } from '@/config';
import { computed, type ComputedRef } from 'vue';

const statusUrl = apiUrl + '/status';

export function useFeatureToggle(featureId: string): ComputedRef<boolean> {
  const { result } = compose(getFeatureStatus(featureId));
  const value = computed(() => !!result.value);
  return value;
}
async function getFeatureStatus<T>(featureId: string): Promise<T | null> {
  const resp = await fetch(`${statusUrl}/features/${featureId}`);
  if (resp.ok) {
    return await resp.json();
  }
  return null;
}
