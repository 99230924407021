import type { Guid, LayoutItem, Translated } from '@/api/types';
import type BlockList from './BlockList.vue';
import { computed, type ComponentPublicInstance, type Ref, ref } from 'vue';
import type SearchBar from './SearchBar.vue';

type ConnectionRefs = {
  filter?: InstanceType<typeof BlockList>;
  list?: InstanceType<typeof BlockList>;
};
export type TranslatedConnectionItem = {
  item: Translated<LayoutItem>;
  label: string;
};
type ConnectionItem<T extends LayoutItem | Translated<LayoutItem>> = {
  item: T;
  label: string;
};
export type ConnectionInfo = {
  self_id: Guid;
  role: 'filter' | 'list' | 'search';
};

export function useBlockListConnections(
  item: Ref<Translated<LayoutItem>>,
  flatList: Ref<TranslatedConnectionItem[] | undefined>,
): {
  onRefCreated: (ref: Element | ComponentPublicInstance | null) => void;
  connection: Ref<ConnectionInfo | undefined>;
  flattened: Ref<TranslatedConnectionItem[]>;
} {
  const flattened = computed(() => {
    if (flatList.value) return flatList.value;
    return flattenLayout([item.value]);
  });
  const connections = computed(() => {
    return flattened.value
      .filter((i) => !!i.item.doclistFilterFor || !!i.item.doclistSearchFor)
      .map((f) => [
        f.item.id,
        f.item.doclistFilterFor ?? f.item.doclistSearchFor!,
      ])
      .flat();
  });
  return {
    onRefCreated: (ref) =>
      addBlockListConnectionRef(
        item.value,
        flattened.value,
        ref as InstanceType<typeof BlockList>,
      ),
    connection: computed(() =>
      connections.value.includes(item.value.id)
        ? ({
            self_id: item.value.id,
            role: item.value.doclistFilterFor
              ? 'filter'
              : item.value.doclistSearchFor
                ? 'search'
                : 'list',
          } as ConnectionInfo)
        : undefined,
    ),
    flattened,
  };
}

export function getLayoutBlockId(parentId = '', index?: number) {
  if (parentId) parentId += ':';
  return `${parentId}${(index ?? 0) + 1}`;
}
export function flattenLayout<T extends LayoutItem | Translated<LayoutItem>>(
  items: T[],
  parentId?: string,
): ConnectionItem<T>[] {
  const res: ConnectionItem<T>[] = [];
  let index = 0;
  for (const i of items) {
    if (!i) return res;
    const id = getLayoutBlockId(parentId, index);
    res.push({ item: i, label: id });
    if (i.children) res.push(...flattenLayout(i.children as T[], id));
    index++;
  }
  return res;
}

const blockListConnections: {
  byFilterId: Ref<{ [key: Guid]: Omit<ConnectionRefs, 'filter'> }>;
  bySearchId: Ref<{ [key: Guid]: Omit<ConnectionRefs, 'filter'> }>;
  byListId: Ref<{ [key: Guid]: Omit<ConnectionRefs, 'list'> }>;
} = { byFilterId: ref({}), bySearchId: ref({}), byListId: ref({}) };
export function getConnectedFilterByListId(list: ConnectionInfo | undefined) {
  if (!list) return undefined;
  return blockListConnections.byListId.value[list.self_id]?.filter;
}
export function getConnectedListByFilterId(filter: ConnectionInfo) {
  return blockListConnections.byFilterId.value[filter.self_id]?.list;
}
export function getConnectedListBySearchId(filter: ConnectionInfo) {
  return blockListConnections.bySearchId.value[filter.self_id]?.list;
}
export function isConnectedFilter(id: Guid) {
  return !!blockListConnections.byFilterId.value[id];
}

function addBlockListConnectionRef(
  item: Pick<LayoutItem, 'id' | 'doclistFilterFor' | 'doclistSearchFor'>,
  lookupList: {
    item: Pick<LayoutItem, 'id' | 'doclistFilterFor' | 'doclistSearchFor'>;
  }[],
  ref: InstanceType<typeof BlockList> | InstanceType<typeof SearchBar>,
) {
  const addConnection = <
    K1 extends keyof typeof blockListConnections,
    K2 extends keyof (typeof blockListConnections)[K1]['value'][Guid],
  >(
    connectionType: K1,
    connectedType: K2,
    id: Guid,
  ) => {
    if (!blockListConnections[connectionType].value[id]) {
      blockListConnections[connectionType].value[id] = {};
    }
    (
      blockListConnections[connectionType].value[id] as {
        [key in typeof connectedType]: typeof ref;
      }
    )[connectedType] = ref;
  };

  if (item.doclistFilterFor) {
    const connectedListId = lookupList.find(
      (i) => i.item.id === item.doclistFilterFor,
    )?.item.id;
    if (!connectedListId) return; // connected to no longer existing component
    addConnection('byListId', 'filter', connectedListId);
  } else {
    const connectedFilters = lookupList.filter(
      (i) => i.item.doclistFilterFor === item.id,
    );
    connectedFilters.forEach((f) => {
      addConnection('byFilterId', 'list', f.item.id);
    });
    const connectedSearchBars = lookupList.filter(
      (i) => i.item.doclistSearchFor === item.id,
    );
    connectedSearchBars.forEach((f) => {
      addConnection('bySearchId', 'list', f.item.id);
    });
  }
}
