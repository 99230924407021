export function formatDate(
  date: Date | string | undefined,
  withTime = true,
  emptyValue = '',
) {
  if (!date) return emptyValue;
  if (typeof date === 'string') date = new Date(date);
  const dateString = date.toLocaleDateString('no-NO', {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
  });
  const timeString = date.toLocaleTimeString('no-NO', {
    hour: '2-digit',
    minute: '2-digit',
  });
  return dateString + (withTime ? ' ' + timeString : '');
}
