import { unref } from 'vue';
import { isRef, isReactive, isProxy, toRaw, type Ref } from 'vue';

/** Use to make sure logs are not showing reactive objects */
function deepToRaw<T>(sourceObj: T | Ref<T>): T {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const objectIterator = (input: any): any => {
    if (Array.isArray(input)) {
      return input.map((item) => objectIterator(item));
    } else if (isRef(input)) {
      return objectIterator(unref(input));
    } else if (isReactive(input) || isProxy(input)) {
      return objectIterator(toRaw(input));
    } else if (
      input &&
      typeof input === 'object' &&
      !(input instanceof Element)
    ) {
      return Object.keys(input).reduce((acc, key) => {
        acc[key as keyof typeof acc] = objectIterator(input[key]);
        return acc;
      }, {} as T);
    } else {
      return input;
    }
  };

  return objectIterator(sourceObj);
}
export function logProps<T extends { [key: string]: unknown }>(
  props: T,
  ...fields: (keyof T)[]
) {
  logRaw(Object.fromEntries(fields.map((field) => [field, props[field]])));
}
export function logRaw(
  input: { [key: string]: unknown | Ref<unknown> },
  identifier?: string,
  asObject = false,
) {
  const entries = Object.entries(input).map(([key, value]) => [
    key,
    value && typeof value === 'object' ? deepToRaw(unref(value)) : value,
  ]);
  identifier ??= entries[0][0] as string;
  if (asObject) {
    console.groupCollapsed(identifier, Object.fromEntries(entries));
  } else {
    console.groupCollapsed(identifier, entries[0][1]);
    entries.forEach(([key, value]) => console.log(key, value));
  }
  console.trace();
  console.groupEnd();
}

/** Assert for typescript that the value is not null/undefined. Also gives a runtime error (console.error or throw) if the value turns out to be null/undefined. */
export function assertDefined<T>(
  value: T | undefined | null,
  shouldThrow?: boolean,
  assertMsg?: string,
): value is T {
  const isDefined = value !== undefined && value !== null;
  assertMsg ??=
    'A value that was expected to not be null/undefined was found to be so. This is a bug that should be fixed.';
  if (!isDefined) {
    if (shouldThrow) {
      throw new Error(assertMsg);
    } else {
      console.error(assertMsg);
    }
  }
  return isDefined;
}
