<script setup lang="ts">
import { ParagraphShimmer } from 'vue3-shimmer';
import ButtonComponent from '@/components/ButtonComponent.vue';
import type { ApiDTO, ApiSchema, BlockApimDetails } from '@/api/types';
import { computed, ref, watchEffect } from 'vue';
import ApiOperationDetails from '../ApiOperationDetails.vue';
import { useI18n } from 'vue-i18n';
import { getApiData, getApiSubscriptions } from '@/api/apiM';
import { throwOnError, compose } from '@/api/composable';
import { useCurrentUser } from '@/api/auth';
import { apimproductsRouteId } from '@/config';

const { t } = useI18n();

const props = defineProps<{
  config: BlockApimDetails;
  versionNumber?: string;
}>();

const emit = defineEmits<{
  (e: 'update:hasSubscription', hasSubscription: boolean): void;
  (e: 'containerLoaded', container: HTMLElement): void;
}>();

const apiMContainer = ref<HTMLElement | null>(null);
const userInfo = useCurrentUser();

const { result: apiData } = props.config.apimId
  ? throwOnError(compose(getApiData(props.config.apimId)))
  : { result: ref<ApiDTO | null>(null) };

const hasSubscription = ref(false);
watchEffect(async () => {
  try {
    if (userInfo.value && props.config.apimId) {
      const subs = await getApiSubscriptions(props.config.apimId);
      hasSubscription.value = (subs?.length ?? 0) > 0;
      emit('update:hasSubscription', hasSubscription.value);
    }
  } catch (e) {
    apiData.value = null;
    throw e;
  }
});

const apiSchemas = computed<Record<string, ApiSchema> | null>(() =>
  apiData.value?.schema ? JSON.parse(apiData.value.schema)?.schemas : null,
);

/** For some reason, updates happens at the wrong time if we use a "normal ref" (wihout the setter function) */
const updateRef = (container: HTMLElement) => {
  if (apiData.value && !apiMContainer.value) {
    apiMContainer.value = container;
    emit('containerLoaded', container);
  }
};
</script>

<template>
  <div v-if="!props.config.apimId">
    {{ t('block.NoApi') }}
  </div>
  <div
    v-else
    :ref="(container) => updateRef(container as HTMLElement)"
    class="html-render mx-5 mt-16 md:mr-20"
  >
    <h2 id="Api-M">
      {{ t('block.ApiOperations') }}
    </h2>
    <ParagraphShimmer
      v-if="apiData === null"
      :lines="15"
      :random-size="true"
      class="mx-auto w-full max-w-5xl"
    />
    <p v-else-if="(apiData?.operations?.length ?? 0) === 0">
      {{ t('block.NoApiOperations') }}
    </p>
    <RouterLink
      v-else-if="!!userInfo && !hasSubscription"
      :to="{ name: apimproductsRouteId }"
      class="mb-4 block"
    >
      <ButtonComponent
        class="self-center"
        type="primary"
        :text="t('block.requestApiMAccess')"
      />
    </RouterLink>
    <div role="tablist" class="tabs tabs-lifted">
      <ApiOperationDetails
        v-for="(operation, index) in apiData?.operations"
        :key="operation.name"
        :operation="operation"
        :has-api-subscription="hasSubscription"
        :apim-schema="apiSchemas"
        :version-number="versionNumber"
        :initial-checked="index === 0"
        :endpoint-data="apiData"
      />
    </div>
  </div>
</template>

<style>
:root {
  --api-details-padding: 6.25rem;
}
</style>
