<script lang="ts" setup>
import type { PageTemplateProps } from '@/api/types';
import BlockPageBanner from '@/components/block/BlockPageBanner.vue';
import BlockRenderer from '@/components/block/BlockRenderer.vue';
import BlockToc from '@/components/block/BlockToc.vue';
import BlockDiscussion from '@/components/block/BlockDiscussion.vue';
import { ref } from 'vue';
import { useRoute } from 'vue-router';

defineProps<PageTemplateProps>();
const route = useRoute();
const containers = ref<{ idx: number; el: HTMLElement }[]>([]);
</script>

<template>
  <div
    v-if="route.meta.fullScreen"
    class="min-h-screen w-full grid-cols-2 items-start justify-center gap-8 p-2 [&_h2]:mb-4"
    :class="[
      `bg-gnist-${bgColor}`,
      fgColor ? `text-gnist-${fgColor}` : undefined,
    ]"
  >
    <BlockRenderer :block="block" />
  </div>
  <template v-else>
    <BlockPageBanner
      :title="block.name ?? ''"
      :block="block"
      :description="block.description ?? ''"
      :tags="block.tags ?? []"
      :show-version-selector="!block.customSchema"
      :is-preview="!!isPreview"
    />

    <!-- Content -->
    <div class="w-full" :class="`bg-gnist-${bgColor}`">
      <div class="gnist-page-width grow pb-16 lg:grid lg:grid-cols-4 lg:gap-10">
        <div class="relative col-span-1 ml-auto pt-8 lg:pb-24">
          <BlockToc :containers="containers" />
        </div>
        <div class="col-span-3">
          <div
            class="mx-5 mt-10"
            :class="fgColor ? `text-gnist-${fgColor}` : 'text-gnist-black'"
            data-cy-id="contentContainer"
            :data-cy-state="!!block.content ? 'loaded' : 'not_loaded'"
          >
            <BlockRenderer v-model:containers="containers" :block="block" />
          </div>
        </div>
      </div>
    </div>
    <BlockDiscussion :block="block" />
  </template>
</template>
