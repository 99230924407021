<script lang="ts">
export type selectorList = string | string[];
</script>
<script lang="ts" setup>
import { assertDefined } from '@/utilities/debugUtils';
import { throttleFireOnly } from '@/utilities/throttle';
import { useWaitForObservedElement } from '@/utilities/useWaitForObservedElement';
import { ref, useAttrs, watch } from 'vue';

defineOptions({
  inheritAttrs: false,
});

const props = defineProps<{
  visiblePartSelector?: selectorList;
  markers?: selectorList | selectorList[];
  loadedObserveSelector?: string;
  showPadding?: number;
  markerPadding?: number;
  markerStyle?: string;
}>();
const outerContainer = ref<HTMLElement | null>();
const innerContainer = ref<HTMLElement | null>();
const loaded = ref(false);
type Size = {
  width: number;
  height: number;
};
type OffsetPositions = {
  top: number;
  left: number;
};
type Rect = OffsetPositions & Size;
type InsetPositions = OffsetPositions & {
  bottom: number;
  right: number;
};
type RectWithAllInsetPositions = Rect & InsetPositions;

function ensureArray<T>(item: T | T[]): T[] {
  return Array.isArray(item) ? item : [item];
}

/** How far from the `outer container` is the part we want to be visible, also considering that we want all of the `outer container` to be filled with the `inner container` (i.e. don't move the `inner cotainer` **too** far) */
const innerContainerActualOffset = ref<OffsetPositions>();
/** The absolute position of the `outer container` on the page */
const outerContainerAbsPos = ref<RectWithAllInsetPositions>();
/** The absolute position of the `inner container` on the page */
const innerContainerAbsPos = ref<RectWithAllInsetPositions>();

/** Get the absolute position of an element within the page */
function getAbsoluteRect(el: Element): RectWithAllInsetPositions {
  const rect = el.getBoundingClientRect();
  const scrollX = window.scrollX;
  const scrollY = window.scrollY;
  return {
    top: Math.round(rect.y + scrollY),
    left: Math.round(rect.x + scrollX),
    bottom: Math.round(rect.y + scrollY + rect.height),
    right: Math.round(rect.x + scrollX + rect.width),
    width: rect.width,
    height: rect.height,
  };
}
/** Returns the width and height of a rectangle encompassing all the supplied elements. Also returns the top and left position of this rectangle, relative to the outer container */
function getRelativeRect(elements: Element[]): Rect {
  if (!assertDefined(outerContainerAbsPos.value, true)) throw '';
  if (!assertDefined(innerContainer.value, true)) throw '';
  innerContainerAbsPos.value = getAbsoluteRect(innerContainer.value);
  // Start by guessing that our target is as far from the edge on both sides as possible (i.e. left edge is all over at the right side, right edge is all over at the left side, top edge is all over at the bottom and bottom edge is all over at the top)
  // This starting position just needs to be greater than any of the elements we are looking for (that are inside the inner container), as we will move the position further and further towards the "correct" edge as needed.
  const targetAbsPos: InsetPositions = {
    top: innerContainerAbsPos.value.height,
    bottom: innerContainerAbsPos.value.height,
    left: innerContainerAbsPos.value.width,
    right: innerContainerAbsPos.value.width,
  };
  /** Expand `targetAbsPos` if needed, i.e. if the distance from the edge of the outer container to the element is 200 and the _currently wanted distance_ is 500, change it to 200. If the _currently wanted distance_ is 100, keep that value.\
   * The initial value is "all the way on the other side", so this will gradually move us towards the correct position. */
  const getMinRelative = (
    elAbsPos: InsetPositions,
    key: keyof InsetPositions,
  ) => {
    let distanceFromOuterContainerEdge = 0;
    if (key === 'top' || key === 'left') {
      distanceFromOuterContainerEdge =
        elAbsPos[key] - outerContainerAbsPos.value![key]; // For top and left, the relative distance is the absolute position of the element minus the absolute position of the outer container
    } else if (key === 'bottom' || key === 'right') {
      distanceFromOuterContainerEdge =
        outerContainerAbsPos.value![key] - elAbsPos[key]; // For bottom and right, the relative distance is the absolute position of the outer container minus the absolute position of the element
    } else {
      throw new Error('Not implemented');
    }
    return Math.min(targetAbsPos[key], distanceFromOuterContainerEdge);
  };
  for (const element of elements) {
    if (!element.checkVisibility()) {
      console.debug(
        'Element is not visible when calculating relative rect, skipping',
        element,
      );
      continue;
    }
    /** Absolute position on the page of this element */
    const elAbsPos = getAbsoluteRect(element);
    targetAbsPos.top = getMinRelative(elAbsPos, 'top');
    targetAbsPos.left = getMinRelative(elAbsPos, 'left');
    targetAbsPos.bottom = getMinRelative(elAbsPos, 'bottom');
    targetAbsPos.right = getMinRelative(elAbsPos, 'right');
  }
  return {
    top: targetAbsPos.top,
    left: targetAbsPos.left,
    width:
      outerContainerAbsPos.value.width -
      (targetAbsPos.right + targetAbsPos.left),
    height:
      outerContainerAbsPos.value.height -
      (targetAbsPos.bottom + targetAbsPos.top),
  };
}
/** Clip so only what we want to see is visible:
 *  1. Change the outer container to have the height and width of what we want to show (as defined by `showRect`) + a bit of padding.
 *  2. Position the inner container so that what we want to show is in the middle of the image.
 *
 *  Also, you may supply a _wanted_ X and/or Y center value. If that is supplied, try to center that value if the visible portion. */
function setVisibilityClipping(
  wantedCenterX = 0,
  wantedCenterY = 0,
): Element[] {
  if (!assertDefined(outerContainer.value?.parentElement)) return [];
  if (!assertDefined(innerContainer.value)) return [];

  // Reset these so the calculations can be correct
  innerContainer.value.style.top = '0px';
  innerContainer.value.style.left = '0px';
  outerContainerAbsPos.value = getAbsoluteRect(outerContainer.value);

  if (!props.visiblePartSelector) return [];

  const visiblePartSelector = ensureArray(props.visiblePartSelector);
  const visiblePartElements = visiblePartSelector
    .map((selector): Element[] =>
      Array.from(innerContainer.value?.querySelectorAll(selector) ?? []),
    )
    .flat();

  /** The relative position (and size) of the part we want to be visible, related to the outer container. */
  const visiblePartRect = getRelativeRect(visiblePartElements);
  const padding = props.showPadding ?? 0;

  outerContainer.value.style.height = `${visiblePartRect.height + padding * 2}px`;
  outerContainer.value.style.width = `${visiblePartRect.width + padding * 2}px`;

  const wantedTopOffset =
    wantedCenterY > 0
      ? Math.max(wantedCenterY - outerContainer.value.clientHeight / 2, 0) // Try to get the wanted center in the center
      : Math.max(visiblePartRect.top - padding, 0); // Ensure we don't go to an offset beyond 0, even after considering the padding
  // Ensure that the bottom of the inner container is not above the bottom of the outer container
  const maxTopOffset = Math.max(
    innerContainer.value.clientHeight - outerContainer.value.clientHeight,
    0,
  );

  const wantedLeftOffset =
    wantedCenterX > 0
      ? Math.max(wantedCenterX - outerContainer.value.clientWidth / 2, 0) // Try to get the wanted center in the center
      : Math.max(visiblePartRect.left - padding, 0); // Ensure we don't go to an offset beyond 0, even after considering the padding

  // Ensure that the right side of the inner container is not to the left of the right side of the outer container
  const maxLeftOffset = Math.max(
    innerContainer.value.clientWidth - outerContainer.value.clientWidth,
    0,
  );
  innerContainerActualOffset.value = {
    top: Math.min(wantedTopOffset, maxTopOffset),
    left: Math.min(wantedLeftOffset, maxLeftOffset),
  };

  innerContainer.value.style.top = `-${innerContainerActualOffset.value.top}px`;
  innerContainer.value.style.left = `-${innerContainerActualOffset.value.left}px`;
  return visiblePartElements;
}

const markerDivs = ref<HTMLElement[]>([]);
function addMarker({
  top: offsetFromOuterContainerTop,
  left: offsetFromOuterContainerLeft,
  width,
  height,
}: Rect) {
  if (!assertDefined(innerContainer.value)) return;

  const wantedLeftOffset =
    offsetFromOuterContainerLeft +
    (innerContainerActualOffset.value?.left ?? 0) -
    (props.markerPadding ?? 0);
  const lastVisibleLeft =
    (outerContainerAbsPos.value?.width ?? 0) +
    (innerContainerActualOffset.value?.left ?? 0);
  const markerWidth = width + (props.markerPadding ?? 0) * 2;
  const wantedTopOffset =
    offsetFromOuterContainerTop +
    (innerContainerActualOffset.value?.top ?? 0) -
    (props.markerPadding ?? 0);
  const lastVisibleTop =
    (outerContainerAbsPos.value?.height ?? 0) +
    (innerContainerActualOffset.value?.top ?? 0);
  const markerHeight = height + (props.markerPadding ?? 0) * 2;

  // If the marker is outside the visible portion of the window, move the visible portion
  // If we have multiple markers, we simplify and only consider the last marker
  if (
    wantedLeftOffset + markerWidth > lastVisibleLeft ||
    wantedTopOffset + markerHeight > lastVisibleTop
  ) {
    setVisibilityClipping(
      wantedLeftOffset + parseInt(`${markerWidth / 2}`),
      wantedTopOffset + parseInt(`${markerHeight / 2}`),
    );
  }

  const markerDiv = document.createElement('div');
  markerDiv.style.position = 'absolute';
  markerDiv.style.top = `${wantedTopOffset}px`;
  markerDiv.style.left = `${wantedLeftOffset}px`;
  markerDiv.style.width = `${markerWidth}px`;
  markerDiv.style.height = `${markerHeight}px`;
  markerDiv.style.border = props.markerStyle ?? '2px solid red';
  innerContainer.value.appendChild(markerDiv);
  markerDivs.value.push(markerDiv);
}
function setMarkers() {
  if (!props.markers) return;
  if (!assertDefined(innerContainer.value)) return;
  if (markerDivs.value.length > 0) {
    markerDivs.value.forEach((m) => m.remove());
    markerDivs.value = [];
  }
  const markers = ensureArray(props.markers);
  for (const marker of markers) {
    const markerSelectors = ensureArray(marker);
    const markerElements = markerSelectors
      .map((selector) =>
        Array.from(innerContainer.value?.querySelectorAll(selector) ?? []),
      )
      .flat();
    addMarker(getRelativeRect(markerElements));
  }
}

function updateUi(addContentChangedObserver?: boolean) {
  if (!loaded.value) return;
  if (!assertDefined(outerContainer.value)) return;
  if (!assertDefined(innerContainer.value)) return;
  const visiblePartElements = setVisibilityClipping();
  setMarkers();
  if (addContentChangedObserver) {
    // If some javascript in the shown elements changes the DOM, we need to recalculate the markers positions
    for (const target of visiblePartElements) {
      const observer = new MutationObserver(function () {
        setMarkers();
      });
      observer.observe(target, {
        childList: true,
        subtree: true,
        attributes: true,
      });
    }
  }
}
watch(loaded, () => updateUi(true));

watch(outerContainer, () => {
  if (!outerContainer.value) return;
  if (props.loadedObserveSelector) {
    const loadMarker = useWaitForObservedElement(
      props.loadedObserveSelector,
      outerContainer.value,
      true,
    );
    watch(loadMarker, () => {
      if (loaded.value) return;
      if (loadMarker.value) loaded.value = true;
    });
  } else {
    loaded.value = true;
  }
});

const parentContainer = ref<HTMLElement | null>(null);
watch(parentContainer, () => {
  if (!parentContainer.value) return;
  const resizeObserver = new ResizeObserver(
    throttleFireOnly(500, () => updateUi()),
  );
  resizeObserver.observe(parentContainer.value);
});

const attrs = useAttrs();
</script>
<template>
  <div ref="parentContainer" class="mx-10 my-2">
    <div class="w-max max-w-full border p-1" data-id="borderContainer">
      <div
        ref="outerContainer"
        class="relative max-w-full overflow-hidden"
        data-id="outerContainer"
      >
        <!-- Add an overlay that covers the component so it cannot be interacted with-->
        <div class="absolute inset-0 z-overlay">&nbsp;</div>
        <div
          ref="innerContainer"
          v-bind="attrs"
          data-id="innerContainer"
          class="bg-gnist-white"
        >
          <slot />
        </div>
      </div>
    </div>
    <slot name="text" />
  </div>
</template>
