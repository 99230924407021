import { ref, type Ref } from 'vue';
import { refreshCurrentUser, type RoleName, type RoleType } from '@/api/auth';
import type { Role, User, UserNotificationSettings } from '@/api/types';
import { apiUrl } from '@/config';
import { compose, type ComposableResult } from '@/api/composable';

export function useUsers(): Ref<User[] | null> {
  const users = ref<User[] | null>(null);
  fetch(`${apiUrl}/users`)
    .then((r) => (r.ok ? r.json() : Promise.resolve([])))
    .then((v) => (users.value = v))
    .catch(() => (users.value = []));
  return users;
}

export function useRoles(types?: RoleType[]): ComposableResult<Role[]> {
  return compose(
    (async (): Promise<Role[]> => {
      const query = types ? `?types=${types.join(',')}` : '';
      const response = await fetch(`${apiUrl}/roles${query}`);
      if (!response.ok) throw new Error('error.get_roles_failed');
      const result: (Omit<Role, 'types'> & { type: string })[] =
        await response.json();
      return result.map(
        (r): Role => ({
          id: r.id,
          name: r.name,
          types: r.type.split(', ').filter((t) => !!t) as RoleType[],
        }),
      );
    })(),
  );
}

export function useRoleMembers(role: RoleName): ComposableResult<User[]> {
  return compose(
    (async (): Promise<User[]> => {
      const response = await fetch(`${apiUrl}/roles/${role}/members`);
      if (!response.ok) throw new Error('error.get_rolemembers_failed');
      return await response.json();
    })(),
  );
}

export async function updateRoles(userId: string, roleIds: number[]) {
  fetch(`${apiUrl}/roles/user/${userId}`, {
    method: 'PUT',
    credentials: 'include',
    body: JSON.stringify(roleIds),
    headers: { 'Content-Type': 'application/json' },
  });
}

export const updateUser = async (user: User & UserNotificationSettings) => {
  await fetch(apiUrl + '/users/current', {
    method: 'PUT',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(user),
  });
  await refreshCurrentUser();
};

export async function deleteUserAccount(includeB2C = false) {
  const response = await fetch(
    `${apiUrl}/users/current${includeB2C ? '?keepLoginHint=true' : ''}`,
    {
      method: 'DELETE',
      credentials: 'include',
    },
  );
  if (!response.ok) {
    console.error(
      `Error deleting user account: ${response.statusText}`,
      response,
    );
    throw new Error('error.delete_user_failed');
  }
  if (includeB2C) {
    window.location.assign(
      `${apiUrl}/auth/delete_user?rd=${encodeURIComponent(
        window.location.href.replace(window.location.origin, ''),
      )}`,
    );
  } else {
    window.location.assign('/');
  }
}
