import type { Suggestion } from '@/api/types';

type VssUserStoryStatus =
  | 'New'
  | 'On Hold'
  | 'Active'
  | 'Resolved'
  | 'Closed'
  | 'Removed';
type VssBugStatus =
  | 'New'
  | 'On Hold'
  | 'Active'
  | 'Review'
  | 'Resolved'
  | 'Closed';
export type VssStatus = VssUserStoryStatus | VssBugStatus;

const suggestionProgressStatuses = ['Planned', 'InProgress'] as const;
type SuggestionProgressStatus = (typeof suggestionProgressStatuses)[number];
const suggestionInternalStatuses = [
  'New',
  'UnderConsideration',
  'InAzureDevOps',
  'Done',
  'Closed',
] as const;
export type SuggestionInternalStatus =
  (typeof suggestionInternalStatuses)[number];
export const suggestionManualStatuses: SuggestionStatus[] = [
  'New',
  'UnderConsideration',
  'Closed',
];
export type SuggestionStatus =
  | Exclude<SuggestionInternalStatus, 'InAzureDevOps'>
  | SuggestionProgressStatus;

export function getSuggestionStatus(
  suggestion: Pick<Suggestion, 'status' | 'workItem'> | null | undefined,
): SuggestionStatus | null {
  if (!suggestion?.status) return null;
  const workItemStatus = suggestion.workItem?.status;

  if (!workItemStatus) {
    if (suggestion.status === 'InAzureDevOps') return 'Planned';
    else return suggestion.status;
  } else if (workItemStatus === 'Removed') return 'Closed';
  else if (workItemStatus === 'Closed') {
    return 'Done';
  } else if (workItemStatus === 'New') return 'Planned';
  else return 'InProgress';
}
