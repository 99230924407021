<script setup lang="ts">
import semver from 'semver';
import { useVersions } from '@/api/versions';
import { Status, type Block } from '@/api/types';
import { computed } from 'vue';
import VersionContextMenu from '@/components/admin/VersionContextMenu.vue';
import VersionCopy from '@/components/admin/VersionCopy.vue';
import { formatDate } from '@/utilities/formatDate';
import { useI18n } from 'vue-i18n';
import { pageTemplates } from '@/renderTemplates';
import { translateStringOrLocale } from '@/i18n';
import TableComponent from '../TableComponent.vue';

const { t } = useI18n();

const props = defineProps<{
  block: Block;
}>();

const emit = defineEmits<{ (e: 'refreshBlock'): void }>();

const { result: versions, refresh } = useVersions(
  props.block.blockId,
  true,
  false,
);

const versionWarning = computed(() => ({
  show: !pageTemplates.find(
    (i) => i.id == props.block?.category?.pageTemplateId,
  )?.hasVersionSelector,
  category: translateStringOrLocale(() => props.block?.category?.name).value,
}));
</script>
<template>
  <h2>
    {{ t('block.versions') }}
  </h2>
  <p class="text-p2 py-4 italic">
    {{ t('admin.blockProduction.versionsSubtitle') }}
  </p>
  <p v-if="versionWarning.show" class="px-8 pb-4">
    <span class="alert alert-warning max-w-max rounded text-gnist-black">
      {{ t('admin.blockProduction.versionsFullpage', versionWarning) }}
    </span>
  </p>

  <TableComponent
    size="table-sm"
    no-zebra
    column-header-class="font-[number:inherit]"
    text-color="sp-blue-dark"
    header-text-color="black"
    class="VersionListTable"
    :rows="versions"
    i18n-key="admin.blockProduction"
    :get-key="(version) => version.versionNumber"
    :columns="[
      'versionNumber',
      'isBacklog',
      'status',
      'draftStatus',
      'publishedDate',
      'lastChanged',
      null,
    ]"
    sort-fields="versionNumber"
    :comparers="{
      versionNumber: (v1, v2) => {
        const v1Parsed = semver.coerce(v1);
        const v2Parsed = semver.coerce(v2);
        if (v1Parsed && v2Parsed) return semver.compare(v1Parsed, v2Parsed);
        else if (v1Parsed && v2Parsed === null)
          return semver.compare(v1Parsed, '0.0.0');
        else if (v1Parsed === null && v2Parsed)
          return semver.compare('0.0.0', v2Parsed);
        return v1.localeCompare(v2);
      },
    }"
  >
    <template #columnHeader="{ item: version }">
      <RouterLink
        :to="{
          name: 'edit_version',
          params: {
            blockId: block.blockId,
            versionId: version.versionId,
          },
        }"
        class="underline"
      >
        <span>{{ version.versionNumber }}</span>
        <span
          v-if="version.versionNumber === block.currentVersionNumber"
          class="italic"
        >
          ({{ t('admin.blockProduction.current') }})
        </span>
      </RouterLink>
    </template>
    <template #columns="{ item: version, rowIndex: idx }">
      <td>
        <p>
          {{ t(`booleans.${version.isBacklog}`) }}
        </p>
      </td>
      <td>
        <p data-cy-id="PublishedStatus">
          <span :class="{ italic: version.status === Status.Draft }">
            {{ t(`block.status.${version.status}`) }}
          </span>
        </p>
      </td>
      <td>
        <p>
          <span v-if="version.draftStatus">
            {{ t(`block.draftStatus.${version.draftStatus}`) }}
          </span>
          <span v-else-if="version.status === Status.Draft">
            {{ t(`block.draftStatus.${version.status}`) }}
          </span>
        </p>
      </td>
      <td>
        <p v-if="version.status === Status.Published">
          {{ formatDate(version.publishedDate) }}
        </p>
        <p v-else class="justify-self-center">-</p>
      </td>
      <td>
        <p>
          {{ formatDate(version.lastChanged) }}
        </p>
      </td>
      <td>
        <VersionContextMenu
          :block="block"
          :all-versions="versions"
          :version="version"
          :idx="idx"
          @refresh-block="
            async (noBubble?) => {
              if (refresh) await refresh();
              if (!noBubble) emit('refreshBlock');
            }
          "
        />
      </td>
    </template>
  </TableComponent>
  <VersionCopy
    :block-id="block.blockId"
    :versions="versions"
    class="mx-auto mt-16 w-fit"
    @created="
      async () => {
        if (refresh) await refresh();
        emit('refreshBlock');
      }
    "
  />
</template>

<style>
.VersionListTable,
.VersionListTable table,
.VersionListTable * {
  @apply static;
}
.VersionListTable table {
  @apply border-y-2 border-gnist-gray-light p-2;
}
.VersionListTable thead {
  @apply border-y-2;
}
.VersionListTable.VersionListTable :not(tfoot) tr {
  font-size: initial;
}
.VersionListTable tr > * {
  @apply p-2;
}
.VersionListTable thread tr > * {
  @apply py-2.5;
}
</style>
