<script setup lang="ts">
import type { VueClass } from '@/api/types';
import MaterialIcon from '@/components/MaterialIcon.vue';
import { useResizeListener } from '@/utilities/useResizeListener';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const props = withDefaults(
  defineProps<{
    text: string;
    type?: 'primary' | 'danger' | 'special';
    disabled?: boolean;
    submit?: boolean;
    icon?: string;
    /** Add button "chrome". Defaults to false for "icon buttons without text", true for other buttons. */
    chrome?: boolean;
    iconClass?: VueClass;
    /** If using an icon, sets the icon to support WCAG SC 2.5.5: The size of the target for pointer inputs is at least 44 by 44 CSS pixels */
    accessibleButton?: boolean;
    truncateOverflow?: boolean;
    /** Tooltips are always on for icon buttons or if truncating on small screens. Supply this if you want tooltips in other scenarios. */
    useTooltip?: boolean;
    iconWithText?: boolean;
    /** Use this if the button is part of a group of selections. The button should be inside a container with the `listbox` role. */
    selected?: boolean;
  }>(),
  {
    type: undefined,
    icon: undefined,
    iconClass: undefined,
    chrome: undefined,
    selected: undefined,
  },
);

const { t } = useI18n();

const slots = defineSlots<{
  /** Use if you want totally custom content in the button. */
  customContent?: () => Element;
}>();

const showChrome = computed(() => {
  if (props.icon && !props.iconWithText) {
    return props.chrome;
  } else {
    return props.chrome !== false; // Only an explicit false will return false
  }
});

const { isBelowSmall } = useResizeListener();
</script>

<template>
  <button
    :disabled="disabled"
    :type="submit ? 'submit' : 'button'"
    :class="{
      'gnist-button': showChrome,
      [`gnist-button-${type}`]: showChrome && type,
      'min-h-11 min-w-11': accessibleButton,
      'tooltip before:text-wrap':
        (isBelowSmall && truncateOverflow) || icon || useTooltip,
      'min-w-0 max-w-full': truncateOverflow,
      'gap-2': iconWithText,
    }"
    :aria-label="t(text)"
    :data-tip="t(text)"
    :role="selected === undefined ? undefined : 'option'"
    :aria-selected="selected"
  >
    <MaterialIcon
      v-if="!!icon"
      :class="[
        {
          'text-gnist-red': type === 'danger',
          'text-3xl': !iconClass && !accessibleButton,
          'text-4xl': !iconClass && accessibleButton,
        },
        iconClass,
      ]"
      :accessible-button="accessibleButton"
    >
      {{ icon }}
    </MaterialIcon>
    <template v-if="slots.customContent">
      <slot name="customContent" />
    </template>
    <span
      v-else-if="!icon || iconWithText"
      :class="{ truncate: truncateOverflow }"
    >
      {{ t(text) }}
    </span>
  </button>
</template>
