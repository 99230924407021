<script setup lang="ts">
import type {
  validationInfo,
  Colors,
  BlockLocaleValue,
  Translated,
} from '@/api/types';
import { computed, defineAsyncComponent, ref, watch, watchEffect } from 'vue';
import { useI18n } from 'vue-i18n';
import InputLabel, {
  useDynamicLabelTexts,
  type IInputLabelProps,
} from './InputLabel.vue';
import { ParagraphShimmer } from 'vue3-shimmer';
import type { EditorType } from '@toast-ui/editor';
import type { IBlobUrlInput } from '@/api/blob';
import type {
  ExposePlaceEditor,
  ExtraButtonOptions,
} from '@/components/forms/InputMarkdownTypes';
import ButtonComponent from '@/components/ButtonComponent.vue';
import MarkdownRenderer from '@/components/block/MarkdownRenderer.vue';
import { assertDefined } from '@/utilities/debugUtils';
import { useStickyToolbar } from '@/utilities/markdown/useStickyToolbar';

// TORGST, 18.8.23: IMPORTANT! This needs to be loaded async to ensure correct order of CSS (at least in dev)!!!
const MarkdownEditor = defineAsyncComponent({
  loader: () => import('@/components/admin/MarkdownEditor.vue'),
  loadingComponent: ParagraphShimmer,
});

const { t } = useI18n();

const props = withDefaults(
  defineProps<
    IInputLabelProps & {
      modelValue?: Translated<BlockLocaleValue>;
      /** If not supplied, the Editor will not support images or "blob links" (links to files uploaded for this document) */
      blobLocation?: IBlobUrlInput;
      showValidationMessage?: boolean;
      previewBgColor?: Colors;
      /** Used for connecting label and input */
      forName: string;
      editorType?: EditorType;
      editorHeight?: string;
      valWhenUndef?: string;
      hideFilepicker?: boolean;
      requestCleanup?: boolean;
      /** Underline is normally not supported in markdown as this usually means a link on the web.
       *  However, we want to allow underlines if the markdown is part of a layout component block that is a link. */
      canUnderline?: boolean;
      /** If true, the editor will not have a limited height, but rather flow as a part of the document */
      flowing?: boolean;
      /** A class to add to the toolbar for the editor. Can be used e.g. to ensure the correct width of the toolbar when it starts "sticking. */
      toolbarClass?: string;
      hideMainEditorDiv?: boolean;
      floatingToolbar?: boolean;
      /** Floating toolbars are always sticky, but add this if you want sticky without floating */
      stickyToolbar?: boolean;
      mainEditor?: ExposePlaceEditor;
      extraButtons?: ExtraButtonOptions;
      noMovedHeight?: boolean;
      /** Let this input be a self contained preview / edit button / placing unit (using an external "parent editor") */
      selfPlace?: boolean;
    }
  >(),
  {
    modelValue: undefined,
    minlength: undefined,
    maxlength: undefined,
    blobLocation: undefined,
    showValidationMessage: false,
    previewBgColor: 'white',
    editorType: 'wysiwyg',
    editorHeight: '45rem',
    valWhenUndef: undefined,
    placeholder: undefined,
    hideFilepicker: false,
    requestCleanup: false,
    toolbarClass: undefined,
    mainEditor: undefined,
    extraButtons: undefined,
  },
);

const emit = defineEmits<{
  (e: 'update:modelValue', value: Translated<BlockLocaleValue>): void;
  (e: 'reportValidationError', name: string, hasError: boolean): void;
  (e: 'cleanup:modelValue', value: string): void;
}>();

const internalState = ref('');
const value = computed({
  get() {
    return props.modelValue === undefined
      ? internalState.value
      : !props.modelValue
        ? props.valWhenUndef ?? ''
        : typeof props.modelValue === 'string'
          ? props.modelValue
          : JSON.stringify(props.modelValue);
  },
  set(value) {
    internalState.value = value;
    onUpdateModelValue.value?.(value);
    emit('update:modelValue', value);
  },
});

function satisfiesRequiredRule(): validationInfo | null {
  return !props.required || value.value ? null : { key: 'required', args: {} };
}

function satisfiesMinlengthRule(): validationInfo | null {
  return !props.minlength || value.value.length >= props.minlength
    ? null
    : { key: 'minLength', args: { min: props.minlength } };
}

function satisfiesMaxlengthRule(): validationInfo | null {
  return !props.maxlength || value.value.length <= props.maxlength
    ? null
    : { key: 'maxLength', args: { max: props.maxlength } };
}

const validationErrors = computed((): validationInfo[] => {
  return [
    satisfiesRequiredRule(),
    satisfiesMinlengthRule(),
    satisfiesMaxlengthRule(),
  ].filter((i) => i !== null) as validationInfo[];
});

function performValidation() {
  emit(
    'reportValidationError',
    props.forName,
    validationErrors.value.length > 0,
  );
}

const validationMessage = computed(() =>
  validationErrors.value
    .map((i) => t(`validation.${i.key}`, i.args))
    .join('\n'),
);

watchEffect(() => {
  performValidation();
});
/** This is where we put the toolbar coming from MarkdownEditor (a bit down in a DOM tree created for the toolbar) */
const movedToolbarRef = ref<HTMLElement | null>(null);
/** This is where we put the input part coming from MarkdownEditor (we need access to it because we are watching it for intersection changes) */
const movedInputRef = ref<HTMLElement | null>(null);
/** This is the parent DOM element where we place the toolbar */
const toolbarParentRef = ref<HTMLElement | null>(null);
/** This points to the actual MarkdownEditor instance */
const editorRef = ref<InstanceType<typeof MarkdownEditor>>();
/** A helper div used for observing when we are ready to stick the toolbar to the top of the screen */
const intersectionHelperDivRef = ref<HTMLElement | null>(null);
/** The toolbar is put here after moving the editor if no other target location is available */
const backupToolbarTargetRef = ref<HTMLElement | null>(null);
const afterEditorRef = ref<HTMLElement | null>(null);

const {
  startObserveTop,
  startObserveBottom,
  stopObserve,
  topAboveViewport,
  movingBottomAboveStickyBottom: editorAboveToolbarBottom,
} = useStickyToolbar(intersectionHelperDivRef, {
  sticky: movedToolbarRef,
  moving: movedInputRef,
});

const isPlaced = ref(false);
const onUpdateModelValue = ref<(val: string) => void>();
const onUnplacedRef = ref<() => void>();

function placeSelf(
  externalToolbarParent: HTMLElement | null,
  connect?: boolean,
) {
  if (!props.mainEditor) return;
  if (!assertDefined(movedToolbarRef.value)) return;
  if (!assertDefined(movedInputRef.value)) return;
  props.mainEditor.placeEditor(
    movedInputRef.value,
    movedToolbarRef.value,
    externalToolbarParent,
    () => (isPlaced.value = false),
  );
  if (connect) {
    props.mainEditor.connectText(value.value, (val) => (value.value = val));
  }
  isPlaced.value = true;
}

// prettier-ignore
function placeEditor(externalInputTarget: HTMLElement, externalToolbarTarget?: HTMLElement): void;
// prettier-ignore
function placeEditor(externalInputTarget: HTMLElement, externalToolbarTarget: HTMLElement, toolbarParent: HTMLElement | null, onUnplaced: () => void): void;
function placeEditor(
  externalInputTarget: HTMLElement,
  externalToolbarTarget: HTMLElement | null | undefined,
  /** Should only be supplied if called from a "child" editor and that child editor has no externalToolbarTarget */
  externalToolbarParent?: HTMLElement | null,
  onUnplaced?: () => void,
) {
  if (!assertDefined(toolbarParentRef.value)) return;
  if (!assertDefined(movedToolbarRef.value)) return;
  if (!assertDefined(movedInputRef.value)) return;

  if (props.mainEditor) {
    placeSelf(externalToolbarTarget ? null : toolbarParentRef.value); // If no toolbar target is supplied, let the parent (mainEditor) select the target - but inject our "parent" into that target
    externalInputTarget.append(movedInputRef.value);
    if (externalToolbarTarget) {
      externalToolbarTarget.append(toolbarParentRef.value); // We do not use "backupToolbarTargetRef" as a fallback - if we don't have an external target, just keep it in the "parent" editor
    }
  } else {
    onUnplacedRef.value?.();
    const isStandaloneEditor = onUnplaced === undefined;
    if (!isStandaloneEditor) onUnplacedRef.value = onUnplaced;

    const targetForEditorInput = isStandaloneEditor
      ? movedInputRef.value
      : externalInputTarget;
    const targetForEditorToolbar = isStandaloneEditor
      ? movedToolbarRef.value
      : externalToolbarTarget!; // The overload that is called from child editors requires there to be a toolbar target (the childs movedToolbarRef), thus the bang

    if (!assertDefined(editorRef.value)) return;
    editorRef.value.placeEditor(targetForEditorInput, targetForEditorToolbar);

    if (!isStandaloneEditor && !externalToolbarParent) {
      return; // The child will handle the final placement itself
    }

    isPlaced.value = true;
    externalInputTarget.append(movedInputRef.value);

    if (!externalToolbarTarget || externalToolbarParent) {
      if (!assertDefined(backupToolbarTargetRef.value)) return;
      // There is no externalToolbarTarget, use the "backupToolbarTargetRef" as a fallback.
      // If this is a "parent" editor, that means the "child" has no externalToolbarTarget and sends us an externalToolbarParent that it wants us to put into our backupToolbarTargetRef.
      externalToolbarTarget = backupToolbarTargetRef.value;
    }
    const toolbarParent = isStandaloneEditor
      ? toolbarParentRef.value
      : externalToolbarParent!; // We have already returned if this is *not* a standalone editor and there is no externalToolbarParent, thus the bang
    externalToolbarTarget.append(toolbarParent);
  }
}
watch(isPlaced, () => {
  if (isPlaced.value) {
    startObserveTop();
    startObserveBottom();
  } else {
    stopObserve();
  }
});
function focusAfterToolbar() {
  (props.mainEditor ?? editorRef.value)?.focusAfterToolbar();
}
defineExpose<ExposePlaceEditor>({
  placeEditor,
  connectText: (val: string, onUpdate: (val: string) => void) => {
    if (props.mainEditor) {
      props.mainEditor.connectText(val, onUpdate);
    } else {
      onUpdateModelValue.value = onUpdate;
      value.value = val;
    }
  },
  unPlaceEditor(caller?: 'internal') {
    if (props.mainEditor) {
      props.mainEditor.unPlaceEditor('internal');
    } else {
      if (caller !== 'internal') {
        if (!assertDefined(editorRef.value)) return;
        editorRef.value.unPlaceEditor();
      }
      onUnplacedRef.value?.();
    }
  },
  focusAfterToolbar,
});
const texts = useDynamicLabelTexts(props);
</script>

<template>
  <label :for="forName" class="flex flex-col">
    <InputLabel
      v-bind="props"
      :label-end="!!maxlength ? `${value.length} / ${maxlength}` : undefined"
      :class="{
        'border-b-2 border-l-2 pl-2 font-bold': selfPlace && !isPlaced,
      }"
    />
    <div
      v-if="!hideMainEditorDiv || isPlaced"
      tabindex="0"
      class="sr-only focus:not-sr-only"
    >
      {{ t('admin.blockProduction.escapeEditor') }}
    </div>
    <div
      class="flex flex-col"
      :data-cy-id="`InputMarkdown_${texts.forName}`"
      :style="`min-height: ${hideMainEditorDiv ? 0 : editorHeight};`"
      @keyup.exact.esc="afterEditorRef?.focus()"
      @keyup.shift.esc="focusAfterToolbar()"
    >
      <div
        ref="backupToolbarTargetRef"
        class="empty:hidden"
        data-id="backupToolbar"
      />
      <template v-if="!mainEditor">
        <span
          v-if="showValidationMessage && validationMessage"
          :data-tip="validationMessage"
          class="tooltip tooltip-open tooltip-warning whitespace-break-spaces"
        >
        </span>
        <MarkdownEditor
          ref="editorRef"
          v-model="value"
          :blob-location="blobLocation"
          :preview-bg-color="previewBgColor"
          :editor-type="editorType"
          :editor-height="editorHeight"
          :placeholder="texts.placeholder"
          :hide-filepicker="hideFilepicker"
          :request-cleanup="requestCleanup"
          :can-underline="canUnderline"
          :flowing="flowing"
          :sticky-toolbar="stickyToolbar"
          :hide-main-editor-div="hideMainEditorDiv"
          :no-moved-height="noMovedHeight"
          @cleanup:model-value="(value) => emit('cleanup:modelValue', value)"
        />
      </template>
      <div
        v-else-if="selfPlace && !isPlaced"
        class="grid grow items-stretch justify-items-stretch overflow-hidden"
        :style="`height: ${editorHeight};`"
      >
        <MarkdownRenderer
          :value="value"
          :blob-location="blobLocation ?? 'public'"
          class="peer z-10"
          inert
        />
        <div
          class="absolute inset-0 grid items-start justify-items-center bg-gnist-gray-light-light bg-opacity-75 pt-4 opacity-0 focus-within:z-10 focus-within:opacity-100 hover:z-10 hover:opacity-100 peer-hover:z-10 peer-hover:opacity-100"
        >
          <ButtonComponent
            text="admin.blockProduction.placeEditor"
            class="z-20"
            @click.prevent="placeSelf(backupToolbarTargetRef, true)"
          />
        </div>
      </div>
      <div
        v-show="isPlaced && !editorAboveToolbarBottom"
        ref="toolbarParentRef"
        class="ToolbarParent"
        :class="{
          floatingToolbar,
          stickyToolbar: floatingToolbar || stickyToolbar,
        }"
      >
        <div ref="intersectionHelperDivRef" class="IntersectionHelperDiv" />
        <div
          :class="{
            ToolbarFixed: topAboveViewport,
            'gnist-page-width': floatingToolbar || stickyToolbar,
          }"
        >
          <div class="Overlay">
            <div
              class="CombinedToolbar"
              :class="{
                'shadow-lg shadow-black': floatingToolbar,
                Combined: extraButtons?.insideMainToolbar,
              }"
            >
              <div
                ref="movedToolbarRef"
                class="min-w-0"
                data-id="InputMarkdownToolbarTarget"
              />
              <div
                class="ExtraButtons"
                :class="
                  extraButtons?.containerClass ??
                  (extraButtons?.insideMainToolbar ? undefined : 'px-2')
                "
              >
                <button
                  v-for="(button, index) in extraButtons?.buttons"
                  :key="`extrabutton_${index}`"
                  class="tooltip tooltip-left"
                  :class="[
                    extraButtons?.buttonClass ??
                      (extraButtons?.insideMainToolbar
                        ? undefined
                        : 'material-symbols-rounded bg-gnist-gray-light px-2 text-2xl text-gnist-black before:font-gnist hover:enabled:bg-gnist-gray disabled:text-gnist-gray'),
                    { '!hidden md:block': !button.showInMobileView },
                  ]"
                  :aria-label="button.tooltip"
                  :data-tip="button.tooltip"
                  :disabled="button.disabled"
                  @click.stop.prevent="button.onClick()"
                >
                  <span
                    role="img"
                    class="material-symbols-rounded after:content-[attr(data-icon)]"
                    :data-icon="button.text"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        ref="movedInputRef"
        data-id="InputMarkdownInputTarget"
        :style="selfPlace && isPlaced ? `height: ${editorHeight};` : undefined"
      />
    </div>
    <div ref="afterEditorRef" class="sr-only focus:not-sr-only" tabindex="0">
      {{ t('admin.blockProduction.afterEditor') }}
    </div>
  </label>
</template>

<style scoped>
.tooltip-warning::before {
  padding: 0.5rem;
}
/**********************************************************************
 * Placement of "floating" toolbar
 ***********************************************************************/
:global(div:has(> .ToolbarParent)) {
  /* The toolbar will position itself with the width of it's closest positioned ancestor.
   * For this to work, any div's "above" the toolbar, until the container we want to have the width of, needs to be positioned as "static" (Gnist changes the default to "relative", which solves some problems and creates others).
   * This ensures that the div that this will be moved into has the correct position. Nodes higher in the DOM must be handled by callers. */
  @apply static;
}
.ToolbarParent.floatingToolbar {
  --toolbar-margin: 1rem;
}
.ToolbarParent.stickyToolbar {
  --toolbar-height: 3rem;
  --toolbar-parent-height: calc(
    var(--toolbar-height) + (var(--toolbar-margin, 0rem) * 2)
  );
  @apply absolute left-0 right-0;
  @apply self-start;
  @apply z-30;
  margin-top: calc(var(--toolbar-parent-height) * -1);
}
.gnist-page-width:not(.ToolbarFixed) {
  @apply px-0;
}
.floatingToolbar .Overlay {
  @apply bg-gnist-gray-light;
  @apply bg-opacity-75;
  padding: var(--toolbar-margin, 0rem);
}
.stickyToolbar .ToolbarFixed {
  top: var(--editor-toolbar-docked-height, 0);
  @apply fixed left-0 right-0 mt-px;
}
.floatingToolbar .ToolbarFixed .Overlay {
  padding: var(--toolbar-margin, 0rem);
  padding-top: 0;
}
.IntersectionHelperDiv {
  @apply invisible;
  @apply absolute left-0 right-0;
  /* Important: this needs to be slightly above the toolbar itself, so that when it triggers a fixed placement for the toolbar it does not move back inside the viewport. */
  --intersect-margin: 0.25rem;
  --intersect-offset: calc(
    var(--editor-toolbar-docked-height, 0rem) - var(--toolbar-margin, 0rem) +
      var(--intersect-margin)
  );
  margin-top: calc(var(--intersect-offset) * -1);
}
.ExtraButtons {
  @apply flex h-8 flex-wrap justify-end gap-2;
}
.CombinedToolbar {
  @apply grid grid-cols-[auto_max-content] items-center bg-gnist-gray-light;
}
.CombinedToolbar.Combined {
  @apply rounded border border-gnist-gray;
}
.CombinedToolbar :deep(.toastui-editor-defaultUI),
.CombinedToolbar :deep(.toastui-editor-defaultUI-toolbar) {
  @apply border-none;
  @apply p-0;
}
:global(.CombinedToolbar:has(.ExtraButtons) *:has(.MovedToolbar)),
:global(.CombinedToolbar:has(.ExtraButtons) .MovedToolbar),
:global(.CombinedToolbar:has(.ExtraButtons) .toastui-editor-toolbar) {
  @apply static;
}
:global(.mobile .toastui-editor-popup) {
  left: 1rem !important;
  right: 1rem !important;
  width: unset;
  margin-left: 0;
}
:deep(.toastui-editor-toolbar-group) {
  @apply contents md:flex;
}
:deep(.toastui-editor-toolbar-group:has(.toastui-editor-dropdown-toolbar)) {
  @apply static md:relative;
}
:deep(.toastui-editor-dropdown-toolbar) {
  @apply left-0 md:left-auto;
  @apply h-auto;
  @apply flex-wrap md:flex-nowrap;
}
</style>
