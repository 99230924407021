<script setup lang="ts">
import type { Block } from '@/api/types';
import DiscussionBoard from '@/components/discussion/DiscussionBoard.vue';
import { useI18n } from 'vue-i18n';
import { SocialPageType } from '@/enums/SocialPageType';

defineProps<{ block: Block }>();
const { t } = useI18n();
</script>
<template>
  <div class="comment-section-block bg-gnist-gray-light-light p-6 pt-20">
    <div class="gnist-page-width w-full">
      <h2>
        {{ t('block.comments') }}
      </h2>
      <DiscussionBoard
        v-if="block.status == 'Published'"
        :discussion-id="block?.blockId"
        :default-discussion-type="SocialPageType.BlockPage"
        class="mt-8"
      />
    </div>
  </div>
</template>

<style>
.comment-section-block .commento-card {
  background-color: white;
}
</style>
