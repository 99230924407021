<script lang="ts">
const badgeRoles = [
  'administrator',
  'editor',
  'docowner',
  'moderator',
] as const satisfies Readonly<RoleName[]>;
type BadgeRole = (typeof badgeRoles)[number];
export function hasBadgeRole(user: Pick<User, 'roles'> | null): boolean {
  if (!user?.roles) return false;
  return !!user.roles?.find((r) => badgeRoles.includes(r as BadgeRole));
}
/** The badge should only display one role. This decides which. */
function getBadgeRole(comment: ThreadComment): BadgeRole | null {
  if (
    comment.commenterUserRoles?.includes('docowner') &&
    comment.commenterOwnsPageBlock === true
  ) {
    return 'docowner';
  } else if (comment.commenterUserRoles?.includes('moderator')) {
    return 'moderator';
  } else if (comment.commenterUserRoles?.includes('editor')) {
    return 'editor';
  } else if (comment.commenterUserRoles?.includes('administrator')) {
    return 'administrator';
  } else return null;
}
</script>
<script setup lang="ts">
import type { RoleName } from '@/api/auth';
import type { ThreadComment } from '@/api/commentoTypes';
import type { Colors, User } from '@/api/types';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const colorMapping: { [key in BadgeRole]: Colors } = {
  administrator: 'sp-blue-light',
  editor: 'orange',
  moderator: 'green',
  docowner: 'purple',
};

const props = defineProps<{
  comment: ThreadComment;
}>();
const badgeValue = computed((): BadgeRole | null =>
  getBadgeRole(props.comment),
);
</script>

<template>
  <p
    v-if="badgeValue"
    class="badge badge-outline badge-sm border-gnist-gray"
    :class="[`bg-gnist-${colorMapping[badgeValue]}`]"
  >
    {{ t(`userAdmin.manualRoles.values.${badgeValue}`, badgeValue) }}
  </p>
</template>
