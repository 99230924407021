<script setup lang="ts">
import ContentPage from '../components/ContentPage.vue';
import BlockList from '../components/BlockList.vue';
import DocumentsOverview from '@/components/DocumentsOverview.vue';
import { useI18n } from 'vue-i18n';
import { editBlockVersionsRouteId } from '@/config';
import { ref } from 'vue';
import SimpleTabSelector from '@/components/SimpleTabSelector.vue';

const { t } = useI18n();
const tabs = ['overviewListHeader', 'overviewToPublishHeader'] as const;
const activeTab = ref<(typeof tabs)[number]>('overviewListHeader');
</script>

<template>
  <ContentPage
    :title="t('admin.management.overviewTitle')"
    :description="t('admin.management.overviewDescription')"
    :hide-description-mobile="true"
    class="bg-gnist-gray-light-light"
  >
    <SimpleTabSelector
      v-model="activeTab"
      :tabs="tabs"
      i18n-key="admin.management"
    />
    <MyComponent />
    <section v-show="activeTab === 'overviewToPublishHeader'" class="py-8">
      <h2 class="mb-4">
        {{ t('admin.management.overviewToPublishHeader') }}
      </h2>
      <div class="p-8">
        <BlockList
          :route-name="editBlockVersionsRouteId"
          :options="{ mode: 'approval' }"
          hide-all-filters
          card-template-id="searchCard"
          @loaded="
            (items) => {
              if (items.length > 0) activeTab = 'overviewToPublishHeader';
            }
          "
        />
      </div>
    </section>
    <DocumentsOverview
      v-show="activeTab === 'overviewListHeader'"
      headerlabel="admin.management.overviewListHeader"
      type="Management"
    />
  </ContentPage>
</template>
