import { apiUrl } from '@/config';
import { getCommentPagePath, SocialPageType } from '@/enums/SocialPageType';
import { nextTick, reactive, ref, watchEffect, type Ref } from 'vue';
import type {
  Commenter,
  Comment,
  Discussion,
  DiscussionAttributes,
  SortStrategy,
  ThreadComment,
  AssessmentType,
  CommentAssessment,
  commentPageId,
  MyThreadsComment,
} from '@/api/commentoTypes';
import { compose, type ComposableResult } from '@/api/composable';
import type { BlockPage, User } from '@/api/types';
import { useCurrentUser } from './auth';

const commentsUrl = apiUrl + '/comments';
const blockPageUrl = apiUrl + '/comments/blocks';
const threadUrl = apiUrl + '/comments/thread';

interface CommonResponse {
  success: boolean;
  message?: string;
}

interface ListResponse extends CommonResponse {
  attributes: DiscussionAttributes;
  commenters: { [key: string]: Commenter };
  comments: Comment[];
  // ignore: configuredOauths
  defaultSortPolicy: SortStrategy;
  isFrozen: boolean;
  requireIdentification: boolean;
  requireModeration: boolean;
}

interface NewCommentResponse extends CommonResponse {
  commentHex: string;
  html: string;
}

async function post<R, T>(path: string, data: T) {
  const response = await fetch(`${commentsUrl}${path}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    mode: 'cors',
    body: JSON.stringify(data),
    referrerPolicy: 'origin-when-cross-origin',
  });
  if (!response.ok) throw response;
  return (await response.json()) as R;
}

export async function commentsGet(
  discussionId: commentPageId,
): Promise<Discussion> {
  const requestPayload = {
    discussionId,
  };

  const response: ListResponse = await post('/list', requestPayload);
  if (!response.success) throw response.message;

  const commenterMap = new Map<string, Commenter>(
    Object.entries(response.commenters),
  );
  const commentMap = new Map<string, ThreadComment>(
    response.comments.map((c) => [
      c.commentHex,
      reactive({
        ...c,
        children: [],
        commenter: commenterMap.get(c.userId),
      }),
    ]),
  );
  for (const comment of commentMap.values()) {
    if (comment.parentHex !== 'root') {
      comment.parent = commentMap.get(comment.parentHex);
      comment.parent?.children.push(comment);
    }
  }

  const allCommentEntries = [...commentMap.values()];
  const threads = allCommentEntries.filter((c) => !c.parent);
  const stickiedComment = response.attributes.stickyCommentHex
    ? commentMap.get(response.attributes.stickyCommentHex)
    : undefined;
  return {
    commenterMap,
    commentMap,
    stickiedComment,
    threads,
    attributes: response.attributes,
    defaultSortPolicy: response.defaultSortPolicy,
  };
}

export async function commentNew(
  discussion: Discussion,
  parentHex: string,
  markdown: string,
  user: Pick<User, 'id' | 'firstName' | 'lastName'>,
  discussionId: commentPageId,
  discussionType: SocialPageType,
): Promise<ThreadComment> {
  const json = {
    discussionId,
    parentHex,
    markdown,
    discussionType,
    commentPagePath: getCommentPagePath(discussionId, discussionType),
  };

  const response: NewCommentResponse = await post('/new', json);
  if (!response.success) throw response.message;

  const comment: ThreadComment = reactive({
    commentHex: response.commentHex,
    userId: user.id,
    commenter: {
      name: `${user.firstName} ${user.lastName}`,
      userId: user.id,
    },
    markdown: markdown,
    html: response.html,
    parentHex: 'root',
    score: 0,
    state: 'approved',
    direction: 0,
    creationDate: new Date().toISOString(),
    deleted: false,
    children: [],
    parent:
      parentHex !== 'root' ? discussion.commentMap.get(parentHex) : undefined,
  });

  if (comment.parent) {
    comment.parent.children.unshift(comment);
  } else {
    discussion.threads.unshift(comment);
  }
  nextTick(() => {
    const el = document.getElementById('comment-' + comment.commentHex);
    if (!el) return;
    el.classList.add('bg-gnist-yellow');
    el.classList.add('bg-opacity-25');
    setTimeout(() => {
      el.classList.remove('bg-gnist-yellow');
      el.classList.remove('bg-opacity-25');
    }, 5000);
  });
  return comment;
}

// TODO: Do we need this since all comments are auto-approved?
export async function commentApprove(): Promise<void> {}
// global.commentApprove = function(commentHex) {
//   const json = {
//     'commenterToken': commenterTokenGet(),
//     'commentHex': commentHex,
//   };

//   post(origin + '/api/comment/approve', json, function(resp) {
//     if (!resp.success) {
//       errorShow(resp.message);
//       return;
//     } else {
//       errorHide();
//     }

//     const card = $(ID_CARD + commentHex);
//     const name = $(ID_NAME + commentHex);
//     const tick = $(ID_APPROVE + commentHex);

//     classRemove(card, 'dark-card');
//     classRemove(name, 'flagged');
//     remove(tick);
//   });
// };

export async function commentDelete(comment: Comment): Promise<void> {
  const json = {
    commentHex: comment.commentHex,
  };

  const response: NewCommentResponse = await post('/delete', json);
  if (!response.success) throw response.message;

  comment.html = '[deleted]';
  comment.deleted = true;
}

export async function commentModeratorDelete(
  comment: { commentHex: string; html: string; deleted?: boolean },
  deleteReason?: string,
): Promise<void> {
  const json = {
    commentHex: comment.commentHex,
    deleteReason,
  };

  const response: NewCommentResponse = await post('/moderator-delete', json);
  if (!response.success) throw response.message;

  comment.html = '[deleted]';
  comment.deleted = true;
}

export async function commentModeratorVerify(
  comment: { commentHex: string },
  reason?: string,
): Promise<void> {
  const json = {
    commentHex: comment.commentHex,
    reason,
  };

  const response: CommonResponse = await post('/moderator-verify', json);
  if (!response.success) throw response.message;
}

export async function commentDocownerDiscard(
  comment: { commentHex: string },
  endpoint: 'docowner-discard' | 'moderator-discard',
  reason?: string,
): Promise<void> {
  const json = {
    commentHex: comment.commentHex,
    reason,
  };

  const response: CommonResponse = await post(`/${endpoint}`, json);
  if (!response.success) throw response.message;
}

export async function vote(
  comment: Comment,
  newDirection: -1 | 0 | 1,
): Promise<void> {
  const json = {
    commentHex: comment.commentHex,
    direction: newDirection,
  };

  const response: CommonResponse = await post('/vote', json);
  if (!response.success) throw response.message;

  comment.score = comment.score + newDirection - comment.direction;
  comment.direction = newDirection;
}

export async function commentEdit(
  comment: Comment,
  markdown: string,
  discussionId: commentPageId,
  discussionType: SocialPageType,
): Promise<void> {
  const json = {
    commentHex: comment.commentHex,
    markdown: markdown,
    commentPagePath: getCommentPagePath(discussionId, discussionType),
  };

  const response: NewCommentResponse = await post('/edit', json);
  if (!response.success) throw response.message;

  comment.markdown = markdown;
  comment.html = response.html;
}

export async function pageUpdate(
  discussion: Discussion,
  isLocked: boolean,
  stickyCommentHex: string,
): Promise<void> {
  const json = {
    discussionId: discussion.attributes.id,
    isLocked,
    stickyCommentHex,
  };

  const response: CommonResponse = await post('/update', json);
  if (!response.success) throw response.message;

  discussion.attributes.isLocked = isLocked;
  discussion.attributes.stickyCommentHex = stickyCommentHex;

  if (stickyCommentHex == 'none') {
    discussion.stickiedComment = undefined;
  } else {
    discussion.stickiedComment = discussion.commentMap.get(stickyCommentHex);
  }
}

export function useDiscussion(
  discussionId: commentPageId,
): ComposableResult<Discussion> {
  return compose(commentsGet(discussionId));
}

export function useUnassessedComments(
  assessmentType: AssessmentType,
  includeAllBlocks = false,
  getNonBlockComments = false,
): ComposableResult<CommentAssessment[]> {
  return compose(
    getUnassessedComments(
      assessmentType,
      includeAllBlocks,
      getNonBlockComments,
    ),
  );
}
async function getUnassessedComments(
  assessmentType: AssessmentType,
  includeAllBlocks: boolean,
  getNonBlockComments: boolean,
): Promise<CommentAssessment[]> {
  const response = await fetch(
    `${commentsUrl}/assess/${assessmentType}?includeAllBlocks=${includeAllBlocks}&getNonBlockComments=${getNonBlockComments}`,
    {
      method: 'GET',
      credentials: 'include',
    },
  );
  if (!response.ok) throw new Error('error.get_categories_failed');
  return response.json();
}

export function useThreads(): Ref<MyThreadsComment[]> {
  const threads = ref<MyThreadsComment[]>([]);
  const userInfo = useCurrentUser();
  watchEffect(() => {
    if (userInfo.value) {
      getMyThreads().then((result) => (threads.value = result));
    }
  });
  return threads;
}

export const getMyThreads = async (): Promise<MyThreadsComment[]> => {
  return fetch(threadUrl, {
    method: 'GET',
    credentials: 'include',
  })
    .then((response) => response.json())
    .then((comment) => comment);
};

const getBlockPages = async (): Promise<BlockPage[]> => {
  const response = await fetch(blockPageUrl, {
    method: 'GET',
    credentials: 'include',
  });
  if (!response.ok) throw new Error(await response.text());
  return response.json();
};

export const useBlockPages = (): ComposableResult<BlockPage[]> => {
  return compose(getBlockPages());
};
