import { KnownHttpHeaders } from '@/temp/constants/knownHttpHeaders';
import { KnownMimeTypes } from '@/temp/constants/knownMimeTypes';
import * as Constants from '@/temp/constants/constants';
import { ref, type Ref } from 'vue';
import { apiUrl } from '@/config';
import type {
  ApiMAnalyticsDTO,
  ApiMSubscriptionDTO,
  ApiDTO,
  ApiPickerDTO,
  BlockApimDetails,
  ApiMetaData,
  ApimProduct,
} from './types';
import {
  composeWithRefresh,
  type ComposableResult,
  compose,
} from './composable';

const apimUrl = apiUrl + '/apim';

export async function callApi(
  endPoint: string,
  operation: string,
  key: string,
  body: string,
  token?: string,
): Promise<{ data?: unknown; resp: Response; dataErr?: unknown }> {
  const headers: HeadersInit = {
    [KnownHttpHeaders.ContentType]: KnownMimeTypes.Json,
    [KnownHttpHeaders.CacheControl]: 'no-cache',
    [Constants.subscriptionKeyHeaderName]: key,
  };
  if (token) {
    headers[KnownHttpHeaders.Authorization] = `Bearer ${token}`;
  }
  const resp = await fetch(endPoint, {
    method: operation,
    headers: headers,
    body: body ? body : undefined,
  });
  console.log('Got reply from API', resp);
  const respText = await resp.text();
  try {
    const data = JSON.parse(respText);
    console.log('Got data from API', data);
    return { data, resp };
  } catch (dataErr) {
    return { resp, dataErr, data: respText };
  }
}

export const useApim = (
  startDate: string,
  endDate: string,
): Ref<ApiMAnalyticsDTO | null> => {
  const apima = ref<ApiMAnalyticsDTO | null>(null);

  getApiMAnalytics(startDate, endDate).then((result) => (apima.value = result));

  return apima;
};
export const getApiMAnalytics = async (
  startDate: string,
  endDate: string,
): Promise<ApiMAnalyticsDTO | null> => {
  const response = await fetch(
    `${apiUrl}/apim/analytics?startDate=${startDate}&endDate=${endDate}`,
    {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
    },
  );

  if (!response.ok) return null;

  return await response.json();
};
export const requestApimSubscription = async (
  productId: string,
): Promise<ApiMSubscriptionDTO> => {
  const response = await fetch(`${apimUrl}/${productId}/subscription`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
  });
  return await response.json();
};

export const getApiSubscriptions = async (
  apimId: string,
): Promise<ApiMSubscriptionDTO[] | null> => {
  const response = await fetch(`${apimUrl}/${apimId}/subscriptions`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
  });
  if (!response.ok) return null;
  return await response.json();
};

export const useSubscriptionList = (
  apiName?: string,
): ComposableResult<ApiMSubscriptionDTO[]> => {
  return compose(getApiMSubscriptions(apiName));
};

const getApiMSubscriptions = async (
  apiName?: string,
): Promise<ApiMSubscriptionDTO[] | null> => {
  if (apiName) {
    return await getApiSubscriptions(apiName);
  }
  const response = await fetch(`${apimUrl}/subscriptions`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
  });
  if (!response.ok) return null;
  return await response.json();
};

export const useApiList = (
  onlyLoadSingleApi?: Ref<BlockApimDetails | undefined>,
): ComposableResult<ApiPickerDTO[] | null> => {
  const refresh = async () => {
    if (!onlyLoadSingleApi) return await getApiList();
    const data = await getApiMetadata(onlyLoadSingleApi.value?.apimId);
    return data ? [data] : null;
  };
  return composeWithRefresh(refresh, onlyLoadSingleApi);
};
export const getApiList = async (): Promise<ApiPickerDTO[]> => {
  const response = await fetch(`${apimUrl}`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
  });
  if (!response.ok) throw new Error('error.get_api_failed');
  return await response.json();
};
export const getApiMetadata = async (
  apimName?: string,
): Promise<ApiPickerDTO | null> => {
  if (!apimName) return null;
  const response = await fetch(`${apimUrl}/${apimName}/meta`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
  });
  if (!response.ok) throw new Error('error.get_api_failed');
  const data: ApiMetaData = await response.json();
  return { ...data, versions: [data] };
};

export const getApiData = async (apiName: string): Promise<ApiDTO> => {
  const response = await fetch(apiUrl + '/apim/' + apiName + '/operations', {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
  });
  if (!response.ok) throw new Error('error.get_operations_failed');
  return await response.json();
};

export const useApimProductsList = (): ComposableResult<
  Omit<ApimProduct, 'apis'>[]
> => {
  return compose(getApimProducts());
};

const getApimProducts = async (): Promise<
  Omit<ApimProduct, 'apis'>[] | null
> => {
  const response = await fetch(`${apimUrl}/products`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
  });
  if (!response.ok) return null;
  return await response.json();
};

export const getApimProductDetails = async (
  productId: string,
): Promise<ApimProduct | null> => {
  const response = await fetch(`${apimUrl}/products/${productId}`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
  });
  if (!response.ok) return null;
  return await response.json();
};
