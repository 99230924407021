<script lang="ts" setup>
import MarkdownRenderer from '@/components/block/MarkdownRenderer.vue';
import ImageRenderer from '@/components/block/ImageRenderer.vue';
import InteractiveImageVisualBoxRenderer from '@/components/block/InteractiveImageVisualBoxRenderer.vue';
import InteractiveImageTextBoxRenderer from '@/components/block/InteractiveImageTextBoxRenderer.vue';
import ModalComponent from '@/components/ModalComponent.vue';
import { useResizeListener } from '@/utilities/useResizeListener';
import { nextTick, ref, watch } from 'vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import type { Point, InteractiveImage, Translated } from '@/api/types';
import type { IBlobUrlInput } from '@/api/blob';

const props = defineProps<{
  value: Translated<InteractiveImage>;
  blobLocation: IBlobUrlInput;
  maximized?: boolean;
  editMode?: boolean;
  focusIndex?: number;
}>();
const emit = defineEmits<{
  (e: 'move', position: Point, index: number): void;
}>();

const { t } = useI18n();
const route = useRoute();

const bgRef = ref<InstanceType<typeof ImageRenderer> | undefined>();
const { windowWidth } = useResizeListener();
const zoomFactor = ref(1);
watch(
  () => [windowWidth.value, props.maximized],
  async () => {
    if (!bgRef.value || !windowWidth.value) return;
    await nextTick();
    bgRef.value.refreshSize();
  },
);
watch([() => bgRef.value?.size], () => {
  if (!bgRef.value) return;
  zoomFactor.value = Math.min(
    bgRef.value.size.width / bgRef.value.size.naturalWidth,
    1,
  );
});

const popupItem = ref<{ label: string; text: string } | null>(null);

const visualVersionRef = ref<HTMLElement | null>(null);
const embedded = ref<{ topOffset: number; box?: boolean }>();
watch(popupItem, () => {
  if (!popupItem.value) return;
  if (route.query['embed'] === 'box') {
    embedded.value = { topOffset: 0, box: true };
  }
  if (route.query['embed'] !== 'full') return;
  visualVersionRef.value?.scrollIntoView();
  if (window !== window.top) {
    embedded.value = {
      topOffset: visualVersionRef.value?.getBoundingClientRect().y ?? 0,
    };
  }
});
</script>

<template>
  <div
    class="grid grid-cols-1 grid-rows-[max-content_max-content_1fr] items-center justify-items-center"
  >
    <div
      class="InteractiveImage max-h-fit max-w-fit self-stretch justify-self-stretch"
      :class="{ maximized }"
    >
      <div
        role="navigation"
        aria-labelledby="skip-to-textversion"
        class="text-center focus-within:py-2"
      >
        <RouterLink
          id="skip-to-textversion"
          class="sr-only focus:not-sr-only"
          :to="{
            hash: '#text-version',
            query: route.query,
            path: route.path,
          }"
        >
          <span>{{ t('interactiveImage.skipToText') }}</span>
        </RouterLink>
      </div>
      <MarkdownRenderer
        v-if="value.initialText"
        :schema="{
          type: 'markdown',
          name: 'initialText',
          title: '',
          showTitle: false,
        }"
        class="mb-12"
        :value="value.initialText"
        :blob-location="blobLocation"
        data-cy-id="InteractiveImage_InitialText"
      />
      <section
        id="visual-version"
        ref="visualVersionRef"
        :aria-label="t('interactiveImage.visualVersion')"
      >
        <ImageRenderer
          v-if="value.background.name"
          ref="bgRef"
          :schema="{
            type: 'image',
            name: 'image',
            title: 'Bakgrunn',
            showTitle: false,
          }"
          :value="value.background"
          :blob-location="blobLocation"
          :decorative="!value.background.alt"
          no-default-class
          show-caption
          class="mb-40"
          caption-class="mt-8"
        />
        <div
          class="InteractiveImageVisualBoxesContainer absolute inset-0 origin-top-left"
          :style="{ transform: `scale(${zoomFactor})` }"
        >
          <InteractiveImageVisualBoxRenderer
            v-for="(item, index) in value.items"
            :key="index"
            :value="item"
            :edit-mode="editMode"
            :focus-index="focusIndex"
            :index="index"
            :blob-location="blobLocation"
            :zoom-factor="zoomFactor"
            :data-cy-id="`InteractiveImageElement_${index}`"
            @popup="(label, text) => (popupItem = { label, text })"
            @resize="(newSize) => (item.size = newSize)"
            @move="(position) => emit('move', position, index)"
          />
          <ModalComponent
            :show-modal="popupItem !== null"
            :title="popupItem?.label ?? ''"
            size="big"
            show-overflow
            show-close-icon
            :title-upper-case="false"
            :embedded="embedded"
            @close="popupItem = null"
          >
            <template #default>
              <MarkdownRenderer
                v-if="blobLocation && popupItem"
                :schema="{
                  type: 'markdown',
                  name: 'extendedText',
                  title: '',
                  showTitle: false,
                }"
                :value="popupItem.text"
                :blob-location="blobLocation"
                class="min-h-60"
              />
            </template>
            <template #buttons>
              <div className="flex justify-end">
                <div class="gnist-button-group">
                  <ButtonComponent
                    type="primary"
                    :text="t('buttons.ok')"
                    @click.prevent="popupItem = null"
                  />
                </div>
              </div>
            </template>
          </ModalComponent>
        </div>
      </section>
      <section
        id="text-version"
        class="flex flex-col"
        :aria-label="t('interactiveImage.textVersion')"
      >
        <InteractiveImageTextBoxRenderer
          v-for="(item, index) in value.items"
          :key="index"
          :value="item"
          :index="index"
          :blob-location="blobLocation"
        />
      </section>
    </div>
  </div>
</template>

<style scoped>
#text-version :deep(h2) {
  @apply mt-0;
}
#text-version :deep(h3),
#text-version :deep(h4) {
  @apply pt-0;
}
</style>
