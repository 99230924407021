import { apiUrl, type supported_locale } from '@/config';
import { ref, watch, type Ref } from 'vue';
import type { ApplicationInsightsDTO, ContentAnalyticsDTO } from './types';
import { useI18n } from 'vue-i18n';

export const useAnalytics = (): Ref<ApplicationInsightsDTO | null> => {
  const analytics = ref<ApplicationInsightsDTO | null>(null);
  const { locale } = useI18n();
  watch(
    locale,
    async () => {
      analytics.value = await getViewCountAnalytics(
        locale.value as supported_locale,
      );
    },
    { immediate: true },
  );

  return analytics;
};
async function getViewCountAnalytics(locale: supported_locale) {
  try {
    const resp = await fetch(`${apiUrl}/analytics/${locale}?fromDays=${30}`);
    if (resp.ok) return await resp.json();
    else return null;
  } catch {
    return null;
  }
}

export const useContentAnalytics = (): Ref<ContentAnalyticsDTO | null> => {
  const analytics = ref<ContentAnalyticsDTO | null>(null);
  const { locale } = useI18n();
  watch(
    locale,
    async () => {
      analytics.value = await getContentAnalytics(
        locale.value as supported_locale,
      );
    },
    { immediate: true },
  );
  return analytics;
};
async function getContentAnalytics(locale: supported_locale) {
  try {
    const resp = await fetch(`${apiUrl}/analytics/${locale}/content`);
    if (resp.ok) return await resp.json();
    else return null;
  } catch {
    return null;
  }
}
