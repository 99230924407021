<script setup lang="ts">
import ButtonComponent from '@/components/ButtonComponent.vue';
import { useSubscriptionList } from '@/api/apiM';
import { useI18n } from 'vue-i18n';
import SubscriptionKey from '@/components/SubscriptionKey.vue';
import { useRoute } from 'vue-router';
import { apimproductsRouteId } from '@/config';
import TableComponent from '@/components/TableComponent.vue';
import type { ApiMSubscriptionDTO } from '@/api/types';

const { t } = useI18n();

const route = useRoute();
const selectedApi = route.query.api?.toString();
const { result: subscriptions } = useSubscriptionList(selectedApi);
</script>

<template>
  <div class="my-10 w-full">
    <h2 class="p-8">
      {{ t('profile.subscriptions') }}
    </h2>
    <RouterLink :to="{ name: apimproductsRouteId }">
      <ButtonComponent
        class="self-center"
        type="primary"
        :text="t('profile.seeProducts')"
      />
    </RouterLink>
    <p v-if="!!selectedApi" class="mt-4">
      {{ t('profile.subscriptionsFiltered') }}:
      <span class="font-bold">
        {{ selectedApi }}
      </span>
    </p>
    <TableComponent
      :rows="subscriptions"
      show-spinner
      i18n-key="profile.subscriptionTable"
      :get-key="(sub) => sub.id"
      :columns="[
        'displayName',
        'state.title',
        'keys.title' as keyof ApiMSubscriptionDTO,
      ]"
      :sort-fields="['displayName', 'state']"
      column-header-class="font-normal"
      class="mt-4"
    >
      <template #columnHeader="{ item: sub }">
        {{ sub.displayName }}
      </template>
      <template #columns="{ item: sub }">
        <td>
          {{ t(`profile.subscriptionTable.state.values.${sub.state}`) }}
        </td>
        <td class="flex items-center justify-between gap-10">
          <div
            v-if="sub.state === 'Active'"
            class="grid w-max grid-cols-[max-content_max-content] gap-x-8"
          >
            <SubscriptionKey
              label="profile.subscriptionTable.keys.primary"
              :subscriptionkey="sub.primaryKey"
            />
            <SubscriptionKey
              label="profile.subscriptionTable.keys.secondary"
              :subscriptionkey="sub.secondaryKey"
            />
          </div>
        </td>
      </template>
    </TableComponent>
  </div>
</template>
