<script lang="ts" setup>
import CommentCard from './CommentCard.vue';
import WriteComment from './WriteComment.vue';
import Commenter from './CommentCommenter.vue';
import { commentNew, useDiscussion, pageUpdate } from '@/api/commentoClient';
import type {
  ThreadComment,
  SortFunction,
  SortStrategy,
  commentPageId,
} from '@/api/commentoTypes';
import CommentSortPolicy from './CommentSortPolicy.vue';
import { nextTick, ref, watch } from 'vue';
import { useStoreAbility } from '@/abilities';
import { useCurrentUser } from '@/api/auth';
import { useI18n } from 'vue-i18n';
import type { SocialPageType } from '@/enums/SocialPageType';
import { watchEffect } from 'vue';

const { t } = useI18n();

const props = defineProps<{
  discussionId: commentPageId;
  /** Used when creating new discussions outside of the forum */
  defaultDiscussionType?: SocialPageType;
}>();

const { result: discussion } = useDiscussion(props.discussionId);
const userInfo = useCurrentUser();
const { can } = useStoreAbility();

const threads = ref<ThreadComment[]>();
watchEffect(() => {
  const filtered = discussion.value?.threads.filter(
    (x) => x.commentHex != discussion.value?.attributes.stickyCommentHex,
  );

  filtered?.forEach((thread) => removeDeletedComments(thread));
  threads.value = filtered?.filter(
    (thread) => !thread.deleted || thread.children.length > 0,
  );
});

const removeDeletedComments = (comment: ThreadComment) => {
  if (!comment.children || comment.children.length === 0) {
    return;
  }

  comment.children.forEach(removeDeletedComments);

  comment.children = comment.children.filter(
    (c) => !c.deleted || c.children.length > 0,
  );
};

function threadLockToggle() {
  if (discussion.value) {
    pageUpdate(
      discussion.value,
      !discussion.value.attributes.isLocked,
      discussion.value.attributes.stickyCommentHex,
    );
  }
}

async function createComment(commentText: string) {
  if (discussion.value && userInfo.value) {
    const newComment = await commentNew(
      discussion.value,
      'root',
      commentText,
      userInfo.value,
      discussion.value.attributes.id,
      discussion.value.attributes.pageType ?? props.defaultDiscussionType,
    );
    await nextTick();
    document
      .getElementById('comment-' + newComment.commentHex)
      ?.scrollIntoView({ behavior: 'smooth' });
  }
}

const sortStrategy = ref<SortStrategy | null>(null);

function sortThreads(comments: ThreadComment[] | undefined, fn: SortFunction) {
  comments?.sort(fn);
  comments?.forEach((c) => sortThreads(c.children, fn));
}

function getSortFunction(strategy: SortStrategy): SortFunction {
  switch (strategy) {
    case 'score-desc':
      return (a: ThreadComment, b: ThreadComment) => b.score - a.score;
    case 'creationdate-desc':
      return (a: ThreadComment, b: ThreadComment) =>
        Date.parse(b.creationDate) - Date.parse(a.creationDate);
    case 'creationdate-asc':
      return (a: ThreadComment, b: ThreadComment) =>
        Date.parse(a.creationDate) - Date.parse(b.creationDate);
    default:
      throw new Error('error.sort_strategy_not_found');
  }
}

function sortComments() {
  if (sortStrategy.value) {
    sortThreads(discussion.value?.threads, getSortFunction(sortStrategy.value));
  }
}

function setSortStrategy(strategy: SortStrategy) {
  sortStrategy.value = strategy;
}

// Set default sort strategy when discussion is loaded
watch(discussion, (newValue) => {
  sortStrategy.value = newValue?.defaultSortPolicy ?? null;
});

// Trigger sort when sort strategy changed by user
watch(sortStrategy, () => {
  sortComments();
});
</script>

<template>
  <div class="discussion-main text-gnist-black">
    <Commenter v-if="userInfo" class="mt-4" />
    <div v-else>
      {{ t('discussion.logInToComment') }}
    </div>

    <div
      v-if="can('update', 'Discussion')"
      class="discussion-moderator-tools mt-4"
    >
      <span
        class="discussion-moderator-tools-header text-sm font-bold uppercase text-[#3b5bdb]"
        >{{ t('discussion.moderatorTools') }}</span
      >
      <button
        class="discussion-moderator-tools-lock-thread ml-3 text-sm font-bold uppercase"
        @click="threadLockToggle"
      >
        {{
          discussion?.attributes.isLocked
            ? t('discussion.unlockThread')
            : t('discussion.lockThread')
        }}
      </button>
    </div>

    <WriteComment
      v-if="discussion && userInfo && !discussion.attributes.isLocked"
      class="mt-3"
      @submit-comment="createComment"
    />

    <div v-if="discussion?.attributes.isLocked" class="pt-8 text-red-600">
      {{ t('discussion.lockedThreadExplaination') }}
    </div>

    <div v-if="discussion && discussion.threads.length > 0">
      <CommentSortPolicy
        :sort-strategy="sortStrategy"
        @set-sort-strategy="setSortStrategy"
      />
      <CommentCard
        v-if="discussion.stickiedComment"
        :comment="discussion.stickiedComment"
        :discussion="discussion"
        :discussion-type="discussion.attributes.pageType"
      />

      <CommentCard
        v-for="comment in threads"
        :key="comment.commentHex"
        :comment="comment"
        :discussion="discussion"
        :discussion-type="discussion.attributes.pageType"
        class="mt-4"
      />
    </div>
    <p v-else class="mt-2 border-t pt-4 font-semibold">0 kommentarer</p>
  </div>
</template>
<style>
.discussion-main {
  text-rendering: optimizeLegibility;
}
</style>
