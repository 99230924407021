<script setup lang="ts">
import ButtonComponent from '@/components/ButtonComponent.vue';
import { ParagraphShimmer } from 'vue3-shimmer';
import MaterialIcon from '@/components/MaterialIcon.vue';
import MarkdownRenderer from '@/components/block/MarkdownRenderer.vue';
import TagRenderer from '@/components/TagRenderer.vue';
import SuggestionStatusBadge from '@/components/SuggestionStatusBadge.vue';
import SearchBar from '../components/SearchBar.vue';
import TagPicker from '@/components/forms/InputTagPicker.vue';
import { throwOnError } from '@/api/composable';
import { useI18n } from 'vue-i18n';
import { useSuggestions } from '@/api/suggestion';
import { formatDate } from '@/utilities/formatDate';
import { descMsgId } from '@/api/menu';
import { useRoute } from 'vue-router';
import { useStoreAbility } from '@/abilities';
import { useSort } from '@/utilities/order';
import { useCurrentUser } from '@/api/auth';
import Dropdown, {
  toDropdownItems,
} from '@/components/forms/InputDropdown.vue';
import { computed, ref } from 'vue';
import {
  getSuggestionStatus,
  type SuggestionStatus,
} from '@/utilities/getSuggestionStatus';
import type { SingleItem, Tag } from '@/api/types';

const { t } = useI18n();
const route = useRoute();

const user = useCurrentUser();
const { can } = useStoreAbility();
const showEditButton = can('update', 'Suggestion');

const { result: suggestions } = throwOnError(useSuggestions());
const { sortedValues: sortedSuggestions } = useSort(
  suggestions,
  [],
  'votes',
  'desc',
);

type SuggestionStatusWithCount = { status: SuggestionStatus; count: number };
const selectedSuggestionStatuses = ref<SuggestionStatusWithCount[]>([]);
const selectedSuggestionTypes = ref<string[]>([]);

const suggestionStatusOptions = computed(() => {
  if (!sortedSuggestions.value) return [];
  const items = sortedSuggestions.value.reduce((acc, s) => {
    const status = getSuggestionStatus(s);
    if (!status) return acc;
    let item = acc.find((i) => i.status === status);
    if (!item) {
      item = { status, count: 1 };
      acc.push(item);
    } else item.count++;
    return acc;
  }, [] as SuggestionStatusWithCount[]);
  return toDropdownItems(items, (s) => [
    s.status,
    t(`suggestions.status.${s.status}`),
    s,
  ]);
});

const statusFilter = (status: SuggestionStatus | null) => {
  if (status === null) return;
  return (
    selectedSuggestionStatuses.value.length === 0 ||
    selectedSuggestionStatuses.value.find((s) => s.status === status)
  );
};

const suggestionTypeOptions = computed(() => {
  if (!sortedSuggestions.value) return [];
  const types = sortedSuggestions.value.map((s) => s.type);
  return toDropdownItems([...new Set(types)], (s) => [
    s,
    t(`suggestions.types.${s}`),
  ]);
});

const typeFilter = (type: string) => {
  return (
    selectedSuggestionTypes.value.length === 0 ||
    selectedSuggestionTypes.value.find((s) => s === type)
  );
};

const selectedTags = ref<Tag[]>([]);
const tagFilter = (tags: Tag[] | undefined) => {
  if (selectedTags.value.length === 0) return true;
  return selectedTags.value.find((t) => tags?.find((bt) => bt.id === t.id));
};

const searchText = ref('');
const matchesSearch = (
  suggestion: SingleItem<NonNullable<typeof sortedSuggestions.value>>,
) => {
  const searchLower = searchText.value.toLowerCase();
  return (
    suggestion.title.toLowerCase().includes(searchLower) ||
    suggestion.description.toLowerCase().includes(searchLower)
  );
};

const filterSuggestions = computed(() => {
  return (
    sortedSuggestions.value?.filter(
      (s) =>
        statusFilter(getSuggestionStatus(s)) &&
        typeFilter(s.type) &&
        tagFilter(s.tags) &&
        matchesSearch(s),
    ) ?? []
  );
});
</script>

<template>
  <div class="w-full bg-gnist-gray-light-light text-gnist-black">
    <ParagraphShimmer
      v-if="suggestions === null"
      :lines="20"
      :random-size="true"
      class="mx-auto w-full max-w-5xl"
    />

    <div v-else class="mx-2 py-8 md:mx-auto md:w-[80rem]">
      <h2 class="mb-16">
        {{ t('header.items.suggestions') }}
      </h2>
      <p class="text-subtitle mt-4 max-w-2xl text-gnist-black">
        {{ t(`${descMsgId}.${route.meta.translationid}`, '&nbsp;') }}
      </p>
      <div class="flex min-h-[6rem] flex-col justify-between md:flex-row">
        <div
          class="flex flex-wrap items-end justify-start gap-x-4 md:flex-nowrap"
        >
          <!-- TODO: consider a top/new/trending filter (ref. https://nhs-digital-api-management.featureupvote.com/) -->
          <Dropdown
            v-model="selectedSuggestionStatuses"
            :options="suggestionStatusOptions"
            :getkey="(s) => s?.status ?? 'empty'"
            :multiple="{ emptyLabel: t('suggestions.admin.status') }"
            class="z-20 px-1 lg:max-w-xs"
            :buttonclass="[
              'w-full mt-2 px-2 py-2 pb-2  [&>*]:pb-2',
              'border rounded',
              'bg-gnist-white',
            ]"
          >
            <template #option="{ item }">
              <SuggestionStatusBadge :status="item.value.status" />
              ({{ item.value.count }})
            </template>
          </Dropdown>
          <Dropdown
            v-model="selectedSuggestionTypes"
            :options="suggestionTypeOptions"
            :getkey="(t) => t ?? 'empty'"
            :multiple="{ emptyLabel: t('suggestions.admin.type') }"
            class="z-20 px-1 lg:max-w-xs"
            :buttonclass="[
              'w-full mt-2 px-2 py-2 pb-2  [&>*]:pb-2',
              'border rounded',
              'bg-gnist-white',
            ]"
          >
            <template #option="{ item }">
              <p
                class="badge badge-outline badge-lg grow rounded-none border-gnist-gray"
                :class="{
                  'bg-gnist-red-light': item.value === 'Bug',
                  'bg-gnist-white': item.value !== 'Bug',
                }"
              >
                {{ item.text }}
              </p>
            </template>
          </Dropdown>
          <TagPicker
            v-model="selectedTags"
            suggestedlabel="blockList.suggestedTags"
            :taglistclass="['max-h-8 overflow-y-auto']"
            placeholder="suggestions.admin.tagFilter"
            class="mr-12 w-full grow [&>*:first-child]:min-h-[3.125rem] [&>*:first-child]:content-center"
            select-only
          />
        </div>
        <div
          class="flex flex-wrap items-end justify-start gap-x-4 md:flex-nowrap md:justify-end"
        >
          <SearchBar
            class="text-p2 my-2 w-full"
            @change="(v: string) => (searchText = v)"
          />
          <RouterLink
            v-if="can('create', 'Bug')"
            :to="{ path: '/suggestions/add/Bug' }"
          >
            <ButtonComponent
              :text="t('suggestions.addBug')"
              class="my-2 h-12"
            />
          </RouterLink>
          <RouterLink v-if="!!user" :to="{ path: '/suggestions/add' }">
            <ButtonComponent :text="t('suggestions.add')" class="my-2 h-12" />
          </RouterLink>
        </div>
      </div>

      <template v-for="suggestion in filterSuggestions" :key="suggestion.id">
        <RouterLink
          :to="{
            name: 'suggestion_details',
            params: { suggestionId: suggestion.id },
          }"
        >
          <div
            v-if="showEditButton"
            class="absolute z-10 w-full cursor-pointer"
          >
            <div
              class="gnist-page-width top-6 flex w-full justify-end px-4 lg:top-0"
            >
              <RouterLink
                :to="{
                  name: 'suggestion_edit',
                  params: { suggestionId: suggestion.id },
                }"
              >
                <MaterialIcon
                  class="rounded-lg text-2xl hover:bg-gnist-gray-light hover:text-gnist-black"
                >
                  edit
                </MaterialIcon>
              </RouterLink>
            </div>
          </div>
          <div
            class="m-4 flex p-4"
            :class="{
              'bg-gnist-white': suggestion.type !== 'Bug',
              'bg-gnist-gray bg-opacity-10': suggestion.type === 'Bug',
            }"
          >
            <div class="border-r-2 border-r-gnist-gray-light p-8">
              <div class="flex justify-center text-3xl font-bold">
                {{ suggestion.votes }}
              </div>
              <div class="text-sm text-gnist-gray-dark">
                {{ t('suggestions.votes') }}
              </div>
            </div>
            <div class="overflow-hidden p-2 px-4">
              <h3 class="line-clamp-1 text-gnist-gray-dark">
                {{ suggestion.title }}
              </h3>
              <div class="line-clamp-4 max-h-28">
                <MarkdownRenderer
                  :value="suggestion.description"
                  :blob-location="
                    suggestion.id ? { suggestionId: suggestion.id } : 'public'
                  "
                  class="my-2"
                />
              </div>
              <div
                class="mt-4 flex grid-cols-2 flex-wrap gap-x-4 border-t-2 lg:grid"
              >
                <span>
                  {{ t('suggestions.suggested_by') }}:
                  <span class="font-bold">{{
                    suggestion.suggestedBy?.name
                  }}</span>
                </span>

                <span>
                  {{ t('suggestions.updated') }}:
                  <span>
                    {{
                      formatDate(
                        suggestion.modified,
                        false,
                        t('suggestions.unknown_date'),
                      )
                    }}
                  </span>
                </span>
                <span>
                  {{ t('suggestions.comments') }}:
                  <span>
                    {{
                      suggestion.commentCount ?? t('suggestions.unknown_count')
                    }}
                  </span>
                </span>
                <span>
                  {{ t('suggestions.upvoted') }}:
                  <span>
                    {{
                      formatDate(
                        suggestion.last_vote,
                        false,
                        t('suggestions.unknown_date'),
                      )
                    }}
                  </span>
                </span>
              </div>
              <div class="mt-2 flex flex-row flex-wrap gap-1">
                <p
                  class="badge badge-outline badge-lg rounded-none border-gnist-gray"
                  :class="{
                    'bg-gnist-red-light': suggestion.type === 'Bug',
                    'bg-gnist-white': suggestion.type !== 'Bug',
                  }"
                >
                  {{ t(`suggestions.types.${suggestion.type}`) }}
                </p>

                <SuggestionStatusBadge :suggestion="suggestion" />
                <TagRenderer :tags="suggestion?.tags" size="badge-lg" />
              </div>
            </div>
          </div>
        </RouterLink>
      </template>
    </div>
  </div>
</template>
