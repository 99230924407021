<script setup lang="ts">
import { useCurrentUser } from '@/api/auth';
import { updateUser } from '@/api/user';
import ModalComponent from './ModalComponent.vue';
import ButtonComponent from './ButtonComponent.vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const userInfo = useCurrentUser();

const saveNotifictionSettings = async () => {
  if (userInfo.value) {
    await updateUser(userInfo.value);
  }
};

const denyNotifications = async () => {
  if (userInfo.value) {
    userInfo.value.sendNewsNotification = false;
    userInfo.value.sendCommentReplyNotification = false;
    userInfo.value.sendSuggestionCommentNotification = false;
    userInfo.value.sendSuggestionChangedNotification = false;

    await updateUser(userInfo.value);
  }
};
</script>

<template>
  <ModalComponent
    v-if="userInfo"
    :show-modal="!userInfo.hideNotificationPrompt"
    :title="t('notificationPrompt.title')"
  >
    <div data-cy-id="NotificationPrompt">
      <label class="font-semibold">{{
        t('profile.emailNotficationTitle')
      }}</label>

      <div class="my-2">
        <input
          id="checkbox-commento"
          v-model="userInfo.sendCommentReplyNotification"
          name="checkbox-commento"
          type="checkbox"
          data-cy-id="SendCommentReplyNotifications"
        />
        <label for="checkbox-commento" class="ml-1">
          {{ t('profile.emailNotficationCommentOnThread') }}
        </label>
      </div>
      <div class="my-2">
        <input
          id="checkbox-news"
          v-model="userInfo.sendNewsNotification"
          name="checkbox-news"
          type="checkbox"
        />
        <label for="checkbox-news" class="ml-1">
          {{ t('profile.emailNotificationNewNewsArticle') }}
        </label>
      </div>
      <div class="my-2">
        <input
          id="checkbox-suggestion-comment"
          v-model="userInfo.sendSuggestionCommentNotification"
          name="checkbox-suggestion-comment"
          type="checkbox"
        />
        <label for="checkbox-suggestion-comment" class="ml-1">
          {{ t('profile.emailNotificationSuggestionComment') }}
        </label>
      </div>
      <div class="my-2">
        <input
          id="checkbox-suggestion-changed"
          v-model="userInfo.sendSuggestionChangedNotification"
          name="checkbox-suggestion-changed"
          type="checkbox"
        />
        <label for="checkbox-suggestion-changed" class="ml-1">
          {{ t('profile.emailNotificationSuggestionChanged') }}
        </label>
      </div>
      <p class="text-p3 mt-4">
        {{ t('notificationPrompt.change') }}
      </p>
    </div>
    <template #buttons>
      <div class="flex justify-end">
        <div class="gnist-button-group">
          <ButtonComponent
            text="Jeg ønsker ikke varslinger"
            @click="denyNotifications"
          />
          <ButtonComponent
            :text="t('admin.blockProduction.save')"
            type="primary"
            data-cy-id="SaveNotificationPromptButton"
            @click="saveNotifictionSettings"
          />
        </div>
      </div>
    </template>
  </ModalComponent>
</template>
