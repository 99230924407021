import type { ApiDTO, ApiOperation } from '@/api/types';

export function computeEndpoint(
  apiData: Pick<ApiDTO, 'gatewayUrl' | 'apiPathUrl'> | null,
  operation: Pick<ApiOperation, 'templateUrl'> | null,
) {
  if (!apiData?.gatewayUrl || !apiData?.apiPathUrl) return '';

  return `${apiData?.gatewayUrl}${apiData?.apiPathUrl}${operation?.templateUrl}`;
}
