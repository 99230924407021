<script setup lang="ts">
import type { News } from '@/api/types';
import { ref } from 'vue';
import EditNewsCard from './EditNewsCard.vue';
import NewsCard from './NewsCard.vue';

defineProps<{
  newsItem: News;
  right?: boolean;
}>();

defineEmits<{
  (e: 'update'): void;
}>();
const edit = ref(false);
</script>

<template>
  <div>
    <EditNewsCard v-if="edit" :news-item="newsItem" @edit="edit = false" />
    <NewsCard
      v-else
      :news-item="newsItem"
      :right="right"
      @edit="edit = true"
      @update="$emit('update')"
    />
  </div>
</template>

<style></style>
