import { onBeforeUnmount, ref, type Ref } from 'vue';
import { assertDefined } from '../debugUtils';

export function useStickyToolbar(
  /** An element that we want to know when ends up above the viewport */
  topIntersector: Ref<HTMLElement | null>,
  /** Two elements, where we want to know when the bottom of a moving element passes above the bottom of a sticky element */
  bottomIntersector?: {
    sticky: Ref<HTMLElement | null>;
    moving: Ref<HTMLElement | null>;
  },
) {
  const topAboveViewport = ref(false);
  const movingBottomAboveStickyBottom = ref(false);
  const lastStickyBottom = ref(0);
  const topObserver = new IntersectionObserver(
    (entries) =>
      (topAboveViewport.value = (entries[0]?.boundingClientRect.top ?? 0) < 0),
    { threshold: 1 },
  );
  const bottomObserver = new IntersectionObserver(
    (entries) => {
      if (!entries[0]) return;
      if (!bottomIntersector) return;
      if (!assertDefined(bottomIntersector.sticky.value)) return;
      const targetVisible = bottomIntersector.sticky.value.checkVisibility();
      if (targetVisible) {
        lastStickyBottom.value =
          bottomIntersector.sticky.value.getBoundingClientRect().bottom;
      }
      movingBottomAboveStickyBottom.value =
        lastStickyBottom.value > 0 &&
        entries[0].boundingClientRect.bottom < lastStickyBottom.value;
    },
    {
      // * If this is a very high item, we want to look closely when we are approaching the bottom threshold
      // If it is a not so high item, we need to look at more places (but we don't need to look as often, since the actual distance for each % of the threshold is smaller)
      threshold: [
        0.0, 0.01, 0.02, 0.03, 0.04, 0.05, 0.06, 0.07, 0.08, 0.09, 0.1, 0.2,
        0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0,
      ],
    },
  );
  const observingTop = ref(false);
  const observingBottom = ref(false);
  function stopObserve() {
    topObserver.disconnect();
    observingTop.value = false;
    bottomObserver.disconnect();
    observingBottom.value = false;
  }
  onBeforeUnmount(() => {
    stopObserve();
  });
  return {
    startObserveTop: () => {
      if (!observingTop.value && topIntersector.value) {
        topObserver.observe(topIntersector.value);
        observingTop.value = true;
      }
    },
    startObserveBottom: () => {
      if (!observingBottom.value && bottomIntersector?.moving.value) {
        bottomObserver.observe(bottomIntersector.moving.value);
        observingBottom.value = true;
      }
    },
    stopObserve,
    topAboveViewport,
    movingBottomAboveStickyBottom,
  };
}
