import {
  getChatPageParamFromId,
  type commentPageId,
  type commentPageParam,
  type chatPageId,
  suggestionPageParam,
} from '@/api/commentoTypes';
import { defaultBlocksRouteName } from '@/utilities/routeUtils';

export enum SocialPageType {
  ChatPage = 0,
  BlockPage = 1,
  SuggestionPage = 2,
}

let socialPageBasePath = '';
export function setSocialPageBasePath(path: string) {
  socialPageBasePath = path;
}

export function getCommentPagePath(
  discussionId: commentPageId,
  discussionType: SocialPageType,
) {
  return (
    socialPageBasePath +
    (discussionType === SocialPageType.ChatPage
      ? discussionId
      : discussionType === SocialPageType.SuggestionPage
        ? `/${suggestionPageParam}/${discussionId}`
        : `/${defaultBlocksRouteName}/${discussionId}`)
  );
}

export function getCommentPageIdParam(
  discussionId: commentPageId,
  discussionType: SocialPageType,
): commentPageParam {
  return discussionType === SocialPageType.ChatPage
    ? getChatPageParamFromId(discussionId as chatPageId)
    : discussionType === SocialPageType.SuggestionPage
      ? suggestionPageParam
      : defaultBlocksRouteName;
}
