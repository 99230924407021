<script setup lang="ts">
import ContentPage from '@/components/ContentPage.vue';
import ModalComponent from '@/components/ModalComponent.vue';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import {
  getApimProductDetails,
  requestApimSubscription,
  useApimProductsList,
  useSubscriptionList,
} from '@/api/apiM';
import { useI18n } from 'vue-i18n';
import { ref, watchEffect } from 'vue';
import { useCurrentUser } from '@/api/auth';
import { computed } from 'vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import { type ApimProduct } from '@/api/types';
import { mySubscriptionsRouteId } from '@/config';
import TableComponent from '@/components/TableComponent.vue';
import { useResizeListener } from '@/utilities/useResizeListener';

const { t } = useI18n();

const { result: products } = useApimProductsList();
const { result: subscriptions } = useSubscriptionList();
const user = useCurrentUser();

const productSubMapping = computed(() =>
  Object.fromEntries(
    products.value?.map((p) => [
      p.id,
      subscriptions.value?.filter((s) =>
        s.scope?.includes(`/products/${p.id}`),
      ),
    ]) ?? [],
  ),
);
const isSubmittingApiMRequest = ref(false);
async function requestApimProduct(productId: string) {
  isSubmittingApiMRequest.value = true;
  try {
    const subscription = await requestApimSubscription(productId);
    if (subscriptions.value) {
      subscriptions.value?.push(subscription);
    }
    isSubmittingApiMRequest.value = false;
  } catch (err) {
    isSubmittingApiMRequest.value = false;
    throw err;
  }
}

const activeProduct = ref<Omit<ApimProduct, 'apis'> | undefined>();
const activeProductDetails = ref<ApimProduct | null>(null);
watchEffect(async () => {
  if (!activeProduct.value) return;
  activeProductDetails.value = null;
  activeProductDetails.value = await getApimProductDetails(
    activeProduct.value.id,
  );
});
const { isBelowSmall } = useResizeListener();
</script>

<template>
  <ContentPage
    :title="t('apimproducts.title')"
    :description="t('apimproducts.preamble')"
  >
    <div v-if="!products" class="flex w-full flex-col items-center">
      <LoadingSpinner class="h-16 w-16" />
    </div>
    <div v-else class="mb-8 grid gap-8 md:grid-cols-2">
      <div
        v-for="product in products"
        :key="product.productName"
        class="flex min-w-0 flex-col gap-4 bg-gnist-white p-4 shadow-xl"
      >
        <h2>{{ product.productName }}</h2>
        <p>{{ product.description }}</p>

        <ButtonComponent
          class="self-start"
          :text="t('apimproducts.seeApis')"
          truncate-overflow
          @click="activeProduct = product"
        />
        <div class="border border-black p-4">
          <h3>{{ t('apimproducts.yourSubs') }}</h3>
          <div v-if="!user">
            {{ t('apimproducts.mustSignin') }}
          </div>
          <progress
            v-else-if="isSubmittingApiMRequest"
            class="progress w-full"
          />
          <div v-else-if="productSubMapping[product.id]?.length === 0">
            <div>{{ t('apimproducts.noSubsForProduct') }}</div>
            <ButtonComponent
              :text="t('apimproducts.orderProduct')"
              class="self-start"
              truncate-overflow
              @click="requestApimProduct(product.id)"
            />
          </div>
          <div v-else>
            <TableComponent
              :rows="productSubMapping[product.id]"
              show-spinner
              i18n-key="profile.subscriptionTable"
              :get-key="(sub) => sub.id"
              :columns="['displayName', 'state.title']"
              column-header-class="font-normal"
              no-zebra
            >
              <template #columnHeader="{ item: sub }">
                <RouterLink
                  :to="{ name: mySubscriptionsRouteId }"
                  class="underline"
                >
                  {{ sub.displayName }}
                </RouterLink>
              </template>
              <template #columns="{ item: sub }">
                <td>
                  {{ t(`profile.subscriptionTable.state.values.${sub.state}`) }}
                </td>
              </template>
            </TableComponent>
          </div>
        </div>
      </div>
    </div>
  </ContentPage>
  <ModalComponent
    :show-modal="!!activeProduct"
    :title="t('apimproducts.apiList')"
    primary-button-type="none"
    size="big"
    :no-scroll="isBelowSmall"
    @close="() => (activeProduct = undefined)"
  >
    <template #default>
      <TableComponent
        size="table-xs"
        pin="rows"
        :rows="activeProductDetails?.apis"
        show-spinner
        i18n-key="apimproducts"
        :get-key="(api) => api.name"
        :columns="['name', 'description']"
      >
        <template #columnHeader="{ item: api }">
          {{ api.displayName }}
        </template>
        <template #columns="{ item: api }">
          <td>
            {{ api.description }}
          </td>
        </template>
      </TableComponent>
    </template>
  </ModalComponent>
</template>
