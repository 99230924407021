<script
  setup
  lang="ts"
  generic="T extends PartialSome<Tag, 'text'>[] | undefined"
>
import { type IInputLabelProps } from './InputLabel.vue';
import Picker from './InputPicker.vue';
import type { PartialSome, SingleItem, Tag } from '@/api/types';
import type { ITagBase } from './InputPicker.vue';
import { useNestedMV } from '@/utilities/useInternalState';
import { createTag, useTags } from '@/api/tags';

const props = defineProps<
  IInputLabelProps & {
    modelValue: T;
    suggestedlabel?: string;
    taglistclass?: string[];
    addPrefix?: string;
    maxItems?: number;
    showEmptySuggestions?: boolean;
    selectOnly?: boolean;
  }
>();
const emit = defineEmits<{
  (e: 'update:modelValue', value: T): void;
  (e: 'focus'): void;
  (e: 'blur'): void;
}>();

const value = useNestedMV(props, (val) => emit('update:modelValue', val), true);

const { result: tags } = useTags();

async function searchTags(query: string): Promise<SingleItem<T>[]> {
  if (!tags.value) return [];
  return tags.value.filter((tag) =>
    tag.text.no.toLowerCase().includes(query),
  ) as SingleItem<T>[];
}
async function addTag(tag: ITagBase): Promise<SingleItem<T>> {
  const newTag = await createTag({ text: { no: tag.name } });
  tags.value?.push(newTag);
  return newTag as SingleItem<T>;
}
</script>

<template>
  <Picker
    v-bind="props"
    v-model="value"
    :getkey="(val) => val?.id ?? -1"
    :gettext="(val) => val?.text?.no ?? ''"
    :filter="searchTags"
    :add="selectOnly ? undefined : (tag) => addTag(tag)"
    :get-by-id="(id) => tags?.find((i) => i.id === id) as SingleItem<T>"
    @focus="emit('focus')"
    @blur="emit('blur')"
  />
</template>
