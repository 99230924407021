import { apiUrl } from '@/config';
import { type ComposableResult, compose } from './composable';
import type {
  ChangeProps,
  Suggestion,
  SuggestionPage,
  SuggestionUserConnection,
  WorkItem,
} from './types';
import { translateStringOrLocaleWithDefault } from '@/i18n';
import { computed, ref, watchEffect } from 'vue';
import type { VoteDirection } from '@/enums/VoteDirection';

const suggestionsUrl = apiUrl + '/suggestions';
const suggestionPageUrl = apiUrl + '/comments/suggestions';

export function useSuggestions(): ComposableResult<TranslatedSuggestion[]> {
  const composable = compose(getSuggestions());
  const translated = ref<TranslatedSuggestion[] | null>(null);
  watchEffect(() => {
    composable.error.value = composable.result.value?.error ?? null;
    translated.value =
      composable.result.value?.data.map((suggestion) =>
        translateSuggestion(suggestion),
      ) ?? null;
  });
  return { ...composable, result: translated };
}
export function useSuggestion(
  suggestionId: number | undefined,
  includeWorkItem = false,
): ComposableResult<Suggestion> {
  const composable = compose(
    !suggestionId ? null : getSuggestion(suggestionId, includeWorkItem),
  );
  const data = computed({
    get: () => composable.result.value?.data ?? null,
    set: (newValue) => {
      if (!composable.result.value || !newValue) return;
      composable.result.value.data = newValue;
    },
  });
  watchEffect(() => {
    composable.error.value = composable.result.value?.error ?? null;
  });
  return { ...composable, result: data };
}

type TranslatedSuggestion = ChangeProps<
  Suggestion,
  'title' | 'description',
  string
>;
function translateSuggestion(suggestion: Suggestion): TranslatedSuggestion {
  return {
    ...suggestion,
    title: translateStringOrLocaleWithDefault(
      suggestion.title,
      'language.unknownValue',
    ).value,
    description: translateStringOrLocaleWithDefault(
      suggestion.description,
      'language.unknownValue',
    ).value,
  };
}

const getSuggestions = async (): Promise<{
  data: Suggestion[];
  error?: Error;
}> => {
  const response = await fetch(suggestionsUrl, {
    method: 'GET',
    credentials: 'include',
  });
  if (!response.ok) throw new Error('error.get_suggestions_failed');
  const suggestions: Suggestion[] = await response.json();
  const error = await addWorkItemsData(suggestions);
  return { data: suggestions, error };
};
async function addWorkItemsData(
  suggestions: Suggestion[],
): Promise<Error | undefined> {
  const workItemIds = suggestions
    .map((i) => i.workItem?.id ?? -1)
    .filter((i) => i !== -1);
  if (workItemIds.length == 0) return;
  try {
    const response = await fetch(`${suggestionsUrl}/workitems`, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify(workItemIds),
      headers: { 'Content-Type': 'application/json' },
    });
    const workItems: WorkItem[] = await response.json();
    for (const suggestion of suggestions) {
      suggestion.workItem = workItems.find(
        (i) => i.id === suggestion.workItem?.id,
      );
    }
  } catch (err) {
    (err as Error).message = 'error.get_workitems_failed';
    return err as Error;
  }
}

export const useSuggestionPages = (): ComposableResult<SuggestionPage[]> => {
  return compose(getSuggestionPages());
};

const getSuggestionPages = async (): Promise<SuggestionPage[]> => {
  const response = await fetch(suggestionPageUrl, {
    method: 'GET',
    credentials: 'include',
  });
  if (!response.ok) throw new Error(await response.text());
  return response.json();
};

const getSuggestion = async (
  suggestionId: number,
  includeWorkItem: boolean,
): Promise<{ data: Suggestion; error?: Error }> => {
  const search = includeWorkItem === true ? '?includeWorkItem=true' : '';
  const response = await fetch(`${suggestionsUrl}/${suggestionId}${search}`, {
    method: 'GET',
    credentials: 'include',
  });
  if (!response.ok) throw new Error('error.get_item_failed');
  const data: Suggestion = await response.json();
  const error =
    data.workItem !== undefined && data.workItem?.id === undefined
      ? new Error('error.get_workitems_failed')
      : undefined;
  return { data, error };
};
export async function updateSuggestion(item: Suggestion) {
  fetch(`${suggestionsUrl}/${item.id}`, {
    method: 'PUT',
    credentials: 'include',
    body: JSON.stringify(item),
    headers: { 'Content-Type': 'application/json' },
  });
}
export async function closeSuggestion(suggestionId: number) {
  fetch(`${suggestionsUrl}/${suggestionId}/close`, {
    method: 'PUT',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
  });
}
export async function createSuggestion(
  item: Omit<Suggestion, 'id' | 'suggestedBy'>,
): Promise<Suggestion> {
  const resp = await fetch(`${suggestionsUrl}`, {
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify(item),
    headers: { 'Content-Type': 'application/json' },
  });
  return resp.json();
}

export async function deleteSuggestion(suggestionId: number) {
  fetch(`${suggestionsUrl}/${suggestionId}`, {
    method: 'DELETE',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
  });
}

export const updateVote = async (
  suggestionId: number,
  direction: VoteDirection,
) => {
  const resp = await fetch(
    `${suggestionsUrl}/${suggestionId}/vote?direction=${direction}`,
    {
      method: 'PUT',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
    },
  );
  if (!resp.ok) throw new Error('error.update_vote_failed');
  return await resp.json();
};
export const updateSubscription = async (
  suggestionId: number,
  subscribe: boolean,
): Promise<SuggestionUserConnection> => {
  const resp = await fetch(
    `${suggestionsUrl}/${suggestionId}/vote?subscribe=${subscribe}`,
    {
      method: 'PUT',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
    },
  );
  if (!resp.ok) throw new Error('error.update_subscription_failed');
  return await resp.json();
};

export const connectWorkItem = async (
  suggestion: Pick<Suggestion, 'workItem'>,
  suggestionId: number | undefined,
): Promise<Suggestion> => {
  const endpoint =
    suggestionId === undefined
      ? 'from_devops'
      : `${suggestionId}/connect_devops`;
  const response = await fetch(`${suggestionsUrl}/${endpoint}`, {
    method: suggestionId === undefined ? 'POST' : 'PUT',
    credentials: 'include',
    body: JSON.stringify(suggestion),
    headers: { 'Content-Type': 'application/json' },
  });
  if (!response.ok) throw new Error('error.get_item_failed');
  return await response.json();
};
export const disconnectWorkItem = async (
  suggestion: Pick<Suggestion, 'workItem'>,
  suggestionId: number,
): Promise<Suggestion> => {
  const response = await fetch(
    `${suggestionsUrl}/${suggestionId}/disconnect_devops`,
    {
      method: 'PUT',
      credentials: 'include',
      body: JSON.stringify(suggestion),
      headers: { 'Content-Type': 'application/json' },
    },
  );
  if (!response.ok) throw new Error('error.get_item_failed');
  return await response.json();
};
export const createWorkItem = async (
  data: Pick<WorkItem, 'type' | 'title' | 'description'>,
): Promise<WorkItem> => {
  const response = await fetch(`${suggestionsUrl}/workitems/create`, {
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify(data),
    headers: { 'Content-Type': 'application/json' },
  });
  if (!response.ok) throw new Error('error.create_devops_failed');
  return await response.json();
};

export const getWorkItemFromSuggestion = async (
  suggestionId: number,
): Promise<WorkItem | undefined> => {
  const response = await fetch(
    `${suggestionsUrl}/workitems/from_suggestion/${suggestionId}`,
    {
      method: 'GET',
      credentials: 'include',
    },
  );
  if (!response.ok) throw new Error('error.get_item_failed');
  if (response.status === 204) return undefined;
  return await response.json();
};
