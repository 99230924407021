<script
  setup
  lang="ts"
  generic="
    T extends NonNullable<unknown>,
    TId extends KeysOfUnion<T> | undefined,
    SortField extends KeyOfRecursive<T, true>
  "
>
import type {
  ArrayItem,
  FgColors,
  KeyOfRecursive,
  KeysOfUnion,
  localeValue,
  OnlyKeysWithValsOfType,
  PartialSome,
  RecurChildType,
  VueClass,
} from '@/api/types';
import {
  useSort,
  type Comparator,
  type SortDirection,
} from '@/utilities/order';
import { computed, ref, toRef, useAttrs } from 'vue';
import { useI18n } from 'vue-i18n';
import LoadingSpinner from './LoadingSpinner.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import ModalComponent from '@/components/ModalComponent.vue';
import { translateStringOrLocale } from '@/i18n';

type SelectedItemType =
  | (undefined extends TId ? T : PartialSome<T, NonNullable<TId>>)
  | null;

const props = defineProps<{
  rows: T[] | null | undefined;
  i18nKey: string;
  usesDynamicTextKeys?: boolean;
  showSpinner?: boolean;
  getKey: (item: T, idx: number) => string | number;
  sortFields?: Readonly<SortField[]> | SortField;
  defaultSortfield?: SortField;
  defaultSortDirection?: SortDirection;
  comparers?: {
    [key in SortField]?: Comparator<
      RecurChildType<T, key> extends Array<unknown> | undefined
        ? ArrayItem<RecurChildType<T, key>>
        : RecurChildType<T, key>
    >;
  };
  skipSortOnContentChange?: boolean;
  groupColumnHeader?: boolean;
  columns: (SortField | `${SortField}.title` | null)[];
  /** Use if you have complex logic for deciding the column headers */
  getColumnHeader?: (column: SortField) => string;
  size?: 'table-xs' | 'table-sm' | 'table-md' | 'table-lg';
  columnHeaderClass?: VueClass;
  rowClass?: VueClass;
  /** Defaults to 'both' */
  pin?: 'rows' | 'cols' | 'both' | 'none';
  noZebra?: boolean;
  textColor?: FgColors;
  headerTextColor?: FgColors;
  noHeader?: boolean;
  delete?: {
    onDelete: (row: T) => Promise<void> | void;
    identificatorLabel?: string;
    title?: string;
    warning?: string;
    getIdentificator: (row: T) => localeValue | string;
    noConfirm?: boolean;
  };
  edit?: {
    title?: string;
    isSelectedItemValid: boolean;
    modalProps?: Omit<
      InstanceType<typeof ModalComponent>['$props'],
      'showModal' | 'title'
    >;
    onSave: () => Promise<void> | void;
  };
  create?: {
    title?: string;
    idProperty: TId;
    onCreateClick: () => Promise<SelectedItemType> | SelectedItemType;
    buttonBelow?: boolean;
  };
  initialRowClass?: VueClass;
}>();

defineOptions({
  inheritAttrs: false,
});
const attrs = useAttrs();

const emit = defineEmits<{
  (e: 'update:selectedItem', row: SelectedItemType): void;
  (e: 'on:updateTriggered'): void;
  (e: 'remove:row', row: SelectedItemType): void;
}>();

const slots = defineSlots<{
  beforeCreate: () => HTMLTableCellElement;
  afterCreate: () => HTMLTableCellElement;
  /** Should return the *content* (not `<th></th>`) for the column header (the first column) if you want a column header.
   * If you don't want a column header, do not use this slot. **Important:** the column header will stick if you scroll horizontally in mobile mode, so it is recommended to use this slot. */
  columnHeader?: (props: {
    item: ArrayItem<(typeof sortedValues)['value']>;
  }) => Element;
  /** Should return the Table Data cells for all column except the column header (if you have one), inlcuding `<td></td>` for each column. */
  columns: (props: {
    item: ArrayItem<(typeof sortedValues)['value']>;
    rowIndex: number;
    items: T[];
  }) => HTMLTableCellElement;
  editor: () => HTMLTableCellElement;
}>();

const { t } = useI18n();

const _sortFields = computed((): SortField[] | undefined =>
  props.sortFields
    ? Array.isArray(props.sortFields)
      ? props.sortFields
      : [props.sortFields]
    : undefined,
);

const { sortOnField, getHeaderClasses, sortedValues, isGrouped } = useSort(
  toRef(props, 'rows'),
  _sortFields.value,
  props.defaultSortfield ?? _sortFields.value?.[0],
  props.defaultSortDirection,
  props.comparers,
  props.skipSortOnContentChange,
  (field) => props.groupColumnHeader && field === props.columns[0],
);

const columnList = computed(
  (): ({ key: SortField; label: string } | undefined)[] => {
    return props.columns.concat(props.delete ? [null] : []).map((col) => {
      const key = col?.replace('.title', '') as SortField;
      return col
        ? {
            key,
            label:
              props.getColumnHeader?.(key) ??
              t(
                `${props.i18nKey}.${col}${props.usesDynamicTextKeys ? '.label' : ''}`,
              ),
          }
        : undefined;
    });
  },
);

const showDetailsModal = ref(false);

function viewDetails(item: SelectedItemType, updateTriggerEvent?: MouseEvent) {
  if (updateTriggerEvent?.defaultPrevented) return;
  if (updateTriggerEvent) {
    emit('on:updateTriggered');
  }
  emit('update:selectedItem', item);
  if (!props.edit) return;
  showDetailsModal.value = true;
}

function closeDetails(keepNewRow?: boolean) {
  showDetailsModal.value = false;
  if (newItem.value && keepNewRow !== true) {
    emit('remove:row', newItem.value);
  }
  // Delay resetting the selected item to avoid information disappearing from the dialog before it's closed
  setTimeout(() => emit('update:selectedItem', null), 200);
  newItem.value = undefined;
}

const newItem = ref<SelectedItemType>();
async function onCreateButtonClick() {
  newItem.value = await props.create?.onCreateClick();
  if (!newItem.value) return;
  viewDetails(newItem.value);
}

async function onSave() {
  await props.edit?.onSave();
  closeDetails(true);
}

const showDeleteModal = ref(false);
const itemToDelete = ref<T>();
async function doDelete() {
  if (!itemToDelete.value) return;
  await props.delete?.onDelete(itemToDelete.value);
  emit('remove:row', itemToDelete.value);
  showDeleteModal.value = false;
  itemToDelete.value = undefined;
}

function getDeleteText(
  key:
    | keyof OnlyKeysWithValsOfType<NonNullable<typeof props.delete>, string>
    | 'getIdentificator',
) {
  if (key === 'getIdentificator') {
    if (!itemToDelete.value) {
      return t('tableComponent.delete.identificator');
    } else {
      return translateStringOrLocale(
        props.delete?.getIdentificator(itemToDelete.value) ??
          'tableComponent.delete.identificator',
      ).value;
    }
  } else {
    return t(props.delete?.[key] ?? `tableComponent.delete.${key}`);
  }
}
</script>

<template>
  <div v-if="!create?.buttonBelow" :class="initialRowClass">
    <slot name="beforeCreate" />
    <ButtonComponent
      v-if="create"
      :text="t(create?.title ?? 'tableComponent.create')"
      @click="onCreateButtonClick"
    />
    <slot name="afterCreate" />
  </div>
  <div class="GnistTable" v-bind="attrs">
    <table
      class="table overflow-auto"
      :class="[
        size,
        {
          'text-gnist-black': !textColor,
          [`text-gnist-${textColor}`]: textColor,
          'table-pin-rows':
            pin === 'rows' || pin === 'both' || pin === undefined,
          'table-pin-cols':
            pin === 'cols' || pin === 'both' || pin === undefined,
        },
      ]"
    >
      <thead
        v-if="!noHeader"
        class="z-20"
        :class="{
          'text-gnist-black': !headerTextColor,
          [`text-gnist-${headerTextColor}`]: headerTextColor,
        }"
      >
        <tr>
          <template v-for="(column, index) in columnList" :key="column">
            <th
              v-if="index === 0"
              class="z-10"
              :class="[
                column && getHeaderClasses(column.key),
                { 'w-0': !column },
              ]"
              @click="column && sortOnField(column.key)"
            >
              {{ column?.label ?? '' }}
            </th>
            <td
              v-else
              :class="[
                column && getHeaderClasses(column.key),
                { 'w-0': !column },
              ]"
              @click="column && sortOnField(column.key)"
            >
              {{ column?.label ?? '' }}
            </td>
          </template>
        </tr>
      </thead>
      <tbody>
        <tr v-if="!sortedValues">
          <td
            :colspan="columns.length"
            class="w-full items-center p-8 text-center"
          >
            <div class="flex w-full flex-col items-center">
              <LoadingSpinner v-if="showSpinner" class="h-16 w-16" />
            </div>
          </td>
        </tr>
        <template v-else>
          <tr
            v-for="(row, index) in sortedValues"
            :key="getKey(row, index)"
            class="bg-gnist-white hover:bg-gnist-blue-light-light"
            :class="[
              rowClass,
              {
                'even:bg-gnist-gray-light-light': !noZebra,
                'cursor-pointer': edit,
              },
            ]"
            @click="(ev) => viewDetails(row as SelectedItemType, ev)"
          >
            <th
              v-if="slots.columnHeader && (row.isGroupHeader || !isGrouped)"
              :rowspan="isGrouped ? row.groupSize : undefined"
              :scope="isGrouped ? 'rowgroup' : undefined"
              class="ColumnHeader z-10"
              :class="[
                columnHeaderClass,
                { 'bg-gnist-white align-top': isGrouped },
              ]"
            >
              <slot name="columnHeader" :item="row" />
            </th>
            <slot
              name="columns"
              :item="row"
              :row-index="index"
              :items="sortedValues"
            />
            <td
              v-if="props.delete"
              class="ButtonRow cursor-default"
              @click.stop
            >
              <ButtonComponent
                :text="t('buttons.delete')"
                class="gnist-button gnist-button-danger"
                @click.prevent="
                  (ev) => {
                    ev.preventDefault();
                    itemToDelete = row;
                    if (props.delete?.noConfirm) {
                      doDelete();
                    } else {
                      showDeleteModal = true;
                    }
                  }
                "
              />
            </td>
          </tr>
        </template>
      </tbody>
    </table>
    <ModalComponent
      :show-modal="showDeleteModal"
      :title="getDeleteText('title')"
      @close="
        () => {
          itemToDelete = undefined;
          showDeleteModal = false;
        }
      "
      @handle-click="doDelete"
    >
      <template #default>
        <p class="py-2">
          <strong>{{ getDeleteText('identificatorLabel') }}: </strong>
          {{ getDeleteText('getIdentificator') }}
        </p>
        <p>
          {{ getDeleteText('warning') }}
        </p>
      </template>
    </ModalComponent>
    <ModalComponent
      :show-modal="showDetailsModal"
      :title="t(props.edit?.title ?? 'tableComponent.detailsTitle')"
      v-bind="props.edit?.modalProps"
      @close="closeDetails"
    >
      <template #default>
        <slot name="editor" />
      </template>
      <template #buttons>
        <div className="flex justify-end">
          <div class="gnist-button-group">
            <ButtonComponent
              :text="t('buttons.cancel')"
              @click="closeDetails"
            />
            <ButtonComponent
              type="primary"
              :text="t('buttons.save')"
              :disabled="!edit?.isSelectedItemValid"
              @click="onSave"
            />
          </div>
        </div>
      </template>
    </ModalComponent>
  </div>
  <div v-if="create?.buttonBelow" :class="initialRowClass">
    <slot name="beforeCreate" />
    <ButtonComponent
      v-if="create"
      :text="t(create?.title ?? 'tableComponent.create')"
      @click="onCreateButtonClick"
    />
    <slot name="afterCreate" />
  </div>
</template>

<style>
.GnistTable {
  @apply max-h-[90vh] w-full max-w-full;
  @apply overflow-x-auto;
  @apply border-b;
}
.GnistTable:not([class*='max-h-']) {
  @apply sm:max-h-[unset];
  @apply sm:overflow-x-visible;
}
.ColumnHeader {
  min-width: clamp(30vw, 4rem, 40vw);
  @apply md:min-w-min;
  max-width: 40vw;
}
.ColumnHeader:not([class*='bg-']) {
  background-color: inherit;
}
.GnistTable .table-lg :where(thead, tfoot) {
  font-size: 1rem;
}
</style>
