<script lang="ts">
export function ensureTabVisible(headerId: string) {
  const tab = document.getElementById(
    `tab_selector_${headerId}`,
  ) as HTMLInputElement;
  tab?.click();
}
</script>
<script setup lang="ts">
import idEncode from '@/utilities/idEncode';
import { useAttrs } from 'vue';

defineProps<{
  groupName: string;
  header: string;
  initialChecked?: boolean;
  headerType?: 'h3' | 'h4' | 'none';
  headerAttributes?: Record<string, string>;
}>();
const attrs = useAttrs();
</script>

<template>
  <input
    :id="`tab_selector_${idEncode(header)}`"
    type="radio"
    :name="groupName"
    role="tab"
    class="tab"
    :aria-label="header"
    :checked="initialChecked"
    :title="header"
  />
  <div
    role="tabpanel"
    class="tab-content overflow-hidden rounded-box border-base-300"
    v-bind="attrs"
    :title="header"
  >
    <component
      :is="headerType ?? 'h3'"
      v-if="headerType !== 'none'"
      :id="idEncode(header)"
      class="flex items-center"
      v-bind="headerAttributes"
    >
      {{ header }}
      <slot name="header" />
    </component>
    <slot />
  </div>
</template>

<style scoped>
/* This is needed to ensure we get "above" the tab-content div, for some reason */
input:checked {
  z-index: 1;
}
input[type='radio']::after {
  @apply line-clamp-1;
}
</style>
