export function timeDifference(
  current: Date,
  previous: Date,
  t: (v: string) => string,
): string {
  // Times are defined in milliseconds
  const msPerSecond = 1000;
  const msPerMinute = 60 * msPerSecond;
  const msPerHour = 60 * msPerMinute;
  const msPerDay = 24 * msPerHour;
  const msPerMonth = 30 * msPerDay;
  const msPerYear = 12 * msPerMonth;

  // Time ago thresholds
  const msJustNow = 5 * msPerSecond; // Up until 5 s
  const msMinutesAgo = 2 * msPerMinute; // Up until 2 minutes
  const msHoursAgo = 2 * msPerHour; // Up until 2 hours
  const msDaysAgo = 2 * msPerDay; // Up until 2 days
  const msMonthsAgo = 2 * msPerMonth; // Up until 2 months
  const msYearsAgo = 2 * msPerYear; // Up until 2 years

  const elapsed = current.getTime() - previous.getTime();

  if (elapsed < msJustNow) {
    return t('time_ago.just now');
  } else if (elapsed < msMinutesAgo) {
    return `${Math.round(elapsed / msPerSecond)} ${t('time_ago.seconds ago')}`;
  } else if (elapsed < msHoursAgo) {
    return `${Math.round(elapsed / msPerMinute)} ${t('time_ago.minutes_ago')}`;
  } else if (elapsed < msDaysAgo) {
    return `${Math.round(elapsed / msPerHour)} ${t('time_ago.hours_ago')}`;
  } else if (elapsed < msMonthsAgo) {
    return `${Math.round(elapsed / msPerDay)} ${t('time_ago.days_ago')}`;
  } else if (elapsed < msYearsAgo) {
    return `${Math.round(elapsed / msPerMonth)} ${t('time_ago.months_ago')}`;
  } else {
    return `${Math.round(elapsed / msPerYear)} ${t('time_ago.years_ago')}`;
  }
}

export function colorFromString(value: string): string {
  const colors = [
    '#396ab1',
    '#da7c30',
    '#3e9651',
    '#cc2529',
    '#922428',
    '#6b4c9a',
    '#535154',
  ];

  let total = 0;
  for (let i = 0; i < value.length; i++) {
    total += value.charCodeAt(i);
  }
  return colors[total % colors.length];
}
