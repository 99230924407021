<script setup lang="ts">
import type { Step, Translated } from '@/api/types';
import { useI18n } from 'vue-i18n';
import MaterialIcon from './MaterialIcon.vue';

defineProps<{
  step: Pick<Translated<Step[]>, 'title'>;
  isActive?: boolean;
  index: number;
  isAddStep?: boolean;
}>();

const emit = defineEmits<{ (e: 'click'): void }>();

const { t } = useI18n();
</script>
<template>
  <button
    class="mx-auto block h-16 w-16 flex-initial text-center"
    :class="{
      'bg-gnist-blue-dark text-gnist-white': isActive,
      'bg-gnist-white text-gnist-blue-dark': !isActive,
      'rounded-full border-[2px] border-gnist-blue-dark': !isAddStep,
    }"
    :aria-label="
      isAddStep
        ? t('admin.blockProduction.newStep')
        : `${t('admin.blockProduction.selectStep')}${step.title}`
    "
    :data-cy-id="isAddStep ? 'StepsEditorAddStep' : undefined"
    @click.prevent="emit('click')"
  >
    <MaterialIcon v-if="isAddStep" class="text-6xl font-extrabold">
      add
    </MaterialIcon>
    <span v-else class="text-2xl font-semibold">{{ index + 1 }}</span>
  </button>
  <p class="mt-2 w-32 cursor-default break-words text-center text-gnist-black">
    {{ step.title }}
  </p>
</template>
