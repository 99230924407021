<script setup lang="ts">
import { WithUndefined, type VueClass } from '@/api/types';
import { computed, type StyleValue } from 'vue';
import { useAttrs } from 'vue';
import type { RouteLocation, RouterLinkProps } from 'vue-router';

const props = defineProps<
  Partial<RouterLinkProps> & {
    href?: string;
    outerClass?: VueClass;
    outerStyle?: StyleValue;
  }
>();

defineOptions({
  inheritAttrs: false,
});
const _attrs = useAttrs();
const attrs = { ..._attrs, target: undefined };

const routerLinkProps = computed(
  () =>
    WithUndefined<typeof props, RouterLinkProps>(
      props,
      { href: undefined, outerClass: undefined, outerStyle: undefined },
      { target: _attrs.target },
    ) as RouterLinkProps,
);
</script>

<template>
  <template v-if="to">
    <RouterLink
      v-bind="routerLinkProps"
      :href="(routerLinkProps.to as RouteLocation).fullPath"
      class="grid cursor-pointer"
      :class="outerClass"
      :style="outerStyle"
    >
      <div v-bind="attrs">
        <slot />
      </div>
    </RouterLink>
  </template>
  <template v-else-if="href">
    <a :href="href" class="grid" :class="outerClass" :style="outerStyle">
      <div v-bind="attrs">
        <slot />
      </div>
    </a>
  </template>
  <template v-else>
    <div v-bind="attrs" :class="outerClass" :style="outerStyle">
      <slot />
    </div>
  </template>
</template>
