<script lang="ts" setup>
import type { SortStrategy } from '@/api/commentoTypes';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

defineProps<{
  sortStrategy: SortStrategy | null;
}>();

defineEmits<{
  (e: 'setSortStrategy', sortStrategy: SortStrategy): void;
}>();
</script>

<template>
  <div>
    <div
      class="commenter-sort-policy-buttons-container mt-4 flex justify-end text-sm"
    >
      <div class="commenter-sort-policy-buttons">
        <button>
          <a
            class="commenter-sort-policy-button commenter-sort-policy-button-selected mx-2"
            :class="{ 'text-[#3b5bdb]': sortStrategy == 'score-desc' }"
            @click="$emit('setSortStrategy', 'score-desc')"
            >{{ t('discussion.upvotes') }}</a
          >
        </button>
        <button>
          <a
            class="commenter-sort-policy-button mx-2"
            :class="{ 'text-[#3b5bdb]': sortStrategy == 'creationdate-desc' }"
            @click="$emit('setSortStrategy', 'creationdate-desc')"
            >{{ t('discussion.newest') }}</a
          >
        </button>
        <button>
          <a
            class="commenter-sort-policy-button mx-2"
            :class="{ 'text-[#3b5bdb]': sortStrategy == 'creationdate-asc' }"
            @click="$emit('setSortStrategy', 'creationdate-asc')"
            >{{ t('discussion.oldest') }}</a
          >
        </button>
      </div>
    </div>
  </div>
</template>
