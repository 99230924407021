import {
  ref,
  type DebuggerOptions,
  type WritableComputedOptions,
  type WritableComputedRef,
  computed,
} from 'vue';

/** This can be used if you need to force refresh a writable computed ref */
export function useRefreshable<T>(
  options: WritableComputedOptions<T>,
  debugOptions?: DebuggerOptions,
): [WritableComputedRef<T>, () => void] {
  const refreshKey = ref(0);

  const compRef = computed(
    {
      get() {
        refreshKey.value;
        return options.get();
      },
      set(newValue) {
        options.set(newValue);
      },
    },
    debugOptions,
  );

  return [compRef, () => refreshKey.value++];
}
