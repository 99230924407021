<script setup lang="ts">
import type { CardTemplateProps } from '@/api/types';
import { useI18n } from 'vue-i18n';
import BlockStatusBar from '@/components/BlockStatusBar.vue';
import TagListRenderer from '@/components/TagListRenderer.vue';

const { t } = useI18n();

defineProps<CardTemplateProps>();
</script>
<template>
  <div
    class="flex h-full flex-col break-words rounded-md bg-gnist-white p-6 text-gnist-black shadow-md lg:w-[20rem]"
  >
    <div class="flex justify-between gap-4">
      <p class="whitespace-nowrap italic leading-6">
        {{ block.category?.name ?? '' }}
      </p>
      <TagListRenderer :tags="block.tags" />
    </div>
    <hr
      class="my-2 border-2"
      :class="`border-gnist-${block.category?.color ?? 'gray'}`"
    />
    <h2 class="text-subtitle">
      {{ block.name }}
    </h2>
    <p class="text-box mt-4 grow">
      {{ block.description }}
    </p>
    <p class="text-a1 my-4 text-center">
      {{ t('blockList.view') }}
    </p>
    <BlockStatusBar class="mx-[-1.5rem] mt-2" :block="block" />
  </div>
</template>
