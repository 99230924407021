<script lang="ts">
export function getStatusText(
  block: Pick<Block, 'isBacklog' | 'status'>,
): string {
  return block.status === 'Published' && block.isBacklog
    ? `block.backlogStatus.${block.status}`
    : `block.status.${block.status}`;
}
</script>

<script setup lang="ts">
import { Status, type Block } from '@/api/types';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const props = defineProps<{
  block: Pick<Block, 'hasBacklog' | 'isBacklog' | 'status'>;
}>();

const statusClass = computed(() => {
  switch (props.block.status) {
    case Status.Draft:
      return 'bg-gnist-gray-light';
    case Status.Archived:
      return 'bg-gnist-yellow';
    case Status.Published:
    default:
      return props.block.hasBacklog ? 'bg-gnist-gray-light' : 'invisible';
  }
});
</script>
<template>
  <p :class="['flex h-4 items-center px-8 py-1 text-xs', statusClass]">
    {{
      block.status === 'Published' && block.hasBacklog
        ? t('block.status.VersionInBacklog')
        : t(getStatusText(block))
    }}
  </p>
</template>
