import { apiUrl } from '@/config';
import {
  compose,
  composeLazy,
  composeWithRefresh,
  type ComposableResult,
  type LazyComposableResult,
} from './composable';
import type { Tag, TagGroup } from './types';
import { isRef, type Ref } from 'vue';

const tagUrl = apiUrl + '/tags';

export function useTags(
  forGroupId?: Ref<number | undefined>,
  delayInit?: boolean,
): LazyComposableResult<Tag[]>;
export function useTags(forGroupId?: number): ComposableResult<Tag[]>;
export function useTags(
  forGroupId?: number | Ref<number | undefined>,
  delayInit?: boolean,
): ComposableResult<Tag[]> | LazyComposableResult<Tag[]> {
  if (isRef(forGroupId)) {
    const composable = composeLazy(() => {
      if (!forGroupId.value) return [];
      return getTags(forGroupId.value);
    }, forGroupId);
    if (!delayInit) composable.init();
    return composable;
  } else {
    return compose(getTags(forGroupId));
  }
}
async function getTags(forGroupId?: number): Promise<Tag[]> {
  const query = forGroupId ? `?tagGroupId=${forGroupId}` : '';
  const response = await fetch(`${tagUrl}${query}`, {
    method: 'GET',
    credentials: 'include',
  });
  if (!response.ok) throw new Error(await response.text());
  return await response.json();
}

export const createTag = async (
  tag: Omit<Tag, 'id' | 'tagGroups'>,
): Promise<Tag> => {
  const response = await fetch(tagUrl, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    body: JSON.stringify(tag),
  });
  if (!response.ok) throw new Error('error.create_failed');
  return await response.json();
};
export const updateTag = async (tag: Tag): Promise<Tag> => {
  const response = await fetch(`${tagUrl}/${tag.id}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    body: JSON.stringify(tag),
  });
  if (!response.ok) throw new Error('error.update_failed');
  return await response.json();
};
export const deleteTag = async (tagId: number): Promise<void> => {
  const response = await fetch(`${tagUrl}/${tagId}`, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
  });
  if (!response.ok) throw new Error('error.delete_failed');
};

const allTagGroupsLazy = composeLazy(() => getTagGroups());
export function useTagGroupsLazy(): LazyComposableResult<TagGroup[]> {
  allTagGroupsLazy.init();
  return allTagGroupsLazy;
}
export function useTagGroups(): ComposableResult<TagGroup[]> {
  return compose(getTagGroups());
}
async function getTagGroups(): Promise<TagGroup[]> {
  const response = await fetch(`${tagUrl}/tagGroups`, {
    method: 'GET',
    credentials: 'include',
  });
  if (!response.ok) throw new Error(await response.text());
  return await response.json();
}
export function useTagGroup(
  tagGroupId: number | Ref<number | undefined>,
): ComposableResult<TagGroup> {
  if (isRef(tagGroupId)) {
    return composeWithRefresh(() => {
      if (!tagGroupId.value) return null;
      return getTagGroup(tagGroupId.value);
    }, tagGroupId);
  } else {
    return compose(getTagGroup(tagGroupId));
  }
}
async function getTagGroup(tagGroupId: number): Promise<TagGroup> {
  const response = await fetch(`${tagUrl}/tagGroups/${tagGroupId}`, {
    method: 'GET',
    credentials: 'include',
  });
  if (!response.ok) throw new Error(await response.text());
  return await response.json();
}
export const createTagGroup = async (
  tagGroup: Omit<TagGroup, 'id' | 'tags'>,
): Promise<TagGroup> => {
  const response = await fetch(`${tagUrl}/tagGroups`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    body: JSON.stringify(tagGroup),
  });
  if (!response.ok) throw new Error('error.create_failed');
  return await response.json();
};
export const updateTagGroup = async (
  tagGroupId: number,
  tagGroup: Omit<TagGroup, 'tags'>,
): Promise<TagGroup> => {
  const response = await fetch(`${tagUrl}/tagGroup/${tagGroupId}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    body: JSON.stringify(tagGroup),
  });
  if (!response.ok) throw new Error('error.update_failed');
  return await response.json();
};
export const deleteTagGroup = async (tagGroupId: number): Promise<void> => {
  const response = await fetch(`${tagUrl}/tagGroup/${tagGroupId}`, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
  });
  if (!response.ok) throw new Error('error.delete_failed');
};
