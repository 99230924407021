<script setup lang="ts">
import { ParagraphShimmer } from 'vue3-shimmer';
import ContentPage from '../components/ContentPage.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import AlzSubNav from '@/components/AlzSubNav.vue';
import { useI18n } from 'vue-i18n';
import { useMyAlzSystems } from '@/api/github';
import { throwOnError } from '@/api/composable';
import { useStoreAbility } from '@/abilities';

const { t } = useI18n();
const { can } = useStoreAbility();

const { result: systems } = throwOnError(
  useMyAlzSystems(can('manage', 'AlzOrders')),
);
const groups: { label: string; sandbox: boolean }[] = [
  { label: 'mySystemsListHeader', sandbox: false },
  { label: 'mySandboxesListHeader', sandbox: true },
];
</script>

<template>
  <ContentPage :title="t('header.items.orders.my_alz_applications')">
    <ParagraphShimmer
      v-if="!systems"
      :lines="6"
      :random-size="true"
      class="mx-auto w-full max-w-5xl"
    />
    <div v-else class="flex w-full flex-col">
      <AlzSubNav />
      <div class="flex flex-col gap-4">
        <fieldset
          v-for="group in groups"
          :key="`${group.sandbox}`"
          class="flex min-w-0 flex-col gap-4 border p-4 ps-4"
        >
          <legend>{{ t(`orders.alz.${group.label}`) }}</legend>
          <div
            v-for="system in systems.filter(
              (s) => s.data.sandbox === group.sandbox,
            )"
            :key="system.data.id"
          >
            <RouterLink
              class="ring-offset-primary-blueDark grow basis-1 whitespace-nowrap rounded-lg px-4 py-2.5 text-sm font-medium text-gnist-black ring-white ring-opacity-60 ring-offset-2 focus:outline-none focus:ring-2"
              :to="{
                name: 'alz_order_form',
                params: { systemId: system.data.id },
              }"
            >
              <ButtonComponent
                :text="
                  system.source === 'PullRequest'
                    ? `${system.data.name} (PR#${system.pullRequestId})`
                    : system.data.name
                "
              />
            </RouterLink>
          </div>
        </fieldset>
        <div class="flex self-end">
          <RouterLink
            class="ring-offset-primary-blueDark grow basis-1 whitespace-nowrap rounded-lg px-4 py-2.5 text-sm font-medium text-gnist-black ring-white ring-opacity-60 ring-offset-2 focus:outline-none focus:ring-2"
            :to="{ name: 'alz_order_form' }"
          >
            <ButtonComponent text="orders.alz.newSystem" type="special" />
          </RouterLink>
          <RouterLink
            class="ring-offset-primary-blueDark grow basis-1 whitespace-nowrap rounded-lg px-4 py-2.5 text-sm font-medium text-gnist-black ring-white ring-opacity-60 ring-offset-2 focus:outline-none focus:ring-2"
            :to="{ name: 'alz_order_form', query: { sandbox: 'true' } }"
          >
            <ButtonComponent text="orders.alz.newSandbox" type="special" />
          </RouterLink>
        </div>
      </div>
    </div>
  </ContentPage>
</template>
