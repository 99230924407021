<script setup lang="ts">
import { computed, ref } from 'vue';
import { colorFromString, timeDifference } from './util';
import {
  commentDelete,
  commentEdit,
  commentNew,
  pageUpdate,
  vote,
  commentModeratorDelete,
} from '@/api/commentoClient';
import type { Discussion, ThreadComment } from '@/api/commentoTypes';
import WriteComment from './WriteComment.vue';
import RoleBadge, { hasBadgeRole } from '../RoleBadge.vue';
import MaterialIcon from '@/components/MaterialIcon.vue';
import { useI18n } from 'vue-i18n';
import { useStoreAbility } from '@/abilities';
import { useCurrentUser } from '@/api/auth';
import type { SocialPageType } from '@/enums/SocialPageType';
import { useRoute } from 'vue-router';
import { firstParam } from '@/utilities/firstParam';

const userInfo = useCurrentUser();
const hasBadge = hasBadgeRole(userInfo.value);

const props = defineProps<{
  comment: ThreadComment;
  discussion: Discussion;
  discussionType: SocialPageType;
}>();

const { can } = useStoreAbility();

const { t } = useI18n();

const route = useRoute();
const isLinkComment = route.params.commentId === props.comment.commentHex;
const showReplyToComment = ref<boolean>(
  firstParam(route.query['edit']) !== null && isLinkComment,
);

function toggleReply() {
  showReplyToComment.value = !showReplyToComment.value;
}

const showEditComment = ref<boolean>(false);

function toggleEdit() {
  showEditComment.value = !showEditComment.value;
}

const isCommentAuthor = computed(() => {
  return props.comment.commenter?.userId === userInfo.value?.id;
});

const isAuthenticated = computed(() => !!userInfo.value);

function saveComment(markdown: string) {
  if (userInfo.value) {
    commentEdit(
      props.comment,
      markdown,
      props.discussion.attributes.id,
      props.discussionType,
    );

    toggleEdit();
  }
}

async function saveCommentReply(markdown: string) {
  if (userInfo.value) {
    await commentNew(
      props.discussion,
      props.comment.commentHex,
      markdown,
      userInfo.value,
      props.discussion.attributes.id,
      props.discussionType,
    );
  }

  toggleReply();
}

function deleteComment() {
  if (confirm(t('discussion.deleteCommentConfirmation'))) {
    commentDelete(props.comment);
  }
}

function moderatorDeleteComment() {
  if (confirm(t('discussion.deleteCommentConfirmation'))) {
    commentModeratorDelete(props.comment);
  }
}

const upvoteClass = computed(() => {
  return {
    '!font-normal': props.comment.direction <= 0,
    '!font-bold': props.comment.direction > 0,
  };
});

const downvoteClass = computed(() => {
  return {
    '!font-normal': props.comment.direction >= 0,
    '!font-bold': props.comment.direction < 0,
  };
});

function voteUp() {
  if (userInfo.value) {
    vote(props.comment, props.comment.direction == 1 ? 0 : 1);
  }
}

function voteDown() {
  if (userInfo.value) {
    vote(props.comment, props.comment.direction == -1 ? 0 : -1);
  }
}

const color = computed(() => {
  return colorFromString(props.comment.commenter?.userId ?? t('anonymous'));
});

const timeAgo = computed(() => {
  return timeDifference(new Date(), new Date(props.comment.creationDate), t);
});

const isSticky = computed(() => {
  return (
    props.comment.commentHex == props.discussion.attributes.stickyCommentHex
  );
});

function setSticky(): void {
  pageUpdate(
    props.discussion,
    props.discussion.attributes.isLocked,
    props.comment.commentHex,
  );
}

function removeSticky(): void {
  pageUpdate(props.discussion, props.discussion.attributes.isLocked, 'none');
}

const collapsed = ref<boolean>(false);

function toggleCollapse() {
  collapsed.value = !collapsed.value;
}
</script>

<template>
  <div
    :id="`comment-${comment.commentHex}`"
    class="comment-card border border-solid border-gnist-gray bg-gnist-white pl-4 pt-4 text-gnist-black transition-all duration-1000"
    :class="{ 'bg-gnist-yellow bg-opacity-25': isLinkComment }"
    :style="`border-left: 4px solid ${color};`"
  >
    <div
      class="mb-1 grid grid-cols-[min-content_1fr_1rem_max-content] grid-rows-[1fr_max-content] justify-between gap-y-1 md:gap-y-0"
    >
      <div
        class="text-xml col-start-1 row-start-1 mr-2 flex h-10 w-10 items-center justify-center rounded-full text-gnist-white md:row-span-2"
        :style="`background: ${color}`"
        aria-hidden="true"
      >
        {{ (comment.commenter?.name ?? t('anonymous')).charAt(0) }}
      </div>
      <div class="flex flex-col-reverse gap-x-2 lg:flex-row">
        <div
          class="col-span-2 col-start-2 row-start-1 lg:leading-snug"
          :class="hasBadge ? 'line-clamp-2' : 'line-clamp-3'"
        >
          {{ comment.commenter?.name ?? t('anonymous') }}
        </div>
        <RoleBadge :comment="comment" class="lg:self-center" />
      </div>
      <div
        class="text-grey col-span-3 col-start-1 row-start-2 ml-2 text-xs md:col-start-2 md:ml-0"
      >
        <span class="comment-score inline">
          {{ comment.score }} {{ t('discussion.points') }}
        </span>
        <span class="inline">
          {{ `&nbsp;&nbsp;&middot;&nbsp;&nbsp;${timeAgo}` }}
        </span>
      </div>

      <div
        class="col-span-2 col-start-3 row-start-1 flex md:row-span-2 md:row-start-1"
      >
        <template v-if="!comment.deleted">
          <button
            v-if="isCommentAuthor"
            :title="t('discussion.remove')"
            class="w-[36px] rounded-full px-2 hover:bg-gnist-gray-light-light"
            @click="deleteComment"
          >
            <MaterialIcon aria-hidden="true" class="text-lg text-gnist-red">
              delete
            </MaterialIcon>
          </button>
          <button
            v-else-if="can('delete', 'Comment')"
            :title="t('discussion.remove')"
            class="w-[36px] rounded-full px-2 hover:bg-gnist-gray-light-light"
            @click="moderatorDeleteComment"
          >
            <MaterialIcon aria-hidden="true" class="text-lg text-gnist-red">
              delete
            </MaterialIcon>
          </button>

          <button
            v-if="
              comment.parentHex === 'root' &&
              !isSticky &&
              can('update', 'Discussion')
            "
            :title="t('discussion.sticky')"
            class="w-[36px] rounded-full px-2 hover:bg-gnist-gray-light-light"
            @click="setSticky"
          >
            <span
              aria-hidden="true"
              role="img"
              class="material-symbols-rounded text-xl text-gnist-gray"
              >grade</span
            >
          </button>

          <button
            v-if="isSticky && can('update', 'Discussion')"
            :title="t('discussion.unsticky')"
            class="w-[36px] rounded-full px-2 hover:bg-gnist-gray-light-light"
            :disabled="!can('update', 'Discussion')"
            @click="removeSticky"
          >
            <MaterialIcon aria-hidden="true" class="text-xl text-[#f59f00]">
              star
            </MaterialIcon>
          </button>

          <button
            v-if="isAuthenticated && isCommentAuthor && !showEditComment"
            :title="t('discussion.edit')"
            class="w-[36px] rounded-full px-2 hover:bg-gnist-gray-light-light"
            @click="toggleEdit"
          >
            <MaterialIcon aria-hidden="true" class="text-grey text-lg">
              edit
            </MaterialIcon>
          </button>

          <button
            v-if="showEditComment"
            :title="t('discussion.close')"
            class="w-[36px] rounded-full px-2 hover:bg-gnist-gray-light-light"
            @click="toggleEdit"
          >
            <MaterialIcon aria-hidden="true" class="text-grey text-xl">
              close
            </MaterialIcon>
          </button>

          <button
            v-if="isAuthenticated && !isCommentAuthor && !showReplyToComment"
            :title="t('discussion.reply')"
            class="w-[36px] rounded-full px-2 hover:bg-gnist-gray-light-light"
            @click="toggleReply"
          >
            <MaterialIcon aria-hidden="true" class="text-grey text-xl">
              reply
            </MaterialIcon>
          </button>

          <button
            v-if="isAuthenticated && showReplyToComment"
            :title="t('discussion.close')"
            class="w-[36px] rounded-full px-2 hover:bg-gnist-gray-light-light"
            @click="toggleReply"
          >
            <MaterialIcon aria-hidden="true" class="text-grey text-xl">
              close
            </MaterialIcon>
          </button>

          <button
            v-if="isAuthenticated && !isCommentAuthor"
            :title="t('discussion.upvote')"
            class="comment-upvote w-[36px] rounded-full hover:bg-gnist-gray-light-light"
            @click="voteUp"
          >
            <MaterialIcon
              aria-hidden="true"
              class="text-grey text-3xl"
              :class="upvoteClass"
            >
              expand_less
            </MaterialIcon>
          </button>

          <button
            v-if="isAuthenticated && !isCommentAuthor"
            :title="t('discussion.downvote')"
            class="w-[36px] rounded-full hover:bg-gnist-gray-light-light"
            @click="voteDown"
          >
            <MaterialIcon
              aria-hidden="true"
              class="text-grey text-3xl"
              :class="downvoteClass"
            >
              expand_more
            </MaterialIcon>
          </button>
        </template>

        <button
          v-if="!collapsed && comment.children.length > 0"
          :title="t('discussion.collapse')"
          class="w-[36px] rounded-full px-2 hover:bg-gnist-gray-light-light"
          @click="toggleCollapse"
        >
          <MaterialIcon aria-hidden="true" class="text-grey text-xl">
            remove
          </MaterialIcon>
        </button>

        <button
          v-if="collapsed && comment.children.length > 0"
          :title="t('discussion.expand')"
          class="w-[36px] rounded-full px-2 hover:bg-gnist-gray-light-light"
          @click="toggleCollapse"
        >
          <MaterialIcon aria-hidden="true" class="text-grey text-xl">
            add
          </MaterialIcon>
        </button>
      </div>
    </div>

    <div>
      <div class="comment-body mx-2 pb-4 text-gnist-black lg:ml-12">
        <!-- eslint-disable vue/no-v-html -->
        <div
          v-if="!showEditComment || !isAuthenticated"
          v-html="comment.html"
        />
        <!--eslint-enable-->
        <WriteComment
          v-if="isAuthenticated && showEditComment"
          class="mr-4 mt-4"
          :existing-comment="comment"
          @submit-comment="saveComment"
        />
        <WriteComment
          v-if="isAuthenticated && showReplyToComment"
          class="mr-4 mt-4"
          @submit-comment="saveCommentReply"
        />
      </div>
      <div
        v-if="comment.children.length && !collapsed"
        class="comment-children"
      >
        <CommentCard
          v-for="child in comment.children"
          :key="child.commentHex"
          :comment="child"
          :discussion="discussion"
          :discussion-type="discussionType"
        />
      </div>
    </div>
  </div>
</template>

<style scoped>
.comment-body :deep(p) {
  margin-top: 6px;
  margin-bottom: 6px;
}

.comment-body :deep(a) {
  text-decoration: underline;
}

.comment-body :deep(blockquote) {
  margin: 0 0 0 8px;
  padding: 0 0 0 5px;
  border-left: 2px solid #adb5bd;
  color: #707880;
}

.comment-children .comment-card {
  border-right-width: 0;
  border-bottom-width: 0;
}
</style>
