import { sleep } from '@/utilities/sleep';
import type { RouteRecordRaw, Router } from 'vue-router';

let router: Router | undefined = undefined;
/** Used to avoid circular dependencies */
export function setRouter(r: Router) {
  router = r;
}
/** Use this to avoid circular dependencies */
export function getRouter(): Router | undefined {
  return router;
}
export async function waitForRouterReady(): Promise<void> {
  while (router === undefined) {
    await sleep(250);
  }
}

function flattenRoutes(
  items: (RouteRecordRaw & { fullPath?: string })[],
  parentPath = '',
): (RouteRecordRaw & { fullPath?: string })[] {
  const result: typeof items = [];
  for (const item of items) {
    item.fullPath = item.path.startsWith('/')
      ? item.path
      : `${parentPath}/${item.path}`;
    result.push(item);
    if (item.children) {
      result.push(...flattenRoutes(item.children, item.fullPath));
    }
  }
  return result;
}
export const hasOnlyOptionalParamsRe =
  /^(?:(?:\/[^:/?\n]*)|(\/:[^/?\n]*\?))*$/m;
export let hardcodedStaticRoutes: (RouteRecordRaw & {
  fullPath?: string | undefined;
  sourceTitle?: string;
})[] = [];
export function setStaticRoutes(routes: RouteRecordRaw[]) {
  hardcodedStaticRoutes = flattenRoutes(routes).filter(
    (i) => i.fullPath?.match(hasOnlyOptionalParamsRe) && !!i.meta?.title,
  );
  hardcodedStaticRoutes.forEach((i) => {
    i.sourceTitle = i.meta?.title;
  });
}
