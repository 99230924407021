<script setup lang="ts">
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

const props = defineProps<{
  label: string;
  text: string;
}>();

const { t } = useI18n();
const copied = ref(false);

const copyToClipboard = () => {
  copied.value = true;
  navigator.clipboard.writeText(props.text);
  setTimeout(() => {
    copied.value = false;
  }, 2000);
};
</script>

<template>
  <a
    class="mr-2 inline-flex cursor-pointer items-center text-sm text-gnist-white"
    role="button"
    @click="copyToClipboard"
  >
    {{ t(label) }}
    <Transition>
      <span
        v-if="!copied"
        role="img"
        aria-hidden="true"
        class="material-symbols-rounded ml-1 text-xl text-gnist-white"
      >
        content_copy
      </span>
    </Transition>
    <Transition>
      <span
        v-if="copied"
        role="img"
        aria-hidden="true"
        class="material-symbols-rounded ml-1 text-xl text-gnist-green"
      >
        done
      </span>
    </Transition>
  </a>
</template>
