<script setup lang="ts">
import {
  editProfile,
  useCurrentUserThrowIfNotLoggedOn,
} from '@/api/auth';
import { deleteUserAccount, updateUser } from '@/api/user';
import ModalComponent from '@/components/ModalComponent.vue';
import Checkbox from '@/components/forms/InputCheckbox.vue';
import TextInput from '@/components/forms/InputText.vue';
import Dropdown from '@/components/forms/InputDropdown.vue';
import { computed, ref } from 'vue';
import ButtonComponent from '../components/ButtonComponent.vue';
import { useI18n } from 'vue-i18n';
import { useFeatureToggle } from '@/utilities/useFeatureToggle';
import { mapValueList } from '@/utilities/mapValueList';
import { SUPPORTED_LOCALES } from '@/config';

const { t } = useI18n();

const useB2C = useFeatureToggle('UseB2C');

const user = useCurrentUserThrowIfNotLoggedOn();
const userModel = useCurrentUserThrowIfNotLoggedOn();

const confirmDelete = ref(false);
const b2cDelete = ref(false);

const submit = () => {
  updateUser(userModel.value);
};

const canSave = computed(() => {
  return JSON.stringify(user.value) !== JSON.stringify(userModel.value);
});

const doDelete = () => {
  deleteUserAccount(b2cDelete.value);
};

const localeOptions = mapValueList(SUPPORTED_LOCALES, 'language');
</script>

<template>
  <div class="mt-10 flex w-full max-w-2xl flex-col">
    <form class="flex flex-col gap-8 p-4" @submit.prevent="submit">
      <h2>
        {{ t('profile.edit') }}
      </h2>

      <div>
        <TextInput
          v-model="userModel.firstName"
          for-name="firstname"
          label="profile.givenName"
        />
        <TextInput
          v-model="userModel.lastName"
          for-name="lastname"
          label="profile.familyName"
        />
        <Dropdown
          v-model="userModel.emailLocale"
          label="profile.emailLocale"
          :options="localeOptions"
          :getkey="(val) => val ?? 'empty'"
        />
      </div>

      <div>
        <label class="font-semibold">
          {{ t('profile.emailNotficationTitle') }}
        </label>
        <Checkbox
          v-model="userModel.sendCommentReplyNotification"
          for-name="checkbox-commento"
          label="profile.emailNotficationCommentOnThread"
          direction="horizontal"
        />
        <Checkbox
          v-model="userModel.sendNewsNotification"
          for-name="checkbox-news"
          label="profile.emailNotificationNewNewsArticle"
          direction="horizontal"
        />

        <Checkbox
          v-model="userModel.sendSuggestionCommentNotification"
          for-name="checkbox-suggestion-comment"
          label="profile.emailNotificationSuggestionComment"
          direction="horizontal"
        />

        <Checkbox
          v-model="userModel.sendSuggestionChangedNotification"
          for-name="checkbox-suggestion-changed"
          label="profile.emailNotificationSuggestionChanged"
          direction="horizontal"
        />
      </div>

      <div class="flex flex-wrap justify-end gap-4">
        <ButtonComponent
          v-if="useB2C"
          class="self-end"
          type="special"
          :text="t('profile.edit_b2c')"
          truncate-overflow
          @click="editProfile()"
        />
        <ButtonComponent
          class="self-end"
          :text="t('profile.save')"
          type="primary"
          :disabled="!canSave"
          :submit="true"
        />
      </div>

      <hr />

      <ButtonComponent
        class="mb-8 mt-8 self-end"
        type="danger"
        :text="t('profile.deleteAccount')"
        @click="confirmDelete = true"
      />
    </form>

    <ModalComponent
      :show-modal="confirmDelete"
      :title="t('profile.deleteAccount')"
      @close="confirmDelete = false"
      @handle-click="doDelete"
    >
      <template #default>
        <p>{{ t('profile.deleteAccountConfirmation') }}</p>
        <Checkbox
          v-if="useB2C"
          v-model="b2cDelete"
          label="profile.deleteB2CAccount"
          direction="horizontal"
          mode="toggle"
        />
      </template>
    </ModalComponent>
  </div>
</template>
