<script lang="ts" setup>
import {
  getEmptyLocaleValue,
  type Category,
  type PartialSome,
} from '@/api/types';
import ContentPage from '@/components/ContentPage.vue';
import CategoryEditor from '@/components/CategoryEditor.vue';
import { useI18n } from 'vue-i18n';
import { ref } from 'vue';
import { throwOnError } from '@/api/composable';
import {
  createCategory,
  deleteCategory,
  updateCategory,
  useCategoriesLazy,
} from '@/api/category';
import { translateStringOrLocale } from '@/i18n';
import { useValidationTracking } from '@/utilities/useValidationTracking';
import { defaultSchema } from '@/components/SchemaEditor.vue';
import TableComponent from '@/components/TableComponent.vue';

const { result: categories } = throwOnError(useCategoriesLazy());

const selectedItem = ref<PartialSome<Category, 'categoryId'> | null>(null);

async function onSave() {
  if (!selectedItem.value) return;
  if (!isValid.value) {
    showValidationMessages.value = true;
    return;
  }
  showValidationMessages.value = false;
  const saveValue: PartialSome<Category, 'categoryId'> = {
    ...selectedItem.value,
    schema: selectedItem.value.schema?.map((s) => ({
      ...s,
      requiredByTemplate: undefined,
      canUnsetRequired: undefined,
    })),
  };
  if (!selectedItem.value.categoryId) {
    const updatedItem = await createCategory(saveValue);
    selectedItem.value.categoryId = updatedItem.categoryId;
  } else {
    await updateCategory(saveValue);
  }
}

function onCreateButtonClick() {
  if (!categories.value) return null;
  const newItem: PartialSome<Category, 'categoryId'> = {
    name: getEmptyLocaleValue(),
    schema: [{ ...defaultSchema, name: 'content' }],
  };
  categories.value.push(newItem as Category);
  return newItem;
}

async function onDelete(itemToDelete: Category) {
  if (!itemToDelete.categoryId) return;
  await deleteCategory(itemToDelete.categoryId.toString());
  categories.value =
    categories.value?.filter((i) => i.categoryId !== itemToDelete.categoryId) ??
    [];
}

const { t } = useI18n();

const showValidationMessages = ref<boolean>(false);
const { onValidChanged, formRef, isValid } = useValidationTracking(
  selectedItem,
  showValidationMessages,
  true,
);
</script>
<template>
  <ContentPage
    :title="t('categoryAdmin.title')"
    class="bg-gnist-gray-light-light"
  >
    <div class="pb-8">
      <div class="w-full max-w-full overflow-x-auto">
        <TableComponent
          size="table-lg"
          :rows="categories"
          show-spinner
          i18n-key="categoryAdmin"
          :get-key="(category) => category.categoryId"
          :columns="['name', 'sortOrder']"
          row-class="cursor-pointer"
          :create="{
            title: 'categoryAdmin.create',
            idProperty: 'categoryId',
            onCreateClick: onCreateButtonClick,
          }"
          :edit="{
            title: 'categoryAdmin.view',
            onSave,
            isSelectedItemValid: isValid,
            modalProps: { size: 'big' },
          }"
          :delete="{
            onDelete,
            getIdentificator: (row) => row.name,
            identificatorLabel: 'categoryAdmin.name',
            title: 'categoryAdmin.delete.title',
            warning: 'categoryAdmin.delete.warning',
          }"
          @update:selected-item="(item) => (selectedItem = item)"
        >
          <template #afterCreate>
            <RouterLink
              :to="{ path: '/usermanual/about_categories' }"
              target="_blank"
              class="mx-4 underline hover:bg-gnist-gray-light"
            >
              {{ t('categoryAdmin.help') }}
            </RouterLink>
          </template>
          <template #columnHeader="{ item: category }">
            {{ translateStringOrLocale(category.name).value }}
          </template>
          <template #columns="{ item: category }">
            <td>
              {{ category.sortOrder }}
            </td>
          </template>
          <template #editor>
            <form ref="formRef">
              <CategoryEditor
                v-model="selectedItem"
                :all-items="categories ?? []"
                @report-validation-error="
                  (name, hasError) => onValidChanged(name, !hasError)
                "
              />
            </form>
          </template>
        </TableComponent>
      </div>
    </div>
  </ContentPage>
</template>

<style scoped>
:deep(th),
:deep(td:not(.ButtonRow)) {
  text-align: left;
  padding: 1rem 0.5rem;
  text-overflow: ellipsis;
}
</style>
