<script setup lang="ts">
import ButtonComponent from '@/components/ButtonComponent.vue';
import TextInput from '@/components/forms/InputText.vue';
import Dropdown, {
  toDropdownItems,
} from '@/components/forms/InputDropdown.vue';
import InputBlockPicker from './forms/InputBlockPicker.vue';
import { useI18n } from 'vue-i18n';
import {
  type Tag,
  type KeyOfRecursiveBelow,
  removeParentKey,
  type KeyOfRecursive,
  type TagGroup,
} from '@/api/types';
import { computed, ref, watch } from 'vue';
import { SUPPORTED_LOCALES } from '@/config';
import { useNestedMV } from '@/utilities/useInternalState';
import { arrayEquals } from '@/utilities/arrayEquals';
import { translateStringOrLocale } from '@/i18n';

const props = defineProps<{
  modelValue: Tag;
  localeKeys: KeyOfRecursiveBelow<Tag, 'text'>[];
  quickEdit: boolean;
  tagGroups: TagGroup[];
}>();
const emit = defineEmits<{
  (e: 'update:modelValue', value: typeof props.modelValue): void;
  (e: 'update:changed', changed: boolean): void;
  (e: 'remove'): void;
}>();

const value = useNestedMV(props, (val) => emit('update:modelValue', val));

function getSource(): Omit<Tag, 'id'> {
  return {
    text: { ...value.value.text },
    tagGroups: [...(value.value.tagGroups ?? [])],
    primaryTagGroupId: value.value.primaryTagGroupId,
    homePageId: value.value.homePageId,
  };
}
const source = ref<Omit<Tag, 'id'>>(getSource());
defineExpose({
  resetSource: () => {
    source.value = getSource();
  },
});

const { t } = useI18n();

const tagGroupOptions = computed(() =>
  toDropdownItems(props.tagGroups, (u) => [
    u.id.toString(),
    translateStringOrLocale(u.label).value,
    u,
  ]),
);
const primaryTagGroupOptions = computed(() =>
  toDropdownItems(value.value.tagGroups, (u) => [
    u.id.toString(),
    translateStringOrLocale(u.label).value,
    u.id,
  ]),
);
watch([primaryTagGroupOptions, () => value.value.primaryTagGroupId], () => {
  if (
    !primaryTagGroupOptions.value.find(
      (o) => o.value === value.value.primaryTagGroupId,
    )
  ) {
    value.value.primaryTagGroupId = undefined;
  }
  if (
    value.value.primaryTagGroupId === undefined &&
    primaryTagGroupOptions.value.length > 0
  ) {
    value.value.primaryTagGroupId = primaryTagGroupOptions.value[0].value;
  }
});

const editing = ref<KeyOfRecursive<Tag>>();
const changed = ref(false);
watch(
  [value, source],
  () => {
    changed.value =
      !!SUPPORTED_LOCALES.find(
        (l) => value.value.text[l] !== source.value.text[l],
      ) ||
      !arrayEquals(value.value.tagGroups, source.value.tagGroups, (u) => u.id) ||
      value.value.primaryTagGroupId !== source.value.primaryTagGroupId ||
      value.value.homePageId !== source.value.homePageId;
  },
  { deep: true },
);
watch(changed, () => emit('update:changed', changed.value));

function getLocaleKey(locale: KeyOfRecursive<Tag>) {
  return removeParentKey(locale, 'text');
}
</script>

<template>
  <component
    :is="index === 0 ? 'th' : 'td'"
    v-for="(localeKey, index) in localeKeys"
    :key="localeKey"
    class="bg-[inherit] pl-8 hover:border hover:border-black md:max-w-full"
    :class="{
      'ColumnHeader z-10': index === 0,
      'material-symbols-rounded-before': index === 0 && changed,
    }"
    @click.self="editing = undefined"
  >
    <form class="flex h-9 items-center" @click.prevent="editing = localeKey">
      <TextInput
        v-model="value.text[getLocaleKey(localeKey)]"
        :label="
          t(`language.values.${getLocaleKey(localeKey)}`) +
          t('tagEditor.columns.text')
        "
        sr-only
        class="w-full"
        input-class="my-0 truncate"
        :no-chrome="editing !== localeKey"
        required
        @focus="editing = localeKey"
        @blur="editing = undefined"
      />
    </form>
  </component>
  <td class="whitespace-nowrap bg-[inherit] hover:border hover:border-black">
    <Dropdown
      v-model="value.tagGroups"
      label="tagEditor.columns.tagGroups"
      sr-only
      :options="tagGroupOptions"
      :multiple="{ emptyLabel: '' }"
      :getkey="(u) => u?.id"
      class="[&_button]:my-0"
      :no-chrome="editing !== 'tagGroups'"
      @focus="editing = 'tagGroups'"
      @blur="editing = undefined"
    />
  </td>
  <td class="whitespace-nowrap bg-[inherit] hover:border hover:border-black">
    <Dropdown
      v-model="value.primaryTagGroupId"
      label="tagEditor.columns.tagGroups"
      sr-only
      :options="primaryTagGroupOptions"
      :getkey="(u) => u"
      class="[&_button]:my-0"
      :no-chrome="editing !== 'primaryTagGroupId'"
      :disabled="primaryTagGroupOptions.length < 2"
      @focus="editing = 'primaryTagGroupId'"
      @blur="editing = undefined"
    />
  </td>
  <td class="whitespace-nowrap bg-[inherit] hover:border hover:border-black">
    <InputBlockPicker
      v-model="value.homePageId"
      label="tagEditor.columns.homePageId"
      sr-only
      class="[&_[data-combobox]]:my-0 [&_[data-combobox]]:min-h-0"
      :no-chrome="editing !== 'homePageId'"
      @focus="editing = 'homePageId'"
      @blur="editing = undefined"
    />
  </td>
  <td>
    <ButtonComponent
      text="tagEditor.delete"
      icon="delete"
      type="danger"
      icon-class="text-2xl leading-6"
      @click="emit('remove')"
    />
  </td>
</template>

<style scoped>
.material-symbols-rounded-before::before {
  content: 'pending_actions';
  @apply absolute;
  @apply z-10;
  @apply text-3xl;
  @apply left-0;
  @apply text-gnist-red;
}
</style>
