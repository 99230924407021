<script lang="ts">
export type IInputLabelProps = {
  label?: string;
  /** Set to true if the label is visually placed somewhere else, but should be kept here for accessibility */
  srOnly?: boolean;
  /** Set to true if there is no visible label, but the space that should be used by the label should be filled (e.g. to align with other inputs) */
  useHeight?: boolean;
  tooltip?: string;
  labelEnd?: string;
  labelClass?: string | string[];
  tooltipClass?: string | string[];
  required?: boolean;
  /** Used for connecting label and input */
  forName?: string;
  descriptionStyle?: 'tooltip' | 'inline';
  minlength?: number;
  maxlength?: number;
  placeholder?: string;
  isBlockField?: boolean;
  limitLabelStart?: boolean;
  dynamicTextKey?: string;
  description?: string;
  mdDescription?: boolean;
  link?: string;
};
export default {
  inheritAttrs: false,
};

export function useDynamicLabelTexts(
  props: Pick<
    IInputLabelProps,
    | 'label'
    | 'dynamicTextKey'
    | 'tooltip'
    | 'placeholder'
    | 'description'
    | 'forName'
  >,
) {
  const { t, te } = useI18n();
  const labelId =
    props.label ??
    (props.dynamicTextKey ? `${props.dynamicTextKey}.label` : '');
  const tooltipId = props.tooltip ?? `${props.dynamicTextKey}.tooltip`;
  const placeholderId =
    props.placeholder ?? `${props.dynamicTextKey}.placeholder`;
  const descriptionId =
    props.description ?? `${props.dynamicTextKey}.description`;
  return computed(() => ({
    label: t(labelId),
    tooltip: te(tooltipId) ? t(tooltipId) : props.tooltip,
    placeholder: te(placeholderId) ? t(placeholderId) : props.placeholder,
    description: te(descriptionId)
      ? t(descriptionId)
      : te(tooltipId)
        ? t(tooltipId)
        : props.description,
    showDescriptionButton: te(descriptionId) || !!props.description,
    forName:
      props.forName ??
      (props.label ?? props.dynamicTextKey)?.split('.').slice(-1)[0],
  }));
}
</script>

<script setup lang="ts">
import MaterialIcon from '@/components/MaterialIcon.vue';
import { useResizeListener } from '@/utilities/useResizeListener';
import { computed, ref, useAttrs, type StyleValue } from 'vue';
import { useI18n } from 'vue-i18n';
import ButtonComponent from '../ButtonComponent.vue';
import IconLink from '../IconLink.vue';
import MarkdownRenderer from '@/components/block/MarkdownRenderer.vue';
import { useRouter } from 'vue-router';

const { t } = useI18n();

const props = defineProps<IInputLabelProps>();
const attrs = useAttrs();
const fallthroughAttrs = computed(
  (): { class?: unknown; style?: StyleValue } => {
    return { class: attrs.class, style: attrs.style as StyleValue };
  },
);

const texts = useDynamicLabelTexts(props);
const router = useRouter();
const linkTarget = props.link ? router.resolve(props.link) : undefined;
const showDescription = ref(false);

const { isBelowSmall } = useResizeListener();
</script>

<template>
  <div
    v-if="texts.label"
    class="GnistInputLabel flex min-w-0 items-center justify-between leading-8"
    :class="{ 'h-8': useHeight }"
    v-bind="fallthroughAttrs"
    :data-is-block-field="isBlockField ? true : undefined"
  >
    <span
      :title="t(texts.label)"
      class="flex min-w-0"
      :class="{
        'w-full': !limitLabelStart,
        tooltip: isBelowSmall,
      }"
      :data-tip="t(texts.label)"
    >
      <slot>
        <span
          class="block min-w-0 truncate text-left"
          :class="[labelClass, { 'sr-only': srOnly }]"
        >
          {{ t(texts.label) }}
        </span>
      </slot>
      <span
        v-if="required"
        class="text-gnist-red-dark"
        :class="[labelClass, { 'sr-only': srOnly }]"
        :title="t('validation.required')"
      >
        &nbsp;*
      </span>
      <span
        v-if="texts.tooltip && descriptionStyle !== 'inline'"
        :data-tip="t(texts.tooltip)"
        class="tooltip static ml-1 whitespace-break-spaces font-normal sm:relative"
        :class="tooltipClass"
      >
        <MaterialIcon
          class="text-2xl hover:text-secondary"
          :aria-describedby="`desc_${texts.forName}`"
        >
          info
        </MaterialIcon>
      </span>
    </span>
    <slot name="labelEnd">
      <span
        v-if="labelEnd !== undefined"
        class="LabelEnd block self-end whitespace-nowrap"
      >
        {{ labelEnd }}
      </span>
    </slot>
    <span
      v-if="linkTarget"
      class="static ml-1 whitespace-break-spaces font-normal sm:relative"
    >
      <IconLink
        label="buttons.external_help"
        icon="open_in_new"
        icon-class="text-2xl hover:text-gnist-gray"
        :aria-describedby="`desc_${texts.forName}`"
        :to="linkTarget"
        target="_blank"
      />
    </span>
    <span
      v-if="texts.showDescriptionButton"
      class="static ml-1 whitespace-break-spaces font-normal sm:relative"
    >
      <ButtonComponent
        text="buttons.show_description"
        icon="help_outline"
        icon-class="text-2xl hover:text-gnist-gray"
        :aria-describedby="`desc_${texts.forName}`"
        @click="showDescription = !showDescription"
      />
    </span>
  </div>
  <span
    v-if="texts.description"
    :id="`desc_${texts.forName}`"
    class="LabelDescription my-2 whitespace-pre-wrap text-wrap text-sm text-gnist-black"
    :class="{ 'sr-only': descriptionStyle !== 'inline' && !showDescription }"
  >
    <template v-if="!mdDescription">
      {{ texts.description }}
    </template>
    <MarkdownRenderer
      v-else
      :value="texts.description"
      blob-location="public"
      class="whitespace-normal bg-gnist-gray-light-light p-2 [&_>_:first-child]:pt-0"
    />
  </span>
</template>
