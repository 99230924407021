<template>
  <div
    aria-hidden="true"
    class="spinner rounded-half border-8 border-solid border-gnist-gray border-opacity-10"
    data-cy-id="LoadingSpinner"
  />
</template>

<style scoped>
.spinner {
  animation: spinning 1s linear infinite;
  border-left-color: rgb(var(--gnist-color-blue) / 0.3);
}

@keyframes spinning {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
