<script lang="ts">
/** Should match requirement in UpdateVersionDTO.cs and CreateVersionDTO.cs */
const versionNumberPattern = new RegExp('^[a-zA-Z0-9._-]+$');
const versionNumberAntiPattern = new RegExp('^[._-]+$');
export function isValidVersionNumber(versionNumber: string): boolean {
  return (
    versionNumberPattern.test(versionNumber) &&
    !versionNumberAntiPattern.test(versionNumber)
  );
}
</script>

<script setup lang="ts">
import type { Guid, Version } from '@/api/types';
import ButtonComponent from '../ButtonComponent.vue';
import ModalComponent from '../ModalComponent.vue';
import { createVersion } from '@/api/versions';
import { computed, ref, watchEffect } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps<{
  blockId: Guid;
  versions: Version[] | null;
}>();

const emit = defineEmits<{ (e: 'created', newVersion: Version): void }>();

const newVersionNumber = ref<string>('');
const copyFromVersion = ref<Version | null>(null);
const showNewVersionModal = ref<boolean>(false);

watchEffect(() => {
  if (props.versions) copyFromVersion.value = props.versions.slice(-1)[0];
});

const newVersionNumberIsValid = computed(() =>
  isValidVersionNumber(newVersionNumber.value),
);

const onCreate = async () => {
  const newVersion = await createVersion(
    props.blockId,
    newVersionNumber.value,
    copyFromVersion.value?.versionId,
  );

  showNewVersionModal.value = false;
  emit('created', newVersion);
};
</script>

<template>
  <div>
    <ButtonComponent
      :text="t('admin.blockProduction.newVersion')"
      type="primary"
      @click="showNewVersionModal = true"
    />
    <ModalComponent
      :show-modal="showNewVersionModal"
      :title="t('admin.blockProduction.newVersion')"
      @close="showNewVersionModal = false"
    >
      <label class="mt-6 block">{{
        t('admin.blockProduction.newVersion')
      }}</label>
      <input v-model="newVersionNumber" class="w-full border p-2" />
      <p class="text-orange-dark min-h-12 text-sm">
        <span
          v-if="versions?.find((v) => v.versionNumber == newVersionNumber)"
          >{{ t('admin.blockProduction.existingVersionError') }}</span
        >
        <span v-if="!newVersionNumberIsValid && newVersionNumber !== ''">{{
          t('admin.blockProduction.invalidVersionName')
        }}</span>
      </p>
      <label class="block">{{
        t('admin.blockProduction.copyFromVersion')
      }}</label>
      <select v-model="copyFromVersion" class="mb-8 w-full border p-2">
        <option
          v-for="version in versions"
          :key="version.versionNumber"
          :value="version"
        >
          {{ version.versionNumber }}
        </option>
      </select>
      <template #buttons>
        <div class="flex justify-end">
          <div class="gnist-button-group">
            <ButtonComponent
              :text="t('admin.blockProduction.cancel')"
              class="mr-2"
              @click="showNewVersionModal = false"
            />
            <ButtonComponent
              :text="t('admin.blockProduction.newVersion')"
              type="primary"
              :disabled="!newVersionNumberIsValid"
              @click="onCreate"
            />
          </div>
        </div>
      </template>
    </ModalComponent>
  </div>
</template>
