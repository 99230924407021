<script setup lang="ts">
import ButtonComponent from '@/components/ButtonComponent.vue';
import { ParagraphShimmer } from 'vue3-shimmer';
import MaterialIcon from '@/components/MaterialIcon.vue';
import MarkdownRenderer from '@/components/block/MarkdownRenderer.vue';
import TagRenderer from '@/components/TagRenderer.vue';
import SuggestionStatusBadge from '@/components/SuggestionStatusBadge.vue';
import DiscussionBoard from '@/components/discussion/DiscussionBoard.vue';
import { SocialPageType } from '@/enums/SocialPageType';
import { computed, watchEffect } from 'vue';
import { useRoute } from 'vue-router';
import { throwOnError } from '@/api/composable';
import { useI18n } from 'vue-i18n';
import {
  updateSubscription,
  updateVote,
  useSuggestion,
} from '@/api/suggestion';
import {
  translateStringOrLocale,
  translateStringOrLocaleWithDefault,
} from '@/i18n';
import { getBlocksRouteLink, getRouteTitle } from '@/utilities/routeUtils';
import { useCurrentUser } from '@/api/auth';
import { useStoreAbility } from '@/abilities';
import {
  suggestionPageIdPrefix,
  type suggestionPageId,
} from '@/api/commentoTypes';
import { VoteDirection } from '@/enums/VoteDirection';
import { getSuggestionStatus } from '@/utilities/getSuggestionStatus';
import { formatDate } from '@/utilities/formatDate';

const { t } = useI18n();
const userinfo = useCurrentUser();
const { can } = useStoreAbility();
const showEditButton = can('update', 'Suggestion');

const route = useRoute();
const suggestionId = parseInt(route.params.suggestionId as string);
const { result: suggestion } = throwOnError(useSuggestion(suggestionId, true));
const title = translateStringOrLocaleWithDefault(
  () => suggestion.value?.title,
  '',
);
const description = translateStringOrLocaleWithDefault(
  () => suggestion.value?.description,
  '',
);
const status = computed(() => getSuggestionStatus(suggestion.value));
watchEffect(() => {
  if (title.value) document.title = getRouteTitle(title.value);
});
const discussionId: suggestionPageId = `${suggestionPageIdPrefix}${suggestionId}`;
const hasVoted = computed(
  () => suggestion.value?.userConnection?.userVote === VoteDirection.Up,
);
const subscribed = computed(
  () => suggestion.value?.userConnection?.subscribeForNotifications === true,
);
const vote = async (direction: VoteDirection) => {
  if (!suggestion.value?.id) return;
  const oldDirection = suggestion.value.userConnection?.userVote ?? 0;
  suggestion.value.userConnection = await updateVote(
    suggestion.value?.id,
    direction,
  );
  const newDirection = suggestion.value.userConnection?.userVote ?? 0;
  if (suggestion.value.votes === undefined) suggestion.value.votes = 0;
  suggestion.value.votes += +(newDirection - oldDirection);
};
const subscribe = async (shouldSubcribe: boolean) => {
  if (!suggestion.value?.id) return;
  suggestion.value.userConnection = await updateSubscription(
    suggestion.value?.id,
    shouldSubcribe,
  );
};
</script>

<template>
  <div class="w-full bg-gnist-gray-light-light text-gnist-black">
    <ParagraphShimmer
      v-if="suggestion === null"
      :lines="6"
      :random-size="true"
      class="mx-auto w-full max-w-5xl"
    />

    <div v-else class="mx-2 py-8 md:mx-auto md:w-[50rem]">
      <div v-if="showEditButton" class="absolute z-10 w-full cursor-pointer">
        <div
          class="gnist-page-width top-6 flex w-full justify-end p-4 lg:top-0"
        >
          <RouterLink
            :to="{
              name: 'suggestion_edit',
              params: { suggestionId: suggestion?.id },
            }"
          >
            <MaterialIcon
              class="rounded-lg text-2xl hover:bg-gnist-gray-light hover:text-gnist-black"
            >
              edit
            </MaterialIcon>
          </RouterLink>
        </div>
      </div>
      <div
        class="m-4 grid grid-cols-[max-content_1fr] gap-y-4 bg-gnist-white p-4 py-6"
      >
        <div class="min-w-[10rem] border-r-2 border-r-gnist-gray-light px-4">
          <div class="border">
            <div
              class="flex justify-center p-2 text-4xl font-bold text-gnist-sp-blue-dark"
            >
              {{ suggestion?.votes }}
            </div>
            <div class="flex justify-center bg-gnist-green-cyan p-3 font-bold">
              {{ t('suggestions.votes') }}
            </div>
          </div>
          <div class="flex justify-center p-2">
            <ButtonComponent
              :text="
                hasVoted ? t('suggestions.unvote') : t('suggestions.upvote')
              "
              :type="hasVoted ? 'special' : 'primary'"
              :disabled="!userinfo"
              @click.prevent="
                vote(hasVoted ? VoteDirection.None : VoteDirection.Up)
              "
            />
          </div>
        </div>
        <div class="p-2 px-4">
          <h3 class="text-gnist-gray-dark">
            {{ title }}
          </h3>
          <MarkdownRenderer
            :value="description"
            :blob-location="
              suggestion.id ? { suggestionId: suggestion.id } : 'public'
            "
            class="my-2"
          />
        </div>
        <div class="col-span-2">
          <div
            class="flex flex-row flex-wrap gap-1 border-t-2 border-t-gnist-gray-light p-2"
          >
            <div
              class="flex grid-cols-3 flex-wrap justify-items-center gap-x-4 lg:grid"
            >
              <span>
                {{ t('suggestions.suggested_by') }}:
                <span class="font-bold">{{
                  suggestion.suggestedBy?.name
                }}</span>
              </span>

              <span>
                {{ t('suggestions.updated') }}:
                <span>
                  {{
                    formatDate(
                      suggestion.modified,
                      false,
                      t('suggestions.unknown_date'),
                    )
                  }}
                </span>
              </span>
              <span>
                {{ t('suggestions.upvoted') }}:
                <span>
                  {{
                    formatDate(
                      suggestion.last_vote,
                      false,
                      t('suggestions.unknown_date'),
                    )
                  }}
                </span>
              </span>
            </div>
          </div>
          <div class="mt-2 flex flex-row flex-wrap gap-1">
            <p
              class="badge badge-outline badge-lg rounded-none border-gnist-gray"
              :class="{
                'bg-gnist-red-light': suggestion.type === 'Bug',
                'bg-gnist-white': suggestion.type !== 'Bug',
              }"
            >
              {{ t(`suggestions.types.${suggestion.type}`) }}
            </p>
            <SuggestionStatusBadge :status="status" />
            <TagRenderer :tags="suggestion?.tags" size="badge-lg" />
          </div>
        </div>
      </div>
      <div v-if="suggestion.blockVersion" class="px-8 pb-8">
        <h3 class="mb-2">{{ t('suggestions.blocklinkHeader') }}</h3>
        <RouterLink
          class="underline"
          :to="
            getBlocksRouteLink(suggestion.blockVersion.blockId, {
              version: suggestion.blockVersion.versionNumber,
            })
          "
        >
          {{ translateStringOrLocale(suggestion.blockVersion.blockName).value }}
        </RouterLink>
      </div>
      <div v-if="!!userinfo" class="px-8">
        <h3>{{ t('suggestions.notificationsHeader') }}</h3>
        <ButtonComponent
          :text="
            subscribed
              ? t('suggestions.unsubscribe')
              : t('suggestions.subscribe')
          "
          :type="subscribed ? 'special' : 'primary'"
          :disabled="!userinfo"
          class="mt-4"
          @click.prevent="subscribe(!subscribed)"
        />
      </div>

      <div class="comment-section-block bg-gnist-gray-light-light p-6 pt-12">
        <div class="gnist-page-width w-full">
          <h2>
            {{ t('suggestions.comments') }}
          </h2>
          <DiscussionBoard
            :discussion-id="discussionId"
            :default-discussion-type="SocialPageType.SuggestionPage"
            class="mt-8"
          />
        </div>
      </div>
    </div>
  </div>
</template>
