import { watch, type Ref, ref } from 'vue';
import { useWaitForObservedElement } from './useWaitForObservedElement';

/** Use to add click handlers to images from dynamic content (e.g. rendered markdown) */
export function useImageClick(
  elementId: Ref<string>,
  onImageClick: (imageElement: HTMLImageElement) => void,
) {
  const parentElement = useWaitForObservedElement(elementId);
  const imageElements = ref<NodeListOf<HTMLImageElement> | null>(null);
  watch(parentElement, () => {
    if (!parentElement.value) return;
    let disconnect = startObserve(parentElement.value, imageElements);
    watch(
      () => parentElement,
      () => {
        disconnect();
        if (!parentElement.value) return;
        disconnect = startObserve(parentElement.value, imageElements);
      },
    );
  });
  watch(imageElements, () => {
    imageElements.value?.forEach((imageElement) => {
      imageElement.onclick = () => onImageClick(imageElement);
    });
  });
  return imageElements;
}
function startObserve(
  target: HTMLElement,
  imageListRef: Ref<NodeListOf<HTMLImageElement> | null>,
) {
  imageListRef.value = target.querySelectorAll('img');
  const observer = new MutationObserver(function () {
    imageListRef.value = target.querySelectorAll('img');
  });
  observer.observe(target, { childList: true, subtree: true });
  return () => observer.disconnect();
}
