<script setup lang="ts">
import ContentPage from '../components/ContentPage.vue';
import SubNav from '@/components/SubNav.vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
</script>

<template>
  <ContentPage :title="t('profile.title')">
    <div class="flex w-full flex-col items-center">
      <SubNav
        :menu="[
          { routeName: 'profile', label: 'profile.info' },
          { routeName: 'favourites', label: 'profile.favorite' },
          { routeName: 'subscriptions', label: 'profile.subscriptions' },
        ]"
      />
      <RouterView />
    </div>
  </ContentPage>
</template>
<style></style>
