<script lang="ts" setup>
import BlockPageBanner from '@/components/block/BlockPageBanner.vue';
import BlockRenderer from '@/components/block/BlockRenderer.vue';
import ImageRenderer from '@/components/block/ImageRenderer.vue';
import { type PageTemplateProps } from '@/api/types';
import { getDocumentRouteFromListRoute } from '@/utilities/routeUtils';
import { ref } from 'vue';
import { RouterLink } from 'vue-router';
import { throwOnError } from '@/api/composable';
import { translateStringOrLocale } from '@/i18n';
import { useI18n } from 'vue-i18n';
import { useOrganization } from '@/api/organization';
import TagListGroup from '@/components/TagListGroup.vue';

const { t } = useI18n();

const props = defineProps<PageTemplateProps>();
const containers = ref<{ idx: number; el: HTMLElement }[]>([]);

const routeName = getDocumentRouteFromListRoute();
const orgId = props.block.ownerOrg?.organizationId ?? '';
const { result: organizationData } = throwOnError(useOrganization(orgId, true));
</script>

<template>
  <div
    class="flex flex-col gap-2 px-4 sm:px-6 md:flex-row lg:px-8"
    :class="[`bg-gnist-${bgColor}`]"
  >
    <div class="w-full pb-0 pt-12 md:w-1/2 md:pb-7 md:pt-16 lg:w-3/5 lg:gap-10">
      <BlockPageBanner
        :title="block.name ?? ''"
        :block="block"
        :tags="block.tags ?? []"
        :class="[`bg-gnist-${bgColor}`]"
        :is-preview="!!isPreview"
        :no-left-margin="true"
      >
        <template #description>
          <p v-if="block.ownerOrg?.webpage">
            {{ t('supplierPage.webpage') }}:
            <a
              :href="block.ownerOrg.webpage"
              class="underline hover:text-gnist-gray-dark"
              target="_blank"
              >{{ block.ownerOrg.webpage }}</a
            >
          </p>
          <div
            v-if="organizationData?.frontendData"
            class="mt-2 flex flex-col md:flex-row md:gap-4"
          >
            <p
              v-if="
                JSON.parse(organizationData?.frontendData).numberOfEmployees
              "
            >
              {{ t('supplierPage.numberOfEmployees') }}:
              {{ JSON.parse(organizationData?.frontendData).numberOfEmployees }}
            </p>
            <p v-if="JSON.parse(organizationData?.frontendData).country">
              {{ t('supplierPage.country') }} :
              {{ JSON.parse(organizationData?.frontendData).country }}
            </p>
          </div>
        </template>
      </BlockPageBanner>
      <div class="text-gnist-black" :class="[`bg-gnist-${bgColor}`]">
        <div
          class="gnist-page-width grow pb-0 lg:grid lg:grid-cols-3 lg:gap-10 lg:pb-16"
        >
          <div class="col-span-3">
            <!-- Content -->
            <div
              class="mx-5 mt-10"
              data-cy-id="contentContainer"
              :data-cy-state="!!block.content ? 'loaded' : 'not_loaded'"
            >
              <div class="mt-6 md:mt-12">
                <BlockRenderer
                  v-model:containers="containers"
                  :block="block"
                  class="mt-8 first:mt-0"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mx-4 pb-4 pt-0 md:pb-7 md:pt-16 lg:w-1/2 lg:gap-8">
      <ImageRenderer
        v-if="block.ownerOrg?.logo"
        :value="{ fullUrl: block.ownerOrg.logo, alt: t('block.ownerLogo') }"
        class="my-10"
      />

      <div class="my-4">
        <TagListGroup
          v-if="block.category"
          :category-name="block.category.name.no"
          :tags="block.tags"
        />
      </div>

      <div class="mt-4 flex flex-col items-stretch gap-1 text-gnist-black">
        <p>{{ t('supplierPage.products') }}:</p>
        <template
          v-for="block in organizationData?.blocks.map((b) => b)"
          :key="block.blockId"
        >
          <RouterLink
            v-if="
              block.category &&
              (translateStringOrLocale(block.category.name).value ==
                t(`supplierPage.commercialProduct`) ||
                translateStringOrLocale(block.category.name).value ==
                  t(`supplierPage.trustHealthApp`) ||
                translateStringOrLocale(block.category.name).value ==
                  t(`supplierPage.openSourceComponent`) ||
                translateStringOrLocale(block.category.name).value ==
                  t(`supplierPage.pegaComponent`))
            "
            :to="{ name: routeName, params: { blockId: block.blockId } }"
          >
            <div class="rounded bg-gnist-blue-light-light p-4">
              {{ translateStringOrLocale(block.name).value }}
              <p class="text-xs">
                {{ translateStringOrLocale(block.description).value }}
              </p>
            </div>
          </RouterLink>
        </template>
      </div>
      <div
        v-if="!organizationData?.isInternal"
        class="mt-4 flex flex-col items-stretch gap-1 text-gnist-black"
      >
        {{ t(`supplierPage.memberOf`) }}:
        <a
          href="https://gnist.sykehuspartner.no/blocks/2376361d-8823-42f0-99cf-03909f8e8afe"
          class="rounded bg-gnist-blue-light-light p-4"
        >
          Norway Health Tech
          <p class="text-xs">En teknologiklynge</p>
        </a>
      </div>
    </div>
  </div>
</template>
