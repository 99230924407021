<script lang="ts" setup>
import { defineAsyncComponent } from 'vue';
import ComponentPreview, { type selectorList } from './ComponentPreview.vue';
import { ParagraphShimmer } from 'vue3-shimmer';
// TORGST, 18.8.23: IMPORTANT! This needs to be loaded async to ensure correct order of CSS (at least in dev)!!!

const MarkdownEditor = defineAsyncComponent({
  loader: () => import('@/components/admin/MarkdownEditor.vue'),
  loadingComponent: ParagraphShimmer,
});

defineProps<{
  visiblePartSelector?: selectorList;
  markers?: selectorList | selectorList[];
}>();
</script>
<template>
  <ComponentPreview
    :visible-part-selector="visiblePartSelector"
    :markers="markers"
    loaded-observe-selector=".NormalEditor .toastui-editor-defaultUI"
    :show-padding="4"
    :marker-padding="2"
    marker-style="2px solid red"
  >
    <MarkdownEditor
      model-value=""
      editor-type="wysiwyg"
      editor-height="10rem"
    />
    <template #text>
      <slot />
    </template>
  </ComponentPreview>
</template>
