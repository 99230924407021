<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { ref, watchEffect } from 'vue';
import BlockToc from '@/components/block/BlockToc.vue';
import ContentPage from '@/components/ContentPage.vue';
import MarkdownRenderer from '@/components/block/MarkdownRenderer.vue';
import type { Schema } from '@/api/types';
import { useRoute } from 'vue-router';
import { nextTick } from 'vue';
import { getRouteTitle } from '@/utilities/routeUtils';

const { t, locale } = useI18n();
const container = ref<HTMLElement | null>(null);
const containers = ref<{ idx: number; el: HTMLElement }[]>([]);
const route = useRoute();
const titleId = `header.items.usermanual.${route.params.filename}`;
const md = ref<string | undefined>();

watchEffect(async () => {
  if (!container.value) return;
  const fileurl = `/documentation/${route.params.filename}_${locale.value}.md`;
  const resp = await fetch(fileurl);
  if (!resp.ok) {
    throw new Error(
      `Failed to fetch ${fileurl}: ${resp.status} ${resp.statusText}`,
    );
  } else if (!resp.headers.get('content-type')?.includes('text/markdown')) {
    throw new Error(
      `Invalid content-type: ${resp.headers.get('content-type')}`,
    );
  }
  md.value = await resp.text();
  await nextTick();
  document.title = getRouteTitle(t(titleId));
  containers.value = [{ idx: 0, el: container.value }];
});
</script>
<template>
  <ContentPage
    :title="t(titleId)"
    class="bg-gnist-blue-light-light [&_.max-w-screen-xl]:max-w-screen-2xl"
    :banner-classes="[
      'w-full max-w-screen-2xl px-8 before:block lg:grid lg:grid-cols-4 lg:gap-10 [&>div]:col-span-3 [&>div]:w-full [&>div]:lg:px-0',
    ]"
  >
    <div class="grow">
      <div class="mx-2 mt-0">
        <div class="w-full text-gnist-black">
          <div
            :class="[
              'pb-16',
              '2xl:mx-auto',
              'grow lg:grid lg:grid-cols-4 lg:gap-10',
            ]"
          >
            <div class="relative col-span-1 ml-auto mt-4 lg:pb-24">
              <BlockToc :containers="containers" />
            </div>
            <div ref="container" class="html-render col-span-3 mt-4 pt-5">
              <MarkdownRenderer
                v-if="md"
                :schema="{ showTitle: false } as Schema"
                :value="md"
                blob-location="public"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </ContentPage>
</template>
