<script lang="ts" setup>
import BlockPageBanner from '@/components/block/BlockPageBanner.vue';
import BlockRenderer from '@/components/block/BlockRenderer.vue';
import ImageRenderer from '@/components/block/ImageRenderer.vue';
import { type PageTemplateProps } from '@/api/types';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

defineProps<PageTemplateProps>();
const containers = ref<{ idx: number; el: HTMLElement }[]>([]);
</script>

<template>
  <div class="px-4 py-12 sm:px-6 lg:px-8" :class="[`bg-gnist-${bgColor}`]">
    <div class="max-w-screen-md sm:max-w-2xl md:max-w-4xl lg:max-w-5xl">
      <BlockPageBanner
        :title="block.name ?? ''"
        :block="block"
        :tags="block.tags ?? []"
        :class="[`bg-gnist-${bgColor}`]"
        :is-preview="!!isPreview"
        :no-left-margin="true"
      >
        <template #description>
          <p v-if="block.ownerOrg?.webpage">
            {{ t('clusterPage.webpage') }}:
            <a
              :href="block.ownerOrg.webpage"
              class="underline hover:text-gnist-gray-dark"
              target="_blank"
              >{{ block.ownerOrg.webpage }}</a
            >
          </p>
        </template>
      </BlockPageBanner>
      <div class="text-gnist-black" :class="[`bg-gnist-${bgColor}`]">
        <div
          class="gnist-page-width grow pb-0 lg:grid lg:grid-cols-3 lg:gap-10 lg:pb-16"
        >
          <div class="col-span-3">
            <!-- Content -->
            <div
              class="mx-5 mt-10"
              data-cy-id="contentContainer"
              :data-cy-state="!!block.content ? 'loaded' : 'not_loaded'"
            >
              <BlockRenderer
                v-model:containers="containers"
                :block="block"
                class="mt-8 first:mt-0"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mx-4 pb-4 pt-0 md:pb-7 md:pt-16 lg:w-1/2 lg:gap-8">
      <ImageRenderer
        v-if="block.ownerOrg?.logo"
        :value="{ fullUrl: block.ownerOrg.logo, alt: t('block.ownerLogo') }"
        class="my-10"
      />
    </div>
  </div>
</template>
