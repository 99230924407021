<script setup lang="ts">
import MaterialIcon from '@/components/MaterialIcon.vue';
import IconLink from '@/components/IconLink.vue';
import { useRouter } from 'vue-router';
import {
  useDynamicLabelTexts,
  type IInputLabelProps,
} from './forms/InputLabel.vue';

const props =
  defineProps<
    Pick<
      IInputLabelProps,
      | 'label'
      | 'dynamicTextKey'
      | 'tooltip'
      | 'placeholder'
      | 'description'
      | 'forName'
      | 'link'
      | 'tooltipClass'
    >
  >();

const texts = useDynamicLabelTexts(props);
const router = useRouter();
const linkTarget = props.link ? router.resolve(props.link) : undefined;
</script>

<template>
  <fieldset class="border p-4 ps-4">
    <legend class="flex">
      <h2>
        {{ texts.label }}
      </h2>
      <span
        v-if="texts.tooltip"
        :data-tip="texts.tooltip"
        class="tooltip static ml-1 whitespace-break-spaces font-normal sm:relative"
        :class="tooltipClass"
      >
        <MaterialIcon
          class="text-2xl hover:text-secondary"
          :aria-describedby="`desc_${texts.forName}`"
        >
          info
        </MaterialIcon>
      </span>
      <span
        v-if="linkTarget"
        class="static ml-1 whitespace-break-spaces font-normal sm:relative"
      >
        <IconLink
          label="buttons.external_help"
          icon="open_in_new"
          icon-class="text-2xl hover:text-gnist-gray"
          :aria-describedby="`desc_${texts.forName}`"
          :to="linkTarget"
          target="_blank"
        />
      </span>
      <span v-if="texts.tooltip" :id="`desc_${texts.forName}`" class="sr-only">
        {{ texts.tooltip }}
      </span>
    </legend>
    <slot />
  </fieldset>
</template>
