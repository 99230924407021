<script setup lang="ts">
import MaterialIcon from '@/components/MaterialIcon.vue';
import { WithUndefined, type VueClass } from '@/api/types';
import { computed } from 'vue';
import type { RouterLinkProps } from 'vue-router';
import { useAttrs } from 'vue';
import { useI18n } from 'vue-i18n';

const props = defineProps<
  RouterLinkProps & {
    label: string;
    iconClass?: VueClass;
    /** Sets the icon to support WCAG SC 2.5.5: The size of the target for pointer inputs is at least 44 by 44 CSS pixels */
    accessibleButton?: boolean;
    icon?: string;
  }
>();

const { t } = useI18n();

const routerLinkProps = computed(
  () =>
    WithUndefined<typeof props, RouterLinkProps>(props, {
      accessibleButton: undefined,
      label: undefined,
      icon: undefined,
      iconClass: undefined,
    }) as RouterLinkProps,
);
const attrs = useAttrs();
const finalLabel = computed(() =>
  attrs['target'] === '_blank'
    ? t(props.label) + t('accessibility.newTab')
    : props.label,
);
</script>

<template>
  <RouterLink v-bind="routerLinkProps">
    <MaterialIcon
      :accessible-button="accessibleButton"
      :label="finalLabel"
      :class="iconClass"
    >
      <slot>{{ icon }}</slot>
    </MaterialIcon>
  </RouterLink>
</template>
