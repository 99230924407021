<script setup lang="ts">
import ButtonComponent from '@/components/ButtonComponent.vue';
import Checkbox from '@/components/forms/InputCheckbox.vue';
import ModalComponent from '@/components/ModalComponent.vue';
import {
  updateNotification,
  useNotifications,
  type NotificationQueryParams,
  updateNotificationCount,
  deleteNotification,
} from '@/api/status';
import type { Notification } from '@/api/types';
import { formatDate } from '@/utilities/formatDate';
import { ParagraphShimmer } from 'vue3-shimmer';
import { useI18n } from 'vue-i18n';
import { translateStringOrLocale } from '@/i18n';
import { ref } from 'vue';

const { t } = useI18n();

const params = ref<NotificationQueryParams>({ includeRead: false });
const { result: notifications } = useNotifications(params);

const markReadUnread = async (
  notification: Pick<Notification, 'id' | 'read'> | undefined,
  read: boolean,
) => {
  await updateNotification(notification?.id, read);
  if (notification === undefined) {
    notifications.value?.map((n) => (n.read = read));
  } else notification.read = read;

  updateNotificationCount();
  setTimeout(() => {
    if (!notifications.value || params.value.includeRead) return;
    notifications.value = notifications.value.filter((n) => !n.read);
  }, 1500);
};

const notificationToDelete = ref<number | null>(null);
const deleteItem = async () => {
  if (notificationToDelete.value === null) return;
  const deleteId = notificationToDelete.value;
  notificationToDelete.value = null;
  await deleteNotification(deleteId);
  if (!notifications.value) return;
  notifications.value = notifications.value.filter((n) => n.id !== deleteId);
};
</script>

<template>
  <div class="w-full bg-gnist-gray-light-light text-gnist-black">
    <ParagraphShimmer
      v-if="notifications === null"
      :lines="20"
      :random-size="true"
      class="mx-auto w-full max-w-5xl"
    />

    <div v-else class="mx-2 py-8 md:mx-auto md:w-[50rem]">
      <div class="flex flex-col items-start md:flex-row">
        <Checkbox
          v-model="params.includeRead"
          label="notifications.hideRead"
          direction="horizontal"
          mode="toggle"
          inverted
        />
        <ButtonComponent
          :text="t('notifications.markAllRead')"
          @click.prevent="markReadUnread(undefined, true)"
        />
      </div>
      <div
        v-for="notification in notifications"
        :key="notification.id"
        class="m-1 ml-0 bg-gnist-white p-2 pl-0"
        :class="{ 'bg-opacity-50': notification.read }"
      >
        <div>
          <RouterLink :to="notification.link">
            <div
              class="grid grid-cols-[max-content_1fr_min-content]"
              :class="{ 'opacity-50': notification.read }"
            >
              <div
                class="flex w-24 items-end justify-center border-r-2 border-r-gnist-gray-light p-2 pb-1 md:items-center md:pb-2"
              >
                {{ formatDate(notification.time, false) }}
              </div>
              <div class="min-w-0 p-2 px-4">
                <div class="mb-2 flex items-center">
                  <div
                    v-if="!notification.read"
                    class="block h-2 w-2 rounded-2xl bg-gnist-red-dark p-1 text-sm leading-none text-white"
                  >
                    &nbsp;
                  </div>
                  <p
                    class="overflow-hidden text-ellipsis whitespace-nowrap"
                    :class="{ 'ml-2': !notification.read }"
                  >
                    {{
                      t(translateStringOrLocale(notification.title).value ?? '')
                    }}
                  </p>
                </div>
                <p
                  class="badge badge-outline badge-sm block max-w-full overflow-hidden text-ellipsis whitespace-nowrap border-gnist-gray bg-gnist-blue-light-light"
                >
                  {{
                    t(
                      `notifications.type.${notification.type}`,
                      notification.type,
                    )
                  }}
                </p>
              </div>
            </div>
          </RouterLink>
          <div
            class="absolute -top-1 bottom-0 left-0 mr-2 flex gap-2 md:left-auto md:right-0 md:top-0 md:items-center md:gap-4"
          >
            <ButtonComponent
              v-if="!notification.read"
              text="notifications.markRead"
              icon="mark_email_read"
              accessible-button
              @click.prevent="markReadUnread(notification, true)"
            />
            <ButtonComponent
              v-else
              text="notifications.markUnread"
              icon="mark_email_unread"
              accessible-button
              @click.prevent="markReadUnread(notification, false)"
            />
            <ButtonComponent
              text="notifications.delete"
              icon="delete"
              accessible-button
              @click.prevent="notificationToDelete = notification.id"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <ModalComponent
    :show-modal="notificationToDelete !== null"
    :title="t('notifications.confirmDelete')"
    @close="notificationToDelete = null"
    @handle-click="deleteItem"
  >
    <template #default>
      <p>
        {{ t('notifications.deletePermanently') }}
      </p>
    </template>
  </ModalComponent>
</template>
